import { Label } from '@shared/components/Label/Label';
import { Modal } from '@shared/components/Modal/Modal';
import { Text } from '@shared/components/Text/Text';

export const Ticket = () => {
  const headerButtons = [
    {
      id: 1,
      name: 'Ред.',
      action: () => {},
    },
  ];

  const footerButtons = [
    {
      id: 3,
      name: 'Отменить поручение',
      color: 'red',
      width: '',
      action: () => {},
    },
  ];

  return (
    <Modal
      modalSize="767px"
      title="Поручение"
      headerButtons={headerButtons}
      footerButtons={footerButtons}
      onClose={() => {}}
    >
      <div>
        <Label>Название</Label>
        <Text size={2} tag="b">
          Название задачи
        </Text>
      </div>

      <div>
        <Label>Описание</Label>
        <Text size={2} tag="p">
          Описание задачи, может быть несколько строк
          <Text tag="b"> и может быть жирненькми</Text>
        </Text>
      </div>

      <div>
        <Label>Срок исполнения</Label>
        <Text size={2} tag="b">
          12.02.2023 12:00
        </Text>
      </div>
    </Modal>
  );
};

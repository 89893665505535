import { Link } from 'react-router-dom';

const GuestListItem = ({ guest }) => {
  return (
    <div className="team__card team-card">
      <span className="team-card__state person-state">Гостевой доступ</span>
      <div className="team-card__user-wrapper">
        <Link to={`/team/guests/${guest.id}`} className="team-card__link">
          &nbsp;
        </Link>
        {/* <img
      className="team-card__userpic"
      src="assets/images/userpic.png"
      alt=""
    /> */}

        <span className="team-card__userpic _avatar-initials _avatar-initials--md">{guest.first_name[0]}</span>

        <div>
          <span className="team-card__username">{guest.first_name}</span>
          <p className="team-card__access">
            Доступ к <b>{guest.count}</b> задачам и проектам
          </p>
        </div>
      </div>
      <a href={guest.email} className="team-card__mail">
        <span className="social-link social-link--mail"></span>
        <span className="team-card__social-text">{guest.email}</span>
      </a>
    </div>
  );
};

export default GuestListItem;

import React from 'react';
import { useTranslation } from 'react-i18next';
//  компонент формы - шапка с крестиком
const ModalHeader = ({ onClose, title, addDepartment, onAdd, messageBasis, hasRightToEdit }) => {
  const { t } = useTranslation();
  return (
    <div className="modal__header">
      <h2 className="modal__title">{title}</h2>
      {messageBasis}
      {/* кнопка добавить департамент */}
      {addDepartment && hasRightToEdit && (
        <div className="modal__header-buttons">
          <button onClick={onAdd} className="modal__header-button">
            {t('FormButtons.add')}
          </button>
        </div>
      )}
      <button onClick={onClose} className="modal__close-modal"></button>
    </div>
  );
};

export default ModalHeader;

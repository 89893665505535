import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getUsers } from '../../../redux/features/usersSlice';
import Preloader from '../../../components/preloaders/Preloader';
import { useSocketContext } from '../../../contexts/SocketContext';
import TeamList from '../team-list/TeamList';
import TeamFilterForm from '../team-filter-form/TeamFilterForm';

// основная часть контента для страницы team
const TeamMain = ({ popupWindow }) => {
  const { socket, addToRoom, leaveRoom, onDepartmentChange, offDepartmentChange } = useSocketContext();
  const dispatch = useDispatch();

  useEffect(() => {
    if (socket?.connected) {
      // socket.emit("add_to_room", "employee_create_change", (response) => {
      //
      // });

      // socket.on("employee_changed", (data) => {
      //
      // });

      // socket.emit("add_to_room", "departments_list", (response) => {
      //
      // });

      if (dispatch) {
        addToRoom('departments_list');

        onDepartmentChange((_data, action) => {
          if (action === 'added' || action === 'edited') dispatch(getUsers());
        });

        return () => {
          leaveRoom('departments_list');
          offDepartmentChange();
        };
      }
    }
  }, [socket, onDepartmentChange, dispatch, addToRoom, leaveRoom, offDepartmentChange]);

  const { employees, departments, positions, isLoading } = useSelector((state) => state.users);
  const [filteredEmployees, setFilteredEmployees] = useState();

  // const filterEmployees = (position, dept, name) => {
  //   let filteredValues;
  //   if (position) {
  //     filteredValues = employees.filter((emp) => emp.position_id === +position);
  //   }
  //   if (dept) {
  //     filteredValues = employees.filter((emp) => emp.position_id === +position);
  //   }
  //   setFilteredEmployees(filteredValues);
  // };

  const filterEmployees = (position, dept, name) => {
    let filteredValues;
    filteredValues = employees.filter((emp) => {
      let doesMatch = true;
      if (
        name &&
        typeof name === 'string' &&
        !emp.first_name?.toLowerCase().includes(name.toLowerCase()) &&
        !emp.last_name?.toLowerCase().includes(name.toLowerCase())
      ) {
        doesMatch = false;
      }
      if (position && emp.position_id !== +position) doesMatch = false;
      if (dept && emp.department_id !== +dept) doesMatch = false;
      return doesMatch;
    });
    setFilteredEmployees(filteredValues);
  };

  // инфа для построения списка сотрудников
  // const [infoIsLoad, setInfoIsLoad] = useState();

  // useEffect(() => {
  //   if (socket?.connected) {
  //     socket.emit("add_to_room", "employees_list", (response) => {
  //
  //     });
  //   }
  // }, [socket]);

  // useEffect(() => {
  //   socket?.on("department_changed", (data, action) => {
  //
  //     // updateState(data, action);
  //   });
  // }, [socket]);

  // после изменений на сервере меняем стейт локально
  //
  // const updateState = (data, action) => {
  //
  //   switch (action) {
  //     case "added":
  //       // setDepartments((prev) => [...prev, data]);
  //       break;
  //     case "edited":
  //       // setDepartments((prev) => getEditedState(prev, data));
  //       break;
  //     case "deleted":
  //       // setTreeList((prev) => prev.filter((item) => item.id !== data.id));
  //       break;
  //     default:
  //     // return departments;
  //   }
  // };

  // заменяем отдел на отредактированный
  // const getEditedState = (arr, newItem) => {
  //   const ind = arr.findIndex(({ id }) => id === newItem.id);
  //   return [...arr.slice(0, ind), newItem, ...arr.slice(ind + 1)];
  // };

  return (
    <>
      <section className="team">
        <div className="team__container container">
          {isLoading && <Preloader />}
          <div className="team__tabs tabs-xl">
            {/* <Link to="#" className="tabs-xl__link tabs-xl__link--active">
              Работающие <span className="tabs-xl__value">9</span>{" "}
            </Link>
            <Link to="#" className="tabs-xl__link">
              Неработающие <span className="tabs-xl__value">2</span>{" "}
            </Link>
            <Link to="#" className="tabs-xl__link">
              Выходит в будущем <span className="tabs-xl__value">3</span>{" "}
            </Link>
            <Link to="#" className="tabs-xl__link">
              Запросы на поиск <span className="tabs-xl__value">7</span>{" "}
            </Link>
            <Link to="#" className="tabs-xl__link">
              Все <span className="tabs-xl__value">12</span>
            </Link> */}
          </div>

          {/* <Link to="/team/departments/1"> Сотрудник id 1 </Link> */}

          <TeamFilterForm
            department={{
              name: 'department',
              icon: true,
              placeholder: 'Отдел',
              type: 'text',
              options: departments ? [{ title: 'Отдел', id: '' }, ...departments] : [{ title: 'Отдел', id: '' }],
            }}
            position={{
              name: 'position',
              icon: true,
              label: 'Должность',
              placeholder: 'Должность',
              type: 'select',
              options: positions ? [{ title: 'Должность', id: '' }, ...positions] : [{ title: 'Должность', id: '' }],
            }}
            sorting={{
              name: 'position_id',
              icon: true,
              label: 'Сортировать по',
              placeholder: 'Сортировать по',
              // label: t(`${translateKey}.position`),
              type: 'select',
              options: ['по дате создания', 'по дате изменения'],
            }}
            filterEmployees={filterEmployees}
          />
          {employees && <TeamList popupWindow={popupWindow} data={filteredEmployees || employees}></TeamList>}
        </div>
      </section>
    </>
  );
};

export default TeamMain;

import { useEffect, useContext } from 'react';
import axios from 'axios';
import { useNavigate, useParams, Link } from 'react-router-dom';
import { useState } from 'react';

import { object, string, boolean, array, number } from 'yup';
import { useForm, useFieldArray } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import Preloader from '../../components/preloaders/Preloader';
import SnackbarContext from '../../contexts/SnackbarContext';
import ConfirmAction from '../../components/warnings/ConfirmAction';

const formSchema = object({
  accessFields: array().of(
    object({
      title: string(),
      type: string(),
      id: number(),
      access: array(),
      toEdit: boolean(),
      toDelete: boolean(),
    }),
  ),
});

const GuestFullProfileModal = () => {
  const {
    register,
    reset,
    control,
    handleSubmit,
    formState: { isDirty },
  } = useForm({
    resolver: yupResolver(formSchema),
    mode: 'onChange',
  });
  const { fields, update } = useFieldArray({
    control,
    name: 'accessFields',
  });

  const { guest_id } = useParams();
  const [guest, setGuest] = useState();

  // получить данные по текущему гостю
  useEffect(() => {
    axios
      .get(`/api/guest_full/${guest_id}`)
      .then((r) => {
        const { projects, tasks } = r.data.guest;
        reset({
          accessFields: [...projects, ...tasks].map((data) => {
            const dataType = data.project ? 'project' : 'task';
            const id = data[`${dataType}_id`];
            return {
              title: data[dataType],
              type: dataType,
              data_id: id,
              access: data.access,
              toEdit: false,
              toDelete: false,
              root_project_id: data.root_project_id,
            };
          }),
        });

        setGuest(r.data.guest);
      })
      .catch(() => {
        showSnackbar('Ошибка при получении данных гостя');
      });
  }, [guest_id, reset]);

  const navigate = useNavigate();

  const { showSnackbar } = useContext(SnackbarContext);

  const [isSubmitting, setIsSubmitting] = useState(false);
  // для отправки запроса по редактированию гостя
  const editGuest = async ({ accessFields }) => {
    setIsSubmitting(true);
    const requestPromises = [];
    for (const guestAccess of accessFields) {
      const requestBody = { guest_id };

      guestAccess.type === 'project'
        ? (requestBody.project_id = guestAccess.data_id)
        : (requestBody.task_id = guestAccess.data_id);

      if (guestAccess.toDelete) {
        requestPromises.push(
          axios.delete('/api/guest_rights/delete', { data: requestBody }).catch(() => {
            showSnackbar('Ошибка при обнолвении гостевого доступа');
          }),
        );
      } else {
        requestBody.rights = guestAccess.access;

        requestPromises.push(
          axios.put('/api/guests/edit', requestBody).catch(() => {
            showSnackbar('Ошибка при обновлении гостевого доступа');
          }),
        );
      }
    }
    await Promise.all(requestPromises);
    showSnackbar('Гостевой доступ обновлен', 'success');
    navigateOnClose();
  };

  const deleteAllAccessibleRights = () => {
    if (guest) {
      axios.delete('/api/guest/delete', {
        data: {
          guest_id: guest.id,
        },
      });
    }
  };

  const [confirmDeleteAll, setConfirmDeleteAll] = useState(false);

  const [modalClosePrompt, setModalClosePrompt] = useState(false);

  const navigateOnClose = () => navigate('/team/guests');

  const handleModalClose = () => {
    if (isDirty) {
      setModalClosePrompt(true);
    } else navigateOnClose();
  };

  return (
    <>
      <section className="modal">
        {modalClosePrompt && (
          <ConfirmAction
            cancel={() => setModalClosePrompt(false)}
            confirm={navigateOnClose}
            actionText="Уверены что хотите закрыть окно, не сохранив изменения?"
          />
        )}

        {confirmDeleteAll && (
          <ConfirmAction
            cancel={() => setConfirmDeleteAll(false)}
            confirm={deleteAllAccessibleRights}
            actionText="Вы собираетесь удалить гостевые доступы данного пользователя из всех проектов и задач, в которых Вы являетесь создателем или ответственным."
          />
        )}
        <div className="modal__wrapper">
          <div className="modal__inner">
            <section className="modal__body">
              <div className="modal__header">
                <h2 className="modal__title">Просмотр гостевого доступа</h2>
                <div className="modal__header-buttons">
                  <button className="modal__header-button" onClick={() => setConfirmDeleteAll(true)}>
                    Удалить
                  </button>
                </div>
                <button className="modal__close-modal" onClick={handleModalClose}></button>
              </div>
              {guest ? (
                <>
                  <div className="modal__row modal__row--start">
                    <div className="modal__col">
                      <label className="modal__label modal__label--bold">Имя</label>
                      <span>{guest.first_name}</span>
                    </div>
                    <div className="modal__col">
                      <label className="modal__label modal__label--bold">Электронная почта</label>
                      <span>{guest.email}</span>
                    </div>
                  </div>
                  <div className="modal__subtitle-wrapper">
                    <h3 className="modal__subtitle">Права доступа</h3>
                  </div>
                  <div className="modal__row modal__row--start">
                    <ol className="modal__access-list access-list">
                      {fields?.map((field, i) => {
                        if (field.toDelete) return '';

                        const url = `/projects/${
                          field.type === 'project' ? field.data_id : `${field.root_project_id}/tasks/${field.data_id}`
                        }`;

                        return (
                          <li className="access-list__item" key={field.id}>
                            <span className="access-list__link">
                              {`${field.type === 'project' ? 'Проект' : 'Задача'}: `}{' '}
                              <Link to={url}>{field.title}</Link>
                            </span>
                            <div className="access-list__button-box">
                              <button
                                className="access-list__button access-list__button--edit"
                                onClick={() => {
                                  update(i, {
                                    ...field,
                                    toEdit: !field.toEdit,
                                  });
                                }}
                              ></button>
                              <button
                                className="access-list__button access-list__button--delete"
                                onClick={() => {
                                  update(i, { ...field, toDelete: true });
                                }}
                              ></button>
                            </div>
                            <ul>
                              <li>
                                <input
                                  className="modal__checkbox"
                                  id={`accessFields.${i}.id` + 1}
                                  name="checkbox"
                                  type="checkbox"
                                  disabled={!field.toEdit}
                                  value="access_children"
                                  {...register(`accessFields.${i}.access`)}
                                />
                                <label
                                  htmlFor={`accessFields.${i}.id` + 1}
                                  className="modal__label modal__label--checkbox"
                                >
                                  Доступ к дочерним задачам
                                </label>
                              </li>
                              {/* <li>
                            <input
                              className="modal__checkbox"
                              id="1"
                              name="checkbox"
                              type="checkbox"
                              disabled="disabled"
                              checked="checked"
                            />
                            <label
                              htmlFor="1"
                              className="modal__label modal__label--checkbox"
                            >
                              Видит входящие данные
                            </label>
                          </li> */}
                              <li>
                                <input
                                  className="modal__checkbox"
                                  id={`accessFields.${i}.id` + 2}
                                  name="checkbox"
                                  type="checkbox"
                                  disabled={!field.toEdit}
                                  value="write"
                                  {...register(`accessFields.${i}.access`)}
                                />
                                <label
                                  htmlFor={`accessFields.${i}.id` + 2}
                                  className="modal__label modal__label--checkbox"
                                >
                                  Может писать
                                </label>
                              </li>

                              <li>
                                <input
                                  className="modal__checkbox"
                                  id={`accessFields.${i}.id` + 3}
                                  name="checkbox"
                                  type="checkbox"
                                  disabled={!field.toEdit}
                                  value="vote"
                                  {...register(`accessFields.${i}.access`)}
                                />
                                <label
                                  htmlFor={`accessFields.${i}.id` + 3}
                                  className="modal__label modal__label--checkbox"
                                >
                                  Может голосовать
                                </label>
                              </li>
                              <li>
                                <input
                                  className="modal__checkbox"
                                  id={`accessFields.${i}.id` + 4}
                                  key={field.id}
                                  name="checkbox"
                                  type="checkbox"
                                  disabled={!field.toEdit}
                                  value="add_files"
                                  {...register(`accessFields.${i}.access`)}
                                />
                                <label
                                  htmlFor={`accessFields.${i}.id` + 4}
                                  className="modal__label modal__label--checkbox"
                                >
                                  Может добавлять картинки и файлы
                                </label>
                              </li>
                            </ul>
                          </li>
                        );
                      })}
                    </ol>
                  </div>
                  <div className="modal__button-box">
                    <button
                      className="modal__button modal__button--create"
                      onClick={handleSubmit(editGuest)}
                      disabled={!isDirty || isSubmitting}
                    >
                      Сохранить
                    </button>
                    <button className="modal__button modal__button--cancel" onClick={handleModalClose}>
                      Отмена
                    </button>
                  </div>
                </>
              ) : (
                <Preloader />
              )}
            </section>
            )
          </div>
        </div>
      </section>
    </>
  );
};

export default GuestFullProfileModal;

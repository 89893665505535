import axios from 'axios';
import { useContext, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';

import { randomSequence, useEventTriggerOnEscPress } from '../../utilize/helper-functions';
import { getStorageTree } from '../../redux/features/storageSlice';
import SnackbarContext from '../../contexts/SnackbarContext';

import ConfirmAction from '../warnings/ConfirmAction';

import ReplaceFilesConfirmModal from './ReplaceFilesConfirmModal';

const AddFilesModal = ({ close, storageIdToReq, folderId }) => {
  const [filesToUpload, setFilesToUpload] = useState([]);

  const [confirmClose, setConfirmClose] = useState();
  const [confirmFilesReplacement, setConfirmFilesReplacement] = useState();

  const handleClose = () => {
    if (filesToUpload.length) return setConfirmClose(true);
    close();
  };

  useEventTriggerOnEscPress(handleClose);

  const formId = useRef(randomSequence());

  const dispatch = useDispatch();
  const { showSnackbar } = useContext(SnackbarContext);

  const [isSubmitting, setIsSubmitting] = useState();
  const abortRequest = useRef(null);

  const hasCompletedUploads = () => {
    return filesToUpload.some((fileData) => fileData.status === 'done');
  };

  const submitFiles = async () => {
    let isCanceled = false;
    for (const uploadingFile of filesToUpload) {
      const formData = new FormData();
      formData.append('files', uploadingFile.file, uploadingFile.file.name);
      formData.append('form_id', formId.current);
      if (folderId) formData.append('folder_id', folderId);
      else formData.append('storage_id', storageIdToReq);

      let progressPercentage = 0;
      abortRequest.current = new AbortController();
      await axios
        .post('/api/storage_files/add', formData, {
          onUploadProgress: (progressEvent) => {
            const currProgress = parseInt(Math.round(progressEvent.loaded * 100) / progressEvent.total);
            if (currProgress !== progressPercentage) {
              progressPercentage = currProgress;

              setFilesToUpload((files) =>
                files.map((f) => {
                  if (f.localId === uploadingFile.localId) {
                    return { ...f, progress: currProgress };
                  }
                  return f;
                }),
              );
              // uploadProgress(fileWithId.localId, currProgress);
            }
          },
          signal: abortRequest.current.signal,
        })
        .then(() => {
          setFilesToUpload((files) =>
            files.map((f) => {
              if (f.localId === uploadingFile.localId) {
                return { ...f, status: 'done' };
              }
              return f;
            }),
          );
          abortRequest.current = null;
        })
        // eslint-disable-next-line
        .catch((e) => {
          setIsSubmitting(false);
          abortRequest.current = null;
          if (e.message === 'canceled') {
            isCanceled = true;
            return;
          }
          showSnackbar(`Возникла ошибка при добавлении файла ${uploadingFile.file.name}`);
        });
      if (isCanceled) break;
    }
    if (!isCanceled) {
      dispatch(getStorageTree({ storageId: storageIdToReq, showSnackbar }));
    }
    close();
  };

  // проверка наличия файлов с таким названием в этой папке на сервере
  const checkFilesExistence = async () => {
    const reqBody = {
      folder_id: folderId ? folderId : null,
      storage_id: folderId ? null : storageIdToReq,
      files: filesToUpload.map((fileData) => fileData.file.name),
    };

    setIsSubmitting(true);
    await axios
      .post('/api/storage_file_exist', reqBody)
      .then((r) => {
        const { storage_files_exist } = r.data;
        if (storage_files_exist.length) {
          return setConfirmFilesReplacement(storage_files_exist);
        }
        submitFiles();
      })
      .catch(() => {
        showSnackbar('Ошибка при проверке наличия таких файлов');
        setIsSubmitting(false);
      });
  };

  const handleFilesAdd = (filesArr) => {
    if (filesArr.length) {
      setFilesToUpload((f) =>
        [...f, ...filesArr].map((file) => {
          if (file.localId) return file;
          return {
            file: file,
            progress: 0,
            localId: randomSequence(),
            status: 'wait',
          };
        }),
      );
    }
  };

  return (
    <>
      <section className="modal">
        <div className="modal__wrapper">
          <div className="modal__inner">
            <section className="modal__body">
              <div className="modal__header">
                <h2 className="modal__title">Добавить файлы</h2>
                <button className="modal__close-modal" onClick={handleClose}></button>
              </div>

              <ul className="storage-list">
                {filesToUpload.map((fileData, i) => (
                  <li key={i} className={`catalog-file catalog-file--upload catalog-file--upload-${fileData.status}`}>
                    <p className="catalog-file__name">{fileData.file.name}</p>
                    <button
                      onClick={() => setFilesToUpload(filesToUpload.filter((file, ind) => ind !== i))}
                      disabled={isSubmitting}
                      className="catalog-file__button-delete"
                    ></button>
                    <div className="progress-bar">
                      <div className="progress-bar__fill" style={{ width: `${fileData.progress}%` }}></div>
                    </div>
                  </li>
                ))}
              </ul>

              <section
                className="modal__row drag-drop"
                onDragOver={(e) => {
                  e.preventDefault();
                }}
                onDrop={async (e) => {
                  e.preventDefault();
                  if (isSubmitting) return;
                  if (e.dataTransfer.files.length) {
                    const items = e.dataTransfer.items;
                    // проверить поддерживается ли браузером функция webkitGetAsEntry
                    if (typeof items[0].webkitGetAsEntry !== 'function') {
                      handleFilesAdd(e.dataTransfer.files);
                    } else {
                      const filesToDrop = [];
                      const filePromises = [];
                      for (let i = 0; i < items.length; i++) {
                        const entry = items[i].webkitGetAsEntry();
                        if (!entry?.isFile) continue;
                        const filePromise = new Promise((resolve, reject) => {
                          entry.file(
                            (file) => {
                              filesToDrop.push(file);
                              resolve();
                            },
                            (e) => reject(e),
                          );
                        });
                        filePromise.catch(() => {
                          showSnackbar('Возникла ошибка при чтении файла');
                        });
                        filePromises.push(filePromise);
                      }

                      await Promise.all(filePromises);

                      handleFilesAdd(filesToDrop);
                    }
                  }
                  // setDragging(false);
                }}
              >
                <label className="drag-drop__label" htmlFor="upload"></label>
                <input
                  className="drag-drop__input"
                  type="file"
                  id="upload"
                  multiple
                  onChange={(e) => handleFilesAdd(e.target.files)}
                />

                <p className="drag-drop__text">
                  Перетяните файлы
                  <br />
                  или кликните, чтобы выбрать
                </p>
              </section>

              <div className="modal__button-box">
                <button
                  className="modal__button modal__button--create"
                  disabled={!filesToUpload.length || isSubmitting}
                  onClick={checkFilesExistence}
                >
                  Добавить
                </button>
                <button className="modal__button modal__button--cancel" onClick={handleClose}>
                  Отмена
                </button>
              </div>
            </section>
          </div>
        </div>
      </section>

      {confirmClose && (
        <ConfirmAction
          confirm={() => {
            if (abortRequest.current) abortRequest.current.abort();
            if (hasCompletedUploads()) {
              dispatch(getStorageTree({ storageId: storageIdToReq, showSnackbar }));
            }
            close();
          }}
          cancel={() => setConfirmClose(false)}
          actionText="Вы уверены, что хотите закрыть окно не сохранив изменения?"
        />
      )}

      {confirmFilesReplacement && (
        <ReplaceFilesConfirmModal
          filesToReplace={confirmFilesReplacement}
          close={() => {
            setConfirmFilesReplacement(null);
            setIsSubmitting(false);
          }}
          confirm={() => {
            submitFiles();
            setConfirmFilesReplacement(null);
          }}
        />
      )}
    </>
  );
};

export default AddFilesModal;

import { useCallback, useEffect, useMemo, useState, useRef } from 'react';
import Cookies from 'js-cookie';

import { useDispatch, useSelector } from 'react-redux';

import { AuthContext } from '../../contexts/AuthContext';
import { useSocketContext } from '../../contexts/SocketContext';
import { clearErrors } from '../../redux/features/usersSlice';
// import useAuth from "../../hooks/useAuth";
// import api from "../../services/api";

function AuthProvider(props) {
  const [isLoaded, setIsLoaded] = useState(false);
  const [user, setUserData] = useState(null);
  const [token, setTokenData] = useState(null);

  const setToken = useCallback((tokenData) => {
    setTokenData(tokenData);

    if (tokenData) {
      //
      Cookies.set('auth-token', tokenData);
    } else {
      Cookies.remove('auth-token');
    }
  }, []);

  // когда в форме логина получаем user и token, отправляем колбэк => все улетело в куки и контекст, становясь доступным всему приложению
  const setUser = useCallback((userData) => {
    setUserData(userData);

    if (userData) {
      Cookies.set('auth-user', JSON.stringify(userData));
    } else {
      Cookies.remove('auth-user');
    }
  }, []);

  const loadData = useCallback(() => {
    //
    const tokenData = Cookies.get('auth-token');
    let userData = Cookies.get('auth-user');
    try {
      userData = JSON.parse(userData);
      setTokenData(tokenData);
      setUserData(userData);
    } catch (e) {}

    setIsLoaded(true);
  }, []);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const { deinitSocket } = useSocketContext();
  const dispatch = useDispatch();

  const logOut = useCallback(() => {
    setUser(null);
    setToken(null);
    deinitSocket();
    dispatch(clearErrors());
  }, [setToken, setUser, deinitSocket, dispatch]);

  const rightTypes = useRef({
    team_edit: 'team_edit',
    positions_edit: 'positions_edit',
    departments_edit: 'departments_edit',
    salary_view: 'salary_view',
    add_news: 'add_news',
  });

  const isUserRightful = useCallback(
    (rightToCheck) => {
      if (user?.rights?.includes('leader') || user?.rights?.includes(rightToCheck)) {
        return true;
      }
      return false;
    },
    [user],
  );

  const contextValue = useMemo(
    () => ({
      isLoaded,
      user,
      token,
      setUser,
      setToken,
      logOut,
      isUserRightful,
      rightTypes: rightTypes.current,
    }),
    [isLoaded, user, token, setToken, setUser, logOut, isUserRightful],
  );

  // если, при получении данных обо всех юзерах, выясняется что токен не корректен,
  // то делаем logout
  const getUsersError = useSelector((state) => state.users.error);

  useEffect(() => {
    if (getUsersError === 'TOKEN ERROR') {
      logOut();
    }
  }, [getUsersError, logOut]);

  return <AuthContext.Provider value={contextValue}>{props.children}</AuthContext.Provider>;
}

export default AuthProvider;

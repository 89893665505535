const ReplaceFilesConfirmModal = ({ filesToReplace, close, confirm }) => {
  return (
    <section className="modal">
      <div className="modal__wrapper">
        <div className="modal__inner">
          <section className="modal__body">
            <div className="modal__header">
              <h2 className="modal__title">
                Файлы с таким названием уже есть в этой папке. Заменить текущие файлы новыми?
              </h2>
              <button className="modal__close-modal"></button>
            </div>
            <div className="modal__row">
              <ul className="storage-list">
                {filesToReplace.map((fileName, i) => (
                  <li key={i} className="storage__catalog-file catalog-file catalog-file--replace">
                    <p className="catalog-file__name">{fileName}</p>
                    {/* <p className="catalog-file__weight">20 мб</p>
                    <p className="catalog-file__date">28.02.2023</p>
                    <span href="#" className="catalog-file__author">
                      Александр Длиннофамильев
                    </span> */}
                  </li>
                ))}
              </ul>
            </div>

            <div className="modal__button-box">
              <button className="modal__button modal__button--create" onClick={confirm}>
                Заменить
              </button>
              <button className="modal__button modal__button--cancel" onClick={close}>
                Отмена
              </button>
            </div>
          </section>
        </div>
      </div>
    </section>
  );
};

export default ReplaceFilesConfirmModal;

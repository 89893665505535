import React from 'react';

import { FilterButton, StyledList } from './styles';
import { namesButton } from './lib';

export const Tabs = ({ searchParams, setSearchParams }) => {
  return (
    <StyledList>
      {namesButton.map((btn) => {
        return (
          <FilterButton
            key={btn.key}
            active={searchParams.get('filter') === btn.key}
            onClick={() => setSearchParams({ filter: btn.key.toString(), page: 1 })}
          >
            {btn.name}
          </FilterButton>
        );
      })}
    </StyledList>
  );
};

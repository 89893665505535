import { useEffect, useState, memo, useCallback } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import axios from 'axios';
import ReactHTMLParser from 'html-react-parser';

import useAuth from '../../../hooks/useAuth';
import UserProfileModal from '../../../windows/profile/UserProfileModal';

import Preloader from '../../preloaders/Preloader';

import { selectGuestProjectRights } from '../../../redux/features/projectsSlice';

import ProjectTree from './ProjectTree';
import SideMenuMembers from './sideMenuComponents/SideMenuMembers';
import SideMenuTags from './sideMenuComponents/SideMenuTags';
import SideMenuProjectFiles from './sideMenuComponents/SideMenuProjectFiles';

import { renderProjectOrTaskName } from './SideMenu';
import AsideUserCard from './sideMenuComponents/AsideUserCard';

const InfoSidePanel = ({ sideMenu, openSideMenu, projectData }) => {
  const { projectId, taskId } = useParams();
  const { t } = useTranslation();

  const [dataType, setDataType] = useState(null);
  const [panelData, setPanelData] = useState(null);
  const [userProfile, showUserProfile] = useState();
  const [projectTree, showProjectTree] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errorLoading, setErrorLoading] = useState(false);

  // каждый раз, когда нажимается кнопка открытия панели, производится reset данных
  useEffect(() => {
    if (sideMenu === 'info' && !panelData && dataType && projectData && !isLoading) {
      setIsLoading(true);
      axios
        .get(`/api/sidebar_info/${dataType}/${projectData.id}`)
        .then((response) => {
          if (dataType === 'project') {
            setPanelData(response.data.project);
          } else {
            setPanelData(response.data.task);
          }
          setIsLoading(false);
        })
        .catch((e) => {
          setIsLoading(false);
          setErrorLoading(true);
        });
    } else if (sideMenu !== 'info' && panelData) {
      setTimeout(() => {
        setPanelData(null);
        setDataType(null);
        showProjectTree(false);
        setSidebarDataType(null);
      }, 1000);
    }
  }, [sideMenu, dataType, projectData, panelData, isLoading]);

  useEffect(() => {
    if (taskId && dataType !== 'task') setDataType('task');
    if (!taskId && dataType !== 'project') setDataType('project');
  }, [taskId, dataType]);

  const [sidebarDataType, setSidebarDataType] = useState();

  const showRelevantTaskInfo = useCallback((dataType, dataId) => {
    setPanelData(null);
    showProjectTree(false);
    setErrorLoading(false);
    setIsLoading(true);
    axios
      .get(`/api/sidebar_info/${dataType}/${dataId}`)
      .then((response) => {
        if (dataType === 'project') {
          setPanelData(response.data.project);
        } else {
          setPanelData(response.data.task);
        }
        setIsLoading(false);
        setSidebarDataType(dataType);
      })
      .catch((e) => {
        setPanelData({});
        setIsLoading(false);
        setErrorLoading(true);
      });
  }, []);

  const renderFiles = () => {
    if (panelData) {
      let files;
      if (panelData.project_files) {
        files = panelData.project_files;
      } else {
        files = panelData.task_files;
      }
      return <SideMenuProjectFiles files={files} dataType={dataType} dataId={panelData.id} />;
    }
  };

  const renderGoals = (goals) => {
    if (!goals) return null;
    return (
      <>
        <p className="aside__subtitle aside__subtitle--button">
          {t('common.result')}
          {/* <button className="aside__button-edit"></button> */}
        </p>
        <ul className="aside__incoming-data incoming-data">
          {goals.map((goal) => (
            <li className="incoming-data__item" key={goal.id}>
              <div className="incoming-data__header">
                <p className="incoming-data__title">{goal.description}</p>
                <p className="incoming-data__type">{t(`Project.result.${goal.type}`)}</p>
              </div>
              {/* <p className="incoming-data__done">Исполнено</p> */}
            </li>
          ))}
        </ul>
      </>
    );
  };

  const auth = useAuth();
  const guestRights = useSelector(selectGuestProjectRights(sidebarDataType || dataType, panelData?.id));

  return (
    <>
      {userProfile && <UserProfileModal profileId={userProfile} onClose={() => showUserProfile(null)} />}

      <aside data-aside="info" className={`aside ${sideMenu === 'info' ? '_active' : ''}`}>
        <div className="aside__header">
          <span className="aside__title aside__title--info">{t('common.info')}</span>
          <button
            className="aside__button-arrow"
            onClick={() => {
              setTimeout(() => {
                setPanelData(null);
                setDataType(null);
              }, 1000);
              openSideMenu(null);
            }}
          ></button>
        </div>
        <div className="aside__body">
          {isLoading && <Preloader />}
          {errorLoading && <div>{t('ErrorMessages.default')}</div>}
          {panelData && (
            <>
              {projectTree ? (
                <ProjectTree
                  update={sideMenu === 'info'}
                  showRelevantTaskInfo={showRelevantTaskInfo}
                  showProjectTree={showProjectTree}
                />
              ) : (
                <>
                  {(!auth?.user?.is_guest || guestRights?.includes('access_children')) && (
                    <button
                      style={{ border: 0, backgroundColor: 'transparent' }}
                      to="#"
                      className="aside__backward"
                      onClick={() => showProjectTree(true)}
                    >
                      Структура проекта
                    </button>
                  )}

                  <p className="aside_parent-task">
                    {renderProjectOrTaskName(panelData, taskId, sidebarDataType, projectData)}
                  </p>

                  {sidebarDataType === 'project' && taskId && (
                    <Link to={`/projects/${projectId}`} className="aside__link">
                      {t('Project.open_project')}
                    </Link>
                  )}

                  {sidebarDataType === 'task' && panelData && panelData.id !== +taskId && (
                    <Link to={`/projects/${projectId}/tasks/${panelData.id}`} className="aside__link">
                      {t('Project.open_task')}
                    </Link>
                  )}

                  <p className="aside__subtitle">{t('Project.description')}</p>

                  {typeof panelData.description === 'string' ? ReactHTMLParser(panelData.description) : ''}

                  {renderFiles()}

                  {/* <p className="aside__subtitle">Входные данные</p>
            <ul className="aside__incoming-data incoming-data">
              <li className="incoming-data__item">
                <div className="incoming-data__header">
                  <p className="incoming-data__title">Дизайн проект</p>
                  <p className="incoming-data__type">файл</p>
                </div>
                <div className="incoming-data__body attach">
                  <Link
                    to="#"
                    className="attach__item"
                    href="assets/docs/sample.pdf"
                    download
                  >
                    <div className="attach__image attach__image--xls"></div>
                    <span>Название файла.xls</span>
                  </Link>
                </div>
              </li>
              <li className="incoming-data__item">
                <div className="incoming-data__header">
                  <p className="incoming-data__title">Описание дизайна</p>
                  <p className="incoming-data__type">текст</p>
                </div>
                <div className="incoming-data__body">
                  Здесь может быть очень большое количество текста, потребуется
                  скролл. Здесь может быть очень большое количество текста,
                  потребуется скролл. Здесь может быть очень большое количество
                  текста, потребуется скролл. Здесь может быть очень большое
                  количество текста, потребуется скролл. Здесь может быть очень
                  большое количество текста, потребуется скролл. Здесь может быть
                  очень большое количество текста, потребуется скролл. Здесь может
                  быть очень большое количество текста, потребуется скролл. Здесь
                  может быть очень большое количество текста, потребуется скролл.
                </div>
              </li>
            </ul> */}

                  {renderGoals(panelData.project_goals || panelData.task_goals)}

                  <p className="aside__subtitle">{t('Project.responsible')}</p>

                  <AsideUserCard
                    userId={panelData.responsible_id}
                    lastName={panelData.responsible_last_name}
                    firstName={panelData.responsible_first_name}
                    showUserProfile={showUserProfile}
                  />

                  <p className="aside__subtitle">{t('Project.executor')}</p>
                  <AsideUserCard
                    userId={panelData.executor_id}
                    lastName={panelData.executor_last_name}
                    firstName={panelData.executor_first_name}
                    showUserProfile={showUserProfile}
                  />

                  <SideMenuMembers
                    members={panelData.project_members || panelData.task_members}
                    showUserProfile={showUserProfile}
                  />

                  <SideMenuTags tags={panelData.project_tags || panelData.task_tags} />

                  {/* <p className="aside__subtitle">{t("Project.roles")}</p>
                <ul className="aside__roles roles">
                  <li className="roles__item roles__item--set">
                    Программист backend
                  </li>
                  <li className="roles__item">Программист frontend</li>
                  <li className="roles__item roles__item--set">Дизайнер</li>
                </ul> */}
                </>
              )}
            </>
          )}
        </div>
      </aside>
    </>
  );
};

export default memo(InfoSidePanel);

import { createContext, useContext, useEffect, useRef, useState } from 'react';
import io from 'socket.io-client';

import { useDispatch } from 'react-redux';

import { server_url } from '../settings/base-url';
import { getOnlineUsers, userConnected, userDisconnected } from '../redux/features/usersSlice';
import useAuth from '../hooks/useAuth';

import SnackbarContext from './SnackbarContext';

const SocketContext = createContext({
  socket: null,
  initSocket: () => {},
  deinitSocket: () => {},
  addToRoom: () => {},
  leaveRoom: () => {},

  //ПРОЕКТЫ, ЗАДАЧИ
  startTask: () => {},
  finishTask: () => {},
  unfinishTask: () => {},
  onTaskStatusChange: () => {},

  // ЧАТ СООБЩЕНИЯ
  likeMessage: () => {},
  dislikeMessage: () => {},
  manageMessagePin: () => {},
  sendMessage: () => {},
  sendMessageEdit: () => {},
  deleteMessage: () => {},
  recoverMessage: () => {},
  sendResultFeedback: () => {},

  // ЮЗЕРЫ, ОТДЕЛЫ
  onDepartmentChange: () => {},
  offDepartmentChange: () => {},

  // ИЗБРАННОЕ
  addToFavorites: () => {},
});

const SocketContextProvider = ({ children }) => {
  const socketConnection = useRef();
  const isConnecting = useRef();
  const [socket, setSocket] = useState();
  const { showSnackbar } = useContext(SnackbarContext);

  const dispatch = useDispatch();
  const auth = useAuth();

  const deinitSocket = () => {
    if (socket) {
      if (socket?.connected) {
        socket.disconnect();
      }
      setSocket(null);
      dispatch(userDisconnected('all'));
    }
    isConnecting.current = false;
    socketConnection.current = null;
  };

  const initSocket = (auth) => {
    if (!isConnecting.current) {
      isConnecting.current = true;
      socketConnection.current = io(server_url, {
        auth: { token: auth },
        transports: ['websocket', 'polling', 'flashsocket'],
      });

      socketConnection.current?.on('connect', () => {
        if (socketConnection.current?.connected) {
          setSocket(socketConnection.current);
          dispatch(getOnlineUsers());
        }
        isConnecting.current = false;
      });

      // ПОТОМ УДАЛИТЬ
      // socketConnection.current?.on('employee_connected', () => {});

      socketConnection.current?.on('disconnect', () => {
        dispatch(userDisconnected('all'));
        setSocket(null);
      });
    }
  };

  useEffect(() => {
    if (socket?.connected) {
      socket.on('employee_connected', (userId) => {
        if (userId === auth?.user?.id) return;

        dispatch(userConnected(userId));
      });

      socket.on('employee_disconnected', (userId) => {
        dispatch(userDisconnected(userId));
      });

      return () => {
        socket.off('employee_connected');
        socket.off('employee_disconnected');
      };
    }
  }, [socket, dispatch, auth]);

  const addToRoom = (roomName) => {
    if (socket?.connected) {
      socket.emit('add_to_room', roomName, () => {
        // joinedRooms.current.push(roomName);
      });
    }
  };

  const leaveRoom = (roomName) => {
    if (socket?.connected) {
      socket.emit('leave_room', roomName, () => {
        // joinedRooms.current = joinedRooms.current.filter((r) => r !== roomName);
      });
    }
  };

  // ПРОЕКТЫ, ЗАДАЧИ

  const startTask = (data) => {
    if (socket?.connected) {
      socket.emit('project_task_start', data, () => {});
    }
  };

  const finishTask = (data) => {
    if (socket?.connected) {
      socket.emit('project_task_finish', data, () => {});
    }
  };

  const unfinishTask = (data) => {
    socket.emit('project_task_return', data, () => {});
  };

  const onTaskStatusChange = (callback) => {
    if (socket?.connected) {
      socket.on('project_task_status_changed', callback);
    }
  };

  // ЧАТ СООБЩЕНИЯ
  const sendMessage = (socket, data, callback) => {
    socket.emit('send_message', data, callback);
  };

  const sendMessageEdit = (data, callback) => {
    if (socket && socket.connected) {
      socket.emit('edit_message', data, callback);
    }
  };

  const cancelLikeDislike = (messageId) => {
    socket.emit('cancel_like_dislike', messageId, () => {});
  };

  const likeMessage = (likesState, messageId) => {
    if (socket && socket.connected) {
      if (!likesState.iLiked) {
        socket.emit('message_like', messageId, () => {});
      } else cancelLikeDislike(messageId);
    }
  };

  const dislikeMessage = (likesState, messageId) => {
    if (socket && socket.connected) {
      if (!likesState.iDisliked) {
        socket.emit('message_dislike', messageId, () => {});
      } else cancelLikeDislike(messageId);
    }
  };

  const manageMessagePin = (isPinned, messageId) => {
    if (socket && socket.connected) {
      if (isPinned) {
        socket.emit('message_unpin', messageId, () => {});
      } else {
        socket.emit('message_pin', messageId, () => {});
      }
    }
  };

  const deleteMessage = (messageId) => {
    if (socket && socket.connected) {
      socket.emit('message_delete', messageId, () => {});
    }
  };

  const recoverMessage = (messageId) => {
    if (socket && socket.connected) {
      socket.emit('message_recover', messageId, () => {});
    }
  };

  // ЮЗЕРЫ, ОТДЕЛЫ
  const onDepartmentChange = (callback) => {
    if (socket?.connected) {
      socket.on('department_changed', callback);
    }
  };

  const offDepartmentChange = () => {
    if (socket?.connected) {
      socket.off('department_changed');
    }
  };

  const sendResultFeedback = (chat_goal_result_id, feedback) => {
    const data = { chat_goal_result_id, result: feedback };

    if (socket?.connected) {
      socket.emit('goal_accept', data);
    } else {
      showSnackbar('Нет соединения с сервером');
    }
  };

  const addToFavorites = (data) => {
    if (socket?.connected) {
      socket.emit('starred_add', data, (res) => {
        console.log('FAVOR RES: ', res);
      });
    }
  };

  return (
    <SocketContext.Provider
      value={{
        socket,
        initSocket,
        deinitSocket,
        addToRoom,
        leaveRoom,
        startTask,
        finishTask,
        unfinishTask,
        onTaskStatusChange,
        likeMessage,
        dislikeMessage,
        manageMessagePin,
        sendMessage,
        sendMessageEdit,
        deleteMessage,
        recoverMessage,
        onDepartmentChange,
        offDepartmentChange,
        sendResultFeedback,
        addToFavorites,
      }}
    >
      {children}
    </SocketContext.Provider>
  );
};

const useSocketContext = () => {
  const context = useContext(SocketContext);

  if (context) {
    return context;
  }
  throw new Error('useSocketContext must be used within a SocketContextProvider');
};

export { useSocketContext, SocketContextProvider };

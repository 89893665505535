import { useEffect, useContext } from 'react';
import axios from 'axios';
import { useState } from 'react';

import { useParams } from 'react-router-dom';
import SnackbarContext from 'src/contexts/SnackbarContext';

import Preloader from '../../../components/preloaders/Preloader';

import GuestFullProfileModal from '../../../windows/profile/GuestFullProfileModal';

import GuestListItem from './GuestListItem';

const GuestList = () => {
  const { guest_id } = useParams();
  const [guestList, setGuestList] = useState(null);
  const [filteredGuestList, setFilteredGuestList] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const { showSnackbar } = useContext(SnackbarContext);

  useEffect(() => {
    axios
      .get('/api/guests_list/')
      .then((r) => {
        setGuestList(r.data.guests);
      })
      .catch(() => {
        showSnackbar('Возникла ошибка при получении списка гостевых доступов');
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const searchGuests = (e) => {
    if (e.target.value) {
      return setFilteredGuestList(
        guestList.filter((guest) => guest.first_name.toLowerCase().includes(e.target.value.toLowerCase())),
      );
    }
    return setFilteredGuestList(null);
  };

  return (
    <>
      {guest_id && <GuestFullProfileModal />}

      <section className="team">
        <div className="team__container container">
          <form className="team__filter team-filter">
            <div className="team-filter__col">
              <div className="search-box">
                <input className="search-box__input" placeholder="Поиск по гостям" onChange={searchGuests} />
                <button type="submit" className="search-box__button"></button>
              </div>
            </div>
            {/* <div className="team-filter__col">
            <div className="team-filter__dropdown team-filter__dropdown--wide dropdown">
              <div className="dropdown__input" data-selected="1">
                Сортировать: дата создания
              </div>
              <ul className="dropdown__select select">
                <li className="select__item" data-option="1">
                  Сортировать: дата создания
                </li>
                <li className="select__item" data-option="2">
                  Сортировать: дата 2
                </li>
                <li className="select__item" data-option="3">
                  Сортировать: дата 3
                </li>
              </ul>
            </div>
          </div> */}
          </form>
          {isLoading && <Preloader />}

          {Array.isArray(filteredGuestList) &&
            filteredGuestList.map((guest, i) => <GuestListItem guest={guest} key={i} />)}

          {!filteredGuestList && Array.isArray(guestList) && (
            <div className="team__list">
              {guestList.map((guest, i) => (
                <GuestListItem guest={guest} key={i} />
              ))}
            </div>
          )}
        </div>
      </section>
    </>
  );
};

export default GuestList;

import useAuth from '../../../../hooks/useAuth';
import { files_url } from '../../../../settings/base-url';
import AttachedFile from '../../../files/AttachedFile';

const SideMenuFiles = ({ files, dataType = 'project', dataId }) => {
  const auth = useAuth();

  if (files?.length) {
    return (
      <div>
        <p className="aside__subtitle"> Файлы</p>
        <div className="attach">
          {files.map((file, i) => {
            const url = `${files_url}/${dataType === 'project' ? 'projects' : 'tasks'}/files/${dataId}/${
              file.file
            }?token=${auth.token}`;

            return <AttachedFile fileUrl={url} fileName={file.file} key={i} />;
          })}
        </div>
      </div>
    );
  }
  return null;
};

export default SideMenuFiles;

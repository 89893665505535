import { useEffect, useState, useCallback, useMemo, useContext } from 'react';
import axios from 'axios';
import { Link, useLocation } from 'react-router-dom';

import { useRef } from 'react';

import SnackbarContext from 'src/contexts/SnackbarContext';

import AddEditNewsModal from '../../windows/news/CreateEditNewsModal';
import LocalDateTime from '../../components/shared/LocalDateTime';
import Preloader from '../../components/preloaders/Preloader';
import useAuth from '../../hooks/useAuth';

import Pagination from './Pagination';

const News = () => {
  const location = useLocation();
  const auth = useAuth();

  const newsBlogsType = useMemo(() => {
    if (location?.pathname.includes('/blogs')) {
      return 'blog';
    } else if (location?.pathname.includes('/news')) {
      return 'news';
    }
  }, [location]);

  const userHasRights = useMemo(() => {
    if (newsBlogsType === 'news' && auth?.isUserRightful(auth.rightTypes.add_news)) {
      return true;
    }
    if (newsBlogsType === 'blog') return true;
  }, [auth, newsBlogsType]);

  const [addNewsModal, toggleAddNewsModal] = useState();
  const [list, setList] = useState();
  const [isLoadingList, setIsLoadingList] = useState();
  const totalPages = useRef();

  const { showSnackbar } = useContext(SnackbarContext);

  const getData = useCallback(
    (pageNum) => {
      setIsLoadingList(true);
      axios
        .get(`/api/news_blog_list/${newsBlogsType}/${pageNum}`)
        .then((r) => {
          if (r.data.page_count) totalPages.current = r.data.page_count;
          setList(r.data);
        })
        .catch(() => {
          showSnackbar('Возникла ошибка при получении данных');
        })
        .finally(() => setIsLoadingList(false));
    },
    [newsBlogsType],
  );

  // получить первоначальные данные
  useEffect(() => {
    if (newsBlogsType && getData) getData(1);
  }, [newsBlogsType, getData]);

  return (
    <>
      {addNewsModal && newsBlogsType && (
        <AddEditNewsModal close={() => toggleAddNewsModal(false)} type={newsBlogsType} />
      )}

      <section className="project-list">
        <div className="project-list__container container">
          <section className="project-list__header project-list__row">
            <h1 className="project-list__title">{`${newsBlogsType === 'blog' ? 'Блоги' : 'Новости'}`}</h1>
            {userHasRights && (
              <button className="project-list__button" onClick={() => toggleAddNewsModal(true)}>
                {`${newsBlogsType === 'blog' ? 'Написать статью в блоге' : 'Добавить новости'}`}
              </button>
            )}
          </section>
          <form className="project-filter">
            <div className="project-filter__row">
              {/* <div className="project-filter__date-range date-range">
                <p className="date-range__title">Поиск по дате:</p>
                <div className="date-range__input-wrapper">
                  <input
                    className="date-range__input date-range__input--start date-range__input--small"
                    data-range
                    readOnly="readOnly"
                    placeholder="от"
                  />
                  <input
                    className="date-range__input date-range__input--end date-range__input--small"
                    data-range
                    readOnly="readOnly"
                    placeholder="до"
                  />
                </div>
                <span className="date-range__icon-calendar"></span>
                <span className="date-range__icon-arrow"></span>
              </div> */}
            </div>
          </form>
          <section className="blog">
            {isLoadingList && <Preloader />}
            {!isLoadingList &&
              list?.newsBlogList?.map((listItem, i) => {
                // заменить html тэги в описании на простой текст
                const plainString = listItem.description?.replace(/<[^>]+>/g, '').replace(/&nbsp;/g, '');

                return (
                  <div className="blog__post" key={i}>
                    <p className="blog__date">
                      <LocalDateTime dateTime={listItem.date_created} />
                    </p>
                    <Link to={`/${newsBlogsType === 'news' ? 'news' : 'blogs'}/${listItem.id}`}>
                      <h3 className="blog__subtitle _pointer">{listItem.title}</h3>
                    </Link>
                    <p className="blog__text _ellipsis-text">{plainString}</p>
                  </div>
                );
              })}
          </section>
          <Pagination getData={getData} totalPages={totalPages.current} />
        </div>
      </section>
    </>
  );
};

export default News;

import React from 'react';

import { useTranslation } from 'react-i18next';

import ResultItem from './ResultItem';
import ResultTextareaBlock from './ResultTextareaBlock';

const ProjectFormResult = ({ setResult, results, initEditableResult, editedItemResult, deleteResult }) => {
  const { t } = useTranslation();

  return (
    <>
      <h3 className="modal__subtitle">{t('common.result')}</h3>
      <ol className="modal__list modal-list">
        {results.length > 0 &&
          results.map((res, ind) => {
            return (
              <ResultItem
                key={ind}
                item={res}
                ind={ind}
                initEditableResult={initEditableResult}
                deleteResult={deleteResult}
              />
            );
          })}
        {/*  */}
      </ol>
      {/* поле ввода для добавления результата */}
      <ResultTextareaBlock editedItemResult={editedItemResult} results={results} setResult={setResult} />
    </>
  );
};

export default ProjectFormResult;

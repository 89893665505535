import { useState, useCallback, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import axios from 'axios';

import { useContext } from 'react';

import ConfirmAction from '../../warnings/ConfirmAction';
import { useEventTriggerOnEscPress, useOutsideTrigger } from '../../../utilize/helper-functions';
import SnackbarContext from '../../../contexts/SnackbarContext';

const MessageTagsModal = ({ close, messageTags, messageId }) => {
  const tags = useSelector((state) => state.tags.tagsList);

  const {
    handleSubmit,
    control,
    formState: { isDirty },
  } = useForm({
    defaultValues: {
      chat_message_tags: messageTags,
    },
  });

  const [isSending, setIsSending] = useState(false);

  const { showSnackbar } = useContext(SnackbarContext);

  const sendTags = ({ chat_message_tags }) => {
    const tagsToSend = chat_message_tags.map((tag) => tag.tag_id);
    setIsSending(true);
    axios
      .patch('/api/chat_message_tags/edit', {
        chat_message_id: messageId,
        chat_message_tags: tagsToSend,
      })
      .then(() => close())
      .catch((e) => {
        setIsSending(false);
        showSnackbar('Ошибка при редактировании тэгов', 'fail');
      });
  };

  const [dropdown, toggleDropdown] = useState(false);

  const [modalClosePrompt, setModalClosePrompt] = useState(false);

  const handleModalClose = useCallback(() => {
    if (dropdown) return toggleDropdown(false);

    if (isDirty) {
      setModalClosePrompt(true);
    } else close();
  }, [isDirty, close, dropdown]);

  useEventTriggerOnEscPress(handleModalClose);

  const dropdownSelectRef = useRef();
  const dropdownInputRef = useRef();

  const refsArray = useRef([dropdownSelectRef, dropdownInputRef]);

  const collapseDropdown = useCallback(() => toggleDropdown(false), []);

  useOutsideTrigger(refsArray.current, collapseDropdown, dropdown);

  return (
    <>
      <section className="modal__body">
        <div className="modal__header">
          <h2 className="modal__title">Поставить тэги</h2>
          <button className="modal__close-modal" onClick={handleModalClose}></button>
        </div>
        <Controller
          name="chat_message_tags"
          control={control}
          render={({ field: { value, onChange } }) => {
            const handleChange = (tagToAdd) => {
              onChange([...value, { ...tagToAdd, tag_id: tagToAdd.id }]);
            };

            const handleDelete = (tagId) => {
              onChange(value.filter((v) => v.tag_id !== tagId));
            };

            const filteredTags = tags?.filter((tag) => value.every((v) => v.tag_id !== tag.id));

            return (
              <>
                <div className="modal__row">
                  <ul className="attach-tags">
                    {value.map((tag, i) => (
                      <li className="attach-tags__item" key={i}>
                        <span className={`message__mark message__mark--${tag.color}`}>{tag.name}</span>
                        <button
                          className="attach-tags__button-delete"
                          type="button"
                          onClick={() => handleDelete(tag.tag_id)}
                        ></button>
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="modal__row modal__row--start">
                  <div className={`modal__dropdown dropdown ${dropdown ? '_show' : ''}`} ref={dropdownInputRef}>
                    <span className="modal__label">Добавить новый тэг</span>
                    <div className="dropdown__input" data-selected="1" onClick={() => toggleDropdown(!dropdown)}>
                      {filteredTags[0]?.name}
                    </div>
                    <ul className="dropdown__select select" ref={dropdownSelectRef}>
                      {filteredTags?.map((tag, i) => (
                        <li key={i} className="select__item" data-option={tag.id} onClick={() => handleChange(tag)}>
                          {tag.name}
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </>
            );
          }}
        />
        <div className="modal__button-box">
          <button
            className="modal__button modal__button--create"
            onClick={handleSubmit(sendTags)}
            disabled={isSending || !isDirty}
          >
            {isSending ? 'Отправка...' : 'Сохранить'}
          </button>
          <button className="modal__button modal__button--cancel" onClick={handleModalClose}>
            Отмена
          </button>
        </div>
      </section>

      {modalClosePrompt && (
        <ConfirmAction
          cancel={() => setModalClosePrompt(false)}
          confirm={close}
          actionText={'Уверены, что хотите закрыть окно без сохранения?'}
        />
      )}
    </>
  );
};

export default MessageTagsModal;

import React from 'react';

const Starred = () => {
  return (
    <main>
      {/* <section className="favorite">
          <div className="favorite__container container">
            <div className="favorite__breadcrumbs breadcrumbs">
              <a href="#" className="breadcrumbs__item">
                Ссылка
              </a>
              <a href="#" className="breadcrumbs__item breadcrumbs__item--active">
                Избранное
              </a>
            </div>
            <h2 className="favorite__title">Избранное</h2>
            <ul className="favorite__list">
              <li className="favorite__item-header">Название проекта/задачи</li>
              <li className="favorite__item">
                <a href="#" className="favorite__link"></a>
                <p className="favorite__name">Название задачи</p>
                <button className="favorite__button-delete">Удалить</button>
              </li>
              <li className="favorite__item">
                <a href="#" className="favorite__link"></a>
                <p className="favorite__name">Название задачи</p>
                <button className="favorite__button-delete">Удалить</button>
              </li>
              <li className="favorite__item">
                <a href="#" className="favorite__link"></a>
                <p className="favorite__name">Название задачи</p>
                <button className="favorite__button-delete">Удалить</button>
              </li>
              <li className="favorite__item">
                <a href="#" className="favorite__link"></a>
                <p className="favorite__name">Название задачи</p>
                <button className="favorite__button-delete">Удалить</button>
              </li>
              <li className="favorite__item">
                <a href="#" className="favorite__link"></a>
                <p className="favorite__name">Название задачи</p>
                <button className="favorite__button-delete">Удалить</button>
              </li>
              <li className="favorite__item">
                <a href="#" className="favorite__link"></a>
                <p className="favorite__name">Название задачи</p>
                <button className="favorite__button-delete">Удалить</button>
              </li>
              <li className="favorite__item">
                <a href="#" className="favorite__link"></a>
                <p className="favorite__name">Название задачи</p>
                <button className="favorite__button-delete">Удалить</button>
              </li>
              <li className="favorite__item">
                <a href="#" className="favorite__link"></a>
                <p className="favorite__name">Название задачи</p>
                <button className="favorite__button-delete">Удалить</button>
              </li>
              <li className="favorite__item">
                <a href="#" className="favorite__link"></a>
                <p className="favorite__name">Название задачи</p>
                <button className="favorite__button-delete">Удалить</button>
              </li>
              <li className="favorite__item">
                <a href="#" className="favorite__link"></a>
                <p className="favorite__name">Название задачи</p>
                <button className="favorite__button-delete">Удалить</button>
              </li>
            </ul>
          </div>
        </section> */}
    </main>
  );
};

export default Starred;

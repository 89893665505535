import { styled } from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const TicketsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const StyledSubheader = styled.div`
  margin: 0 -24px;
  padding: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${({ theme }) => theme.light};

  @media (max-width: 600px) {
    gap: 20px;
    flex-direction: column;
    align-items: flex-start;
  }
`;

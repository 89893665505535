import DatePicker from 'react-datepicker';
import styled from 'styled-components';

export const StyledDateWrapper = styled.div`
  position: relative;

  &::after {
    content: '';
    background-image: url('../../../assets/images/icons/calendar.svg');
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    bottom: 14px;
    height: 14px;
    pointer-events: none;
    position: absolute;
    right: 14px;
    width: 14px;
  }

  & .react-datepicker-wrapper {
    width: 100%;
  }

  & .react-datepicker {
    display: flex;
  }
`;
export const StyledDatePicker = styled(DatePicker)`
  outline: none;
  border: 1px solid ${({ theme }) => theme.border};
  border-radius: 2px;
  padding: 8px 34px 8px 12px;
  width: 100%;
  font: inherit;
  font-size: 16px;
  line-height: 24px;
  color: ${({ theme }) => theme.darkest};
  cursor: pointer;

  &::placeholder {
    color: ${({ theme }) => theme.dark};
  }

  &:focus {
    border-color: ${({ theme }) => theme.main};
    box-shadow: ${({ theme }) => theme.shadowInput};
  }
}

${(props) =>
  props.error === 'true' &&
  `
      border-color: ${({ theme }) => theme.error};
      box-shadow: ${({ theme }) => theme.shadowInputError};
  `}
`;

import React, { useRef, cloneElement, useState } from 'react';
import ReactDOM from 'react-dom';

const InlineModal = ({ children, close }) => {
  const checkInputs = useRef();

  const [promptCloseDialog, setPromptCloseDialog] = useState();

  const checkInputsAndClose = () => {
    if (typeof checkInputs.current === 'function' && checkInputs.current()) {
      if (!promptCloseDialog) return setPromptCloseDialog(true);
    }
    close();
  };

  return ReactDOM.createPortal(
    <section className="modal">
      <div className="modal__wrapper">
        <div className="modal__inner">{cloneElement(children, { checkInputs, checkInputsAndClose, close })}</div>
      </div>
    </section>,
    document.querySelector('#modal'),
  );
};

export default InlineModal;

import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { setOpenFolder } from '../../redux/features/storageSlice';

import FileDetailsRow from './FileDetailsRow';
import FolderDetailsRow from './FolderDetailsRow';

const FolderDetails = ({
  setAddFolderModal,
  setAddFilesModal,
  renderUserName,
  handleFolderSelect,
  setOpenFolderId,
  noURLParams,
  storageIdToReq,
  addStorageFileReference,
}) => {
  const { openFolder, folderPath, storageTree } = useSelector((state) => state.storage);
  const dispatch = useDispatch();
  // const { storageId } = useParams();
  const users = useSelector((state) => state.users.employees);
  const navigate = useNavigate();

  if (!openFolder || !users) return '';
  return (
    <>
      <section className="storage__content">
        <section className="storage__header">
          <div className="storage__breadcrumbs breadcrumbs">
            {folderPath?.map((path, i) => {
              for (const title in path) {
                return (
                  <span
                    key={i}
                    // to={`/storages/${storageId}/folder/${path[title]}`}
                    onClick={() => handleFolderSelect(path[title])}
                    className={`_pointer breadcrumbs__item${i === folderPath.length - 1 ? '--active' : ''} `}
                  >
                    {title}
                  </span>
                );
              }
              return '';
            })}
          </div>
          <h2 className="storage__title storage__title--folder">{openFolder.title}</h2>
          <div className="storage__button-box">
            <button
              className="storage__add-folder"
              title="Добавить папку"
              onClick={() => setAddFolderModal(true)}
            ></button>

            <button className="storage__add-file" title="Добавить файл" onClick={() => setAddFilesModal(true)}></button>
          </div>
        </section>
        <section className="storage__body">
          {(openFolder.storage_id || openFolder.folder_id) && (
            // <Link
            //   to={`/storages/${storageId}${
            //     openFolder.folder_id ? "/folder/" + openFolder.folder_id : ""
            //   }`}
            // >
            <button
              className="storage__button-back"
              onClick={() => {
                const parentFolderId = openFolder.folder_id;

                if (noURLParams) {
                  if (parentFolderId) {
                    setOpenFolderId(parentFolderId);
                    return;
                  }
                  dispatch(setOpenFolder({ openFolder: storageTree, path: null }));
                  setOpenFolderId(null);
                  return;
                }

                navigate(`/storages/${storageIdToReq}${parentFolderId ? '/folder/' + parentFolderId : ''}`);
              }}
            >
              На уровень выше
            </button>
            // </Link>
          )}
          <ul className="storage__list storage-list">
            {openFolder?.folders?.map((folder, i) => (
              <FolderDetailsRow
                key={i}
                renderUserName={renderUserName}
                folder={folder}
                handleFolderSelect={handleFolderSelect}
                storageId={storageIdToReq}
              />
            ))}
          </ul>
          <ul className="storage__list storage-list">
            {openFolder?.files?.map((fileData, i) => (
              <FileDetailsRow
                key={i}
                fileData={fileData}
                renderUserName={renderUserName}
                storageId={storageIdToReq}
                addStorageFileReference={addStorageFileReference}
              />
            ))}
          </ul>
        </section>
      </section>
    </>
  );
};

export default FolderDetails;

import React from 'react';
import { useTranslation } from 'react-i18next';

const ProjectFormTabsItem = ({ value, handler, open }) => {
  return (
    <div className={`modal__tag tag ${open ? 'tag--color' : ''}`} onClick={handler}>
      {`${open ? '-' : '+'}  ${value}`}
    </div>
  );
};

const ProjectFormTabs = ({ handler, tabs }) => {
  const { t } = useTranslation();
  return (
    <div className="modal__row">
      <ProjectFormTabsItem
        value={t('Project.project_create_edit_tabs.result')}
        handler={() => handler('result')}
        open={!!tabs.result}
      />
      <ProjectFormTabsItem
        value={t('Project.project_create_edit_tabs.deadlines')}
        handler={() => handler('deadlines')}
        open={!!tabs.deadlines}
      />
      <ProjectFormTabsItem
        value={t('Project.project_create_edit_tabs.tags')}
        handler={() => handler('tags')}
        open={!!tabs.tags}
      />
      {/* <ProjectFormTabsItem
        value={t("Project.project_create_edit_tabs.incoming_data")}
        handler={() => handler("incoming_data")}
        open={!!tabs.incoming_data}
      />
      <ProjectFormTabsItem
        value={t("Project.project_create_edit_tabs.outgoing_data")}
        handler={() => handler("outgoing_data")}
        open={!!tabs.outgoing_data}
      /> */}
    </div>
  );
};

export default ProjectFormTabs;

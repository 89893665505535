import { useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

const Pagination = ({ totalPages }) => {
  const [currPageNum, setCurrPageNum] = useState(1);

  const [searchParams, setSearchParams] = useSearchParams();

  const queryParams = useMemo(() => Object.fromEntries([...searchParams]), [...searchParams]);

  // react массив кнопок для отображения
  const buttons = useMemo(() => {
    const buttonsArr = [];

    if (!totalPages) return '';

    // cчитает максимальное кол-тво кнопок предыдущих страниц (до 5)
    const prevBtnsAmount = currPageNum - totalPages + 5;
    // цикл для добавления кнопок до кнопки текущей страницы
    // повторять цикл пока предыдущие кнопки не достигли первой страницы
    // и кол-тво добавленных кнопок меньше 3 или пока не достигнута максимальное количество (prevBtnsAmount)
    for (let i = 1, j = currPageNum; j > 1 && (i < 3 || i < prevBtnsAmount); i++, j--) {
      buttonsArr.unshift(
        <button
          className="pagination__item"
          key={currPageNum - i}
          onClick={() => {
            setCurrPageNum(currPageNum - i);
          }}
        >
          {currPageNum - i}
        </button>,
      );
    }
    // добавляет кнопку текущей страницы
    buttonsArr.push(
      <button className="pagination__item pagination__item--active _no-pointer" key={currPageNum}>
        {currPageNum}
      </button>,
    );

    // cчитает максимальное кол-тво кнопок следующих страниц (до 5)
    const nextBtnsAmount = 5 - currPageNum;
    // цикл для добавления кнопок до кнопки текущей страницы
    // повторять цикл пока предыдущие кнопки не достигли последней страницы
    // и кол-тво добавленных кнопок меньше 3 или пока не достигнута максимальное количество (nextBtnsAmount)
    for (let i = 1, j = currPageNum; j < totalPages && (i <= nextBtnsAmount || i < 3); i++, j++) {
      buttonsArr.push(
        <button
          className="pagination__item"
          key={currPageNum + i}
          onClick={() => {
            setCurrPageNum(currPageNum + i);
          }}
        >
          {currPageNum + i}
        </button>,
      );
    }

    return buttonsArr;
  }, [totalPages, currPageNum]);

  useEffect(() => {
    const pageParam = searchParams.get('page');

    if (pageParam != currPageNum) {
      setCurrPageNum(1);
    }
  }, [searchParams]);

  useEffect(() => {
    setSearchParams({ ...queryParams, page: currPageNum });
  }, [currPageNum]);

  return (
    <div className="project-list__pagination pagination">
      <button
        className="pagination__button pagination__button--prev"
        disabled={currPageNum < 4 || totalPages < 6}
        onClick={() => {
          const pageToNavigate = totalPages - currPageNum < 3 ? totalPages - 5 : currPageNum - 3;

          setCurrPageNum(pageToNavigate);
        }}
      ></button>
      {buttons}
      <button
        className="pagination__button pagination__button--next"
        disabled={(currPageNum < 3 && totalPages < 6) || totalPages - (currPageNum + 3) < 0}
        onClick={() => {
          const pageToNavigate = currPageNum < 3 ? 6 : currPageNum + 3;

          setCurrPageNum(pageToNavigate);
        }}
      ></button>
    </div>
  );
};

export default Pagination;

import { useState, useEffect, useCallback } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import axios from 'axios';

import { localDateNumberFormat } from '../../../utilize/helper-functions';
import { renderMessage } from '../../../utilize/draftjs-helper-functions';

import useAuth from '../../../hooks/useAuth';
import Preloader from '../../preloaders/Preloader';
import UserProfileModal from '../../../windows/profile/UserProfileModal';
import AttachedFile from '../../files/AttachedFile';
import UserStatus from '../../employees/UserStatus';
import { files_url } from '../../../settings/base-url';

import ProjectTree from './ProjectTree';
import { renderProjectOrTaskName } from './SideMenu';

const SearchSidePanel = ({ sideMenu, openSideMenu, projectData }) => {
  const auth = useAuth();
  const { projectId, taskId } = useParams();
  const { t } = useTranslation();
  const [projectTree, showProjectTree] = useState(false);
  const [dataType, setDataType] = useState(null);
  const [panelData, setPanelData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [userProfile, showUserProfile] = useState();

  const [projectOrTaskToSearch, setProjectOrTaskToSearch] = useState(null);
  const [errorLoading, setErrorLoading] = useState(false);
  const methods = useForm();

  // useEffect(() => {
  //   if (!dataType) {
  //     taskId ? setDataType("task") : setDataType("project");
  //   }
  // }, [taskId, dataType]);
  useEffect(() => {
    if (taskId && dataType !== 'task') setDataType('task');
    if (!taskId && dataType !== 'project') setDataType('project');
  }, [taskId, dataType]);

  const searchMessages = async ({ search_word }) => {
    if (search_word && dataType && projectData) {
      setPanelData(null);
      setIsLoading(true);
      if (errorLoading) setErrorLoading(false);

      let searchDataType, dataId;
      if (projectOrTaskToSearch) {
        searchDataType = projectOrTaskToSearch.dataType;
        dataId = projectOrTaskToSearch.dataId;
      } else {
        searchDataType = dataType;
        dataId = projectData.id;
      }

      try {
        const response = await axios.get(`/api/sidebar_search/${searchDataType}/${dataId}/${search_word}`);
        const chat_messages = response.data.chat_messages;
        setIsLoading(false);
        setPanelData({
          messages: chat_messages,
          id: projectData.id,
        });
      } catch (e) {
        setIsLoading(false);
        setErrorLoading(true);
      }
    }
  };

  // reset данных при закрытии
  useEffect(() => {
    if (sideMenu !== 'search' && (panelData || projectOrTaskToSearch)) {
      setTimeout(() => {
        setPanelData(null);
        setProjectOrTaskToSearch(null);
        methods.reset();
      }, 1000);
    }
  }, [sideMenu, panelData, projectOrTaskToSearch, methods]);

  const showRelevantTaskInfo = useCallback(
    (dataType, dataId, title) => {
      const search_word = methods.getValues('search_word');
      setPanelData(null);
      showProjectTree(false);
      setErrorLoading(false);
      setProjectOrTaskToSearch({
        dataType,
        dataId,
        title,
      });
      if (search_word) {
        axios
          .get(`/api/sidebar_search/${dataType}/${dataId}/${search_word}`)
          .then((response) => {
            const chat_messages = response.data.chat_messages;
            setPanelData({
              messages: chat_messages,
              id: dataId,
            });

            setIsLoading(false);
            setDataType(dataType);
            setErrorLoading(false);
          })
          .catch(() => {
            setIsLoading(false);
            setErrorLoading(true);
          });
      }
    },
    [methods],
  );

  return (
    <>
      {userProfile && <UserProfileModal profileId={userProfile} onClose={() => showUserProfile(null)} />}

      <aside data-aside="search" className={`aside ${sideMenu === 'search' ? '_active' : ''}`}>
        <div className="aside__header">
          <span className="aside__title aside__title--search">{t('common.search')}</span>
          <button className="aside__button-arrow" onClick={() => openSideMenu(null)}></button>
        </div>
        <div className="aside__body">
          {projectTree ? (
            <ProjectTree
              update={sideMenu === 'search'}
              showRelevantTaskInfo={showRelevantTaskInfo}
              showProjectTree={showProjectTree}
            />
          ) : (
            <>
              <div style={{ cursor: 'pointer' }} className="aside__backward" onClick={() => showProjectTree(true)}>
                Структура проекта
              </div>

              {renderProjectOrTaskName(projectOrTaskToSearch, taskId, projectOrTaskToSearch?.dataType, projectData)}

              {projectOrTaskToSearch?.dataType === 'project' && taskId && (
                <Link to={`/projects/${projectId}`} className="aside__link">
                  {t('Project.open_project')}
                </Link>
              )}

              {projectOrTaskToSearch?.dataType === 'task' && projectOrTaskToSearch?.dataId !== +taskId && (
                <Link to={`/projects/${projectId}/tasks/${projectOrTaskToSearch.dataId}`} className="aside__link">
                  {t('Project.open_task')}
                </Link>
              )}

              <form onSubmit={methods.handleSubmit(searchMessages)} className="search-box">
                <input
                  className="search-box__input"
                  placeholder="Поиск"
                  defaultValue={''}
                  {...methods.register('search_word')}
                  autoComplete="off"
                />
                <button type="submit" className="search-box__button"></button>
              </form>

              {isLoading && <Preloader />}

              {errorLoading && <div>{t('ErrorMessages.default')}</div>}

              {panelData && <p className="aside__subtitle">{t('common.search_results')}</p>}

              {panelData?.messages?.length > 0 && (
                <>
                  {panelData.messages.map((chat_message, i) => {
                    const {
                      message_tags,
                      employee_id,
                      employee_first_name,
                      employee_last_name,
                      date_created,
                      editor_state,
                      message_files,
                      id,
                      chat_goal_results,
                      title,
                    } = chat_message;
                    return (
                      <section className="task-current" key={i}>
                        <div className="task-current__body">
                          {chat_goal_results?.length > 0 && (
                            <p className="task-status task-status--pending">{t('Project.result.pending')}</p>
                          )}

                          {message_tags?.map((tag, i) => (
                            <span className={`task-current__tag tag tag--icon-${tag.color}`} key={i}>
                              {tag.name}
                            </span>
                          ))}

                          <div className="task-current__item">
                            <div className="task-current__header">
                              <div
                                onClick={() => {
                                  if (!auth?.user?.is_guest) showUserProfile(employee_id);
                                }}
                                className={`task-current__usercard ${
                                  auth?.user?.is_guest ? '_no-pointer' : '_pointer'
                                }`}
                              >
                                <UserStatus
                                  userId={employee_id}
                                  lastName={employee_last_name}
                                  firstName={employee_first_name}
                                />
                                {/* <img
                                  className="task-current__userpic"
                                  src="assets/images/userpic.png"
                                  alt=""
                                /> */}
                                <span className="task-current__username">
                                  {`${employee_first_name || ''} ${employee_last_name || ''}`}
                                </span>{' '}
                              </div>
                              <span className="task-current__date">{localDateNumberFormat(date_created)}</span>
                            </div>
                            <div className="task-current__message">
                              {title && (
                                <div>
                                  <strong>{title}</strong>
                                </div>
                              )}

                              {renderMessage(editor_state)}

                              <div className="attach">
                                {message_files?.length > 0 && (
                                  <>
                                    {message_files.map((file, i) => {
                                      const url = `${files_url}/chat_messages/files/${id}/${file.id}/${file.file}?token=${auth.token}`;

                                      return (
                                        <AttachedFile
                                          fileUrl={url}
                                          fileName={file.file}
                                          storageFileId={file.from_storage}
                                          key={i}
                                        />
                                      );
                                    })}
                                  </>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>
                    );
                  })}
                </>
              )}
            </>
          )}
        </div>
      </aside>
    </>
  );
};

export default SearchSidePanel;

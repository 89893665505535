import { useState, useRef, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import axios from 'axios';

import UserProfileModal from '../../../windows/profile/UserProfileModal';
import Preloader from '../../preloaders/Preloader';
import { localDateNumberFormat } from '../../../utilize/helper-functions';
import useAuth from '../../../hooks/useAuth';
import { renderMessage } from '../../../utilize/draftjs-helper-functions';

import AttachedFile from '../../files/AttachedFile';
import UserStatus from '../../employees/UserStatus';
import { files_url } from '../../../settings/base-url';

import ProjectTree from './ProjectTree';
import { renderProjectOrTaskName } from './SideMenu';

const TagsSidePanel = ({ sideMenu, openSideMenu, projectData }) => {
  const auth = useAuth();
  const { projectId, taskId } = useParams();
  const { t } = useTranslation();
  const tags = useSelector((state) => state.tags.tagsList);
  const [selectedTagId, setSelectedTagId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [errorLoading, setErrorLoading] = useState(false);
  const [panelData, setPanelData] = useState(null);
  const [projectTree, showProjectTree] = useState(false);
  const [userProfile, showUserProfile] = useState();
  // const [projectOrTaskToSearch, setProjectOrTaskToSearch] = useState(null);
  const projectOrTaskToSearch = useRef();

  const dataType = useRef();

  // useEffect(() => {
  //   if (!dataType.current) {
  //     dataType.current = taskId ? "task" : "project";
  //   }
  // }, [taskId]);

  useEffect(() => {
    if (taskId && dataType.current !== 'task') dataType.current = 'task';
    if (!taskId && dataType.current !== 'project') dataType.current = 'project';
  }, [taskId]);

  const fetchData = async (dataTypeToSearch, dataId, tagIdToSearch) => {
    try {
      const response = await axios.get(`/api/sidebar_tags/${dataTypeToSearch}/${dataId}/${tagIdToSearch}`);
      const chat_messages = response.data.chat_messages;
      setPanelData({
        messages: chat_messages || [],
      });
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      setErrorLoading(true);
    }
  };

  const searchByTag = (tagIdToSearch) => {
    if (tagIdToSearch && dataType.current) {
      setSelectedTagId(tagIdToSearch);
      setIsLoading(true);
      setErrorLoading(false);
      setPanelData(null);

      let dataTypeToSearch, dataId;
      if (projectOrTaskToSearch.current) {
        dataTypeToSearch = projectOrTaskToSearch.current.dataType;
        dataId = projectOrTaskToSearch.current.dataId;
      } else {
        dataTypeToSearch = dataType.current;
        dataId = projectData.id;
      }

      fetchData(dataTypeToSearch, dataId, tagIdToSearch);
    }
  };

  const showRelevantTaskInfo = (dataType, dataId, dataTitle) => {
    setPanelData(null);
    showProjectTree(false);
    setErrorLoading(false);
    projectOrTaskToSearch.current = {
      dataType,
      dataId,
      title: dataTitle,
    };
    if (selectedTagId) {
      fetchData(dataType, dataId, selectedTagId);
    }
  };

  // reset данных при закрытии
  useEffect(() => {
    if (sideMenu !== 'tags' && (panelData || projectOrTaskToSearch.current)) {
      setTimeout(() => {
        setPanelData(null);
        projectOrTaskToSearch.current = null;
        setIsLoading(false);
        setErrorLoading(false);
        setSelectedTagId(null);
      }, 1000);
    }
  }, [sideMenu, panelData, projectOrTaskToSearch]);

  return (
    <>
      {userProfile && <UserProfileModal profileId={userProfile} onClose={() => showUserProfile(null)} />}

      <aside data-aside="tags" className={`aside ${sideMenu === 'tags' ? '_active' : ''}`}>
        <div className="aside__header">
          <span className="aside__title aside__title--tags">{t('Project.tag_search')}</span>
          <button
            className="aside__button-arrow"
            onClick={() => {
              setTimeout(() => {
                setPanelData(null);
                projectOrTaskToSearch.current = null;
                setIsLoading(false);
                setErrorLoading(false);
                setSelectedTagId(null);
              }, [1000]);
              openSideMenu(null);
            }}
          ></button>
        </div>
        <div className="aside__body">
          {projectTree ? (
            <ProjectTree
              update={sideMenu === 'tags'}
              showRelevantTaskInfo={showRelevantTaskInfo}
              showProjectTree={showProjectTree}
            />
          ) : (
            <>
              <Link className="aside__backward" to="#" onClick={() => showProjectTree(true)}>
                Структура проекта
              </Link>

              {renderProjectOrTaskName(
                projectOrTaskToSearch.current,
                taskId,
                projectOrTaskToSearch.current?.dataType,
                projectData,
              )}

              {projectOrTaskToSearch.current?.dataType === 'project' && taskId && (
                <Link to={`/projects/${projectId}`} className="aside__link">
                  {t('Project.open_project')}
                </Link>
              )}

              {projectOrTaskToSearch.current?.dataType === 'task' &&
                projectOrTaskToSearch.current?.dataId !== +taskId && (
                  <Link
                    to={`/projects/${projectId}/tasks/${projectOrTaskToSearch.current?.dataId}`}
                    className="aside__link"
                  >
                    {t('Project.open_task')}
                  </Link>
                )}

              <div className="aside__tags tags-block">
                {tags?.map((tag, i) => (
                  <button
                    className={`aside__tag tag tag--icon-${tag.color} ${selectedTagId === tag.id ? 'tag--active' : ''}`}
                    onClick={() => searchByTag(tag.id)}
                    key={i}
                  >
                    {tag.name}
                  </button>
                ))}

                {isLoading && <Preloader />}

                {errorLoading && <div>{t('ErrorMessages.default')}</div>}

                {panelData && <p className="aside__subtitle">{t('common.search_results')}</p>}

                {panelData?.messages?.length > 0 && (
                  <>
                    {panelData.messages.map((chat_message, i) => {
                      const {
                        message_tags,
                        employee_id,
                        employee_first_name,
                        employee_last_name,
                        date_created,
                        editor_state,
                        message_files,
                        id,
                        chat_goal_results,
                        title,
                      } = chat_message;
                      return (
                        <section className="task-current" key={i}>
                          <div className="task-current__body">
                            {chat_goal_results.length > 0 && (
                              <p className="task-status task-status--pending">{t('Project.result.pending')}</p>
                            )}

                            {message_tags.map((tag, i) => {
                              let isTagSelected = false;
                              if (selectedTagId === tag.tag_id) isTagSelected = true;

                              return (
                                <span
                                  className={`task-current__tag tag tag--icon-${tag.color} ${
                                    isTagSelected ? 'tag--active' : ''
                                  }`}
                                  key={i}
                                >
                                  {tag.name}
                                </span>
                              );
                            })}

                            <div className="task-current__item">
                              <div className="task-current__header">
                                <div
                                  to="#"
                                  onClick={() => {
                                    if (!auth?.user?.is_guest) showUserProfile(employee_id);
                                  }}
                                  className={`task-current__usercard ${
                                    auth?.user?.is_guest ? '_no-pointer' : '_pointer'
                                  }`}
                                >
                                  <UserStatus
                                    userId={employee_id}
                                    firstName={employee_first_name}
                                    lastName={employee_last_name}
                                  />
                                  {/* <img
                                    className="task-current__userpic"
                                    src="assets/images/userpic.png"
                                    alt=""
                                  /> */}
                                  <span className="task-current__username">
                                    {`${employee_first_name || ''} ${employee_last_name || ''}`}
                                  </span>{' '}
                                </div>
                                <span className="task-current__date">{localDateNumberFormat(date_created)}</span>
                              </div>
                              <div className="task-current__message">
                                {title && (
                                  <div>
                                    <strong>{title}</strong>
                                  </div>
                                )}

                                {renderMessage(editor_state)}

                                <div className="attach">
                                  {message_files?.length > 0 && (
                                    <>
                                      {message_files.map((file, i) => {
                                        const url = `${files_url}/chat_messages/files/${id}/${file.id}/${file.file}?token=${auth.token}`;

                                        return (
                                          <AttachedFile
                                            fileUrl={url}
                                            fileName={file.file}
                                            storageFileId={file.from_storage}
                                            key={i}
                                          />
                                        );
                                      })}
                                    </>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </section>
                      );
                    })}
                  </>
                )}
              </div>
            </>
          )}
        </div>
      </aside>
    </>
  );
};

export default TagsSidePanel;

import { useContext, useEffect } from 'react';
import moment from 'moment';

import 'moment/locale/ru';
import SnackbarContext from 'src/contexts/SnackbarContext';
moment.locale('ru');

export const randomSequence = () => {
  let a = Date.now().toString().split('');
  let n = a.length;

  for (let i = n - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    const tmp = a[i];
    a[i] = a[j];
    a[j] = tmp;
  }
  return a.join('');
};

export const isFileNameImage = (fileName) => {
  const imageReg = /\.(gif|jpg|jpeg|tiff|png)$/i;
  if (fileName) return imageReg.test(fileName);
  return false;
};

export const addFileNameId = (file) => {
  let arr = file.name.split('.');
  let newFileName = arr[0] + '_' + randomSequence() + '.' + arr[arr.length - 1];
  return newFileName;
};

export function useOutsideTrigger(refsArray, callback, currentState) {
  useEffect(() => {
    if (currentState) {
      function handleClickOutside(event) {
        for (const ref of refsArray) {
          if (ref.current && ref.current.contains(event.target)) {
            return;
          }
        }
        callback();
      }
      // Bind the event listener
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }
  }, [refsArray, callback, currentState]);
}

export function useEventTriggerOnEscPress(callback) {
  // регистрация eventa keypress для выхода из модального окна в случае нажатии на кнопку Esc
  useEffect(() => {
    const exitOnEscPress = (event) => {
      if (event.keyCode === 27) {
        if (!event.target.className?.includes('date-range__input') && !event.target.id.includes('react-select')) {
          callback();
        }
      }
    };

    document.addEventListener('keydown', exitOnEscPress);

    return () => document.removeEventListener('keydown', exitOnEscPress);
  }, [callback]);
}

export const useDuplicateErrorHandler = () => {
  const { showSnackbar } = useContext(SnackbarContext);
  const callback = (error, warningMessage) => {
    const errData = error.response?.data;
    if (errData?.status !== 500 || errData.error?.code !== 'ER_DUP_ENTRY') return false;
    // showSnackbar('Отдел с таким названием уже существует');
    showSnackbar(warningMessage);
    return true;
  };
  return callback;
};

const moscowTime = moment.duration('03:00:00');

export const showLocalDateTime = (dateTime, dateStyle) => {
  return moment(dateTime)
    .subtract(moscowTime)
    .format(dateStyle || 'D MMMM k:mm');
};

export const localDateNumberFormat = (dateTime) => {
  return moment(dateTime).subtract(moscowTime).format('DD.MM.yyyy');
};

// форматирует массив данных о работниках (из сервера), в подходящий для библиотеки React Select формат данных
export const formatReactSelectEmployeesOptions = (arr) => {
  if (Array.isArray(arr)) {
    return arr.map((el) => {
      return {
        label: (el.last_name || '') + ' ' + el.first_name,
        value: (el.last_name || '') + el.first_name,
        id: el.id,
      };
    });
  } else {
    return [];
  }
};

// форматирует массив данных о тэгах (из сервера), в подходящий для библиотеки React Select формат данных
export const formatReactSelectTagsOptions = (arr) => {
  if (Array.isArray(arr)) {
    return arr.map((el) => {
      return {
        label: el.name,
        value: el.name,
        color: el.color,
        id: el?.tag_id || el.id,
      };
    });
  } else return [];
};

export const isEmpty = (obj) => {
  for (const x in obj) return false;
  return true;
};

export const arraysEqual = (a, b) => {
  if (a === b) return true;
  if (a?.length !== b?.length) return false;

  for (var i = 0; i < a.length; ++i) {
    try {
      if (JSON.stringify(a[i]) !== JSON.stringify(b[i])) return false;
    } catch (e) {
      return false;
    }
  }
  return true;
};

export const checkTokenError = (error) => {
  const errorData = error.response?.data;
  if (errorData?.status === 401 && errorData?.message === 'Incorrect token') {
    return 'TOKEN ERROR';
  }
};

export const getFileTypeFromName = (name) => {
  const re = /(?:\.([^.]+))?$/;
  const ext = re.exec(name);
  switch (ext[1]) {
    case 'jpg':
    case 'gif':
      return 'IMAGE';
    case 'pdf':
      return 'PDF';
    default:
      return 'UNKNOWN';
  }
};

const insertFilenameOrder = (fileName, number) => {
  var extensionIndex = fileName.lastIndexOf('.');
  const orderToInsert = ` (${number})`;
  if (extensionIndex === -1) {
    return fileName + orderToInsert;
  } else {
    return fileName.slice(0, extensionIndex) + orderToInsert + fileName.slice(extensionIndex);
  }
};

export const formatDateforPicker = (dateString) => {
  let dateToReturn = '';
  if (dateString) {
    const formattedDate = new Date(dateString.substr(0, 10).replace(/-/g, '/'));
    if (formattedDate instanceof Date && !isNaN(formattedDate)) dateToReturn = formattedDate;
  }
  return dateToReturn;
};

export const incrementLastNumberInParentheses = (filename) => {
  const regex = / \((\d+)\)\./g;
  let match, lastMatch;
  do {
    match = regex.exec(filename);
    if (match) lastMatch = match;
  } while (match !== null);
  if (lastMatch) {
    const newOrder = ` (${parseInt(lastMatch[1]) + 1})`;

    return filename.slice(0, lastMatch.index) + newOrder + filename.slice(lastMatch.index + lastMatch[0].length - 1);
  } else {
    return insertFilenameOrder(filename, 2);
  }
};

export const assignIconLink = (type, value) => {
  const titlesToIcons = {
    Facebook: { icon: 'fb', url: 'https://www.facebook.com' },
    instagram: { icon: 'insta', url: 'https://www.instagram.com' },
    Twitter: { icon: 'twi', url: 'https://www.twitter.com' },
  };
  const isListed = titlesToIcons.hasOwnProperty(type);
  if (isListed) {
    return (
      <a
        href={titlesToIcons[type].url + `/${value}`}
        target="_blank"
        rel="noreferrer"
        className={`modal__social-link social-link social-link--${titlesToIcons[type].icon}`}
      >
        &nbsp;
      </a>
    );
  }

  return <span className={'modal__social-link social-link'}>&nbsp;</span>;
};

export const rightsTranslation = {
  access_children: 'Доступ к дочерним задачам',
  write: 'Может писать',
  vote: 'Может голосовать',
  add_files: 'Может добавлять картинки и файлы',
};

export const downloadFile = (filePath) => {
  const element = document.createElement('a');
  element.href = filePath;
  element.download = true;
  element.target = '_blank';
  element.rel = 'noreferrer';
  document.body.appendChild(element);
  element.click();
  document.body.removeChild(element);
};

const clickCounter = {
  times: 0,
  clickTimeout: null,
};

export const doubleClickHandle = (doubleClickCallback, singleClickCallback) => {
  clickCounter.times++;
  if (clickCounter.times >= 2) {
    clearTimeout(clickCounter.clickTimeout);
    if (doubleClickCallback) doubleClickCallback();
    clickCounter.times = 0;
    return;
  }
  clearTimeout(clickCounter.clickTimeout);
  clickCounter.clickTimeout = setTimeout(() => {
    if (singleClickCallback) singleClickCallback();
    clickCounter.times = 0;
  }, 250);
};

export const clearTextSelection = () => {
  if (document.selection?.selection?.empty) {
    document.selection.empty();
  } else if (window.getSelection) {
    var sel = window.getSelection();
    sel.removeAllRanges();
  }
};

export const convertBytes = function (bytes) {
  const sizes = ['байт', 'кб', 'мб', 'гб', 'тб'];

  if (bytes === 0 || isNaN(bytes)) {
    return 'н/д';
  }

  const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));

  if (i === 0) {
    return bytes + ' ' + sizes[i];
  }

  return (bytes / Math.pow(1024, i)).toFixed(1) + ' ' + sizes[i];
};

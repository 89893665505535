import { StyledInput } from './style';

export const Input = ({ error, type, value, placeholder, onChange, ...props }) => {
  const onChangeHandler = (evt) => onChange(evt.currentTarget.value);

  return (
    <StyledInput
      error={error}
      type={type}
      value={value}
      onChange={onChangeHandler}
      placeholder={placeholder}
      {...props}
    />
  );
};

import React from 'react';

import { StyledText } from './style';

// size (размер текста) может быть от 1 до 6, через as динамически меняется тег элемента
export const Text = (props) => {
  return (
    <StyledText as={props.tag} size={props.size} color={props.color} style={props.style} className={props.className}>
      {props.children}
    </StyledText>
  );
};

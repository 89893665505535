// export const joinRoom = (socket, roomId) => {
//   // socket.on("user_writes", (employee_id, chat_id) => {
//   //   console.log(
//   //     `the employee_${employee_id} writes in the chat_room_${chat_id}`
//   //   );
//   // });
//   socket.emit("add_to_room", `chat_room_${roomId}`, (response) => {
//
//   });

//   socket.on("send_message", (data) => {
//
//   });
// };

export const userWrites = (socket, chatId) => {
  socket.emit('user_writes', chatId, () => {});
};

export const onUserWrites = (socket, callback) => {
  socket.on('user_writes', callback);
};

export const onUserStoppedWriting = (socket, callback) => {
  socket.on('user_stopped_writing', callback);
};

export const offUserWrites = (socket) => {
  socket.off('user_writes');
  socket.off('user_stopped_writing');
};

export const userStoppedWriting = (socket, chatId) => {
  socket.emit('user_stopped_writing', chatId, () => {});
};

export const sendMessage = (socket, data, callback) => {
  socket.emit('send_message', data, callback);
};

export const onChatChange = (socket, callback) => {
  socket.on('chat_message_changed', callback);
};

export const offChatChange = (socket) => {
  socket.off('chat_message_changed');
};

import React from 'react';
import { ThreeDots } from 'react-loader-spinner';

const ThreeDotsLoader = ({ visible }) => {
  let loader = {
    Component: ThreeDots,
    props: {
      color: '#825adf',
      height: 30,
      width: 30,
    },
    name: 'LineWave',
  };

  return (
    <span style={{ visibility: `${visible ? 'visible' : 'hidden'}` }}>
      <loader.Component {...loader.props} />
    </span>
  );
};

export default ThreeDotsLoader;

import React from 'react';

import TaskListItem from './TaskListItem';

const TaskList = ({ tasksList }) => {
  return (
    <ul className="my-tasks__list">
      {tasksList.map((el, i) => {
        return <TaskListItem task={el} key={i} />;
      })}
    </ul>
  );
};

export default TaskList;

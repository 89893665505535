import { useState, forwardRef, useImperativeHandle } from 'react';
import './Snackbar.css';

const Snackbar = forwardRef(({ snackbarDetails }, ref) => {
  const [showSnackbar, setShowSnackbar] = useState();

  useImperativeHandle(ref, () => ({
    show() {
      setShowSnackbar(true);
      setTimeout(() => {
        snackbarDetails.current = null;
        setShowSnackbar(false);
      }, 5000);
    },
  }));

  return (
    <div
      className="snackbar"
      id={showSnackbar ? 'show' : 'hide'}
      style={{
        backgroundColor: snackbarDetails.current?.type === 'success' ? '#001529' : '#ff0033',
        color: 'white',
      }}
    >
      <div className="symbol">
        <h1>{snackbarDetails.current?.type === 'success' ? <>&#x2713;</> : <>&#x2613;</>}</h1>
      </div>
      <div className="message">{snackbarDetails.current?.message}</div>
    </div>
  );
});

export default Snackbar;

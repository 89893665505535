import React, { useState } from 'react';

import { Text } from '@shared/components/Text/Text';
import { Avatar } from '@shared/components/Avatar';

import { Button } from '@shared/components/Button/Button';

import { files_url } from 'src/settings/base-url';

import useAuth from 'src/hooks/useAuth';

import moment from 'moment';

import { Status } from 'src/shared/components/Status';

import { Card, Container, Title, UserCard } from './styles';
import { TicketModal } from './ui/TIcketModal';

export const TicketCard = ({ ticket }) => {
  const { id, title, creator_id, creator_avatar, creator_first_name, date_finish, ticket_actions } = ticket;

  const [visible, setVisible] = useState(false);
  const toggleModal = () => setVisible((prev) => !prev);

  const auth = useAuth();
  const date = new Date(date_finish);
  const action = ticket_actions[ticket_actions.length - 1].action;

  const getTicketAction = (action) => {
    switch (action) {
      case 'created':
        return 'Ожидает выполнения';
      case 'canceled':
        return 'Отменено';
      case 'confirmation':
        return 'Ожидает проверки';
      case 'confirmed':
        return 'Подтверждено';
      case 'returned':
        return 'Возрващено';
      default:
        break;
    }
  };

  const getActionColor = (action) => {
    switch (action) {
      case 'created':
        return '';
      case 'canceled':
        return 'red';
      case 'confirmation':
        return 'yellow';
      case 'confirmed':
        return 'green';
      case 'returned':
        return 'red';
      default:
        break;
    }
  };

  return (
    <>
      <Card key={id}>
        <Container>
          <Status color={getActionColor(action)}>{getTicketAction(action)}</Status>
          <Text as="span" size={2}>
            Исполнить до {moment.utc(date).format('L')} {moment.utc(date).format('LT')}
          </Text>
          <Title>{title}</Title>

          <UserCard>
            <Avatar
              size={32}
              src={`${files_url}/employees/avatar/${creator_id}/small_${creator_avatar}?token=${auth.token}`}
            />
            <Text size="2">{creator_first_name}</Text>
          </UserCard>
        </Container>
        <Button onClick={toggleModal}>Подробнее</Button>
      </Card>

      {visible && <TicketModal ticket={ticket} onClose={toggleModal} />}
    </>
  );
};

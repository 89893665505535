import axios from 'axios';
import { useEffect } from 'react';
import { useCallback } from 'react';
import { useContext, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// import { useParams } from "react-router-dom";
import SnackbarContext from '../../contexts/SnackbarContext';
import { getStorageTree } from '../../redux/features/storageSlice';
import { formatDateWithDots } from '../calendar-form/CalendarForm';
import Preloader from '../preloaders/Preloader';

import FolderAside from './FolderAside';
import StoragesDropdown from './StoragesDropdown';

const processDuplicateError = (error, type, showSnackbar, defaultMessage) => {
  //
  if (error.response?.status === 409 && error.response.data.message?.includes('already exists')) {
    //
    let dataType = '';
    if (type === 'folder') dataType = 'Папка';
    else if (type === 'file') dataType = 'Файл';
    showSnackbar(`${dataType} с таким названием уже существует`);
  } else {
    showSnackbar(defaultMessage);
  }
};

const TransferCopyFilesModal = ({ close, data, type, mode, renderUserName, storageId }) => {
  const currentStorageTree = useSelector((state) => state.storage.storageTree);
  const [selectedStorage, setSelectedStorage] = useState(null);
  const [selectedFolderData, setSelectedFolderData] = useState({
    id: null,
    path: null,
  });

  const handleDestinationFolderSelect = (folderId, folderPath) => {
    if (type === 'folder' && folderId === data.id) return;
    setSelectedFolderData({ id: folderId, path: folderPath });
  };

  const [isLoadingStorageTree, setIsLoadingStorageTree] = useState();

  const { showSnackbar } = useContext(SnackbarContext);

  const [selectedStorageTree, setSelectedStorageTree] = useState(null);

  const getStorageTreeDropdown = useCallback(() => {
    setIsLoadingStorageTree(true);
    setSelectedStorageTree(null);
    axios
      .get(`/api/storage_tree/${selectedStorage.id}`)
      .then((r) => {
        setIsLoadingStorageTree(false);
        setSelectedStorageTree(r.data.storage_tree);
      })
      .catch(() => {
        setIsLoadingStorageTree(false);
        showSnackbar('Возникла ошибка при загрузке данных выбранного хранилища');
      });
  }, [selectedStorage, showSnackbar]);

  // если файлы перемещаются внутри хранилища - показать дерево текущего хранилища
  // если файлы копируются в другое хранилище - получить дерево выбранного хранилища
  useEffect(() => {
    if (mode === 'transfer' && currentStorageTree) {
      setSelectedStorageTree(currentStorageTree);
    } else if (mode === 'copy' && selectedStorage) getStorageTreeDropdown();
  }, [selectedStorage, getStorageTreeDropdown, mode, currentStorageTree]);

  const [dropdown, setDropdown] = useState();

  const dispatch = useDispatch();

  const [isSubmitting, setIsSubmitting] = useState();

  const submitTransferRequest = () => {
    const reqBody = {
      [`${type}_id`]: data.id,
    };

    if (selectedFolderData.id !== 'root') {
      reqBody.parent_folder_id = selectedFolderData.id;
    }

    setIsSubmitting(true);
    axios
      .put('/api/move_storage_content', reqBody)
      .then(() => {
        dispatch(getStorageTree({ storageId, showSnackbar }));
        close();
      })
      .catch((error) => {
        processDuplicateError(error, type, showSnackbar, 'Возникла ошбика при переносе');
        setIsSubmitting(false);
      });
  };

  const submitCopyRequest = () => {
    const reqBody = {
      [`${type}_id`]: data.id,
    };
    if (selectedFolderData.id === 'root') {
      reqBody.parent_storage_id = selectedStorage.id;
    } else {
      reqBody.parent_folder_id = selectedFolderData.id;
    }

    setIsSubmitting(true);
    axios
      .post('/api/copy_storage_content', reqBody)
      .then(() => {
        showSnackbar('Данные успешно скопированы', 'success');

        close();
      })
      .catch((error) => {
        processDuplicateError(error, type, showSnackbar, 'Произошла ошибка при копировании данных в другое хранилище');
        setIsSubmitting(false);
      });
  };

  const handleSubmit = () => {
    if (!selectedFolderData.id) return;

    if (mode === 'transfer') submitTransferRequest();
    else if (mode === 'copy') submitCopyRequest();
  };

  return (
    <section className="modal">
      <div className="modal__wrapper">
        <div className="modal__inner">
          <section className="modal__body">
            <div className="modal__header">
              <h2 className="modal__title">
                {mode === 'transfer' && 'Перенести в папку этого хранилища'}
                {mode === 'copy' && 'Скопировать в другое хранилище'}
              </h2>
              <button className="modal__close-modal" onClick={close}></button>
            </div>
            <div className="modal__row">
              <ul className="storage-list">
                <li className={`storage__catalog-${type} catalog-${type} catalog-${type}--replace`}>
                  <p className="catalog-file__name">{data.title}</p>
                  <p className="catalog-file__weight">{/*20 мб*/}</p>
                  <p className="catalog-file__date">{formatDateWithDots(data.date_created)}</p>
                  {renderUserName(data.creator_id)}
                </li>
              </ul>
            </div>
            {mode === 'copy' && (
              <div className="modal__row">
                <h3 className="modal__subtitle">Хранилище</h3>
                <StoragesDropdown
                  handleAnotherStorageSelect={(data) => setSelectedStorage(data)}
                  selectedStorageTitle={selectedStorage?.title}
                />
              </div>
            )}

            {isLoadingStorageTree && <Preloader />}

            {selectedStorageTree && !isLoadingStorageTree && (
              <div className="modal__row">
                <h3 className="modal__subtitle">Папка</h3>

                <div
                  className={`modal__storage-dropdown dropdown ${dropdown ? '_show' : ''}`}
                  onClick={() => setDropdown(!dropdown)}
                >
                  <div className="dropdown__input" data-selected="">
                    {selectedFolderData.path?.reduce((prev, curr, index) => {
                      let pathString = prev;
                      pathString += Object.keys(curr)[0];
                      if (selectedFolderData.path.length - 1 !== index) {
                        pathString += ' / ';
                      }
                      return pathString;
                    }, '')}
                  </div>

                  <div className="dropdown__select select select--relative">
                    <ul className="rubric">
                      <li
                        className="rubric__item"
                        data-spoilers
                        onClick={(e) => {
                          e.stopPropagation();
                          setSelectedFolderData({
                            id: 'root',
                            path: [
                              {
                                [selectedStorageTree.title]: selectedStorageTree.id,
                              },
                            ],
                          });
                        }}
                      >
                        <span
                          className={`rubric__link _active ${selectedFolderData.id === 'root' ? '_highlight' : ''}`}
                          data-spoiler
                        >
                          {selectedStorageTree.title}
                        </span>
                        {selectedStorageTree.folders.length > 0 && (
                          <ul className="rubric">
                            {selectedStorageTree.folders.map((folder, i) => {
                              return (
                                <FolderAside
                                  key={i}
                                  data={folder}
                                  handleSelect={(id, folderPath) => handleDestinationFolderSelect(id, folderPath)}
                                  selectedFolderId={selectedFolderData.id}
                                />
                              );
                            })}
                          </ul>
                        )}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            )}
            <div className="modal__button-box">
              <button
                className="modal__button modal__button--create"
                disabled={!selectedFolderData.id || isSubmitting}
                onClick={handleSubmit}
              >
                {mode === 'transfer' && !isSubmitting && 'Перенести'}
                {mode === 'copy' && !isSubmitting && 'Скопировать'}
                {isSubmitting && 'Отправка...'}
              </button>
              <button className="modal__button modal__button--cancel" onClick={close}>
                Отмена
              </button>
            </div>
          </section>
        </div>
      </div>
    </section>
  );
};

export default TransferCopyFilesModal;

import { useRef, useState, useCallback, useEffect, useContext, memo, useMemo } from 'react';
import { EditorState, RichUtils, convertToRaw, convertFromRaw } from 'draft-js';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import './textEditor.css';
import axios from 'axios';

import { userWrites, userStoppedWriting } from '../../settings/sockets';
import useAuth from '../../hooks/useAuth';
import { useSocketContext } from '../../contexts/SocketContext';
import { randomSequence, useOutsideTrigger } from '../../utilize/helper-functions';

import SelectTagPopover from '../tags/SelectTagPopover';
import SnackbarContext from '../../contexts/SnackbarContext';
import LocalDateTime from '../shared/LocalDateTime';
import FilesUploadManage from '../files/FilesUploadManage';
import { files_url } from '../../settings/base-url';
import StorageModal from '../../windows/storage/StorageModal';
import ModalPortal from '../modals/ModalPortal';
import FileToUpload from '../files/FileToUpload';

import EditorWithMentions from './mentions/EditorWithMentions';
import editorStyles from './TextEditorChat.module.css';

let typing, typingTimeoutId;

const MESSAGE_SUBMIT_STATUS = {
  pending_files_upload: 'pending_files_upload',
  ready_to_send: 'ready_to_send',
  sending_message: 'sending_message',
};

// в качестве редактора сообщений используется сторонняя библиотека draft-js
const ChatTextEditor = ({
  draggedFiles,
  chatId,
  goals,
  quoteMessage,
  setQuoteMessage,
  messageToEdit,
  editMessage,
  filesUploadDisabled,
  // fileFromStorage,
  storageId,
  setDisableChatFilesDrop,
}) => {
  const { t } = useTranslation();
  const { taskId } = useParams();
  const tagsList = useSelector((state) => state.tags.tagsList);
  const auth = useAuth();

  //показывает кнопки стилей
  const [stylingToolbar, setStylingToolbar] = useState(false);

  const { socket, initSocket, sendMessage, sendMessageEdit } = useSocketContext();
  // для показа всплывающих информационных сообщений
  const { showSnackbar } = useContext(SnackbarContext);

  // для ввода заголовка сообщения
  const [messageTitle, setChatTitle] = useState('');
  // state библиотеки draft-js
  const [editorState, setEditorState] = useState(() => EditorState.createEmpty());
  const [selectedTags, setSelectedTags] = useState([]);
  // для отображения статуса отправки файлов при отправлении сообщения
  // const [fileUploadPercentages, setFileUploadPercentages] = useState({});
  // для выбора результата при написании сообщения
  const [goalMessage, setGoalMessage] = useState(null);

  const editorRef = useRef();
  const containerRef = useRef();

  // для редактирования списка файлов при редактировании сообщения
  // const [messageFilesToEdit, setMessageFilesToEdit] = useState(null);
  const [filesDeleteArr, setFilesDeleteArr] = useState([]);
  const messageEditFilesToDelete = useRef([]);

  const { filesToEdit, addFilesFormDataTemplate } = useMemo(() => {
    let filesToEdit, addFilesFormDataTemplate;
    if (messageToEdit?.message_files && auth?.token) {
      filesToEdit = messageToEdit.message_files.map((file) => ({
        ...file,
        name: file.file,
        src: `${files_url}/chat_messages/files/${messageToEdit.chat_message_id}/${file.id}/${file.file}?token=${auth.token}`,
      }));

      addFilesFormDataTemplate = new FormData();
      addFilesFormDataTemplate.append('chat_message_id', messageToEdit.chat_message_id);
    }
    return { filesToEdit, addFilesFormDataTemplate };
  }, [messageToEdit, auth]);

  // если в одном из сообщений нажимается кнопка "Редактировать",
  // то установить все параметры для редактирования этого сообщения
  useEffect(() => {
    if (messageToEdit) {
      // установить state draft-js для редактирования текста сообщения
      if (messageToEdit.editor_state) {
        const editorState = convertFromRaw(JSON.parse(messageToEdit.editor_state));
        setEditorState(EditorState.createWithContent(editorState));
        // сфокусировать курсор на редакторе сообщения
        setTimeout(() => {
          if (editorRef.current) {
            editorRef.current.focus();
            // if (containerRef.current) {
            //   containerRef.current.scrollIntoView({
            //     behavior: "smooth",
            //     block: "center",
            //     inline: "start",
            //   });
            // }
          }
        }, 10);
      }
      // если в редактируемом сообщении есть тэги, то установить тэги для редактирования
      if (messageToEdit.tags) {
        setSelectedTags(
          messageToEdit.tags.map((tag) => {
            const updTag = { ...tag };
            updTag.id = tag.tag_id;
            return updTag;
          }),
        );
      }

      // если в редактируемом сообщении добавлен результат, то установить результат для редактирования
      if (messageToEdit.goals.length) {
        const { project_goal_id, task_goal_id } = messageToEdit.goals[0];
        const selectedGoal = goals.find((goal) => goal.id === project_goal_id || goal.id === task_goal_id);

        if (selectedGoal) setGoalMessage(selectedGoal);
      }
      // если в редактируемом сообщении есть заголовок, то установить заголовок для редактирования
      if (messageToEdit.title && !messageToEdit.goals.legnth) {
        toggleTitleInput(true);
        setChatTitle(messageToEdit.title);
      }
      // если редактируемое сообщение является ответом на другое сообщение, то установить соответствующие параметры для редактирования ответа
      if (messageToEdit.reply_text || messageToEdit.reply_first_name) {
        const author = `${messageToEdit.reply_first_name || ''} ${messageToEdit.reply_last_name || ''}`;
        setQuoteMessage({
          author,
          quoteText: messageToEdit.reply_text,
          dateTime: <LocalDateTime dateTime={messageToEdit.reply_date} />,
        });
      }
    }
  }, [messageToEdit, setQuoteMessage, goals]);

  // инициировать сокет, если ранее не был инициирован
  useEffect(() => {
    if (!socket?.connected && auth?.token) {
      initSocket(auth.token);
    }
  }, [socket, auth, initSocket]);

  // если файлы были сброшены перетаскиванием, то данный эффект перехватывает эти файлы с компонента-родителя (SingleProjectChat)
  useEffect(() => {
    if (draggedFiles) setFilesToUpload([...draggedFiles]);
  }, [draggedFiles]);

  const [filesToUpload, setFilesToUpload] = useState([]);
  const [messageSubmitStatus, setMessageSubmitStatus] = useState();
  const [isFilesUploading, setIsFilesUploading] = useState();

  const [titleInput, toggleTitleInput] = useState(false);
  const [chatOption, setChatOption] = useState();

  // функция отправляет socket сообщение серверу, когда пользователь печатает
  const updateTyping = useCallback(() => {
    if (socket && auth?.user?.id) {
      if (!typing) {
        typing = true;
        userWrites(socket, chatId);
      }

      clearTimeout(typingTimeoutId);

      typingTimeoutId = setTimeout(() => {
        userStoppedWriting(socket, chatId);
        typing = false;
      }, 3000);
    }
  }, [socket, auth, chatId]);

  // регистрация функции для отправления socket сообщения серверу, когда юзер печатает
  useEffect(() => {
    const editor = document.getElementById('chatMessageBox');
    editor.addEventListener('keydown', updateTyping);

    // отмена регистрации, при выходе из страницы
    return () => {
      editor.removeEventListener('keydown', updateTyping);
    };
  }, [updateTyping]);

  // управлять командами с клавиатуры для форматирования выделенного текста (Ctrl+B, Ctrl+U)
  const handleKeyCommand = (command) => {
    const newState = RichUtils.handleKeyCommand(editorState, command);
    if (newState) {
      setEditorState(newState);
      return 'handled';
    }
    return 'not-handled';
  };

  const onBoldClick = (e) => {
    e.preventDefault();
    setEditorState(RichUtils.toggleInlineStyle(editorState, 'BOLD'));
  };

  const onUnderlineClick = (e) => {
    e.preventDefault();
    setEditorState(RichUtils.toggleInlineStyle(editorState, 'UNDERLINE'));
  };

  const form_id = useRef(randomSequence());

  const reset = useRef(() => {
    setEditorState(EditorState.createEmpty());
    toggleTitleInput(false);
    setChatTitle('');
    setFilesToUpload([]);
    setFilesFromStorage([]);
    setSelectedTags([]);
    setQuoteMessage(null);
    setGoalMessage(null);
    setMessageSubmitStatus(null);
    setIsFilesUploading(false);
    form_id.current = randomSequence();
    if (typeof clearFileManager.current === 'function') {
      clearFileManager.current();
    }
    editMessage(null);
    messageEditFilesToDelete.current = [];
    // если не поставить timeout, то react не успевает очистить state редактора и сразу устанавливается фокус с текущим текстом
    setTimeout(() => editorRef.current.focus(), 1);
  });

  const [filesFromStorage, setFilesFromStorage] = useState([]);

  const addStorageFileReference = useCallback((storageFileData) => {
    setFilesFromStorage((files) => {
      const alreadyAdded = files.some((fileData) => fileData.id === storageFileData.id);
      if (alreadyAdded) return files;
      return [...files, storageFileData];
    });
    showSnackbar('Ссылка на документ добавлена в сообщение', 'success');
  }, []);

  // useEffect(() => {
  //   if (!fileFromStorage) return;
  //   setFilesFromStorage((files) => {
  //     const addedFile = files.find((fileData) => fileData.id === fileFromStorage.id);
  //     if (addedFile) return files;
  //     return [...files, fileFromStorage];
  //   });
  //   showSnackbar('Ссылка на документ добавлена в сообщение', 'success');
  // }, [fileFromStorage, showSnackbar]);

  const submitMessage = useCallback(async () => {
    if (isFilesUploading && isFilesUploading !== 'completed') {
      setMessageSubmitStatus(MESSAGE_SUBMIT_STATUS.pending_files_upload);
      return;
    }

    // если это сообщение отправляется как результат и для результата нужен файл, то проверить
    // есть ли отправляемый файл; есть ли файл, прикрепляемый из хранилища;
    // при редактировании сообщения - есть ли нетронутый файл (который не удаляется)
    // если ни одно из вышеперечисленных условий не выполнено, то показать сообщение, что нужно добавить файл
    if (goalMessage?.type === 'file') {
      if (!filesToUpload.length && !filesFromStorage.length && !filesToEdit?.find((f) => !filesDeleteArr.includes(f))) {
        setMessageSubmitStatus(null);
        return showSnackbar('Нужно добавить файл');
      }
    }

    setMessageSubmitStatus(MESSAGE_SUBMIT_STATUS.sending_message);
    if (filesFromStorage.length) {
      const storageFilesReq = {
        storage_files: filesFromStorage.map((f) => f.id),
        form_id: form_id.current,
      };
      if (messageToEdit) {
        storageFilesReq.chat_message_id = messageToEdit.chat_message_id;
      }
      await axios.post('/api/chat_message_files/add_from_storage', storageFilesReq).catch(() => {
        showSnackbar('Возникла ошибка при прикреплении файла из хранилища');
      });
    }
    //при редактировании сообщения, удалить файлы, если юзер решил удалить
    if (messageToEdit && filesDeleteArr.length) {
      const filesRemoveReq = {
        chat_message_id: messageToEdit.chat_message_id,
        files: filesDeleteArr.map((f) => f.id),
      };
      await axios.patch('/api/chat_message_files/remove', filesRemoveReq).catch(() => {
        showSnackbar('Возникла ошибка при удалении файла');
      });
    }

    if (socket && socket.connected) {
      const currentContent = editorState.getCurrentContent();
      const rawEditorContent = convertToRaw(currentContent);
      const plainText = currentContent.getPlainText();

      let messageData = {
        chat_messages: {
          chat_id: chatId,
          message: plainText,
          editor_state: JSON.stringify(rawEditorContent),
          title: messageTitle,
        },
        form_id: form_id.current,
      };
      // если есть упоминания юзеров или url-ссылки, добавить в запрос
      const { entityMap } = rawEditorContent;
      const mentionedUserIds = [];
      const links = [];
      for (const entity in entityMap) {
        if (entityMap[entity].type === 'mention') {
          const userId = entityMap[entity].data.mention.userId;
          if (mentionedUserIds.indexOf(userId) === -1) {
            mentionedUserIds.push(userId);
          }
        } else if (entityMap[entity].type === 'LINK') {
          links.push(entityMap[entity].data.url);
        }
      }

      if (mentionedUserIds.length) {
        messageData.chat_message_mentions = mentionedUserIds.map((id) => ({
          employee_id: id,
        }));
      }
      if (links.length) messageData.chat_message_links = links;

      // добавить тэги в запрос, если есть
      if (selectedTags.length) {
        messageData.chat_message_tags = selectedTags.map((tag) => ({
          tag_id: tag.id,
        }));
      }
      // добавить результат к сообщению, если выбран

      if (goalMessage) {
        messageData.chat_goal_results = {};
        taskId
          ? (messageData.chat_goal_results.task_goal_id = goalMessage.id)
          : (messageData.chat_goal_results.project_goal_id = goalMessage.id);
      }
      // добавить цитирование другого сообщения, если выбрана
      if (quoteMessage) {
        messageData.chat_messages.reply_id = quoteMessage.messageId;
        messageData.chat_messages.reply_text = quoteMessage.quoteText;
      } else {
        messageData.chat_messages.reply_id = null;
        messageData.chat_messages.reply_text = null;
      }

      // отправить на редактирование сообщения, если ранее была нажата кнопка "Редактировать" в сообщении
      if (messageToEdit) {
        messageData.chat_message_id = messageToEdit.chat_message_id;
        sendMessageEdit(messageData, reset.current);
      } else sendMessage(socket, messageData, reset.current);
    } else {
      showSnackbar('Нет соединения с сервером', 'fail');
    }
  }, [
    chatId,
    editorState,
    filesToUpload,
    filesFromStorage,
    filesDeleteArr,
    goalMessage,
    isFilesUploading,
    // messageFilesToEdit,
    messageTitle,
    messageToEdit,
    quoteMessage,
    selectedTags,
    sendMessage,
    sendMessageEdit,
    showSnackbar,
    socket,
    taskId,
  ]);

  // если все файлы были выгружены на сервер и отправляемое сообщение ожидало отправки файлов,
  // то поставить статус отправки сообщения "готово" с последующим вызовом функции отправки сообщения
  useEffect(() => {
    if (messageSubmitStatus === MESSAGE_SUBMIT_STATUS.pending_files_upload && isFilesUploading === 'completed') {
      setMessageSubmitStatus(MESSAGE_SUBMIT_STATUS.ready_to_send);
    }
  }, [messageSubmitStatus, isFilesUploading]);

  useEffect(() => {
    if (messageSubmitStatus === MESSAGE_SUBMIT_STATUS.ready_to_send) {
      submitMessage();
    }
  }, [messageSubmitStatus, submitMessage]);

  const removeFilesOnClose = useRef();
  const clearFileManager = useRef();

  const openFileSelectPopup = () => {
    document.getElementById('upfile').click();
  };

  // const removeFile = (index) => {
  //   setFiles(files.filter((file, i) => i !== index));
  // };

  // react-ссылки на контекстное меню текстового редактора (всплывашки)
  const dotsOptionRef = useRef();
  const dotsButtonRef = useRef();
  const tagsOptionRef = useRef();
  const tagsButtonRef = useRef();
  const goalsOptionRef = useRef();
  const goalsButtonRef = useRef();
  const clearChatOption = useCallback(() => setChatOption(null), []);
  const popupRefsArray = useRef([
    dotsOptionRef,
    dotsButtonRef,
    tagsButtonRef,
    tagsOptionRef,
    goalsOptionRef,
    goalsButtonRef,
  ]);

  // закрывает контекстное меню чата при клике вне меню
  useOutsideTrigger(popupRefsArray.current, clearChatOption, chatOption);

  const isStyleSelected = (styleType) => {
    const currentInlineStyle = editorState.getCurrentInlineStyle();
    return currentInlineStyle.has(styleType) ? 'selected' : '';
  };

  // валидация для отключения кнопки Отправить
  const validateBeforeSubmit = () => {
    return (
      messageSubmitStatus ||
      (!editorState.getCurrentContent().hasText() &&
        filesToUpload.length === 0 &&
        filesFromStorage.length === 0 &&
        filesToEdit?.every((file) => filesDeleteArr.includes(file)))
    );
  };

  const showCancelButton = () => {
    if (messageToEdit) return true;
    if (isFilesUploading) return true;
    return false;
  };

  const cancelAll = () => {
    removeFilesOnClose.current();
    reset.current();
  };

  const [showStorage, setShowStorage] = useState();

  return (
    <>
      <div id="chatMessageBox">
        {/* если в сообщении выбран результат, то блок кода ниже отображает этот результат */}
        {goalMessage && (
          <div className="chat__quote-box quote-box">
            <div className="quote-box__body">
              <button className="quote-box__button-delete" onClick={() => setGoalMessage(null)}></button>
              <div className="message__attach-link">
                {t('common.result')}: {goalMessage.description}
                {goalMessage.type === 'file' ? ` (${t('Goals.file')})` : ` (${t('Goals.text')})`}
              </div>
            </div>
          </div>
        )}

        {/* сообщение может быть либо результатом, либо с заголовком */}
        {!goalMessage && titleInput && (
          <input
            type="text"
            className="chat__input"
            placeholder={t('common.title')}
            value={messageTitle}
            onChange={(e) => setChatTitle(e.target.value)}
          />
        )}

        {/* если пишется ответ на другое сообщение, то данный блок показывает сообщение на которое отвечают */}
        {quoteMessage && (
          <div className="chat__quote-box quote-box">
            <div className="quote-box__body">
              <button className="quote-box__button-delete" onClick={() => setQuoteMessage(null)}></button>
              <div className="quote">
                <span className="quote__name">{quoteMessage.author}</span>
                <span className="quote__date"> ({quoteMessage.dateTime})</span>
                <p className="quote__message">{quoteMessage.quoteText} </p>
              </div>
            </div>
          </div>
        )}

        {/* показывает кнопки форматирования */}
        {stylingToolbar && (
          <div className="toolbar">
            <button
              className={`toolbar__item ${isStyleSelected('BOLD')}`}
              onClick={(e) => e.preventDefault()}
              onMouseDown={onBoldClick}
              type="button"
            >
              <b>B</b>
            </button>
            <button
              className={`toolbar__item ${isStyleSelected('UNDERLINE')}`}
              onClick={(e) => e.preventDefault()}
              onMouseDown={onUnderlineClick}
              type="button"
            >
              U
            </button>
          </div>
        )}

        {/* редактор draft-js с функционалом по отмечанию других юзеров */}
        <div className={editorStyles.editor}>
          <EditorWithMentions
            editorState={editorState}
            setEditorState={setEditorState}
            handleKeyCommand={handleKeyCommand}
            quoteMessage={quoteMessage}
            editorRef={editorRef}
            containerRef={containerRef}
          />
        </div>
      </div>

      {/* показывает, если прикреплены файлы к сообщению */}
      {(filesToUpload?.length > 0 || messageToEdit?.message_files.length > 0 || filesFromStorage.length > 0) && (
        <div className="chat__attach attach">
          {filesFromStorage.map((f, i) => (
            <FileToUpload
              file={f}
              progressPercent={100}
              isUploaded
              removeFile={() => {
                setFilesFromStorage((files) => files.filter((file, index) => index !== i));
              }}
              key={i}
              index={i}
            />
          ))}
          <FilesUploadManage
            formId={form_id.current}
            filesToUpload={filesToUpload}
            filesToEdit={filesToEdit}
            addFilesFormDataTemplate={addFilesFormDataTemplate}
            filesDeleteArr={filesDeleteArr}
            setFilesDeleteArr={setFilesDeleteArr}
            filesUploadPath="/api/chat_message_files/add"
            filesDeletePath="/api/chat_message_files/remove"
            setIsFilesUploading={setIsFilesUploading}
            removeFilesOnClose={removeFilesOnClose}
            removeById
            showStorage={showStorage}
            clearFileManager={clearFileManager}
          />
        </div>
      )}

      {/* показывает если выбраны тэги  */}
      {selectedTags.length > 0 && (
        <ul className="chat__attach-tags attach-tags">
          {selectedTags.map((tag, i) => (
            <li className="attach-tags__item" key={i}>
              <span className={`message__mark message__mark--${tag.color}`}>{tag.name}</span>
              <button
                className="attach-tags__button-delete"
                onClick={() => setSelectedTags(selectedTags.filter((t) => t.id !== tag.id))}
              ></button>
            </li>
          ))}
        </ul>
      )}

      {/* кнопки отправления/отмены сообщения и доп. функционала редактора */}
      <div className="chat__row">
        <div className="chat__col">
          <button className="chat__button-send" onClick={submitMessage} disabled={validateBeforeSubmit()}>
            {messageToEdit
              ? t(`FormButtons.${messageSubmitStatus ? 'saving' : 'save'}`)
              : t(`FormButtons.${messageSubmitStatus ? 'sending' : 'send'}`)}
          </button>

          {/* показывать кнопку Отмена, если сообщение редактируется */}
          {showCancelButton() && (
            <button className="chat__button-cancel" onClick={cancelAll}>
              {t(`FormButtons.cancel`)}
            </button>
          )}
        </div>
        <div className="chat__col">
          {/* кнопка с троеточием */}
          <div className="chat__controls-wrapper">
            <button
              className="chat__controls chat__controls--dots"
              title={t('Project.other_actions')}
              onClick={() => setChatOption(chatOption === 'dots' ? null : 'dots')}
              ref={dotsButtonRef}
            ></button>

            <section className={`chat-menu ${chatOption === 'dots' ? 'active' : ''}`} ref={dotsOptionRef}>
              <p
                className="chat-menu__item"
                onClick={() => {
                  toggleTitleInput(!titleInput);
                  setChatOption(null);
                }}
              >
                {t('FormButtons.message_header')}
              </p>
              {/* <p className="chat-menu__item">{t("FormButtons.add_poll")}</p>
              <p className="chat-menu__item">
                {t("FormButtons.add_client_contact")}
              </p> */}
            </section>
          </div>

          {/* <div className="chat__controls-wrapper">
            <button
              className="chat__controls chat__controls--event"
              title={t("Project.mark_event")}
            ></button>
          </div> */}

          {/* кнопка форматирования текста */}
          <div className="chat__controls-wrapper">
            <button
              className="chat__controls chat__controls--richtext"
              title={t('Project.format')}
              onClick={() => setStylingToolbar(!stylingToolbar)}
            ></button>
          </div>

          <div className="chat__controls-wrapper">
            <button
              className="chat__controls chat__controls--tags"
              title={t('Project.add_tag')}
              onClick={() => setChatOption(chatOption === 'tags' ? null : 'tags')}
              ref={tagsButtonRef}
            ></button>
            <SelectTagPopover
              tagsList={tagsList}
              chatOption={chatOption}
              tagsOptionRef={tagsOptionRef}
              selectedTags={selectedTags}
              setSelectedTags={setSelectedTags}
            />
          </div>

          {/* кнопка открытия хранилища */}
          {!filesUploadDisabled && (
            <div className="chat__controls-wrapper">
              <button
                title="Открыть файловое хранилище"
                className="chat__controls chat__controls--database"
                onClick={() => {
                  setShowStorage(true);
                  if (setDisableChatFilesDrop) setDisableChatFilesDrop(true);
                }}
              ></button>
            </div>
          )}

          {/* кнопка прикрепления файлов */}
          {!filesUploadDisabled && (
            <div className="chat__controls-wrapper">
              <button
                title={t('Project.add_files')}
                className="chat__controls chat__controls--attach"
                onClick={openFileSelectPopup}
              >
                <input
                  type="file"
                  multiple
                  id="upfile"
                  style={{ display: 'none' }}
                  onChange={(e) => setFilesToUpload([...e.target.files])}
                />
              </button>
            </div>
          )}

          {/* кнопка выбора результата */}
          {goals?.length > 0 && (
            <div className="chat__controls-wrapper">
              <button
                className="chat__controls chat__controls--goal"
                title={t('Project.mark_result')}
                onClick={() => setChatOption(chatOption === 'goals' ? null : 'goals')}
                ref={goalsButtonRef}
              ></button>

              <ul
                className={`chat__incoming-menu incoming-menu ${chatOption === 'goals' ? 'active' : null}`}
                ref={goalsOptionRef}
              >
                {goals?.map((goal, i) => {
                  return (
                    <li
                      key={i}
                      className="incoming-menu__item"
                      onClick={() => {
                        setGoalMessage(goal);
                        setChatOption(null);
                      }}
                    >
                      <div className="incoming-menu__header">
                        <p className="incoming-menu__title">{goal.description}</p>
                        <p className="incoming-menu__type">{t(`Goals.${goal.type}`)}</p>
                      </div>
                    </li>
                  );
                })}
              </ul>
            </div>
          )}
        </div>
      </div>

      {showStorage && (
        <ModalPortal>
          <StorageModal
            noURLParams
            close={() => {
              setShowStorage(false);
              if (setDisableChatFilesDrop) setDisableChatFilesDrop(false);
            }}
            storageId={storageId}
            addStorageFileReference={addStorageFileReference}
          />
        </ModalPortal>
      )}
    </>
  );
};

export default memo(ChatTextEditor);

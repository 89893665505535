import React from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';

import { PageTickets } from 'src/pages/PageTickets';

import { PageTicketsForMe } from 'src/pages/PageTickets/PageTicketsForMe';

import Home from '../../pages/home/home';
import Login from '../../pages/login/login';
import NotFound from '../../pages/NotFound';
import PrivateRoute from '../components/PrivateRoute';
import Forgot from '../../pages/forgot/forgot';
import Contacts from '../../pages/Contacts';
import Team from '../../pages/team/team';
import Salaries from '../../pages/team/salaries';
import Money from '../../pages/team/money';
import Reminders from '../../pages/Reminders/Reminders';
import Starred from '../../pages/Starred/Starred';
import Alerts from '../../pages/Alerts/Alerts';
import Tasks from '../../pages/Tasks/Tasks';
import Clients from '../../pages/Contacts/Clients';
import Partners from '../../pages/Contacts/Partners';
import Vacations from '../../pages/team/vacations';
import ProjectsRoutes from '../../pages/Projects/ProjectsRoutes';
import NewsRoutes from '../../pages/NewsBlogs/NewsBlogsRoutes';
import RestorePassword from '../../pages/restore-password/restore-password';
import TeamTree from '../../pages/team/team-tree/TeamTree';
import Nav from '../../components/nav/nav';
import UserProfileModal from '../../windows/profile/UserProfileModal';
import TeamMain from '../../pages/team/team-main/TeamMain';
import GuestList from '../../pages/team/guest-list/GuestList';
import StorageRoutes from '../../pages/Storage/StorageRoutes';

import StyledTest from '../../pages/StyledTest/StyledTest';

const AllRouters = ({ popupWindow }) => {
  let location = useLocation();
  let state = location.state; // откуда user перешел на страницу, предыдущий адрес

  return (
    <>
      <Routes location={state?.backgroundLocation || location}>
        <Route path="/login/" element={<Login />} />

        <Route path={'/restore_password/:token'} element={<RestorePassword />} />

        <Route exact path="/forgot" element={<Forgot />} />

        <Route
          path="*"
          element={
            <>
              <Nav popupWindow={popupWindow} />

              <Routes location={state?.backgroundLocation || location}>
                <Route
                  exact
                  path="/"
                  element={
                    <PrivateRoute>
                      <Home />
                    </PrivateRoute>
                  }
                />

                <Route
                  path={'/team'}
                  element={
                    <PrivateRoute>
                      <Team />
                    </PrivateRoute>
                  }
                >
                  <Route
                    path="users"
                    element={
                      <PrivateRoute>
                        <TeamMain />
                      </PrivateRoute>
                    }
                  />

                  <Route
                    path="guests/:guest_id?"
                    element={
                      <PrivateRoute>
                        <GuestList />
                      </PrivateRoute>
                    }
                  />

                  <Route
                    path="tree"
                    element={
                      <PrivateRoute>
                        <TeamTree />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="salaries"
                    element={
                      <PrivateRoute>
                        <Salaries />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="money"
                    element={
                      <PrivateRoute>
                        <Money />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="vacations"
                    element={
                      <PrivateRoute>
                        <Vacations />
                      </PrivateRoute>
                    }
                  />
                </Route>

                <Route
                  path="/contacts"
                  element={
                    <PrivateRoute>
                      <Contacts />
                    </PrivateRoute>
                  }
                />

                <Route path="/projects/*" element={<ProjectsRoutes popupWindow={popupWindow} />} />

                <Route
                  path="/contacts/clients"
                  element={
                    <PrivateRoute>
                      <Clients />
                    </PrivateRoute>
                  }
                />

                <Route
                  path="/contacts/partners"
                  element={
                    <PrivateRoute>
                      <Partners />
                    </PrivateRoute>
                  }
                />

                <Route
                  path="/reminders"
                  element={
                    <PrivateRoute>
                      <Reminders />
                    </PrivateRoute>
                  }
                />

                <Route
                  path="/starred"
                  element={
                    <PrivateRoute>
                      <Starred />
                    </PrivateRoute>
                  }
                />

                <Route
                  path="/alerts"
                  element={
                    <PrivateRoute>
                      <Alerts />
                    </PrivateRoute>
                  }
                />

                <Route
                  path="/tasks"
                  element={
                    <PrivateRoute>
                      <Tasks />
                    </PrivateRoute>
                  }
                />

                <Route
                  path="/news/*"
                  element={
                    <PrivateRoute>
                      <NewsRoutes />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/blogs/*"
                  element={
                    <PrivateRoute>
                      <NewsRoutes />
                    </PrivateRoute>
                  }
                />

                <Route
                  path="/tickets"
                  element={
                    <PrivateRoute>
                      <PageTickets />
                    </PrivateRoute>
                  }
                />

                <Route
                  path="/tickets/me"
                  element={
                    <PrivateRoute>
                      <PageTicketsForMe />
                    </PrivateRoute>
                  }
                />

                <Route
                  path="/storages/*"
                  element={
                    <PrivateRoute>
                      <StorageRoutes />
                    </PrivateRoute>
                  }
                />

                <Route
                  path="*"
                  element={
                    <PrivateRoute>
                      <NotFound />
                    </PrivateRoute>
                  }
                />
              </Routes>
            </>
          }
        />
      </Routes>
      {/* фоновая страница */}
      <Routes>
        <Route
          path="/team/departments/:id"
          element={
            <PrivateRoute>
              <UserProfileModal page="TeamTree" />
            </PrivateRoute>
          }
        />

        <Route
          path="/team/users/:id"
          element={
            <PrivateRoute>
              <UserProfileModal page="TeamList" />
            </PrivateRoute>
          }
        />

        {/* <Route
          path="/team/guests/:id"
          element={
            <PrivateRoute>
              <GuestFullProfileModal />
            </PrivateRoute>
          }
        /> */}

        <Route path="/styled-test" element={<StyledTest />} />
      </Routes>
    </>
  );
};

export default AllRouters;

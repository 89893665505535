import { NavLink } from 'react-router-dom';
import { StyledLink } from '@shared/components/Link/style';

export const Link = ({ link, tag, color, hover, children }) => {
  return (
    <NavLink to={link}>
      <StyledLink as={tag} color={color} hover={hover}>
        {children}
      </StyledLink>
    </NavLink>
  );
};

import { Modal } from '@shared/components/Modal/Modal';
import { Label } from '@shared/components/Label/Label';
import { Text } from '@shared/components/Text/Text';
import { StyledModalRowEnd } from '@shared/components/Modal/style';
import { User } from '@shared/components/User/User';

import { Button } from '@shared/components/Button/Button';

import { files_url } from 'src/settings/base-url';
import useAuth from 'src/hooks/useAuth';

import { useConfirmTicketMutation } from 'src/redux/features/api/tickets';

import { useMemo, useRef, useState } from 'react';

import { TicketReport } from '@components/Tickets/TicketsFromMe/TicketReport/TicketReport';

import moment from 'moment';

import { Link } from '@shared/components/Link/Link';

import { ModalStatusInfo } from '@shared/components/Modal/ModalStatusInfo';

import { Container } from '@shared/components/Container';

import AttachedFile from '@components/files/AttachedFile';

import HTMLReactParser from 'html-react-parser';

import { TicketStatus } from '../TicketStatus';

import { ButtonsContainer, EditButton } from './styles';
import { ReturnTicketForm } from './ui/ReturnTicketForm';

export const TicketInfoModal = ({ ticket, executor, executionDate, onClose, onEditTicket, onCancelTicket }) => {
  const [formVisible, setFormVisible] = useState(false);

  const auth = useAuth();

  const [confirmTicket, { isLoading: isConfirmTicketLoading, isConfirmTicketError }] = useConfirmTicketMutation();

  const isNeedToCheck = useMemo(
    () => ticket.ticket_actions[ticket.ticket_actions.length - 1].action === 'confirmation',
    [ticket],
  );

  const isCanceled = useMemo(
    () => ticket.ticket_actions[ticket.ticket_actions.length - 1].action === 'canceled',
    [ticket],
  );

  const isConfirmed = useMemo(
    () => ticket.ticket_actions[ticket.ticket_actions.length - 1].action === 'confirmed',
    [ticket],
  );

  const isExpired = useMemo(() => moment().diff(ticket.date_finish) > 0, [ticket]);

  const handleСonfirmTicket = async () => {
    await confirmTicket({
      ticket_id: ticket.id,
    });
    onClose();
  };

  const removeFilesOnClose = useRef();

  const onCloseHandler = async () => {
    const onRemove = removeFilesOnClose.current;

    if (onRemove) {
      await onRemove();
    }

    onClose();
  };

  return (
    <Modal
      modalSize="767px"
      title="Поручение"
      onClose={onCloseHandler}
      withoutButtons
      headerContent={
        <EditButton size={'sm'} color={'light'} onClick={onEditTicket}>
          Редактировать
        </EditButton>
      }
    >
      {ticket.base_chat_message_id && (
        <div>
          <ModalStatusInfo>
            <Text tag="p">
              Поручение создано на основе {ticket.base_chat_message.project_title ? 'проекта' : 'задачи'}
              <Link link="/linktotask" tag="b" hover="var(--main)">
                {` "${ticket.base_chat_message.project_title || ticket.base_chat_message.task_title}".`}
              </Link>
            </Text>
            <Text tag="p">{ticket.base_chat_message.message}</Text>
          </ModalStatusInfo>
        </div>
      )}
      <div>
        <StyledModalRowEnd>
          <div>
            <Label>Название</Label>
            <Text size={2} tag="b">
              {ticket.title}
            </Text>
          </div>
          <div>
            <Label>Кому</Label>
            <User
              size="sm"
              picture={`${files_url}/employees/avatar/${executor.id}/small_${executor.avatar}?token=${auth.token}`}
              name={executor.first_name}
            ></User>
          </div>
        </StyledModalRowEnd>
      </div>

      <Container direction="column" gap={'8px'}>
        <div>
          <Label>Описание</Label>
          <Text size={2} tag="p">
            {HTMLReactParser(ticket.description)}
          </Text>
        </div>

        <div>
          {ticket.ticket_actions[0].ticket_action_files?.length > 0 && (
            <>
              {ticket.ticket_actions[0].ticket_action_files.map((file, i) => {
                const url = `${files_url}/ticket_actions/files/${ticket.ticket_actions[0].id}/${file.id}/${file.file}?token=${auth.token}`;
                return (
                  <AttachedFile
                    fileUrl={url}
                    fileName={file.file}
                    fileId={file.id}
                    key={i}
                    storageFileId={file.from_storage}
                  />
                );
              })}
            </>
          )}
        </div>
      </Container>

      <div>
        <Label>Срок исполнения</Label>
        <Container direction="column" gap="2px">
          <Text size={2} tag="b">
            {moment.utc(executionDate).format('L')} {moment.utc(executionDate).format('LT')}
          </Text>
          {isExpired && !isConfirmed && <TicketStatus color="red">Просрочено</TicketStatus>}
        </Container>
      </div>

      <Container direction={'column'}>
        {ticket.ticket_actions.map((action) => {
          return action.report ? <TicketReport action={action} /> : null;
        })}
      </Container>

      {!isCanceled && !isConfirmed && (
        <>
          {!formVisible ? (
            <ButtonsContainer>
              {isNeedToCheck && (
                <>
                  <Button color="green" onClick={handleСonfirmTicket}>
                    Принять поручение
                  </Button>
                  <Button color="red" onClick={() => setFormVisible(true)}>
                    Не принимать поручение
                  </Button>
                </>
              )}
              <Button onClick={onCancelTicket}>Отменить поручение</Button>
            </ButtonsContainer>
          ) : (
            <ReturnTicketForm
              ticketId={ticket.id}
              onCancel={() => setFormVisible(false)}
              onClose={onClose}
              removeFilesOnClose={removeFilesOnClose}
            />
          )}
        </>
      )}
    </Modal>
  );
};

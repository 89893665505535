import { Fragment } from 'react';

import ProgressBar from '../files/ProgressBar_2';
import { unique13NumRegex } from '../../utilize/regex';
import { isFileNameImage } from '../../utilize/helper-functions';

const FileToUpload = ({
  file,
  progressPercent,
  removeFile,
  index,
  isRemoving,
  isUploaded,
  errorUploading,
  removable = true,
}) => {
  let childContent;

  let src = '';
  if (file.src) src = file.src;
  else if (file instanceof File || file instanceof Blob) {
    src = URL.createObjectURL(file);
  }

  if ((file.type && file.type.includes('image')) || isFileNameImage(file.name)) {
    childContent = (
      <Fragment>
        <div className="attach__image">
          <a href={src} target="_blank" rel="noreferrer">
            <img className="attach__user-image" src={src} alt="att" />
          </a>
        </div>
        <span>{file.name?.replace(unique13NumRegex, '.')}</span>
      </Fragment>
    );
  } else {
    childContent = (
      <a href={src} target="_blank" rel="noreferrer" className="_inline-flex">
        <div className="attach__image attach__image--xls"></div>
        <span>{file.name?.replace(unique13NumRegex, '.')}</span>
      </a>
    );
  }

  return (
    <div style={{ marginRight: '5px' }}>
      <div className="attach__item">
        {childContent}
        {removable && (
          <button
            className="attach__button-delete"
            onClick={(e) => {
              e.stopPropagation();
              removeFile(file, index);
            }}
            type="button"
          ></button>
        )}
        <hr />
      </div>
      <ProgressBar
        progressPercent={progressPercent}
        isRemoving={isRemoving}
        isUploaded={isUploaded}
        errorUploading={errorUploading}
      />
    </div>
  );
};

export default FileToUpload;

import { StyledSelect } from './style';

// стили для динамически изменяемых классов в реакт-селект
const style = {
  control: (base, state) => ({
    ...base,
    border: state.isFocused ? '1px solid var(--main)' : 0,
    boxShadow: state.isFocused ? 'var(--shadow-input)' : 'none',
    borderRadius: '2px',
  }),
  option: (base, state) => ({
    ...base,
    opacity: state.data === state.selectProps.value ? '0.5' : '1',
    pointerEvents: state.data === state.selectProps.value ? 'none' : 'all',
    backgroundColor: state.isFocused ? 'var(--main-light)' : '',
    color: 'var(--darkest)',
  }),
  menuList: (base) => ({
    ...base,
    padding: 0,
  }),
};

export const SingleSelect = ({
  options,
  formatOptionLabel,
  placeholder = 'Выбрать из списка',
  onChange,
  defaultValue,
  filterOption,
  ...props
}) => {
  return (
    <StyledSelect
      {...props}
      styles={style}
      formatOptionLabel={formatOptionLabel}
      options={options}
      getOptionValue={(option) => option.id}
      onChange={onChange}
      placeholder={placeholder}
      defaultValue={defaultValue}
      filterOption={filterOption}
    />
  );
};

export const MultipleSelect = () => {
  return <div></div>;
};

import StyledMockAvatar from '@assets/images/userpic.png';
import { User } from '@shared/components/User/User';
import { stateToHTML } from 'draft-js-export-html';
import moment from 'moment';
import { files_url } from 'src/settings/base-url';
import * as yup from 'yup';

export const formatOptionLabel = ({ pic, name, id }) => {
  return <User id={id} size="sm" picture={pic} name={name}></User>;
};

export const getOptions = (users, token) => {
  const options = users.map((user) => ({
    id: user.id,
    name: user.first_name,
    pic: !!user.avatar
      ? `${files_url}/employees/avatar/${user.id}/small_${user.avatar}?token=${token}`
      : StyledMockAvatar,
  }));

  return options;
};

export const schema = yup.object({
  user: yup.object().required(),
  title: yup.string().required(),
  description: yup.object(),
  date: yup.date(),
});

export const transformFormData = (data, messageId, formId) => {
  return {
    row: {
      title: data.title,
      description: stateToHTML(data.description.getCurrentContent()),
      executor_id: data.user.id,
      base_chat_message_id: messageId,
      date_finish: moment(data.date).format('YYYY[/]MM[/]D') + ' ' + moment(data.date).format('LT'),
    },
    form_id: formId,
  };
};

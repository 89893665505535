import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getTags } from '../../redux/features/tagsSlice';
import { useEventTriggerOnEscPress } from '../../utilize/helper-functions';

const TagsListModal = ({ close }) => {
  const dispatch = useDispatch();
  const { tagsList } = useSelector((state) => state.tags);

  useEffect(() => {
    if (!tagsList) {
      dispatch(getTags());
    }
  }, [tagsList, dispatch]);

  useEventTriggerOnEscPress(close);

  return (
    <div className="modal__row">
      <div className="modal__scroll-box">
        <input className="modal__scroll-checkbox" type="checkbox" id="tag1" />
        {tagsList &&
          tagsList.map((tag, i) => {
            return (
              <label className="modal__scroll-item" htmlFor="tag1" key={i}>
                <span className={`dropdown__tag dropdown__tag--${tag.color}`}></span>
                <span className="dropdown__tag-name">{tag.name}</span>
              </label>
            );
          })}
      </div>
    </div>
  );
};

export default TagsListModal;

// Страница проекта/таска - это одновременно чат по этому проекту/таску
// Список сообщений работает по принципу загрузки последних 20 сообщений с прокруткой вверх для загрузки
// предыдущих сообщений.
// Если юзер нажимает на сообщение, которое еще не загружено (например в сообщении-ответе на старое сообщение)
// то загружаются 40 старых сообщений с интересующим сообщением по-середине. В таком случае, прокрутку можно
// делать в обе стороны для загрузки предыдущих или последующих сообщений.

import axios from 'axios';
import { useEffect, useRef, useState, useCallback, useContext } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import SnackbarContext from 'src/contexts/SnackbarContext';

import {
  addPreviousOldMessages,
  addNextOldMessages,
  resetOldMessagesState,
  getOldMessages,
} from '../../../redux/features/oldMessagesSlice';
import {
  clearProjectStructure,
  // selectGuestProjectRights,
} from '../../../redux/features/projectsSlice';
import SingleProjectHeader from '../../../components/chat/SingleProjectHeader';
import SideMenu from '../../../components/chat/side-menu/SideMenu';
import SingleProjectChat from '../../../components/chat/SingleProjectChat';
import MessageContainer from '../../../components/chat/message/MessageContainer';
import InlineModal from '../../../components/modals/InlineModal';
import CreateTaskModal from '../../../components/tasks/CreateTaskModal';
import Preloader from '../../../components/preloaders/Preloader';

import {
  requestPreviousMessages,
  requestNextMessages,
  formatMessages,
} from '../../../utilize/message-helper-functions';

// import { useMessageContext } from "../../../contexts/MessageContext";

const ProjectOrTask = () => {
  const { projectId, taskId } = useParams();
  const dispatch = useDispatch();
  const initialScrollToLastMessage = useRef(false);
  const specificChatStatusLoadedOnce = useRef(false);

  const [projectData, setProjectData] = useState();
  const [pinnedMessages, setPinnedMessages] = useState([]);
  const [messagesList, setMessagesList] = useState([]);
  // для показа значка загрузки при загрузке предыдущих сообщений
  const [messagesLoading, setMessagesLoading] = useState('');
  // для показа значка загрузки при загрузке последующих сообщения с сервера (когда юзер открыл старое сообщение)
  const [nextMessagesLoading, setNextMessagesLoading] = useState('');

  // после ухода из страницы чата, очистить все данные по старым сообщениям (на случай перехода на страницу другого чата)
  useEffect(() => {
    return () => {
      if ((taskId && projectData?.id !== +taskId) || (!taskId && projectData?.id !== +projectId)) {
        dispatch(resetOldMessagesState());
        dispatch(clearProjectStructure());
      }
    };
  }, [dispatch, projectId, taskId]);

  const scrollToLastMessage = () => {
    if (messagesList.length) {
      const lastMessageId = messagesList[messagesList.length - 1].props.id;
      const lastMessage = document.getElementById(`messageId${lastMessageId}`);
      if (lastMessage) {
        lastMessage.scrollIntoView({ block: 'center' });
      }
    }
  };

  // прокрутить к последнему сообщению, при первоначальной загрузке страницы
  useEffect(() => {
    if (messagesList.length && !initialScrollToLastMessage.current) {
      scrollToLastMessage();
      initialScrollToLastMessage.current = true;
    }
  }, [messagesList]);

  const getProjectData = useCallback(async () => {
    // определить есть ли taskId в URL (чтобы загрузить данные по таску или проекту)
    const url = taskId ? `/api/task_data/${taskId}` : `/api/project_data/${projectId}`;

    return axios
      .get(url)
      .then((response) => {
        const { result } = response.data;

        if (Array.isArray(result) && !result.length) {
          // если в сервере нет такого проекта/задачи, указать что не найдено
          setProjectData({
            title: taskId ? 'ЗАДАЧА НЕ НАЙДЕНА' : 'ПРОЕКТ НЕ НАЙДЕН',
          });
          return null;
        }
        const projectData = taskId ? result : result[0];
        setProjectData(projectData);
        return projectData;
      })
      .catch(() => {
        showSnackbar('Возникла ошибка во время запроса данных');
      });
  }, [taskId]);

  // запросить с сервера данные по текущему проекту
  useEffect(() => {
    const getData = async () => {
      initialScrollToLastMessage.current = false;
      specificChatStatusLoadedOnce.current = false;

      const projectData = await getProjectData();
      // запросить закрепленные сообщения
      if (projectData?.chat?.id) {
        axios
          .get(`api/pinned_messages/${projectData.chat.id}`)
          .then((response) => {
            if (response.data?.messages?.pinned_messages?.length) {
              const pinnedMessagesList = formatMessages({
                projectData,
                messages: response.data.messages.pinned_messages.reverse(),
                isPinned: true,
              });
              setPinnedMessages(pinnedMessagesList);
            }
          })
          .catch(() => {
            showSnackbar('Возникла ошибка при получении списка закрепленных сообщений');
          });

        // получить последние 20 сообщений чата
        const messagesList = formatMessages({
          projectData,
          messages: projectData?.chat.messages?.reverse(),
        });

        setMessagesList(messagesList);
      }
    };
    getData();
  }, [getProjectData]);

  const [searchParams] = useSearchParams();

  const { requestedOldMessageId, oldMessages } = useSelector((state) => state.oldMessages);
  // const oldMessagesLoadedUpToDate = useRef(false);

  // если в URL указан msgId, проверить уже загружено ли сообщение вместе с первоначалальными данными,
  // если не загружено, то сделать запрос на получение состояния чата вокруг этого сообщения
  useEffect(() => {
    if (specificChatStatusLoadedOnce.current) return;
    const msgIdParam = searchParams.get('msg');
    if (!msgIdParam || !projectData) return;
    //  остановить функцию, если id проекта/таска не соответствует проекту/таску в state
    if ((taskId && projectData.id !== +taskId) || (!taskId && projectData.id !== +projectId)) return;

    const messageAlreadyLoaded = projectData.chat.messages.find((msg) => msg.id === +msgIdParam);
    if (messageAlreadyLoaded) {
      if (messagesList) {
        const renderedMessage = document.getElementById(`messageId${messageAlreadyLoaded.id}`);
        if (!renderedMessage) return;
        renderedMessage.scrollIntoView({ block: 'center' });
        specificChatStatusLoadedOnce.current = true;
      }
      return;
    } else {
      dispatch(
        getOldMessages({
          chat_id: projectData.chat.id,
          old_message_id: msgIdParam,
        }),
      );
      specificChatStatusLoadedOnce.current = true;
    }
  }, [searchParams, projectData, messagesList]);

  // нужно для прокрутки страницы чата к загруженным сообщениям
  const currentScrollHeight = useRef();
  const chatPageRef = useRef();

  const [subtaskAddModal, showSubtaskAddModal] = useState(false);

  // прокрутить страницу чата до блока загруженных сообщений
  useEffect(() => {
    if ((messagesList.length > 20 || oldMessages?.length > 20) && currentScrollHeight.current) {
      const scrollDiff = chatPageRef.current.scrollHeight - currentScrollHeight.current;

      chatPageRef.current.scrollTop = scrollDiff;
      currentScrollHeight.current = null;
    }
  }, [messagesList, oldMessages]);

  // для показа кнопки прокрутки до конца страницы (до последнего сообщения)
  const [scrollToEndButton, showScrollToEndButton] = useState(false);

  const { showSnackbar } = useContext(SnackbarContext);

  const getPreviousMessages = (target) => {
    if (projectData?.chat) {
      const chatId = projectData.chat.id;
      // запрашивать данные по предыдущим сообщениям от старого сообщения, если юзер открыл старое сообщение
      if (requestedOldMessageId) {
        if (oldMessages?.length) {
          // если список старых сообщений загружен, то загружать предыдущие сообщения от
          // загруженного первого старого сообщения
          const earliestOldMessageId = oldMessages[0].id;
          setMessagesLoading('previous');
          requestPreviousMessages(chatId, earliestOldMessageId)
            .then((response) => {
              if (response.data.messages?.chat_messages?.length) {
                // определить текущую высоту прокрутки страницы
                currentScrollHeight.current = target.scrollHeight + 30;
                // добавить вновь загруженные из сервера старые сообщения к ранее загруженным старым сообщениям
                dispatch(addPreviousOldMessages(response.data.messages.chat_messages));
                if (messagesLoading !== 'endPrevious') setMessagesLoading(null);
              } else setMessagesLoading('endPrevious');
            })
            .catch(() => {
              showSnackbar('Возникла ошибка при загрузке предыдущих сообщений');
            });
        }
      }
      // если список загруженных сообщений не пустой и юзер не открыл старое (не из последнего feeda) сообщение,
      // то выбрать наиболее раннее, для загрузки предыдущих сообщений
      else if (messagesList?.length) {
        const earliestMessageId = messagesList[0].props.id;
        setMessagesLoading('previous');
        requestPreviousMessages(chatId, earliestMessageId)
          .then((response) => {
            let previousMessages = response.data.messages?.chat_messages;
            if (previousMessages?.length) {
              currentScrollHeight.current = target.scrollHeight + 30;
              previousMessages = previousMessages.reverse().map((message) => {
                return <MessageContainer key={message.id} {...message} projectData={projectData} />;
              });
            } else {
              return setMessagesLoading('end');
            }
            setMessagesList([...previousMessages, ...messagesList]);
            setMessagesLoading('');
          })
          .catch(() => {
            showSnackbar('Возникла ошибка при загрузке предыдущих сообщений');
            setMessagesLoading('');
          });
      }
    }
  };

  // получить предыдущие 20 сообщений при прокрутке вверх
  const handleScroll = (element) => {
    //
    if (element.target.scrollTop === 0 && messagesLoading !== 'loading' && messagesLoading !== 'end') {
      getPreviousMessages(element.target);
    } else if (chatPageRef.current.scrollHeight - element.target.scrollTop === element.target.clientHeight) {
      if (!requestedOldMessageId) {
        // скрыть кнопку-скроллер, если в конце страницы и юзер не просматривает старое сообщение
        if (scrollToEndButton) showScrollToEndButton(false);
      } else if (nextMessagesLoading !== 'loading' && projectData?.chat && oldMessages?.length) {
        // получить последующие от старого сообщения, при прокрутке в конец страницы, если юзер просматривал старое сообщение
        const lastMessageId = oldMessages[oldMessages.length - 1].id;
        setNextMessagesLoading('loading');

        requestNextMessages(projectData.chat.id, lastMessageId)
          .then((response) => {
            setNextMessagesLoading(null);

            if (response.data.messages?.end) {
              const messages = formatMessages({
                projectData,
                messages: [...oldMessages, ...response.data.messages.chat_messages],
              });
              setMessagesList(messages);
              // oldMessagesLoadedUpToDate.current = true;
              return dispatch(resetOldMessagesState());
            }

            dispatch(
              addNextOldMessages({
                messages: response.data.messages?.chat_messages,
              }),
            );
          })
          .catch(() => {
            showSnackbar('Возникла ошибка при загрузке последующих сообщений');
          });
      }
    } else if (!scrollToEndButton) {
      // показать кнопку-скроллер в конец страницы, если юзер не находится в конце страницы
      showScrollToEndButton(true);
    }
  };

  // для перехода от старых сообщений к последним актуальным сообщениям
  const resetToLatestMessages = useCallback(() => {
    dispatch(resetOldMessagesState());
    setTimeout(scrollToLastMessage, 10);
    // if (messagesList.length > 0) {
    //   setTimeout(() => {
    //     const lastMessageId = messagesList[messagesList.length - 1].props.id;
    //     const lastMessage = document.getElementById(`messageId${lastMessageId}`);
    //     if (lastMessage) {
    //       lastMessage.scrollIntoView();
    //     }
    //   }, 10);
    // }
  }, [dispatch, messagesList]);

  return (
    <main className="no-padding">
      {/* модальное окно создания таска к данному проекту, открывается при нажатии на кнопку создания таска */}
      {subtaskAddModal && (
        <InlineModal close={() => showSubtaskAddModal(false)}>
          <CreateTaskModal projectId={projectId} parentTaskId={taskId} />
        </InlineModal>
      )}

      <section className="chat">
        <div className="chat__container container">
          <div className="chat__wrapper">
            <div className="chat__page" ref={chatPageRef} onScroll={handleScroll} style={{ position: 'relative' }}>
              {!projectData && <Preloader />}

              {projectData && (
                <>
                  <SingleProjectHeader
                    projectData={projectData}
                    getProjectData={getProjectData}
                    showSubtaskAddModal={showSubtaskAddModal}
                    pinnedMessagesList={pinnedMessages}
                  />
                  {projectData.id && (
                    <SingleProjectChat
                      messagesLoading={messagesLoading}
                      projectData={projectData}
                      messagesList={messagesList}
                      pinnedMessages={pinnedMessages}
                      setMessagesList={setMessagesList}
                      setPinnedMessages={setPinnedMessages}
                      nextMessagesLoading={nextMessagesLoading}
                      resetToLatestMessages={resetToLatestMessages}
                      scrollToEndButton={scrollToEndButton}
                      chatPageRef={chatPageRef}
                      // guestRights={guestRights}
                    />
                  )}
                </>
              )}
            </div>
            <SideMenu projectData={projectData} />
          </div>
        </div>
      </section>
    </main>
  );
};

export default ProjectOrTask;

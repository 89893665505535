import axios from 'axios';
import { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Preloader from 'src/components/preloaders/Preloader';
import SnackbarContext from 'src/contexts/SnackbarContext';
import NewsBlogItem from 'src/components/home/NewsBlogItem';

import ProjectListItem from '../Projects/project-list/project-list-container/ProjectListItem';

const Home = () => {
  const { showSnackbar } = useContext(SnackbarContext);

  const [summary, setSummary] = useState();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    axios
      .get('/api/main_page')
      .then((r) => {
        setSummary(r.data.mainPageData);
      })
      .catch(() => {
        showSnackbar('Возникла ошибка при загрузке данных');
      })
      .finally(() => setIsLoading(false));
  }, []);

  return (
    <>
      <main className="no-padding">
        <section className="index">
          {isLoading && <Preloader />}
          {summary && (
            <div className="index__container container">
              <div className="index__col">
                <div className="index__section-box">
                  <section className="index__section">
                    <div className="index__title-wrapper">
                      <Link className="index__title" to="/news">
                        Новости
                      </Link>
                    </div>
                    <div className="index__post-wrapper">
                      {summary.news.map((newsItem, i) => (
                        <NewsBlogItem newsBlogItem={newsItem} key={i} type="news" />
                      ))}
                    </div>
                  </section>
                  <section className="index__section">
                    <div className="index__title-wrapper">
                      <Link className="index__title" to="/blogs">
                        Блог
                      </Link>
                    </div>
                    <div className="index__post-wrapper">
                      {summary.blogs.map((blog, i) => (
                        <NewsBlogItem newsBlogItem={blog} key={i} type="blogs" />
                      ))}
                    </div>
                  </section>
                  {/* <section className="index__section">
                    <div className="index__title-wrapper">
                      <span className="index__title" href="">
                        Опросы
                      </span>
                    </div>
                    <div className="index__post-wrapper">
                      <div className="index__post index-post">
                        <div className="index__message message">
                          <div className="message__col">
                            <div className="message__row">
                              <div className="message__info">
                                <p
                                  className="
                                message__username
                                message__username--status
                                message__username--offline
                              "
                                >
                                  Никита Курин
                                </p>
                                <p className="message__date">20 Jan 20:00</p>
                              </div>
                            </div>
                            <div className="index__content">
                              <div className="message__survey survey">
                                <p className="survey__date">Опрос до 5 июня 2020</p>
                                <form className="survey__form">
                                  <p className="survey__question">На каком устройстве вы работаете?</p>
                                  <input className="survey__input" type="radio" id="var1" name="question" value="pc" />
                                  <label className="survey__label" htmlFor="var1">
                                    Стационарный компьютер
                                  </label>
                                  <input
                                    className="survey__input"
                                    type="radio"
                                    id="var2"
                                    name="question"
                                    value="mono"
                                    defaultChecked="checked"
                                  />
                                  <label className="survey__label" htmlFor="var2">
                                    Моноблок
                                  </label>
                                  <input className="survey__input" type="radio" id="var3" name="question" value="lap" />
                                  <label className="survey__label" htmlFor="var3">
                                    Ноутбук
                                  </label>
                                  <button className="survey__submit-button" type="submit">
                                    Голосовать
                                  </button>
                                  <div className="survey__result-box">
                                    <span className="survey__total">10 голосов</span>
                                    <button className="survey__cancel-button">Отказаться от голосования</button>
                                  </div>
                                </form>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="index__post index-post">
                        <div className="index__message message">
                          <div className="message__col">
                            <div className="message__row">
                              <div className="message__info">
                                <p
                                  className="
                                message__username
                                message__username--status
                                message__username--online
                              "
                                >
                                  Никита Курин
                                </p>
                                <p className="message__date">20 Jan 20:00</p>
                              </div>
                            </div>
                            <div className="index__content">
                              <div className="message__survey survey">
                                <p className="survey__date">Опрос до 5 июня 2020</p>
                                <form className="survey__form">
                                  <p className="survey__question">На каком устройстве вы работаете?</p>
                                  <input className="survey__input" type="radio" id="var4" name="question" value="pc" />
                                  <label className="survey__label" htmlFor="var4">
                                    Стационарный компьютер
                                  </label>
                                  <input
                                    className="survey__input"
                                    type="radio"
                                    id="var5"
                                    name="question"
                                    value="mono"
                                    defaultChecked="checked"
                                  />
                                  <label className="survey__label" htmlFor="var5">
                                    Моноблок
                                  </label>
                                  <input className="survey__input" type="radio" id="var6" name="question" value="lap" />
                                  <label className="survey__label" htmlFor="var6">
                                    Ноутбук
                                  </label>
                                  <button className="survey__submit-button" type="submit">
                                    Голосовать
                                  </button>
                                  <div className="survey__result-box">
                                    <span className="survey__total">10 голосов</span>
                                    <button className="survey__cancel-button">Отказаться от голосования</button>
                                  </div>
                                </form>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section> */}
                </div>
              </div>
              <div className="index__col">
                <div className="index__title-wrapper">
                  <Link className="index__title" to="/projects">
                    Проекты
                  </Link>
                </div>
                <div className="index__cards">
                  {summary.projects.map((project, i) => (
                    <ProjectListItem item={project} key={i} />
                  ))}
                </div>
              </div>
            </div>
          )}
        </section>
      </main>
    </>
  );
};

export default Home;

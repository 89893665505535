import { useTranslation } from 'react-i18next';
import { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { Link, useParams } from 'react-router-dom';

import { useSelector } from 'react-redux';

import { localDateNumberFormat } from '../../../utilize/helper-functions';

import { renderMessage } from '../../../utilize/draftjs-helper-functions';
import useAuth from '../../../hooks/useAuth';
import UserProfileModal from '../../../windows/profile/UserProfileModal';
import Preloader from '../../preloaders/Preloader';
import AttachedFile from '../../files/AttachedFile';
import UserStatus from '../../employees/UserStatus';
import { files_url } from '../../../settings/base-url';

import { selectGuestProjectRights } from '../../../redux/features/projectsSlice';

import { renderProjectOrTaskName } from './SideMenu';

import ProjectTree from './ProjectTree';

const LinksSidePanel = ({ sideMenu, openSideMenu, projectData }) => {
  const { projectId, taskId } = useParams();
  const auth = useAuth();
  const { t } = useTranslation();
  const [projectTree, showProjectTree] = useState(false);
  const [dataType, setDataType] = useState(null);
  const [panelData, setPanelData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [errorLoading, setErrorLoading] = useState(false);

  const [userProfile, showUserProfile] = useState();

  //

  // каждый раз, когда нажимается кнопка открытия панели, производится reset данных
  useEffect(() => {
    if (sideMenu === 'link' && !panelData && dataType && projectData && !isLoading) {
      setIsLoading(true);
      setErrorLoading(false);
      axios
        .get(`/api/sidebar_links/${dataType}/${projectData.id}`)
        .then((response) => {
          const chat_messages = response.data.chat_messages;
          setPanelData({
            messages: chat_messages,
            id: projectData.id,
            title: projectData.title,
          });

          setIsLoading(false);
        })
        .catch(() => {
          setPanelData({});
          setIsLoading(false);
          setErrorLoading(true);
        });
    } else if (sideMenu !== 'link' && panelData) {
      setTimeout(() => {
        setPanelData(null);
        setDataType(null);
        showProjectTree(false);
        setSidebarDataType(null);
      }, 1000);
    }
  }, [sideMenu, dataType, projectData, panelData, isLoading]);

  // useEffect(() => {
  //   if (!dataType) {
  //     taskId ? setDataType("task") : setDataType("project");
  //   }
  // }, [taskId, dataType]);
  useEffect(() => {
    if (taskId && dataType !== 'task') setDataType('task');
    if (!taskId && dataType !== 'project') setDataType('project');
  }, [taskId, dataType]);

  const [sidebarDataType, setSidebarDataType] = useState();

  const showRelevantTaskInfo = useCallback((dataType, dataId, title) => {
    setPanelData(null);
    showProjectTree(false);
    setErrorLoading(false);
    setIsLoading(true);
    axios
      .get(`/api/sidebar_links/${dataType}/${dataId}`)
      .then((response) => {
        const chat_messages = response.data.chat_messages;

        setPanelData({
          messages: chat_messages,
          id: dataId,
          title: title,
        });

        setIsLoading(false);
        setSidebarDataType(dataType);
        setErrorLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
        setErrorLoading(true);
      });
  }, []);

  const guestRights = useSelector(selectGuestProjectRights(sidebarDataType || dataType, panelData?.id));

  return (
    <>
      {userProfile && <UserProfileModal profileId={userProfile} onClose={() => showUserProfile(null)} />}

      <aside data-aside="link" className={`aside ${sideMenu === 'link' ? '_active' : ''}`}>
        <div className="aside__header">
          <span className="aside__title aside__title--link">{t('common.links')}</span>
          <button
            className="aside__button-arrow"
            onClick={() => {
              setTimeout(() => {
                setPanelData(null);
                setDataType(null);
                showProjectTree(false);
              }, 1000);
              openSideMenu(null);
            }}
          ></button>
        </div>
        <div className="aside__body">
          {errorLoading && <div>{t('ErrorMessages.default')}</div>}

          {projectTree ? (
            <ProjectTree
              update={sideMenu === 'link'}
              showRelevantTaskInfo={showRelevantTaskInfo}
              showProjectTree={showProjectTree}
            />
          ) : (
            <>
              {(!auth?.user?.is_guest || guestRights?.includes('access_children')) && (
                <span className="aside__backward _pointer" onClick={() => showProjectTree(true)}>
                  Структура проекта
                </span>
              )}

              <p className="aside_parent-task">
                {renderProjectOrTaskName(panelData, taskId, sidebarDataType, projectData)}
              </p>

              {isLoading && <Preloader />}

              {sidebarDataType === 'project' && taskId && (
                <Link to={`/projects/${projectId}`} className="aside__link">
                  {t('Project.open_project')}
                </Link>
              )}

              {sidebarDataType === 'task' && panelData && panelData.id !== +taskId && (
                <Link to={`/projects/${projectId}/tasks/${panelData.id}`} className="aside__link">
                  {t('Project.open_task')}
                </Link>
              )}

              {panelData &&
                Array.isArray(panelData.messages) &&
                panelData.messages.map((chat_message, i) => {
                  const {
                    message_tags,
                    employee_id,
                    employee_first_name,
                    employee_last_name,
                    date_created,
                    editor_state,
                    message_files,
                    id,
                    chat_goal_results,
                    title,
                  } = chat_message;
                  return (
                    <section className="task-current" key={i}>
                      <div className="task-current__body">
                        {chat_goal_results.length > 0 && (
                          <p className="task-status task-status--pending">{t('Project.result.pending')}</p>
                        )}

                        {message_tags.map((tag, i) => (
                          <span className={`task-current__tag tag tag--icon-${tag.color}`} key={i}>
                            {tag.name}
                          </span>
                        ))}

                        <div className="task-current__item">
                          <div className="task-current__header">
                            <div
                              // to={`/team/users/${employee_id}`}
                              to="#"
                              onClick={() => {
                                if (!auth?.user?.is_guest) showUserProfile(employee_id);
                              }}
                              className={`task-current__usercard ${auth?.user?.is_guest ? '_no-pointer' : '_pointer'}`}
                            >
                              <UserStatus
                                userId={employee_id}
                                firstName={employee_first_name}
                                lastName={employee_last_name}
                              />
                              {/* <img
                                className="task-current__userpic"
                                src="assets/images/userpic.png"
                                alt=""
                              /> */}
                              <span className="task-current__username">
                                {`${employee_first_name || ''} ${employee_last_name || ''}`}
                              </span>{' '}
                            </div>
                            <span className="task-current__date">{localDateNumberFormat(date_created)}</span>
                          </div>
                          <div className="task-current__message">
                            {title && (
                              <div>
                                <strong>{title}</strong>
                              </div>
                            )}

                            {renderMessage(editor_state)}

                            <div className="attach">
                              {message_files?.length > 0 && (
                                <>
                                  {message_files.map((file, i) => {
                                    const url = `${files_url}/chat_messages/files/${id}/${file.id}/${file.file}?token=${auth.token}`;

                                    return (
                                      <AttachedFile
                                        fileUrl={url}
                                        fileName={file.file}
                                        key={i}
                                        storageFileId={file.from_storage}
                                      />
                                    );
                                  })}
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                  );
                })}
            </>
          )}
        </div>
      </aside>
    </>
  );
};

export default LinksSidePanel;

import React from 'react';
import { Link } from 'react-router-dom';
import ReactHtmlParser from 'html-react-parser';
import { useTranslation } from 'react-i18next';

import { formatDateWithDots } from '../../../../components/calendar-form/CalendarForm';
import './ProjectListItem.css';
import UserStatus from '../../../../components/employees/UserStatus';

const projectStatus = {
  created: { text: 'Создан', color: 'grey' },
  in_progress: { text: 'В работе', color: 'yellow' },
  finished: { text: 'Завершен', color: 'green' },
};

const ProjectListItem = ({ item }) => {
  const {
    id,
    project_goals,
    date_start,
    date_finish,
    description,
    status,
    title,
    project_tags,
    responsible_id,
    responsible_last_name,
    responsible_first_name,
  } = item;
  const { t } = useTranslation();
  return (
    <div className="project-list__card project-card">
      <div className="project-card__header">
        <span className="project-card__date">
          {(date_start || date_finish) && (
            <>
              {date_start && formatDateWithDots(date_start)} - {date_finish && formatDateWithDots(date_finish)}
            </>
          )}
        </span>

        {projectStatus[status] && (
          <span className={`project-card__status project-card__status--${projectStatus[status].color}`}>
            {projectStatus[status].text}
          </span>
        )}
      </div>
      <p className="project-card__title">{title}</p>
      {/* format--description - класс для того, чтобы было видно форматирование через тэги  */}
      <div className="project-card__description format--description">
        {typeof myVar === 'string' ? ReactHtmlParser(description) : ''}
      </div>
      <span className="project-card__staff">{t('Project.responsible')}</span>
      <div className="project-card__user">
        <UserStatus userId={responsible_id} firstName={responsible_first_name} lastName={responsible_last_name} />
        <span className="project-card__username">{`${responsible_first_name || ''} ${
          responsible_last_name || ''
        }`}</span>
      </div>
      <div>
        {project_tags?.map((item, i) => (
          <span key={i} className="project-card__tag tag">
            {item.name}
          </span>
        ))}
      </div>
      <ul className="project-card__hover-list">
        <Link to={`/projects/${id}`} className="project-card__link"></Link>
        <li className="project-card__hover-item">
          {/* <p className="project-card__title">Цели проекта</p> */}
          {/* <p className="project-card__title">{title} </p> */}
          {/* <p className="project-card__title">{status}status </p> */}
          {/* <div className="project-card__header">
            <div className="project-card__user project-card__user--no-margin">
              <img
                className="project-card__userpic"
                src="assets/images/userpic.png"
                alt=""
              />
              <span className="project-card__username">Никита Родин</span>
            </div>
            <div className="project-card__done">Готово</div>
          </div> */}

          {project_goals?.map((item, i) => (
            <p key={i}>{item.description}</p>
          ))}
          {/* <p>Тут цели проекта </p> */}
        </li>
      </ul>
    </div>
  );
};

export default ProjectListItem;

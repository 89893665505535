import React, { Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import { ThemeProvider } from 'styled-components';

import { RichTextEditorProvider } from '@components/RichTextEditor/RichTextEditorContext';

import { colors } from './variables';

import './index.css';
import App from './components/app/app';

import AuthProvider from './providers/auth-provider/AuthProvider';
// import SocketProvider from "./providers/SocketProvider/_SocketProvider";
import { SocketContextProvider } from './contexts/SocketContext';
import './i18n';
import { store } from './redux/store';

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <ThemeProvider theme={colors}>
      <Suspense fallback={<div>Loading...</div>}>
        <Provider store={store}>
          <SocketContextProvider>
            <AuthProvider>
              <RichTextEditorProvider>
                <BrowserRouter>
                  <App />
                </BrowserRouter>
              </RichTextEditorProvider>
            </AuthProvider>
          </SocketContextProvider>
        </Provider>
      </Suspense>
    </ThemeProvider>
  </React.StrictMode>,
);

import styled from 'styled-components';

export const StyledIconButton = styled.button`
  width: ${(props) => props.size || 16}px;
  height: ${(props) => props.size || 16}px;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url(${(props) => props.icon});
  background-color: transparent;
  border: none;

  &:hover {
    filter: invert(50%) sepia(29%) saturate(7072%) hue-rotate(190deg) brightness(100%) contrast(101%);
  }
`;

export const colors = {
  darkest: '#262626',
  darkestSecondary: '#001529',
  dark: '#8C8C8C',
  main: '#1890FF',
  mainLight: '#BAE7FF',
  lightest: '#FFFFFF',
  light: '#FAFAFA',
  success: '#52C41A',
  successDark: '#389E0D',
  successLight: '#F6FFED',
  warning: '#F5222D',
  warningLight: '#FFFBE6',
  warningSecondary: '#FA8C16',
  error: '#FF4D4F',
  errorDark: '#FF7875',
  errorLight: '#FFA39E',
  errorLightest: '#FFF1F0',
  backdrop: 'rgba(0, 0, 0, 0.5)',
  border: '#D9D9D9',
  borderSecondary: '#F0F0F0',
  shadow: '0 0 4px #D9D9D9',
  shadowInput: '0 0 4px rgba(24, 144, 255, 0.5)',
  shadowInputError: '0 0 4px rgba(255, 77, 79, 0.5)',
  shadowModal: '0 4px 4px rgba(0, 0, 0, 0.25)',
  backgroundError: '#FFF1F0',
  disabled: '#BFBFBF',
  disabledSecondary: '#F5F5F5',
  yellow: '#E1A95F',
};

import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useContext } from 'react';
import SnackbarContext from 'src/contexts/SnackbarContext';
import { useTranslation } from 'react-i18next';
import axios from 'axios';

import { useDuplicateErrorHandler } from 'src/utilize/helper-functions';

import {
  url_departments_add,
  url_get_departments_list,
  url_put_departments_edit,
  url_delete_departments,
  url_get_departments_create,
  url_get_department_edit,
} from '../../settings/base-url';
import { useSocketContext } from '../../contexts/SocketContext';
import useAuth from '../../hooks/useAuth';
import ModalHeader from '../../components/modals/modal-header';
import NotificationSuссess from '../../components/notifications/NotificationSuссess';

import DepartmentList from './department-list/DepartmentList';
import DepartmentForm from './department-form-create-edit/DepartmentFormCreateEdit';

const Departments = (props) => {
  const { socket, onDepartmentChange } = useSocketContext();

  // настройки
  const auth = useAuth();
  const { t } = useTranslation();
  const translateKey = 'DepartmentForm'; // для перевода

  const hasRightToEdit = useMemo(() => auth.isUserRightful(auth.departments_edit), [auth]);

  // const socket = initSocket(auth.token);

  // для формы, базовые поля
  const headers = useMemo(
    () => ({
      Authorization: auth.token,
    }),
    [auth],
  );

  // статус модального окна
  const [modalAddDep, setModalAddDep] = useState({
    isOpen: false,
    action: null,
  });
  // для уведомлений, что изменения вступили в силу (пока не исп)
  const [responce /*, setResponce*/] = useState(null);
  // данные с сервера об отделе (название,  руководитель, списки сотрудников )
  const [departData, setDepartData] = useState(null);
  const [infoIsLoad, setInfoIsLoad] = useState(false);
  const [departments, setDepartments] = useState(null); // список отделов

  // после изменений на сервере меняем стейт локально
  const updateState = useCallback((data, action) => {
    switch (action) {
      case 'added':
        setDepartments((prev) => [...prev, data]);
        break;
      case 'edited':
        setDepartments((prev) => getEditedState(prev, data));
        break;
      case 'deleted':
        setDepartments((prev) => prev.filter((item) => item.id !== data.id));
        break;
      default:
        // return departments;
        return;
    }
  }, []);

  const getDepartments = useCallback(() => {
    axios.get(url_get_departments_list, { headers }).then(
      (res) => {
        setDepartments(res.data.departments);
      },
      () => {
        showSnackbar('Возникла ошибка при получении списка отделов');
      },
    );
  }, [headers]);

  // список отделов, вход в комнату
  // сделать вход при редактировании
  useEffect(() => {
    if (socket && socket.connected) {
      socket.emit('add_to_room', 'departments_list');
    }

    getDepartments();
  }, [auth.token, headers, socket, getDepartments]);

  // подписались на изменения с сервера, меняем локальный  (список отделов)
  useEffect(() => {
    if (socket && socket.connected) {
      // onDepartmentChange(updateState);
      // socket.on("department_changed", (data, action) => {
      //   updateState(data, action);
      // });
    }
  }, [auth.token, socket, updateState, onDepartmentChange]);

  // заменяем отдел на отредактированный
  const getEditedState = (arr, newItem) => {
    const ind = arr.findIndex(({ id }) => id === newItem.id);
    return [...arr.slice(0, ind), newItem, ...arr.slice(ind + 1)];
  };

  // отрисовка модального окна для редактирования, получение data о редактируемом отделе
  const loadDepartmentData = (item) => {
    const { id, title, head_id, first_name, last_name } = item;

    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: auth.token,
      department_id: id,
    };

    axios.get(url_get_department_edit + `/${id}`, { headers }).then(
      (res) => {
        const { free_employees, employees } = res.data.department[0];
        let data = {
          id: id,
          title: title,
          head_id: head_id,
          head_name: `${first_name} ${last_name || ''}`,
          employees: employees,
          free_employees: free_employees,
        };
        setDepartData({ ...data });
        setInfoIsLoad(true);
        setModalAddDep({ isOpen: true, action: 'edit' });
      },
      () => {},
    );
  };

  const isDuplicateError = useDuplicateErrorHandler();
  // const isDuplicateError = (error) => {
  //   const errData = error.response?.data;
  //   if (errData?.status !== 500 || errData.error?.code !== 'ER_DUP_ENTRY') return false;
  //   showSnackbar('Отдел с таким названием уже существует');
  //   return true;
  // };

  // отправляем data о новом отделе на сервер, закрываем модалку создания отдела
  const addNewDepart = (data) => {
    const { title, employees_department, head_id } = data;

    let body = {
      row: {
        title: title,
        head_id: head_id.value,
      },
      employees: employees_department ? employees_department.map((a) => a.id) : [],
    };
    axios
      .post(url_departments_add, body, { headers })
      .then(() => {
        onCloseAddModal();
        getDepartments();
      })
      .catch((error) => {
        if (isDuplicateError(error, 'Отдел с таким названием уже существет')) return;
        showSnackbar('Возникла ошибка при создании отдела');
      });
  };

  // отправили на сервер data об отделе, который отредактировали. закрыли модалку для редактирования
  const sendEditedDepart = (data) => {
    const { title, employees_department, head_id } = data;
    // console.log('HEAD ID: ', head_id);
    // return;
    let body = {
      row: {
        title: title,
        head_id: head_id.value,
      },
      employees: employees_department ? employees_department.map((a) => a.id) : [],
      department_id: departData.id,
    };
    axios
      .put(url_put_departments_edit, body, { headers })
      .then(() => {
        onCloseAddModal();
        getDepartments();
      })
      .catch((error) => {
        if (isDuplicateError(error, 'Отдел с таким названием уже существет')) return;
        showSnackbar('Возникла ошибка при редактировании отдела');
      });
  };

  // срабатывает на кнопку отправить в форме (submit)
  const sendDataForm = (data) => {
    switch (modalAddDep.action) {
      case 'add':
        return addNewDepart(data);
      case 'edit':
        return sendEditedDepart(data);
      default:
        return addNewDepart(data);
    }
  };

  // при клике на крестик модалки с списком отдела => вышли из комнаты
  const onCloseDepartmentList = () => {
    if (socket && socket.connected) {
      socket.emit('leave_room', 'departments_list');
    }
    props.onClose();
  };

  // закрыть окно создания-редактирования
  const onCloseAddModal = () => {
    setModalAddDep(false);
    setInfoIsLoad(false);
  };

  // вызов модального окна для добавления, (кнопка добавить), загрузка инфы для модалки
  const onAdd = () => {
    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: auth.token,
      department_id: null,
    };

    axios
      .get(url_get_departments_create, {
        headers,
      })
      .then(
        (res) => {
          // тут задаем параметры prev value для формы
          setDepartData({
            employees: [],
            free_employees: res.data.free_employees,
          });
          setInfoIsLoad(true);
          setModalAddDep({ isOpen: true, action: 'add' });
        },
        () => {
          showSnackbar('Возникла ошибка при добавлении отдела');
        },
      );
  };

  const { showSnackbar } = useContext(SnackbarContext);

  // отправляем запрос на сервер на удаление => клик по иконке корзины в списке отделов
  const onDelete = (id) => {
    fetch(url_delete_departments, {
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: auth.token,
      },
      body: JSON.stringify({ department_id: id }),
    })
      .then(() => {
        getDepartments();
      })
      .catch(() => {
        showSnackbar('Ошибка при удалении');
      });
  };

  // настройка для полей формы
  const inputs = {
    title: {
      // название отдела
      name: 'title',
      icon: true,
      placeholder: modalAddDep.action === 'edit' ? t(`${translateKey}.title_edit`) : t(`${translateKey}.title_create`),
      label: t(`${translateKey}.label_title`) + '*',
      type: 'text',
    },
    head_id: {
      // руководитель
      name: 'head_id',
      label: t(`${translateKey}.head`),
      type: 'select',
      options: departData?.free_employees || [],
    },
    employees_department: {
      // свободные сотрудники отдела ( потому что того желает react select, массив уже выбранных сотрудников получаем из свободных (иначе при удалении из поля они не добавляются в выпадающий список) )
      name: 'employees_department',
      icon: true,
      label: t(`${translateKey}.workers`),
      type: 'select',
      subType: 'tag',
      options: departData?.free_employees || [],
    },
  };

  return (
    <>
      <section className="modal">
        <div className="modal__wrapper">
          <div className={modalAddDep.isOpen ? 'modal__inner modal__inner--medium' : 'modal__inner'}>
            {(modalAddDep.action === 'edit' || modalAddDep.action === 'add') && infoIsLoad && (
              <>
                <section className="modal__body test">
                  <DepartmentForm
                    infoIsLoad={infoIsLoad}
                    departData={departData} //  данные с сервера об отделе
                    action={modalAddDep.action === 'add' ? 'add' : 'edit'}
                    inputs={inputs} // поля hook forms
                    onClose={onCloseAddModal}
                    onCreate={sendDataForm}
                    setModalAddDep={setModalAddDep}
                  />
                </section>
              </>
            )}

            {/* список сотрудников в модальном окне  */}
            {!modalAddDep.isOpen && (
              <section className="modal__body">
                {responce === 'success' && <NotificationSuссess text="Новый отдел добавлен" />}
                {responce === 'error' && <NotificationSuссess text="Произошла ошибка!" />}
                <ModalHeader
                  addDepartment
                  title={t('TeamHeader.departments')}
                  onClose={onCloseDepartmentList}
                  onAdd={onAdd}
                  hasRightToEdit={hasRightToEdit}
                />
                <DepartmentList
                  departments={departments}
                  onEdit={loadDepartmentData}
                  onDelete={onDelete}
                  onCloseDepartmentList={onCloseDepartmentList}
                  hasRightToEdit={hasRightToEdit}
                />
              </section>
            )}
          </div>
        </div>
      </section>
    </>
  );
};

export default Departments;

import * as React from 'react';

import { useRichTextEditor } from './useRichTextEditor';

const RichTextEditorContext = React.createContext(undefined);

export const useEditorApi = () => {
  const context = React.useContext(RichTextEditorContext);
  if (context === undefined) {
    throw new Error('useEditorApi must be used within TextEditorProvider');
  }

  return context;
};

export const RichTextEditorProvider = ({ children }) => {
  const editorApi = useRichTextEditor();

  return <RichTextEditorContext.Provider value={editorApi}>{children}</RichTextEditorContext.Provider>;
};

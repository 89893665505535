import { useCallback, useEffect, useState, useRef, memo, useContext } from 'react';
import axios from 'axios';

import { useTranslation } from 'react-i18next';

import { isEmpty, randomSequence, addFileNameId } from '../../utilize/helper-functions';

import SnackbarContext from '../../contexts/SnackbarContext';

import FileToUpload from './FileToUpload';

const FilesUploadManage = ({
  filesToEdit,
  filesToUpload,
  filesDeleteArr, // массив файлов, помеченных на удаление. Обновляется здесь и используется компонентом-родителем при отправке запроса на редактирование
  setFilesDeleteArr,
  formId,
  filesUploadPath,
  filesDeletePath,
  filesAddFormData,
  addFilesFormDataTemplate,
  removeFilesReqBody,
  setIsFilesUploading,
  // removeFilesOnClose - ref предосталяется компонентом-родителем, а в этом компоненте, к этому ref привязывается функция,
  // которая отменяет отправку файлов и которую можно будет вызвать в компоненте-родителе
  removeFilesOnClose,
  removeById,
  clearFileManager,
}) => {
  const { showSnackbar } = useContext(SnackbarContext);
  const { t } = useTranslation();
  const [uploadingFiles, setUploadingFiles] = useState([]);
  const [fileUploadPercentages, setFileUploadPercentages] = useState({});
  const [processedUploads, setProcessedUploads] = useState([]);

  if (clearFileManager) {
    clearFileManager.current = () => {
      setUploadingFiles([]);
      setFileUploadPercentages({});
      setProcessedUploads([]);
    };
  }

  // функция для обновления процентов по прогрессу
  const updateUploadProgress = useCallback((fileLocalId, percentage) => {
    setFileUploadPercentages((fileUploadPercentages) => ({
      ...fileUploadPercentages,
      [fileLocalId]: percentage,
    }));
  }, []);

  const [currentlyUploadingFile, setCurrentlyUploadingFile] = useState();
  const abortRequest = useRef(null);

  // функция для отправки файла на сервер и показа статуса отправки
  //  (используется вместе с useEffect как while цикл)
  const upload = useCallback(
    async (fileWithId) => {
      setCurrentlyUploadingFile(fileWithId.file);

      const formData = new FormData();
      if (addFilesFormDataTemplate) {
        for (const pair of addFilesFormDataTemplate.entries()) {
          formData.append(pair[0], pair[1]);
        }
      }

      let uniqueName = addFileNameId(fileWithId.file);
      formData.append('files', fileWithId.file, uniqueName);
      formData.append('form_id', formId);
      if (filesAddFormData) {
        formData.append(filesAddFormData.type, filesAddFormData.id);
      }

      let progressPercentage = 0;
      abortRequest.current = new AbortController();
      await axios
        .post(filesUploadPath, formData, {
          onUploadProgress: (progressEvent) => {
            const currProgress = parseInt(Math.round(progressEvent.loaded * 100) / progressEvent.total);
            if (currProgress !== progressPercentage) {
              progressPercentage = currProgress;
              updateUploadProgress(fileWithId.localId, currProgress);
            }
          },
          signal: abortRequest.current.signal,
        })
        .then((res) => {
          abortRequest.current = null;
          setProcessedUploads((f) => [
            ...f,
            {
              fileWithId: fileWithId,
              isRemoving: false,
              isUploaded: true,
              uniqueName: uniqueName,
              serverId: res.data[0]?.id,
            },
          ]);

          let isCompleted = false;
          setUploadingFiles((uploadingFiles) => {
            const remaingingFilesList = uploadingFiles.filter(
              (uploadingFileWithId) => uploadingFileWithId.localId !== fileWithId.localId,
            );

            // проверить, передал ли компонент-родитель функцию "setIsFilesUploading",
            // и если в массиве больше не осталось файлов для передачи на сервер,
            // то подготовить эту функцию для вызова со статусом "завершено"
            if (!remaingingFilesList.length && setIsFilesUploading) {
              isCompleted = true;
            }

            return remaingingFilesList;
          });
          setTimeout(() => isCompleted && setIsFilesUploading('completed'), 10);
          setCurrentlyUploadingFile(null);
        })
        .catch((err) => {
          if (err?.message !== 'canceled') {
            setProcessedUploads((f) => [
              ...f,
              {
                fileWithId: fileWithId,
                isRemoving: false,
                isUploaded: false,
                errorUploading: true,
              },
            ]);
            setUploadingFiles((f) =>
              f.filter((uploadingFileWithId) => uploadingFileWithId.localId !== fileWithId.localId),
            );
            showSnackbar(t('ErrorMessages.upload'));
            setCurrentlyUploadingFile(null);
          }
        });
    },
    [
      addFilesFormDataTemplate,
      formId,
      updateUploadProgress,
      showSnackbar,
      t,
      filesUploadPath,
      filesAddFormData,
      setIsFilesUploading,
    ],
  );

  // отправлять файлы по одному на сервер (работает как while цикл)
  useEffect(() => {
    if (uploadingFiles.length && !currentlyUploadingFile) {
      upload(uploadingFiles[0]);
    }
  }, [uploadingFiles, upload, currentlyUploadingFile]);

  // удалить все проценты по прогессу, после того как все файлы будут обработаны
  useEffect(() => {
    if (!uploadingFiles.length && !isEmpty(fileUploadPercentages)) {
      setFileUploadPercentages({});
    }
  }, [fileUploadPercentages, uploadingFiles]);

  const memoizedArr = useRef([]);

  // когда компонент получает новые файлы, данная функция обновляет state для запуска загрузки этих файлов на сервер
  useEffect(() => {
    if (filesToUpload && filesToUpload.length) {
      // если массив полученных файлов идентичный с ранее полученным массивом, то никаких операция не проводится
      if (memoizedArr.current === filesToUpload) return;
      if (setIsFilesUploading) setIsFilesUploading(true);
      memoizedArr.current = filesToUpload;
      memoizedArr.current = filesToUpload;
      let filesArrayToUpload = filesToUpload;
      if (!Array.isArray(filesToUpload)) {
        filesArrayToUpload = [...filesToUpload];
      }
      const filesWithIds = filesArrayToUpload.map((file) => ({
        file: file,
        localId: randomSequence(),
      }));
      setUploadingFiles((f) => [...f, ...filesWithIds]);
    }
  }, [filesToUpload, setIsFilesUploading]);

  const removeFile = (file) => {
    setUploadingFiles((f) => {
      return f.filter((uploadingFileWithId) => uploadingFileWithId.file !== file);
    });

    if (file === currentlyUploadingFile && abortRequest.current) {
      abortRequest.current.abort();
      setCurrentlyUploadingFile(null);
    }
  };

  const removeUploadedFile = async (file, index) => {
    const processedFile = processedUploads.find(
      (proccessedFileWithId) => proccessedFileWithId.fileWithId.file === file,
    );

    if (!processedFile) return;

    // если юзер нажал на кнопку "удалить" в файле, при отправке которого ранее возникла ошибка,
    // то просто удалить со списка файлов
    if (!processedFile.isUploaded) {
      return setProcessedUploads((processedFiles) =>
        processedFiles.filter((processedFileWithId) => processedFileWithId.fileWithId.file !== file),
      );
    }

    // отобразить, что загруженный на сервер файл переходит в процесс удаления
    setProcessedUploads((u) => {
      const uploads = [...u];
      uploads[index] = { ...uploads[index], isRemoving: true };
      return uploads;
    });

    const removeData = {
      form_id: formId,
    };
    if (removeById) removeData.files = [[processedFile.serverId]];
    else removeData.files = [[processedFile.uniqueName]];

    axios
      .patch(filesDeletePath, removeData)
      .then(() => {
        setProcessedUploads((processedFiles) =>
          processedFiles.filter((processedFileWithId) => processedFileWithId.fileWithId.file !== file),
        );
      })
      .catch(() => {
        showSnackbar(t('ErrorMessages.delete'));
      });
  };

  // используется для добавления в массив удаления файлов, который отправляется на сервер,
  // когда нажимается кнопка сохранить во время редактирования
  const addToDeleteFilesList = (file) => {
    if (!filesDeleteArr.includes(file)) {
      setFilesDeleteArr((f) => [...f, file]);
    }
  };

  removeFilesOnClose.current = useCallback(
    async (obj) => {
      //сделать аборт операции, если был в процессе загрузки файла на сервер,
      if (abortRequest.current) abortRequest.current.abort();

      // удалить уже загруженные файлы из сервера, если юзер закрыл окно и запрос по созданию не был завершен
      const filesToDeleteOnClose = [];
      for (const file of processedUploads) {
        // если для удаления требуется id, присвоенный сервером, то использовать id. Если нет, то использовать имя
        if (removeById) {
          if (file.serverId) {
            filesToDeleteOnClose.push(file.serverId);
          }
        } else if (file.uniqueName) {
          filesToDeleteOnClose.push(file.uniqueName);
        }
      }

      let removeData = removeFilesReqBody || { form_id: formId };

      // Если нужно переопределить значения объекта при отправке запроса, передаем в аргументе функции объект с необходимыми данными
      if (obj) {
        const key = Object.keys(obj)[0];
        const value = obj[key];

        removeData = { [key]: value };
      }

      removeData.files = filesToDeleteOnClose;

      if (filesToDeleteOnClose.length) {
        await axios
          .patch(filesDeletePath, removeData)
          .then(() => {})
          .catch(() => {
            showSnackbar('Возникла ошибка при удалении файлов');
          });
      }

      if (setIsFilesUploading) setIsFilesUploading(false);
      setUploadingFiles([]);
      setProcessedUploads([]);
    },
    [formId, processedUploads, filesDeletePath, removeFilesReqBody, removeById, setIsFilesUploading],
  );

  if (uploadingFiles.length || processedUploads.length || filesToEdit?.length) {
    return (
      // <div className="chat__attach attach">
      <>
        {/* Если открыт функционал редактирования, то отобразить ранее загруженные файлы */}
        {filesToEdit?.map((file, i) => (
          <FileToUpload
            file={file}
            progressPercent={100}
            key={i}
            removeFile={() => addToDeleteFilesList(file)}
            index={i}
            isRemoving={filesDeleteArr.includes(file)}
            isUploaded={true}
          />
        ))}

        {processedUploads.map((proccessedFileWithId, i) => (
          <FileToUpload
            file={proccessedFileWithId.fileWithId.file}
            progressPercent={100}
            key={i}
            removeFile={removeUploadedFile}
            index={i}
            isRemoving={proccessedFileWithId.isRemoving}
            isUploaded={proccessedFileWithId.isUploaded}
            errorUploading={proccessedFileWithId.errorUploading}
          />
        ))}
        {uploadingFiles.map((fileWithId, i) => (
          <FileToUpload
            file={fileWithId.file}
            progressPercent={fileUploadPercentages[fileWithId.localId]}
            key={i}
            removeFile={removeFile}
            index={i}
          />
        ))}
      </>
      // </div>
    );
  } else {
    return null;
  }
};

export default memo(FilesUploadManage);

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

import { url_get_employees_list, url_get_emplyee_info } from '../../settings/base-url';
import { checkTokenError } from '../../utilize/helper-functions';

const initialState = {
  departments: null,
  employees: null,
  onlineEmployees: null,
  positions: null,
  isLoading: false,
  isUpdatingUser: false,
  error: null,
};

export const getUsers = createAsyncThunk('users/getUsers', async (payload, thunkAPI) => {
  try {
    const users = await axios.get(url_get_employees_list);
    return users.data.employeesList;
  } catch (e) {
    const tokenError = checkTokenError(e);
    return thunkAPI.rejectWithValue(tokenError || 'Error while fetching users');
  }
});

export const getOnlineUsers = createAsyncThunk('users/getOnlineUsers', async (payload, thunkAPI) => {
  try {
    const response = await axios.get('/api/online_employees');
    return response.data.onlineEmployees;
  } catch (e) {
    return thunkAPI.rejectWithValue('Error while fetching users');
  }
});

export const updateUser = createAsyncThunk('users/updateUser', async (userId, thunkAPI) => {
  try {
    const response = await axios.get(`${url_get_emplyee_info}/${userId}`);
    return response.data.employeeInfo.employee[0];
  } catch (e) {
    const isTokenError = checkTokenError(e);

    return thunkAPI.rejectWithValue(isTokenError || 'Error while updating user');
  }
});

const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    userConnected: (state, { payload }) => {
      state.onlineEmployees?.push({ id: payload });
    },
    userDisconnected: (state, { payload }) => {
      if (payload === 'all') state.onlineEmployees = null;
      else {
        const index = state.onlineEmployees?.findIndex((user) => user.id === payload);
        if (index > -1) {
          state.onlineEmployees.splice(index, 1);
        }
      }

      // state.onlineEmployees = state.onlineEmployees?.filter(
      //   (user) => user.id !== payload
      // );
    },
    clearErrors: (state) => {
      state.error = null;
    },
  },

  extraReducers: {
    [getUsers.pending]: (state) => {
      state.isLoading = true;
    },
    [getUsers.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.employees = payload.employees;
      state.departments = payload.departments;
      state.positions = payload.positions;
    },
    [getUsers.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },

    [getOnlineUsers.fulfilled]: (state, { payload }) => {
      state.onlineEmployees = payload;
    },

    [getOnlineUsers.rejected]: () => {},

    [updateUser.pending]: (state) => {
      state.isUpdatingUser = true;
    },
    [updateUser.fulfilled]: (state, { payload }) => {
      state.isUpdatingUser = false;
      if (payload) {
        const ind = state.employees?.findIndex((user) => user.id === payload.id);

        if (ind !== -1) {
          state.employees[ind] = payload;
        }
      }
    },
    [updateUser.rejected]: (state) => {
      state.isUpdatingUser = false;
    },
  },
});

export const { userConnected, userDisconnected, clearErrors } = usersSlice.actions;

export default usersSlice.reducer;

import axios from 'axios';
import { useEffect, useState, useContext } from 'react';
import { useParams } from 'react-router-dom';

import { formatDateWithDots } from '../calendar-form/CalendarForm';
import { downloadFile } from '../../utilize/helper-functions';
import { files_url } from '../../settings/base-url';
import useAuth from '../../hooks/useAuth';
import Preloader from '../preloaders/Preloader';
import SnackbarContext from '../../contexts/SnackbarContext';

import FolderVersionRowMenu from './FolderVersionsRowMenu';

const FileVersionsModal = ({ close, currentFileData, renderUserName }) => {
  const [fileVersions, setFileVersions] = useState();
  const auth = useAuth();
  const { storageId } = useParams();
  const [isLoading, setIsLoading] = useState(true);

  const { showSnackbar } = useContext(SnackbarContext);

  useEffect(() => {
    if (currentFileData) {
      axios
        .get(`/api/storage_file_history/${currentFileData.id}`)
        .then((r) => {
          const { storage_file_history } = r.data;
          setFileVersions(storage_file_history);
          setIsLoading(false);
        })
        .catch(() => {
          showSnackbar('Возникла ошибка при загрузке предыдущих версий файла');
          setIsLoading(false);
        });
    }
  }, [currentFileData, showSnackbar]);

  if (!auth?.token) return '';
  return (
    <section className="modal">
      <div className="modal__wrapper">
        <div className="modal__inner">
          <section className="modal__body">
            <div className="modal__header">
              <h2 className="modal__title">История версий</h2>
              <button className="modal__close-modal" onClick={close}></button>
            </div>
            <div className="modal__row">
              <h3 className="modal__subtitle">Текущая</h3>
              <ul className="storage-list">
                <li
                  className="storage__catalog-file catalog-file"
                  onClick={() => downloadFile(`${files_url}/storages/${currentFileData.path}?token=${auth.token}`)}
                >
                  <p className="catalog-file__name">{currentFileData.title}</p>
                  <p className="catalog-file__weight">{/*20 мб*/}</p>
                  <p className="catalog-file__date">{formatDateWithDots(currentFileData.date_created)}</p>
                  {renderUserName(currentFileData.creator_id)}
                </li>
              </ul>
            </div>
            {isLoading && <Preloader />}
            {fileVersions && !isLoading && (
              <div className="modal__row">
                <h3 className="modal__subtitle">Предыдущие</h3>
                <ul className="storage-list">
                  {fileVersions.map((versionData, i) => (
                    <li
                      key={i}
                      className="storage__catalog-file catalog-file"
                      onClick={() =>
                        downloadFile(
                          `${files_url}/storages/versions/files/${storageId}/${versionData.id}/${versionData.title}?token=${auth.token}`,
                        )
                      }
                    >
                      <p className="catalog-file__name">{versionData.title}</p>
                      <p className="catalog-file__weight">{/*20 мб*/}</p>
                      <p className="catalog-file__date">{formatDateWithDots(versionData.date_created)}</p>
                      {renderUserName(versionData.creator_id)}
                      <FolderVersionRowMenu dataType="file" versionData={versionData} setIsSubmitting={setIsLoading} />
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </section>
        </div>
      </div>
    </section>
  );
};

export default FileVersionsModal;

import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';

import useAuth from '../../../hooks/useAuth';
import { url_get_employees_tree } from '../../../settings/base-url';
// import "react-sortable-tree/style.css";
import './TeamTree.css';

import Preloader from '../../../components/preloaders/Preloader';

import { useSocketContext } from '../../../contexts/SocketContext';

import TeamTreeListItem from './TeamTreeListItem';

// изенить локально стейт, когда сокет будет отправлять правильный объект

const TeamTree = () => {
  const auth = useAuth();
  const { socket } = useSocketContext();

  const [treeList, setTreeList] = useState(null);

  // список сотрудников, вход в комнату
  useEffect(() => {
    if (socket) socket.emit('add_to_room', 'employees_tree');

    axios.get(url_get_employees_tree).then(
      (res) => {
        //
        setTreeList(res.data.employeesTree);
      },
      () => {},
    );
  }, [auth.token, socket]);

  // подписались на изменения с сервера, меняем локальный  (список отделов)
  useEffect(() => {
    if (socket) {
      socket.on('department_changed', (data, action) => {
        updateState(data, action);
      });
    }
  }, [auth.token, socket]);

  // после изменений на сервере меняем стейт локально
  //
  const updateState = (data, action) => {
    switch (action) {
      case 'added':
        // setDepartments((prev) => [...prev, data]);
        break;
      case 'edited':
        // setDepartments((prev) => getEditedState(prev, data));
        break;
      case 'deleted':
        setTreeList((prev) => prev.filter((item) => item.id !== data.id));
        break;
      default:
      // return departments;
    }
  };

  //
  // заменяем отдел на отредактированный
  // const getEditedState = (arr, newItem) => {
  //   const ind = arr.findIndex(({ id }) => id === newItem.id);
  //   return [...arr.slice(0, ind), newItem, ...arr.slice(ind + 1)];
  // };

  // useEffect(() => {
  //   socket.on("connect_error", (err) => {
  //
  //   });;
  // }, [auth.token, socket]);

  let location = useLocation();

  return (
    <div style={{ height: 900 }}>
      {!treeList && <Preloader />}
      <section className="team">
        {/* <button onClick={test334}> клик </button> */}
        <div className="team__container container">
          <section className="team__tree team-tree">
            <ul>
              {treeList &&
                treeList.map((i) => (
                  <div key={i.id}>
                    <h2 className="team-tree__title">{i.title}</h2>
                    <TeamTreeListItem item={i} location={location} />
                  </div>
                ))}
            </ul>
          </section>
        </div>
      </section>
    </div>
  );
};

export default TeamTree;

import React from 'react';
import { useTranslation } from 'react-i18next';
// универсальный компонент формы - кнопки сохранить / отмена
const ModalBottom = ({ onClose, onCreate, isValid, isSending, action }) => {
  // const checkIsValidForm = () => {
  //   if (
  //     !!formValues.row.title &&
  //     !!formValues.row.head_id &&
  //     !!formValues.employees?.length > 0
  //   ) {
  //
  //     return false;
  //   } else {
  //     return true;
  //   }
  // };

  const { t } = useTranslation();

  return (
    <div className="modal__button-box">
      <button
        disabled={!isValid || isSending}
        // disabled={checkIsValidForm()}
        onClick={onCreate}
        className="modal__button modal__button--create"
        type="button"
      >
        {isSending && t('FormButtons.sending')}
        {!isSending && (action === 'edit' ? t('FormButtons.save') : t('FormButtons.create'))}
      </button>
      <button type="button" onClick={onClose} className="modal__button modal__button--cancel">
        {t('FormButtons.cancel')}
      </button>
    </div>
  );
};

export default ModalBottom;

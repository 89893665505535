import { useRef, memo, useEffect, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { useParams } from 'react-router-dom';

import { getOldMessages } from '../../../redux/features/oldMessagesSlice';
import useAuth from '../../../hooks/useAuth';
import LocalDateTime from '../../shared/LocalDateTime';
import { renderMessage } from '../../../utilize/draftjs-helper-functions';
import { useSocketContext } from '../../../contexts/SocketContext';
import { selectableTextAreaMouseUp } from '../../../utilize/message-helper-functions';

import AttachedFile from '../../files/AttachedFile';
import UserStatus from '../../employees/UserStatus';
import { files_url } from '../../../settings/base-url';

import MessageControls from './MessageControls';

const Message = ({
  date_created,
  deleted,
  editMessage,
  edited,
  editor_state,
  employee_id,
  employee_last_name,
  employee_first_name,
  hideProjectOptions,
  hidePin,
  goals,
  id: messageId,
  is_guest,
  likes,
  message_files,
  tags,
  title,
  projectData,
  pinned,
  pinnedMessageType,
  reply_id,
  reply_date,
  reply_first_name,
  reply_last_name,
  reply_text,
  sendResultFeedback,
  showPopover,
  showUserProfile,
  message,
}) => {
  const { likeMessage, dislikeMessage, manageMessagePin, recoverMessage } = useSocketContext();
  const { taskId } = useParams();

  const projectType = useMemo(() => (taskId ? 'task' : 'project'), [taskId]);

  const dispatch = useDispatch();

  // определить, если в сообщении есть проектный результат или результат для задачи
  const objectGoal = useMemo(() => {
    let goal;
    if (Array.isArray(goals) && goals.length) goal = { ...goals[0] };
    if (!projectData || !goal) return goal;

    if (goal.project_goal_id) {
      const selectedProjectGoal = projectData.project_goals?.find(
        (projectGoal) => projectGoal.id === goal.project_goal_id,
      );
      if (selectedProjectGoal) {
        goal.description = selectedProjectGoal.description;
      }
    } else if (goal.task_goal_id && projectData) {
      const selectedTaskGoal = projectData.task_goals?.find((taskGoal) => taskGoal.id === goal.task_goal_id);
      if (selectedTaskGoal) {
        goal.description = selectedTaskGoal.description;
      }
    }
    return goal;
  }, [goals, projectData]);

  const { t } = useTranslation();

  const fullName = useMemo(
    () => `${employee_first_name || ''} ${employee_last_name || ''}`,
    [employee_first_name, employee_last_name],
  );

  const localDateCreated = useMemo(() => <LocalDateTime dateTime={date_created} />, [date_created]);

  const auth = useAuth();
  const messageRef = useRef();

  const isMousePressedHere = useRef(false);

  const handleMouseUp = useCallback(
    (event) => {
      selectableTextAreaMouseUp(event, isMousePressedHere, showPopover, localDateCreated, fullName, messageId);
    },
    [showPopover, messageId, localDateCreated, fullName],
  );

  const detectMouseDown = () => {
    isMousePressedHere.current = true;
  };

  const [hideableMessage, setHideableMessage] = useState({
    hideable: false,
    display: false,
  });

  // если высота сообщения больше 100 пихелей, то свернуть сообщение
  useEffect(() => {
    if (messageRef?.current) {
      const messageHeight = Number(getComputedStyle(messageRef.current).height.slice(0, -2));

      if (messageHeight >= 100) {
        setHideableMessage({
          hideable: true,
          display: 'hide',
        });
      } else {
        setHideableMessage((h) => {
          if (h.hideable) {
            return { hideable: false, display: 'show' };
          }
          return h;
        });
      }
    }
  }, [reply_text, editor_state]);

  const likesState = useMemo(() => {
    const likesObj = {
      likesQty: 0,
      dislikesQty: 0,
      iLiked: false,
      iDisliked: false,
    };

    if (likes) {
      return likes.reduce((likesState, like) => {
        like.like === 'dislike' ? likesState.dislikesQty++ : likesState.likesQty++;
        if (like.employee_id === auth.user.id) {
          like.like === 'dislike' ? (likesState.iDisliked = true) : (likesState.iLiked = true);
        }
        return likesState;
      }, likesObj);
    } else return likesObj;
  }, [likes, auth]);

  const handlePinClick = () => {
    manageMessagePin(pinned, messageId);
  };

  const handleMessageEditClick = () => {
    editMessage({
      chat_message_id: messageId,
      editor_state,
      tags,
      message_files,
      title,
      goals,
      reply_date,
      reply_first_name,
      reply_last_name,
      reply_text,
    });
  };

  const handleReplyClick = () => {
    const replyMessage = document.getElementById(`messageId${reply_id}`);
    if (replyMessage) {
      replyMessage.scrollIntoView({ block: 'center' });
    } else {
      dispatch(
        getOldMessages({
          chat_id: projectData.chat.id,
          old_message_id: reply_id,
        }),
      );
    }
  };

  const showProfileOnClick = useRef(true);

  return (
    <div
      className={`chat__message message ${pinned ? 'message--pin' : ''}`}
      // не указывать id в DOM, если это закрепленное сообщение, чтобы не было возможности скролла по ссылкам на это закрепленное сообщение
      // id этого div используется для скролла на этот div при клике на ссылкку к этому сообщению
      id={pinnedMessageType ? '' : `messageId${messageId}`}
    >
      <div className="message__col">
        <div className="message__row">
          <UserStatus
            userId={employee_id}
            showProfileOnClick={showProfileOnClick}
            firstName={employee_first_name}
            lastName={employee_last_name}
            projectType={projectType}
            projectId={projectData?.id}
            is_guest={is_guest}
            projectData={projectData}
          />
          <div className="message__info">
            <div className="message__username">{fullName}</div>
            <div className="message__date">{localDateCreated}</div>
            <div className="message__thumb-box">
              {edited && <button className="message__edit-button">{t('FormButtons.ed_')}</button>}

              <button
                className={`message__thumb ${likesState.iLiked ? 'message__thumb--active' : ''}`}
                onClick={() => likeMessage(likesState, messageId)}
              >
                <span className="message__icon message__icon--thumb-up"></span>
                {likesState.likesQty > 0 && <span>{likesState.likesQty}</span>}
              </button>

              <button
                className={`message__thumb ${likesState.iDisliked ? 'message__thumb--active' : ''}`}
                onClick={() => dislikeMessage(likesState, messageId)}
              >
                <span className="message__icon message__icon--thumb-down"></span>
                {likesState.dislikesQty > 0 && <span>{likesState.dislikesQty}</span>}
              </button>
            </div>
          </div>
        </div>

        {deleted === 1 ? (
          <div className="message__content">
            <div className="message__delete-text">Сообщение удалено</div>
            {auth?.user?.id === employee_id && (
              <span
                style={{ cursor: 'pointer' }}
                to="#"
                className="message__content-link"
                onClick={() => recoverMessage(messageId)}
              >
                Восстановить
              </span>
            )}
          </div>
        ) : (
          <>
            <div
              className={`message__content ${
                hideableMessage.hideable ? `message__content--${hideableMessage.display}` : ''
              }`}
              ref={messageRef}
              onMouseDown={detectMouseDown}
              onMouseUp={handleMouseUp}
            >
              {reply_text && reply_date && (reply_first_name || reply_last_name) && (
                <div className="message__quote quote _pointer" onClick={handleReplyClick}>
                  <span className="quote__name">{`${reply_first_name || ''} ${reply_last_name || ''} `}</span>{' '}
                  <span className="quote__date">
                    <LocalDateTime dateTime={reply_date} />
                  </span>
                  <div className="quote__message">{reply_text}</div>
                </div>
              )}

              {tags && (
                <div className="message__mark-box">
                  {tags.map((tag) => {
                    return (
                      <span key={tag.id} className={`message__mark message__mark--${tag.color}`}>
                        {tag.name}
                      </span>
                    );
                  })}
                </div>
              )}

              {objectGoal && (
                <div className="message__attach-link">{`${t('common.result')}: ${objectGoal?.description || ''}`}</div>
              )}

              {title && !objectGoal && (
                <div>
                  <strong>{title}</strong>
                </div>
              )}
              {renderMessage(editor_state, showUserProfile)}
            </div>

            {hideableMessage.hideable && (
              <button
                className="message__read-more-button"
                onClick={() => {
                  if (messageRef?.current) {
                    setHideableMessage((h) => ({
                      ...h,
                      display: h.display === 'hide' ? 'show' : 'hide',
                    }));
                  }
                }}
              >
                {t(`FormButtons.${hideableMessage.display === 'hide' ? 'read_all' : 'fold'}`)}
              </button>
            )}

            {/* Отображение файлов, прикрепленных к этому сообщению */}
            <div className="message__attach attach">
              {message_files?.length > 0 && (
                <>
                  {message_files.map((file, i) => {
                    const url = `${files_url}/chat_messages/files/${messageId}/${file.id}/${file.file}?token=${auth.token}`;
                    return (
                      <AttachedFile
                        fileUrl={url}
                        fileName={file.file}
                        fileId={file.id}
                        key={i}
                        storageFileId={file.from_storage}
                      />
                    );
                  })}
                </>
              )}

              {objectGoal?.result === 'wait' &&
                (projectData?.responsible_id === auth?.user?.id ? (
                  <div className="message__attach-buttons">
                    <button onClick={() => sendResultFeedback(objectGoal.id, 'ok')} className="message__attach-button">
                      {t('FormButtons.accept')}
                    </button>
                    <button
                      onClick={() => sendResultFeedback(objectGoal.id, 'declined')}
                      className="message__attach-button message__attach-button--cancel"
                    >
                      {t('FormButtons.decline')}
                    </button>
                  </div>
                ) : (
                  <span className="message__status message__status--pending">Ожидает проверки</span>
                ))}

              {objectGoal?.result === 'ok' && <span className="message__status message__status--accept">Принят</span>}
              {objectGoal?.result === 'declined' && (
                <span className="message__status message__status--decline">Не принят</span>
              )}
            </div>
          </>
        )}
      </div>
      <MessageControls
        projectTitle={projectData?.title}
        handlePinClick={handlePinClick}
        handleMessageEditClick={handleMessageEditClick}
        employee_id={employee_id}
        messageId={messageId}
        message={message}
        editor_state={editor_state}
        fullName={fullName}
        date_created={date_created}
        messageTags={tags}
        hideProjectOptions={hideProjectOptions}
        hidePin={hidePin}
      />
    </div>
  );
};

export default memo(Message);

import axios from 'axios';
import { useContext, useEffect, useState } from 'react';

import useAuth from '../../hooks/useAuth';
import SnackbarContext from '../../contexts/SnackbarContext';

import FolderVersionsList from './FolderVersionsList';
import FolderVersionDetails from './FolderVersionDetails';

const FolderVersionsModal = ({ close, currentFolderData, renderUserName }) => {
  const [folderVersions, setFolderVersions] = useState();
  const [isLoadingVersions, setIsLoadingVersions] = useState(true);
  const [folderVersionDetails, setFolderVersionDetails] = useState();
  const [parentFolderStack, setParentFolderStack] = useState([]);
  const auth = useAuth();

  const { showSnackbar } = useContext(SnackbarContext);

  useEffect(() => {
    if (currentFolderData) {
      axios
        .get(`/api/storage_folder_history/${currentFolderData.id}`)
        .then((r) => {
          const { storage_folder_history } = r.data;
          setFolderVersions(storage_folder_history);
          setIsLoadingVersions(false);
        })
        .catch(() => {
          showSnackbar('Возникла ошибка при получении данных о версиях папки');
          setIsLoadingVersions(false);
        });
    }
  }, [currentFolderData, showSnackbar]);

  if (!auth?.token) return '';
  return (
    <section className="modal">
      <div className="modal__wrapper">
        <div className="modal__inner">
          <section className="modal__body">
            <div className="modal__header">
              <h2 className="modal__title">История версий</h2>
              <button className="modal__close-modal" onClick={close}></button>
            </div>
            {folderVersionDetails ? (
              <FolderVersionDetails
                folderVersionDetails={folderVersionDetails}
                renderUserName={renderUserName}
                setFolderVersionDetails={setFolderVersionDetails}
                parentFolderStack={parentFolderStack}
                setParentFolderStack={setParentFolderStack}
              />
            ) : (
              <FolderVersionsList
                renderUserName={renderUserName}
                setFolderVersionDetails={setFolderVersionDetails}
                folderVersions={folderVersions}
                currentFolderData={currentFolderData}
                isLoadingVersions={isLoadingVersions}
                parentFolderStack={parentFolderStack}
                setParentFolderStack={setParentFolderStack}
              />
            )}
          </section>
        </div>
      </div>
    </section>
  );
};

export default FolderVersionsModal;

import { useState, useRef, useContext } from 'react';
import axios from 'axios';
import { useDispatch } from 'react-redux';

import { useParams } from 'react-router-dom';

import { useOutsideTrigger } from '../../utilize/helper-functions';
import ConfirmAction from '../warnings/ConfirmAction';
import { getStorageTree } from '../../redux/features/storageSlice';
import SnackbarContext from '../../contexts/SnackbarContext';

const FolderVersionRowMenu = ({ dataType, versionData, setIsSubmitting }) => {
  const [openFolderMenu, setOpenFolderMenu] = useState();
  const { storageId } = useParams();

  const folderMenuBtn = useRef();
  const folderMenu = useRef();

  const [confirmPrimaryChoice, setConfirmPrimaryChoice] = useState();

  useOutsideTrigger([folderMenuBtn, folderMenu]);

  const dispatch = useDispatch();
  const { showSnackbar } = useContext(SnackbarContext);

  const submitPrimaryChoice = () => {
    setIsSubmitting(true);
    axios
      .put('/api/set_as_current_version', {
        [`${dataType}_id`]: versionData.id,
      })
      .then(() => {
        dispatch(getStorageTree({ storageId, showSnackbar }));
        showSnackbar(`Основная версия ${dataType === 'folder' ? 'папки' : 'файла'} изменена`, 'success');
      })
      .catch(() => {
        showSnackbar('Возникла ошибка при изменении основной версии');
      });
  };

  return (
    <>
      <button
        ref={folderMenuBtn}
        className="catalog-folder__button-dots"
        onClick={(e) => {
          e.stopPropagation();
          setOpenFolderMenu(openFolderMenu ? '' : 'active');
        }}
      ></button>
      <section ref={folderMenu} className={`catalog-folder__menu chat-menu ${openFolderMenu}`}>
        <p
          className="chat-menu__item"
          onClick={(e) => {
            e.stopPropagation();
            setOpenFolderMenu('');
            setConfirmPrimaryChoice(true);
          }}
        >
          Сделать основным
        </p>
      </section>

      {confirmPrimaryChoice && (
        <ConfirmAction
          actionText="Вы уверены, что хотите сделать эту версию основной?"
          confirm={(e) => {
            e.stopPropagation();
            submitPrimaryChoice();
            setConfirmPrimaryChoice(false);
          }}
          cancel={() => setConfirmPrimaryChoice(false)}
        />
      )}
    </>
  );
};

export default FolderVersionRowMenu;

import { useState, useContext, useEffect, useMemo, useRef } from 'react';
import { useForm, Controller } from 'react-hook-form';
import JSZip from 'jszip';
import { string, object } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import axios from 'axios';
import { useDispatch } from 'react-redux';
// import {saveAs} from "file-saver";

import ConfirmAction from '../warnings/ConfirmAction';
import SnackbarContext from '../../contexts/SnackbarContext';
import { incrementLastNumberInParentheses, useEventTriggerOnEscPress } from '../../utilize/helper-functions';
import { getStorageData, getStorageTree } from '../../redux/features/storageSlice';

const formSchema = object({ title: string().required() });

// dataId - id родителя (хранилище или папка)
const AddFolderModal = ({ storageId, folderId, close }) => {
  const {
    register,
    handleSubmit,
    // watch,
    formState: { isDirty, isValid },
    setFocus,
    control,
  } = useForm({
    resolver: yupResolver(formSchema),
    mode: 'onChange',
    defaultValues: { title: '' },
  });

  const [confirmClose, setConfirmClose] = useState();

  const handleClose = () => {
    if (isDirty) return setConfirmClose(true);
    close();
  };

  useEventTriggerOnEscPress(handleClose);

  const { showSnackbar } = useContext(SnackbarContext);

  const [isSubmitting, setIsSubmitting] = useState();

  const dispatch = useDispatch();

  const [replacementData, setReplacementData] = useState();

  const { dataType, dataId } = useMemo(() => {
    const dataType = folderId ? 'folder' : 'storage';
    const dataId = folderId || storageId;
    return { dataType, dataId };
  }, [folderId, storageId]);

  // сфокусировать на текстовом поле
  useEffect(() => {
    setFocus('title');
  }, [setFocus]);

  const [filesToUpload, setFilesToUpload] = useState([]);

  const [folderUploadStatus, setFolderUploadStatus] = useState();
  const abortFolderUploadController = useRef();

  const createFolder = async (data) => {
    if (filesToUpload.length) {
      const zip = new JSZip();
      for (let file = 0; file < filesToUpload.length; file++) {
        let filename = filesToUpload[file].name;
        const filePath = filesToUpload[file].filePath;
        // если файл с таким названием уже внесен в список для архивации, то добавить номер в названии файла
        while (zip.files[filename]) {
          filename = incrementLastNumberInParentheses(filename);
        }
        zip.file(filePath || filename, filesToUpload[file], {
          createFolders: true,
        });
      }
      setFolderUploadStatus({ status: 'archiving' });
      const blob = await zip.generateAsync({ type: 'blob' }).catch(() => {
        showSnackbar('Возникла ошибка при архивации файлов в папке');
        setIsSubmitting(false);
        setFolderUploadStatus(null);
      });
      // saveAs(blob, "archive.zip");
      // return;

      setFolderUploadStatus({ status: 'uploading', progress: 0 });

      const formData = new FormData();
      formData.append('archive', blob, 'archive.zip');
      formData.append('title', data.title);
      folderId ? formData.append('folder_id', folderId) : formData.append('storage_id', storageId);

      let progressPercentage = 0;
      abortFolderUploadController.current = new AbortController();

      await axios
        .post('/api/storage_folders/add', formData, {
          onUploadProgress: (progressEvent) => {
            const currProgress = parseInt(Math.round(progressEvent.loaded * 100) / progressEvent.total);
            if (currProgress !== progressPercentage) {
              progressPercentage = currProgress;
              setFolderUploadStatus({
                status: 'uploading',
                progress: currProgress,
              });
            }
          },
          signal: abortFolderUploadController.current.signal,
        })
        .catch(() => {
          showSnackbar('Возникла ошибка при отправке файлов');
          setIsSubmitting(false);
          setFolderUploadStatus(null);
          return;
        });
    } else {
      await axios
        .post('/api/storage_folders/create', {
          title: data.title,
          storage_id: folderId ? null : storageId,
          folder_id: folderId,
        })
        .catch(() => {
          showSnackbar('Возникла ошибка при создании папки');
          setIsSubmitting(false);
          return;
        });
    }

    dispatch(getStorageData({ storageId, showSnackbar }));
    dispatch(getStorageTree({ storageId, showSnackbar }));
    close();
  };

  const checkFolderExistence = (data) => {
    setIsSubmitting(true);
    axios
      .get(`/api/storage_folder_exist/${dataType}/${dataId}/${data.title}`)
      .then((r) => {
        const { storage_folder_exist } = r.data;
        if (storage_folder_exist) {
          return setReplacementData(data);
        }
        createFolder(data);
      })
      .catch(() => {
        showSnackbar('Возникла ошибка при проверке наличия папки');
        setIsSubmitting(false);
      });
  };

  // проверить поддерживается ли функция webkitdirectory по перекидке папки в браузер
  const folderUploadSupport = useMemo(() => {
    const input = document.createElement('input');

    return typeof input.webkitdirectory === 'boolean';
  }, []);

  const handleFolderSelect = (files, fieldOnChange) => {
    if (files.length) {
      const relativePath = files[0].webkitRelativePath;
      if (relativePath) {
        const folderName = relativePath.split('/')[0];
        fieldOnChange(folderName);
      }
      return setFilesToUpload((f) => [
        ...f,
        ...[...files].map((f) => {
          let filePath = '';
          if (f.webkitRelativePath) {
            filePath = f.webkitRelativePath.split('/');
            filePath.shift();
            filePath = filePath.join('/');
            f.filePath = filePath;
          }
          return f;
        }),
      ]);
    }
  };

  const handleFolderDrop = async (e, fieldOnChange) => {
    e.preventDefault();
    if (isSubmitting) return;
    const dataTransferEntry = e.dataTransfer.items[0].webkitGetAsEntry();
    if (dataTransferEntry.isDirectory) {
      const folderName = dataTransferEntry.fullPath.split('/')[1];
      if (folderName) fieldOnChange(folderName);
      const foldersArr = [dataTransferEntry];
      const filesArr = [];
      while (foldersArr.length) {
        const entry = foldersArr.shift();
        const entryPromise = new Promise((resolve, reject) => {
          entry.createReader().readEntries(
            async (entries) => {
              if (!entries?.length) resolve();
              for (const entry of entries) {
                if (entry.isDirectory) {
                  foldersArr.push(entry);
                } else if (entry.isFile) {
                  let filePath = '';
                  if (typeof entry.fullPath === 'string') {
                    filePath = entry.fullPath.split('/');
                    filePath.splice(0, 2);
                    filePath = filePath.join('/');
                  }
                  const fileExtractPromise = new Promise((resolve, reject) => {
                    entry.file(
                      (file) => {
                        file.filePath = filePath;
                        filesArr.push(file);
                        resolve();
                      },
                      (error) => reject(error),
                    );
                  });
                  await fileExtractPromise.catch((e) => reject(e));
                }
              }
              resolve();
            },
            (error) => {
              reject(error);
            },
          );
        });
        await entryPromise.catch(() => {
          showSnackbar('Возникла ошибка при чтении файлов в папке');
        });
      }
      if (filesArr) setFilesToUpload(filesArr);
    }
  };

  return (
    <>
      <section className="modal">
        <div className="modal__wrapper">
          <div className="modal__inner">
            <section className="modal__body">
              <div className="modal__header">
                <h2 className="modal__title">Создать папку</h2>
                <button className="modal__close-modal" onClick={handleClose}></button>
              </div>
              <Controller
                control={control}
                name="title"
                render={({ field: { value, onChange, ref } }) => {
                  //
                  return (
                    <div className="modal__row">
                      <label className="modal__label" htmlFor="folderTitle">
                        Название папки
                      </label>
                      <input
                        ref={ref}
                        className="modal__input"
                        id="folderTitle"
                        value={value}
                        onChange={onChange}
                        {...register('title')}
                      />
                    </div>
                  );
                }}
              />

              {filesToUpload.length > 0 && (
                <div className="modal__row">
                  <label className="modal__label">Файлы в папке</label>
                  <ul className="storage-list">
                    {filesToUpload.map((file, i) => (
                      <li key={i} className={`catalog-file catalog-file--upload`}>
                        <p className="catalog-file__name">{file.name}</p>
                        <button
                          onClick={() => setFilesToUpload(filesToUpload.filter((file, ind) => ind !== i))}
                          disabled={isSubmitting}
                          className="catalog-file__button-delete"
                        ></button>
                      </li>
                    ))}
                  </ul>
                </div>
              )}

              {folderUploadSupport && !folderUploadStatus && (
                <Controller
                  name="title"
                  control={control}
                  render={({ field: { onChange } }) => {
                    return (
                      <section
                        className="modal__row drag-drop"
                        onDragEnter={(e) => {
                          e.currentTarget.style.backgroundColor = '#fff';
                          e.currentTarget.style.color = '#262626';
                        }}
                        onDragOver={(e) => e.preventDefault()}
                        onDrop={(e) => {
                          handleFolderDrop(e, onChange);
                          e.currentTarget.style.backgroundColor = '#f5f5f5';
                          e.currentTarget.style.color = '#8c8c8c';
                        }}
                        onDragLeave={(e) => {
                          e.currentTarget.style.backgroundColor = '#f5f5f5';
                          e.currentTarget.style.color = '#8c8c8c';
                        }}
                      >
                        <label className="drag-drop__label" htmlFor="uploadFolder"></label>
                        <input
                          className="drag-drop__input"
                          type="file"
                          id="uploadFolder"
                          webkitdirectory="true"
                          mozdirectory="true"
                          directory="true"
                          multiple={true}
                          onChange={(e) => {
                            handleFolderSelect(e.target.files, onChange);
                          }}
                        />

                        <p className="drag-drop__text">
                          или перетяните существующую папку с файлами
                          <br />
                          или кликните, чтобы выбрать папку
                        </p>
                      </section>
                    );
                  }}
                />
              )}

              {folderUploadStatus && (
                <div className="modal__row">
                  <label className="modal__label">Статус отправки:</label>
                  {folderUploadStatus.status === 'archiving' && <div>Идет архивация файлов в папке</div>}
                  {folderUploadStatus.status === 'uploading' && (
                    <div className="progress-bar">
                      <div className="progress-bar__fill" style={{ width: `${folderUploadStatus.progress}%` }}></div>
                    </div>
                  )}
                </div>
              )}

              <div className="modal__button-box">
                <button
                  className="modal__button modal__button--create"
                  disabled={!isValid || !isDirty || isSubmitting}
                  onClick={handleSubmit(checkFolderExistence)}
                >
                  Создать
                </button>
                <button className="modal__button modal__button--cancel" onClick={handleClose}>
                  Отмена
                </button>
              </div>
            </section>
          </div>
        </div>
      </section>
      {confirmClose && (
        <ConfirmAction
          confirm={close}
          cancel={() => setConfirmClose(false)}
          actionText="Вы уверены что хотите закрыть форму, не сохранив изменения?"
        />
      )}

      {replacementData && (
        <ConfirmAction
          actionText="Папка с таким названием уже существует. Вы хотите заменить эту папку?"
          confirm={() => createFolder(replacementData)}
          cancel={() => {
            setReplacementData(null);
            setIsSubmitting(false);
          }}
        />
      )}
    </>
  );
};

export default AddFolderModal;

import { useState, useCallback, useEffect, useRef, useMemo, useContext } from 'react';
import axios from 'axios';
import { useParams, useLocation, useNavigate } from 'react-router-dom';

import ReactHTMLParser from 'html-react-parser';

import { useSelector } from 'react-redux';

import Preloader from '../../components/preloaders/Preloader';
import { useOutsideTrigger } from '../../utilize/helper-functions';
import useAuth from '../../hooks/useAuth';
import ConfirmAction from '../../components/warnings/ConfirmAction';
import SnackbarContext from '../../contexts/SnackbarContext';
import CreateEditNewsModal from '../../windows/news/CreateEditNewsModal';
import { formatDateWithDots } from '../../components/calendar-form/CalendarForm';

import UserStatus from '../../components/employees/UserStatus';
import { files_url } from '../../settings/base-url';
import AttachedFile from '../../components/files/AttachedFile';
import UserTextingListener from '../../components/chat/UserTextingListener';
import ChatTextEditorContainer from '../../components/text-editor/ChatTextEditorContainer';
import { useSocketContext } from '../../contexts/SocketContext';
import { useChatListener, formatMessages, requestNextMessages } from '../../utilize/message-helper-functions';
import { offChatChange, onChatChange } from '../../settings/sockets';
import { MessageContextProvider } from '../../contexts/MessageContext';
import MessageTextSelectionPopoverContainer from '../../components/chat/MessageTextSelectionPopover';
import DropFilesWrapperContainer from '../../components/drag-field/DropFilesWrapper';
// import MessageContainer from "../../components/chat/message/MessageContainer";

const SingleNewsBlog = () => {
  const { newsBlogId } = useParams();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const type = useMemo(() => {
    if (pathname?.includes('news')) return 'news';
    if (pathname?.includes('blog')) return 'blog';
  }, [pathname]);

  const [newsBlogData, setNewsBlogData] = useState();
  const [messagesList, setMessagesList] = useState([]);

  const author = useSelector((state) => {
    if (newsBlogData) {
      return state.users.employees?.find((employee) => employee.id === newsBlogData.creator_id);
    }
  });

  const [loading, setLoading] = useState(true);
  const auth = useAuth();
  // проверить есть ли у юзера права на изменение статуса проекта/таска
  const userHasRights = useMemo(() => {
    if (!auth?.user?.id || !auth?.rightTypes) return false;
    if (type === 'news' && auth?.isUserRightful(auth.rightTypes.add_news)) {
      return true;
    }
    if (type === 'blogs' && auth?.isUserRightful()) return true;
    if (auth.user.id === newsBlogData?.creator_id) return true;
    return false;
  }, [auth, newsBlogData, type]);

  const [isChatEnd, setIsChatEnd] = useState(false);

  const getData = useCallback(() => {
    if (newsBlogId) {
      setLoading(true);
      axios
        .get(`/api/news_blog_data/${newsBlogId}`)
        .then((r) => {
          const newsBlogData = r.data.newsBlogData;

          const formattedMessages = formatMessages({
            projectData: newsBlogData,
            messages: newsBlogData.chat.messages,
            additionalMessageProps: { hideProjectOptions: true, hidePin: true },
          });

          setNewsBlogData(r.data.newsBlogData);
          setMessagesList(formattedMessages);
          if (formattedMessages.length < 20) setIsChatEnd(true);
        })
        .catch(() => showSnackbar('Возникла ошибка при получении данных'))
        .finally(() => setLoading(false));
    }
  }, [newsBlogId]);
  // получить данные по новости/блогу
  useEffect(() => getData(), [getData]);

  const optionsMenuRef = useRef();
  const optionsBtnRef = useRef();
  const refsArray = useRef([optionsMenuRef, optionsBtnRef]);

  const clearOptionsMenu = useCallback(() => {
    toggleOptionsMenu(false);
  }, []);

  const [optionsMenu, toggleOptionsMenu] = useState(false);

  // для закрытия всплывашек когда кликают вне этих всплывашек
  useOutsideTrigger(refsArray.current, clearOptionsMenu, optionsMenu);

  const { showSnackbar } = useContext(SnackbarContext);

  const [isDeleting, setIsDeleting] = useState();

  const handleDelete = async () => {
    if (newsBlogData) {
      setIsDeleting(true);
      if (newsBlogData.files?.length) {
        await axios
          .patch('/api/news_blogs/files/remove', {
            news_blog_id: newsBlogData.id,
            files: newsBlogData.files.map((file) => file.file),
          })
          .catch(() => {
            showSnackbar('Ошибка при удалении файлов');
            return setIsDeleting(false);
          });
      }

      axios
        .delete('/api/news_blogs/delete', {
          data: {
            news_blog_id: newsBlogData.id,
          },
        })
        .then(() => {
          showSnackbar(type === 'news' ? 'Новость удалена' : 'Блог удален', 'success');
          return navigate(type === 'news' ? '/news' : '/blogs');
        })
        .catch(() => {
          showSnackbar('Произошла ошибка при удалении');
          setIsDeleting(false);
        });
    }
  };

  const [confirmDelete, setConfirmDelete] = useState();

  const [editModal, showEditModal] = useState();
  const showProfileOnClick = useRef(true);

  const { socket, addToRoom } = useSocketContext();

  // войти в комнату socket для чата
  useEffect(() => {
    if (socket && socket.connected && newsBlogData?.chat?.id) {
      addToRoom(`chat_room_${newsBlogData.chat.id}`);
    }
  }, [socket, newsBlogData, addToRoom]);

  const chatPageRef = useRef();

  const [nextMessagesLoading, setNextMessagesLoading] = useState('');

  // custom react hook для слушания за изменением чата со стороны других юзеров
  const chatChangeListener = useChatListener({
    isChatEnd,
    projectData: newsBlogData,
    chatPageRef,
    setMessagesList,
    messagesList,
    additionalMessageProps: {
      hideProjectOptions: true,
      hidePin: true,
    },
  });

  // cлушать за изменением чата со стороны других пользователей
  useEffect(() => {
    if (socket && socket.connected) {
      onChatChange(socket, chatChangeListener);

      return () => offChatChange(socket);
    }
  }, [socket, chatChangeListener]);

  const handleScroll = (element) => {
    if (
      chatPageRef.current.scrollHeight - element.target.scrollTop === element.target.clientHeight &&
      messagesList.length >= 20 &&
      nextMessagesLoading !== 'loading' &&
      !isChatEnd
    ) {
      setNextMessagesLoading('loading');

      const lastMessageId = messagesList[messagesList.length - 1].props.id;

      // return;
      requestNextMessages(newsBlogData.chat.id, lastMessageId)
        .then((response) => {
          const messages = formatMessages({
            projectData: newsBlogData,
            messages: response.data.messages.chat_messages,
            additionalMessageProps: { hideProjectOptions: true, hidePin: true },
          });

          setMessagesList((m) => [...m, ...messages]);
          setNextMessagesLoading(null);
          if (response.data.messages.end) setIsChatEnd(true);
        })
        .catch(() => {
          showSnackbar('Возникла ошибка при запросе последующих сообщений');
        });
    }
  };

  // для прикрепления файлов при отправке сообщения с файлами
  const [draggedFiles, setDraggedFiles] = useState();
  const [disableChatFilesDrop, setDisableChatFilesDrop] = useState();

  return (
    <MessageContextProvider>
      <main className="no-padding">
        {loading ? (
          <Preloader />
        ) : (
          <section className="chat">
            <div className="chat__container container">
              {newsBlogData && (
                <div className="chat__wrapper">
                  <div
                    className="chat__page"
                    ref={chatPageRef}
                    onScroll={handleScroll}
                    style={{ position: 'relative' }}
                  >
                    <DropFilesWrapperContainer setFiles={setDraggedFiles} disabled={disableChatFilesDrop}>
                      <section className="chat__body">
                        <div className="chat__message message">
                          <div className="message__col">
                            {author && (
                              <div className="message__row">
                                <UserStatus
                                  userId={author.id}
                                  firstName={author.first_name}
                                  lastName={author.last_name}
                                  showProfileOnClick={showProfileOnClick}
                                />
                                <div className="message__info">
                                  <p className="message__username">{`${author.first_name} ${
                                    author.last_name || ''
                                  }`}</p>
                                  <p className="message__date">{formatDateWithDots(newsBlogData.date_created)}</p>
                                </div>
                              </div>
                            )}
                            <div className="message__content">
                              <h2 className="message__title">{newsBlogData.title}</h2>
                              {typeof newsBlogData.description === 'string'
                                ? ReactHTMLParser(newsBlogData.description)
                                : ''}
                            </div>
                            <div className="message__attach attach">
                              {newsBlogData.files?.length > 0 && (
                                <>
                                  {newsBlogData.files.map((file, i) => {
                                    const url = `${files_url}/news_blogs/files/${newsBlogData.id}/${file.file}?token=${auth.token}`;
                                    return <AttachedFile fileUrl={url} fileName={file.file} key={i} />;
                                  })}
                                </>
                              )}
                            </div>
                          </div>
                          <div className="message__col">
                            <div className="message__row active">
                              {userHasRights && (
                                <div className="message__controls-wrapper">
                                  <button
                                    className="message__controls message__controls--dots"
                                    onClick={() => toggleOptionsMenu(!optionsMenu)}
                                    ref={optionsBtnRef}
                                  ></button>
                                  <section className={`chat-menu ${optionsMenu ? 'active' : ''}`} ref={optionsMenuRef}>
                                    <p
                                      className="chat-menu__item"
                                      onClick={() => {
                                        toggleOptionsMenu(false);
                                        showEditModal(true);
                                      }}
                                    >
                                      Редактировать
                                    </p>

                                    <p
                                      className="chat-menu__item"
                                      onClick={() => {
                                        toggleOptionsMenu(false);
                                        handleDelete(true);
                                      }}
                                    >
                                      Удалить
                                    </p>
                                    <p className="chat-menu__separator"></p>
                                  </section>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>

                        {/* список самых первых сообщений */}
                        {messagesList}
                        {nextMessagesLoading === 'loading' && <Preloader />}
                      </section>
                      {newsBlogData && (
                        <>
                          <section className="chat__footer">
                            <UserTextingListener chatId={newsBlogData.chat.id} />
                            <ChatTextEditorContainer
                              chatId={newsBlogData.chat.id}
                              draggedFiles={draggedFiles}
                              setDisableChatFilesDrop={setDisableChatFilesDrop}
                            />
                            {/* показывает контекстное меню при выделении текста в любом сообщении */}
                          </section>
                          <MessageTextSelectionPopoverContainer hideProjectOptions={true} />
                        </>
                      )}
                    </DropFilesWrapperContainer>
                  </div>
                </div>
              )}
            </div>
          </section>
        )}
      </main>

      {confirmDelete && (
        <ConfirmAction
          confirm={handleDelete}
          cancel={() => setConfirmDelete(false)}
          actionText="Пожалуйста, подтвердите удаление."
          isSubmitting={isDeleting}
        />
      )}

      {editModal && type && (
        <CreateEditNewsModal
          dataToEdit={newsBlogData}
          type={type}
          close={() => showEditModal(false)}
          getData={getData}
        />
      )}
    </MessageContextProvider>
  );
};

export default SingleNewsBlog;

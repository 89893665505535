import { useEffect, useState, useCallback, memo } from 'react';
import { useTranslation } from 'react-i18next';

import { useDispatch, useSelector } from 'react-redux';

import ChatTextEditorContainer from '../text-editor/ChatTextEditorContainer';

import { useSocketContext } from '../../contexts/SocketContext';
import { onChatChange, offChatChange } from '../../settings/sockets';

import Preloader from '../preloaders/Preloader';

import DropFilesWrapper from '../drag-field/DropFilesWrapper';

import { updateOldMessage } from '../../redux/features/oldMessagesSlice';

import MessageContainer from './message/MessageContainer';

import MessageTextSelectionPopover from './MessageTextSelectionPopover';

import OldMessagesViewer from './OldMessagesViewer';

import UserTextingListener from './UserTextingListener';

const SingleProjectChat = ({
  projectData,
  messagesList,
  pinnedMessages,
  setMessagesList,
  setPinnedMessages,
  messagesLoading,
  nextMessagesLoading,
  resetToLatestMessages,
  scrollToEndButton,
  chatPageRef,
  // guestRights,
}) => {
  //
  const { socket, addToRoom } = useSocketContext();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  // войти в комнату socket для чата
  useEffect(() => {
    if (socket && socket.connected && projectData?.chat?.id) {
      addToRoom(`chat_room_${projectData.chat.id}`);
    }
  }, [socket, projectData, addToRoom]);

  // для прикрепления файлов при отправке сообщения с файлами
  const [draggedFiles, setDraggedFiles] = useState();

  // переменные для перехода в старые сообщения
  const { requestedOldMessageId, loadingOldMessages, oldMessages } = useSelector((state) => state.oldMessages);
  // функция для обновления чата при получении сообщений из сокета
  const chatChangeListener = useCallback(
    (data, action) => {
      if (projectData?.chat?.id) {
        switch (action) {
          case 'added':
            //
            if (data.chat_id === projectData.chat.id) {
              let focusOnThisMessage = false;
              // если юзер находится в конце страницы чата, то, после добавления сообщения, сфокусировать на последнем сообщении
              if (
                chatPageRef?.current?.scrollHeight - chatPageRef?.current?.scrollTop ===
                chatPageRef?.current?.clientHeight
              ) {
                focusOnThisMessage = true;
              }

              setMessagesList((messages) => [
                ...messages,
                <MessageContainer
                  key={data.id}
                  {...data}
                  message_files={data.files}
                  goals={data.chat_goal_results}
                  projectData={projectData}
                />,
              ]);

              if (focusOnThisMessage) {
                setTimeout(() => {
                  const newMessage = document.getElementById(`messageId${data.id}`);
                  if (newMessage) {
                    newMessage.scrollIntoView();
                  }
                }, 100);
              }
            }
            break;

          case 'edited':
          case 'like':
          case 'dislike':
          case 'cancel_like_dislike':
          case 'recovered':
          case 'goal_accept':
            if (data.chat_id === projectData.chat.id) {
              const foundIndex = messagesList.findIndex((message) => message.props.id === data.id);
              if (foundIndex !== -1) {
                setMessagesList((messagesList) => {
                  const updMessages = [...messagesList];
                  updMessages[foundIndex] = (
                    <MessageContainer
                      key={data.id}
                      {...data}
                      message_files={data.files}
                      goals={data.chat_goal_results}
                      projectData={projectData}
                    />
                  );
                  return updMessages;
                });
              }

              const foundPinndeMessageIndex = pinnedMessages.findIndex((message) => message.props.id === data.id);
              if (foundPinndeMessageIndex !== -1) {
                setPinnedMessages((messagesList) => {
                  const updMessages = [...messagesList];
                  updMessages[foundPinndeMessageIndex] = (
                    <MessageContainer
                      key={data.id}
                      {...data}
                      message_files={data.files}
                      goals={data.chat_goal_results}
                      projectData={projectData}
                      pinnedMessageType={true}
                    />
                  );
                  return updMessages;
                });
              }

              dispatch(updateOldMessage({ data }));
            }
            break;

          case 'pinned':
            const messageToPin = messagesList.find((message) => message.props.id === data.id);
            if (messageToPin) {
              setMessagesList((messages) =>
                messages.map((message) => {
                  if (message.props.id === messageToPin.props.id) {
                    return (
                      <MessageContainer key={data.id} {...messageToPin.props} pinned={1} pinnedMessageType={false} />
                    );
                  } else return message;
                }),
              );
              setPinnedMessages((pinnedMessages) => [
                ...pinnedMessages,
                <MessageContainer key={data.id} {...messageToPin.props} pinned={1} pinnedMessageType={true} />,
              ]);
            } else {
              const oldMessageToPin = oldMessages.find((message) => message.id === data.id);

              if (oldMessageToPin) {
                setPinnedMessages((pinnedMessages) => [
                  ...pinnedMessages,
                  <MessageContainer key={data.id} {...oldMessageToPin} pinned={1} pinnedMessageType={true} />,
                ]);
              }
            }

            dispatch(updateOldMessage({ data, type: action }));
            break;

          case 'unpinned':
            const messageToUnpin = pinnedMessages.find((message) => message.props.id === data.id);
            if (messageToUnpin) {
              setPinnedMessages((pinnedMessages) =>
                pinnedMessages.filter((pinnedMessage) => pinnedMessage.props.id !== messageToUnpin.props.id),
              );
              setMessagesList((messages) => {
                const unpinnedMessageIndex = messages.findIndex((message) => message.props.id === data.id);
                if (unpinnedMessageIndex !== -1) {
                  return messages.map((message) => {
                    if (message.props.id === data.id) {
                      return <MessageContainer key={data.id} {...messageToUnpin.props} pinned={0} />;
                    } else return message;
                  });
                } else return messages;
              });
            }
            dispatch(updateOldMessage({ data, type: action }));
            break;

          case 'deleted':
            const messageIndexToDelete = messagesList.findIndex((message) => message.props.id === data.id);

            if (messageIndexToDelete !== -1) {
              setMessagesList((messages) => {
                const updMessages = [...messages];
                updMessages[messageIndexToDelete] = (
                  <MessageContainer key={data.id} {...updMessages[messageIndexToDelete].props} deleted={1} />
                );
                return updMessages;
              });
            }

            const pinnedMessageIndexToDelete = pinnedMessages.findIndex((message) => message.props.id === data.id);
            if (pinnedMessageIndexToDelete !== -1) {
              setPinnedMessages((pinnedMessages) => {
                const updPinnedMessages = [...pinnedMessages];
                updPinnedMessages[pinnedMessageIndexToDelete] = (
                  <MessageContainer
                    key={data.id}
                    {...updPinnedMessages[pinnedMessageIndexToDelete].props}
                    deleted={1}
                    pinnedMessageType={true}
                  />
                );
                return updPinnedMessages;
              });
            }
            dispatch(updateOldMessage({ data, type: action }));

            break;

          default:
            return;
        }
      }
    },
    [projectData, messagesList, pinnedMessages, setMessagesList, setPinnedMessages, chatPageRef, dispatch, oldMessages],
  );

  // cлушать за изменением чата со стороны других пользователей
  useEffect(() => {
    if (socket && socket.connected) {
      onChatChange(socket, chatChangeListener);

      return () => offChatChange(socket);
    }
  }, [socket, chatChangeListener]);

  const scrollToLastMessage = () => {
    if (messagesList?.length) {
      const lastMessageId = messagesList[messagesList.length - 1].props.id;
      const lastMessage = document.getElementById(`messageId${lastMessageId}`);
      if (lastMessage) {
        lastMessage.scrollIntoView();
      }
    }
  };

  const [disableChatFilesDrop, setDisableChatFilesDrop] = useState();

  return (
    <>
      <DropFilesWrapper setFiles={setDraggedFiles} disabled={disableChatFilesDrop}>
        <section className="chat__body">
          {requestedOldMessageId ? (
            <>
              {/* компонент используется для показа старых сообщений (не из актуального feed) с двухсторонней прокруткой */}
              <OldMessagesViewer
                requestedOldMessageId={requestedOldMessageId}
                oldMessages={oldMessages}
                projectData={projectData}
                loadingOldMessages={loadingOldMessages}
                messagesLoading={messagesLoading}
                nextMessagesLoading={nextMessagesLoading}
                resetToLatestMessages={resetToLatestMessages}
              />
            </>
          ) : (
            <>
              {messagesLoading === 'previous' && <Preloader />}

              {/* если самое начало чата, то показывает сообщение "больше нет сообщений в чате" */}
              {messagesLoading === 'end' && <div className="chat__message message">{t('Chat.no_more')}</div>}

              {/* список актуальных, последних сообщений */}
              {messagesList}

              {/* скрывать/показывать кнопку скролла чата на самое актуальное состояние чата */}
              {scrollToEndButton && (
                <button
                  className="chat__button-scroll"
                  onClick={() => {
                    scrollToLastMessage();
                  }}
                />
              )}
            </>
          )}
        </section>
        {projectData && (
          <>
            <section className="chat__footer">
              <UserTextingListener chatId={projectData.chat?.id} />
              <ChatTextEditorContainer
                draggedFiles={draggedFiles}
                chatId={projectData.chat?.id}
                goals={projectData.project_goals || projectData.task_goals}
                storageId={projectData.storage_id}
                setDisableChatFilesDrop={setDisableChatFilesDrop}
              />
            </section>

            {/* показывает контекстное меню при выделении текста в любом сообщении */}
            <MessageTextSelectionPopover />
          </>
        )}
      </DropFilesWrapper>
    </>
  );
};

export default memo(SingleProjectChat);

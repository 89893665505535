import { memo } from 'react';
import { useTranslation } from 'react-i18next';

const SelectTagPopover = ({ tagsList, chatOption, tagsOptionRef, selectedTags, setSelectedTags }) => {
  const { t } = useTranslation();
  const onTagSelect = (tagToAdd) => {
    const isSelected = selectedTags.find((tag) => tag.id === tagToAdd.id);
    if (isSelected) return;
    setSelectedTags((t) => [...t, tagToAdd]);
  };

  return (
    <section className={`chat-menu ${chatOption === 'tags' ? 'active' : null}`} ref={tagsOptionRef}>
      <div className="chat-menu__header">
        <h3 className="chat-menu__title">{t('common.tags')}</h3>
        {/* <button className="chat-menu__button-add"></button> */}
      </div>

      {tagsList?.map((tag, i) => {
        return (
          <p className="chat-menu__item" key={i} onClick={() => onTagSelect(tag)}>
            <span className={`chat-menu__mark chat-menu__mark--${tag.color}`}>{tag.name}</span>
          </p>
        );
      })}
    </section>
  );
};

export default memo(SelectTagPopover);

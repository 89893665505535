import moment from 'moment';

import { files_url } from 'src/settings/base-url';

import useAuth from 'src/hooks/useAuth';

import { Term } from '@shared/components/Term/Term';
import { Text } from '@shared/components/Text/Text';

import AttachedFile from '@components/files/AttachedFile';

import HTMLReactParser from 'html-react-parser';

import { StyledTicketAttachWrapper, StyledTicketReport, StyledTicketTitle } from './style';

export const TicketReport = ({ action }) => {
  const auth = useAuth();

  const actionDate = new Date(action.date_action);

  return (
    <StyledTicketReport>
      <Term>
        {moment.utc(actionDate).format('L')} {moment.utc(actionDate).format('LT')}
      </Term>
      <StyledTicketTitle tag="h3">
        {action.action === 'returned' ? 'Необходимо исправить' : 'Отчет о выполнении задания'}
      </StyledTicketTitle>
      <Text tag="p">{HTMLReactParser(action.report)}</Text>

      <StyledTicketAttachWrapper>
        {action.ticket_action_files?.length > 0 && (
          <>
            {action.ticket_action_files.map((file, i) => {
              const url = `${files_url}/ticket_actions/files/${action.id}/${file.id}/${file.file}?token=${auth.token}`;
              return (
                <AttachedFile
                  fileUrl={url}
                  fileName={file.file}
                  fileId={file.id}
                  key={i}
                  storageFileId={file.from_storage}
                />
              );
            })}
          </>
        )}
      </StyledTicketAttachWrapper>
    </StyledTicketReport>
  );
};

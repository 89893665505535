import { configureStore } from '@reduxjs/toolkit';

import { setupListeners } from '@reduxjs/toolkit/dist/query';

import usersReducer from './features/usersSlice';
import tagsReducer from './features/tagsSlice';
import projectsReducer from './features/projectsSlice';
import oldMessagesReducer from './features/oldMessagesSlice';
import storageReducer from './features/storageSlice';
import { ticketsApi } from './features/api/tickets';

export const store = configureStore({
  reducer: {
    users: usersReducer,
    tags: tagsReducer,
    projects: projectsReducer,
    oldMessages: oldMessagesReducer,
    storage: storageReducer,
    [ticketsApi.reducerPath]: ticketsApi.reducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(ticketsApi.middleware),
});

setupListeners(store.dispatch);

import React from 'react';
import { useTranslation } from 'react-i18next';

const ResultItem = ({ item, ind, initEditableResult, deleteResult }) => {
  const { t } = useTranslation();

  return (
    <li className="modal-list__item">
      <span className="modal-list__name">
        {ind + 1}. {item.description}
      </span>
      <div className="modal-list__link">
        {item.type === 'file' && t('common.file')}
        {item.type === 'text' && 'Текст'}
      </div>
      <div className="modal__controls-box">
        <div onClick={() => initEditableResult(item)} className="modal__controls modal__controls--edit"></div>
        <div onClick={() => deleteResult(item)} className="modal__controls modal__controls--delete"></div>
      </div>
    </li>
  );
};

export default ResultItem;

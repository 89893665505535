import { StyledIconButton } from './style';

export const IconButton = ({ size, icon, onClick, children, ...props }) => {
  const onClickHandler = (e) => {
    onClick(e);
  };

  return (
    <StyledIconButton {...props} type="button" size={size} icon={icon} onClick={onClickHandler}>
      {children}
    </StyledIconButton>
  );
};

import React, { useState } from 'react';
import { useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';

import EmployeesForm from '../../components/employees/employees-form/EmployeesForm';
import useAuth from '../../hooks/useAuth';
// import CalendarForm from "../../components/form/CalendarForm";

const TeamHeader = () => {
  const { isUserRightful, rightTypes } = useAuth();
  const location = useLocation();

  const userHasRight = useMemo(() => isUserRightful(rightTypes.team_edit), [isUserRightful, rightTypes]);
  // const { t } = useTranslation();
  const [modalAddEmployee, setModalAddEmployee] = useState(false);

  //  тут делаем запрос на получние данных для формы
  const onOpenModal = () => {
    setModalAddEmployee(true);
  };
  // закрыть окно и сбросить все состояния формы, сотрудников и тд
  const onCloseModal = () => {
    setModalAddEmployee(false);
  };

  return (
    <section className="subheader">
      <div className="subheader__container container">
        <div className="subheader__col">
          <Link
            // onClick={() => window.props.onOpen(department_window)}
            className={`subheader__link _pointer ${location.pathname.includes('users') ? '_highlighted' : ''}`}
            to="/team/users"
          >
            Команда
          </Link>
          <Link
            // onClick={() => window.props.onOpen(department_window)}
            className={`subheader__link _pointer ${location.pathname.includes('guests') ? '_highlighted' : ''}`}
            to="/team/guests"
          >
            Гостевые доступы
          </Link>
          {/*
          <Link
            className="subheader__link"
            to="departments"
          >
            {t("TeamHeader.depatrments")}
          </Link>

          <Link
            className="subheader__link"
            to="salaries"
          >
            {t("TeamHeader.salaries")}
          </Link>
          <Link
            className="subheader__link"
            to="vacations"
          >
            {t("TeamHeader.vacations")}
          </Link> */}
        </div>
        <div className="subheader__col">
          {userHasRight && location.pathname.includes('/users') && (
            <button className="subheader__button" onClick={onOpenModal}>
              Добавить сотрудника
            </button>
          )}
          {/* <button className="subheader__button">
            Добавить поиск сотрудника
          </button> */}
        </div>
      </div>
      {/* если нажата кнопка Добавить сотрудника и у юзера есть права на создание другого юзера,
          то открыть форму создания юзера */}
      {modalAddEmployee && userHasRight && <EmployeesForm onCloseModal={onCloseModal} action="add" />}
    </section>
  );
};

export default TeamHeader;

import React, { useMemo, useState } from 'react';

import { Text } from '@shared/components/Text/Text';
import { Avatar } from '@shared/components/Avatar';

import { useSelector } from 'react-redux';

import { files_url } from 'src/settings/base-url';

import useAuth from 'src/hooks/useAuth';

import { Button } from '@shared/components/Button/Button';

import moment from 'moment';

import { TicketStatus } from '../TicketStatus';

import { TicketInfoModal } from '../TicketInfoModal';

import { EditTicketModal } from '../EditTicketModal';

import { CancelTicketModal } from '../CancelTicketModal';

import { Card, Container, Title, UserCard } from './styles';

const getTicketAction = (action) => {
  switch (action) {
    case 'created':
      return 'Создано';
    case 'canceled':
      return 'Отменено';
    case 'confirmation':
      return 'Ожидает проверки';
    case 'confirmed':
      return 'Подтверждено';
    case 'returned':
      return 'Возрващено';
    default:
      break;
  }
};

const getActionColor = (action) => {
  switch (action) {
    case 'created':
      return '';
    case 'canceled':
      return 'red';
    case 'confirmation':
      return 'yellow';
    case 'confirmed':
      return 'green';
    case 'returned':
      return 'red';
    default:
      break;
  }
};

export const TicketCard = ({ ticket }) => {
  const [visible, setVisible] = useState(false);
  const [editVisible, setEditVisible] = useState(false);
  const [cancelVisible, setCancelVisible] = useState(false);

  const auth = useAuth();
  const users = useSelector((state) => state.users.employees);
  const executor = useMemo(() => users.filter((user) => user.id === ticket.executor_id)[0], [ticket]);

  const executionDate = new Date(ticket.date_finish);
  const action = ticket.ticket_actions[ticket.ticket_actions.length - 1].action;
  const actionDate = new Date(ticket.ticket_actions[ticket.ticket_actions.length - 1].date_action);

  return (
    <>
      <Card>
        <Container>
          <TicketStatus color={getActionColor(action)}>
            {getTicketAction(action)} {moment.utc(actionDate).format('L')} {moment.utc(actionDate).format('LT')}
          </TicketStatus>

          <Text as="span" size={2}>
            Исполнить до {moment.utc(executionDate).format('L')} {moment.utc(executionDate).format('LT')}
          </Text>

          <Title>{ticket.title}</Title>

          <UserCard>
            <Avatar
              size={32}
              src={`${files_url}/employees/avatar/${executor.id}/small_${executor.avatar}?token=${auth.token}`}
            />
            <Text size="2">{executor.first_name}</Text>
          </UserCard>
        </Container>
        <Button onClick={() => setVisible(true)}>Подробнее</Button>
      </Card>
      {visible && (
        <TicketInfoModal
          ticket={ticket}
          executor={executor}
          executionDate={executionDate}
          onClose={() => setVisible(false)}
          onEditTicket={() => {
            setVisible(false);
            setEditVisible(true);
          }}
          onCancelTicket={() => {
            setVisible(false);
            setCancelVisible(true);
          }}
        />
      )}
      {editVisible && (
        <EditTicketModal
          ticket={ticket}
          executor={executor}
          executionDate={executionDate}
          onClose={() => setEditVisible(false)}
        />
      )}
      {cancelVisible && <CancelTicketModal id={ticket.id} onClose={() => setCancelVisible(false)} />}
    </>
  );
};

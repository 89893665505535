import { useState, useRef, useContext, useEffect, useMemo, useCallback } from 'react';
import axios from 'axios';
import { useDispatch } from 'react-redux';

import { useForm, Controller } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';

import { object, string, array } from 'yup';

import { Modal } from '@shared/components/Modal/Modal';
import { Label } from '@shared/components/Label/Label';

import { StyledModalRowCenter } from '@shared/components/Modal/style';

import Preloader from '../../components/preloaders/Preloader';
import useAuth from '../../hooks/useAuth';
import DefaultAvatar from '../../assets/images/userpic.png';

import SnackbarContext from '../../contexts/SnackbarContext';
import { useEventTriggerOnEscPress, assignIconLink } from '../../utilize/helper-functions';
import ConfirmAction from '../../components/warnings/ConfirmAction';

import { updateUser } from '../../redux/features/usersSlice';
import { files_url } from '../../settings/base-url';

import { StyledProfileChangeButton, StyledProfileStatus, StyledProfileUserpic } from './style';

const imageReg = /[/.](jpg|jpeg|tiff|png)$/i;

const defaultSocials = [
  {
    type: 'instagram',
    value: '',
  },
  { type: 'Facebook', value: '' },
];

const formSchema = object({
  status: string(),
  social: array(),
});

const MyProfileModal = ({ close, userData, getUserData }) => {
  const auth = useAuth();
  const dispatch = useDispatch();
  const { showSnackbar } = useContext(SnackbarContext);

  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { isDirty },
  } = useForm({
    resolver: yupResolver(formSchema),
    mode: 'onChange',
    defaultValues: {
      social: [
        { type: 'Twitter', value: '' },
        { type: 'Facebook', value: '' },
        { type: 'instagram', value: '' },
      ],
    },
  });

  useEffect(() => {
    if (userData) {
      const { employee, employee_social } = userData;

      let isEmpty =
        !employee_social ||
        employee_social.length === 0 ||
        employee_social.every((socialItem) => socialItem.value === '');
      if (employee) {
        reset({
          status: employee.status,
          social: isEmpty ? defaultSocials : employee_social,
        });
      }
      setAvatarToUpload(null);
    }
  }, [reset, userData]);

  useEffect(() => {
    getUserData();
  }, [getUserData]);

  const uploadImageRef = useRef();

  const [avatarToUpload, setAvatarToUpload] = useState();

  const inputFileHandler = (e) => {
    if (imageReg.test(e.target.files[0].name)) {
      setAvatarToUpload({
        file: e.target.files[0],
        url: URL.createObjectURL(e.target.files[0]),
      });
    }
  };

  const [isUploading, setIsUploading] = useState(false);

  const updateProfileRequest = async (data) => {
    setIsUploading(true);

    try {
      if (avatarToUpload) {
        const formData = new FormData();
        formData.append('avatar', avatarToUpload.file, avatarToUpload.file.name);
        formData.append('employee_id', auth.user.id);
        await axios.post('/api/employees/personal_data_avatar_load', formData);
      }
      await axios.put('api/employees/personal_data_edit', {
        employee_id: auth.user.id,
        status: data.status,
        social: data.social,
      });
      await getUserData();
      dispatch(updateUser(userData.employee.id));
      setIsUploading(false);

      showSnackbar('Профиль обновлен', 'success');
    } catch (e) {
      setIsUploading(false);
      showSnackbar('Возникла ошибка');
    }
  };

  const avatarUrl = useMemo(() => {
    if (avatarToUpload) {
      return avatarToUpload.url;
    } else if (userData?.employee?.avatar && auth?.token) {
      return `${files_url}/employees/avatar/${auth.user.id}/${userData.employee.avatar}?token=${auth.token}`;
    } else return DefaultAvatar;
  }, [avatarToUpload, userData, auth]);

  const [modalClosePrompt, setModalClosePrompt] = useState(false);

  const handleModalClose = useCallback(() => {
    if (isDirty || avatarToUpload) {
      setModalClosePrompt(true);
    } else close();
  }, [isDirty, avatarToUpload, close]);

  //

  // регистрация eventa keypress для выхода из модального окна в случае нажатии на кнопку Esc
  useEventTriggerOnEscPress(handleModalClose);

  return (
    <Modal
      title="Обо мне"
      onClose={handleModalClose}
      confirmButtonText={isUploading ? 'Сохраняется...' : 'Сохранить'}
      disabledSaveButton={isUploading || (!avatarToUpload && !isDirty)}
      onSave={handleSubmit(updateProfileRequest)}
    >
      {userData ? (
        <>
          <StyledModalRowCenter>
            <StyledProfileUserpic>
              <img src={avatarUrl} alt="аватарка" />
              <input type="file" ref={uploadImageRef} accept=".jpg,.jpeg,.tiff,.png" onChange={inputFileHandler} />
              <StyledProfileChangeButton
                onClick={() => {
                  uploadImageRef.current.click();
                }}
              >
                Заменить
              </StyledProfileChangeButton>
            </StyledProfileUserpic>
            <div>
              <Label htmlFor="description">Статус</Label>
              <StyledProfileStatus id="description" rows="10" {...register('status')}></StyledProfileStatus>
            </div>
          </StyledModalRowCenter>

          <Controller
            control={control}
            name="social"
            render={({ field: { onChange, value } }) => {
              const fieldValues = value;

              const handleChange = (e, index) => {
                fieldValues[index] = {
                  ...fieldValues[index],
                  value: e.target.value,
                };
                onChange(fieldValues);
              };

              const removeInput = (index) => {
                onChange(fieldValues.filter((item, i) => i !== index));
              };

              const addInput = () => {
                onChange([...fieldValues, { type: '', value: '' }]);
              };

              return (
                <>
                  <div className="modal__subtitle-wrapper">
                    <h3 className="modal__subtitle">Соцсети</h3>
                    <button className="modal__button-add" onClick={() => addInput()}></button>
                  </div>
                  <div className="modal__row" id="social_inputs">
                    {fieldValues.map((socialItem, index) => {
                      return (
                        <div className="modal__social" key={index}>
                          {assignIconLink(socialItem.type, socialItem.value)}
                          <input
                            className="modal__input"
                            // placeholder="facebook.com"
                            name={socialItem.type}
                            onChange={(e) => handleChange(e, index)}
                            value={socialItem.value}
                          />
                          <button
                            className="modal__controls modal__controls--delete modal__controls--xl"
                            onClick={() => removeInput(index)}
                          ></button>
                        </div>
                      );
                    })}
                  </div>
                </>
              );
            }}
          ></Controller>
        </>
      ) : (
        <Preloader />
      )}

      {modalClosePrompt && (
        <ConfirmAction
          cancel={() => setModalClosePrompt(false)}
          confirm={close}
          actionText="Уверены, что хотите закрыть окно без сохранения?"
        />
      )}
    </Modal>
  );
};

export default MyProfileModal;

import { useState, useContext, useEffect, useMemo, useRef } from 'react';
import JSZip from 'jszip';
import axios from 'axios';
import { useDispatch } from 'react-redux';

import ConfirmAction from '../warnings/ConfirmAction';
import SnackbarContext from '../../contexts/SnackbarContext';
import { incrementLastNumberInParentheses, useEventTriggerOnEscPress } from '../../utilize/helper-functions';
import { getStorageTree } from '../../redux/features/storageSlice';

// dataId - id родителя (хранилище или папка)
const DropFoldersAndFilesModal = ({ storageId, folderId, close, droppedItems }) => {
  const [confirmClose, setConfirmClose] = useState();

  const { showSnackbar } = useContext(SnackbarContext);

  const [isSubmitting, setIsSubmitting] = useState();

  const dispatch = useDispatch();

  const { dataType, dataId } = useMemo(() => {
    const dataType = folderId ? 'folder' : 'storage';
    const dataId = folderId || storageId;
    return { dataType, dataId };
  }, [folderId, storageId]);

  const memoizedDroppedItems = useRef();

  const [folderEntriesToUpload, setFolderEntriesToUpload] = useState([]);
  const [filesToUpload, setFilesToUpload] = useState([]);

  // отсортировать подброшенные папки и файлы в соответствующие массивы, для их дальнейшей отправки на сервер
  useEffect(() => {
    const handleDrop = async () => {
      if (droppedItems === memoizedDroppedItems.current) return;
      if (!droppedItems.length) return;
      memoizedDroppedItems.current = droppedItems;

      const folderEntries = [];
      const filesArr = [];
      const filePromises = [];
      for (let i = 0; i < droppedItems.length; i++) {
        if (!droppedItems[i].webkitGetAsEntry) continue;
        const entry = droppedItems[i].webkitGetAsEntry();
        if (entry.isDirectory) {
          entry.uploadStatus = 'wait';
          folderEntries.push(entry);
        } else if (entry.isFile) {
          const filePromise = new Promise((resolve, reject) => {
            entry.file(
              (file) => {
                file.uploadStatus = 'wait';
                filesArr.push(file);
                resolve();
              },
              (e) => reject(e),
            );
          });
          filePromise.catch(() => {
            showSnackbar('Возникла ошибка при чтении файла');
          });
          filePromises.push(filePromise);
        }
      }
      await Promise.all(filePromises);

      if (folderEntries.length) setFolderEntriesToUpload(folderEntries);
      if (filesArr.length) setFilesToUpload(filesArr);
    };
    handleDrop();
  }, [droppedItems]);

  const renderTitle = () => {
    if (folderEntriesToUpload.length && !filesToUpload.length) return ' папки';
    else if (!folderEntriesToUpload.length && filesToUpload.length) return ' файлы';
    else return ' папки и файлы';
  };

  const folderNameDuplicateFlag = useRef();
  const fileNameDuplicateFlag = useRef();
  const [nameDuplicatesWarning, setNameDuplicatesWarning] = useState();

  // после проверки дубликатов на сервере, отслеживать убрали ли из списка дубликаты
  // если убрали дубликаты, то предупреждение скрыть
  useEffect(() => {
    if (!folderNameDuplicateFlag.current) return;
    if (
      nameDuplicatesWarning &&
      !folderEntriesToUpload.some((folder) => folder.nameExists) &&
      !filesToUpload.some((file) => file.nameExists)
    ) {
      setNameDuplicatesWarning(false);
    }
  }, [folderEntriesToUpload, filesToUpload]);

  const checkFolderExistence = (title, index) => {
    setIsSubmitting(true);
    return axios
      .get(`/api/storage_folder_exist/${dataType}/${dataId}/${title}`)
      .then((r) => {
        const { storage_folder_exist } = r.data;
        if (storage_folder_exist) {
          folderNameDuplicateFlag.current = true;
          setFolderEntriesToUpload((f) => {
            const updFolderEntriesToUpload = [...f];
            updFolderEntriesToUpload[index].nameExists = true;
            return updFolderEntriesToUpload;
          });
        }
        // createFolder(data);
      })
      .catch(() => {
        showSnackbar(`Возникла ошибка при попытке проверки наличия папки с названием "${title}"`);
        setIsSubmitting(false);
      });
  };

  const checkFilesExistence = () => {
    const reqBody = {
      [`${dataType}_id`]: dataId,
      // folder_id: folderId ? folderId : null,
      // storage_id: folderId ? null : storageIdToReq,
      files: filesToUpload.map((file) => file.name),
    };

    return axios
      .post('/api/storage_file_exist', reqBody)
      .then((r) => {
        const { storage_files_exist } = r.data;
        if (storage_files_exist.length) {
          fileNameDuplicateFlag.current = true;
          setFilesToUpload((f) =>
            f.map((file) => {
              if (!storage_files_exist.includes(file.name)) return file;
              file.nameExists = true;
              return file;
            }),
          );
        }
      })
      .catch(() => {
        showSnackbar('Ошибка при проверке наличия таких файлов на сервере');
        setIsSubmitting(false);
      });
  };

  const abortRequest = useRef();

  const uploadFoldersAndFiles = async () => {
    let isCanceled = false;
    for (let ind = 0; ind < folderEntriesToUpload.length; ind++) {
      const subfoldersArr = [folderEntriesToUpload[ind]];
      const filesToZipArr = [];
      while (subfoldersArr.length) {
        const folder = subfoldersArr.shift();
        const entryPromise = new Promise((resolve, reject) => {
          folder.createReader().readEntries(
            async (entries) => {
              if (!entries?.length) resolve();
              for (const entry of entries) {
                if (entry.isDirectory) {
                  subfoldersArr.push(entry);
                } else if (entry.isFile) {
                  // filePath нужен для сохранения структуры папки (пути файлов внутри папки)
                  let filePath = '';
                  if (typeof entry.fullPath === 'string') {
                    filePath = entry.fullPath.split('/');
                    filePath.splice(0, 2);
                    filePath = filePath.join('/');
                  }
                  const fileExtractPromise = new Promise((resolve, reject) => {
                    entry.file(
                      (file) => {
                        file.filePath = filePath;
                        filesToZipArr.push(file);
                        resolve();
                      },
                      (error) => reject(error),
                    );
                  });
                  await fileExtractPromise.catch(() => reject());
                }
              }
              resolve();
            },
            (error) => {
              reject(error);
            },
          );
        });
        await entryPromise.catch(() => {
          showSnackbar('Возникла ошибка при чтении файлов в папке');
        });
      }
      // console.log
      const zip = new JSZip();
      for (const fileToZip of filesToZipArr) {
        let filename = fileToZip.name;
        const filePath = fileToZip.filePath;
        // если файл с таким названием уже внесен в список для архивации, то добавить номер в названии файла
        while (zip.files[filename]) {
          filename = incrementLastNumberInParentheses(filename);
        }
        zip.file(filePath || filename, fileToZip, {
          createFolders: true,
        });
      }
      const blob = await zip.generateAsync({ type: 'blob' }).catch(() => {
        showSnackbar('Возникла ошибка при архивации файлов в папке');
      });
      // blob.title = entry.name;

      const formData = new FormData();
      formData.append('archive', blob, 'archive.zip');
      formData.append('title', folderEntriesToUpload[ind].name);
      folderId ? formData.append('folder_id', folderId) : formData.append('storage_id', storageId);

      let progressPercentage = 0;
      abortRequest.current = new AbortController();

      await axios
        .post('/api/storage_folders/add', formData, {
          onUploadProgress: (progressEvent) => {
            const currProgress = parseInt(Math.round(progressEvent.loaded * 100) / progressEvent.total);
            if (currProgress !== progressPercentage && currProgress % 2 === 0) {
              progressPercentage = currProgress;
              setFolderEntriesToUpload((f) => {
                const updState = [...f];
                updState[ind].progress = currProgress;
                return updState;
              });
            }
          },
          signal: abortRequest.current.signal,
        })
        .then(() => {
          setFolderEntriesToUpload((f) => {
            const updState = [...f];
            updState[ind].uploadStatus = 'done';
            return updState;
          });
          abortRequest.current = null;
        })
        // eslint-disable-next-line
        .catch((e) => {
          setIsSubmitting(false);
          if (e.message === 'canceled') {
            isCanceled = true;
            return;
          }
          showSnackbar(`Возникла ошибка при отправке папки "${folderEntriesToUpload[ind].name}"`);
          return;
        });
      if (isCanceled) return;
    }

    for (let ind = 0; ind < filesToUpload.length; ind++) {
      const formData = new FormData();
      formData.append('files', filesToUpload[ind], filesToUpload[ind].name);
      // formData.append('form_id', formId.current);
      formData.append(`${dataType}_id`, dataId);

      let progressPercentage = 0;
      abortRequest.current = new AbortController();
      await axios
        .post('/api/storage_files/add', formData, {
          onUploadProgress: (progressEvent) => {
            const currProgress = parseInt(Math.round(progressEvent.loaded * 100) / progressEvent.total);
            if (currProgress !== progressPercentage) {
              progressPercentage = currProgress;

              setFilesToUpload((f) => {
                const updState = [...f];
                updState[ind].progress = currProgress;
                return updState;
              });
              // uploadProgress(fileWithId.localId, currProgress);
            }
          },
          signal: abortRequest.current.signal,
        })
        .then(() => {
          setFilesToUpload((f) => {
            const updState = [...f];
            updState[ind].uploadStatus = 'done';
            return updState;
          });
          abortRequest.current = null;
        })
        // eslint-disable-next-line
        .catch((e) => {
          setIsSubmitting(false);
          abortRequest.current = null;
          if (e.message === 'canceled') {
            isCanceled = true;
            return;
          }
          showSnackbar(`Возникла ошибка при добавлении файла ${filesToUpload[ind].name}`);
        });
      if (isCanceled) return;
    }
    dispatch(getStorageTree({ storageId, showSnackbar }));
    close();
  };

  const handleSubmit = async () => {
    setIsSubmitting(true);
    if (nameDuplicatesWarning) {
      folderNameDuplicateFlag.current = false;
      setNameDuplicatesWarning(false);
      uploadFoldersAndFiles();
      return;
    }
    folderNameDuplicateFlag.current = false;
    fileNameDuplicateFlag.current = false;
    const nameDuplicateRequests = [];
    for (let i = 0; i < folderEntriesToUpload.length; i++) {
      nameDuplicateRequests.push(checkFolderExistence(folderEntriesToUpload[i].name, i));
    }
    nameDuplicateRequests.push(checkFilesExistence());
    // for (let i = 0; i< filesToUpload.length; i++){
    //   checkFilesExistence()
    // }

    await Promise.all(nameDuplicateRequests);
    if (folderNameDuplicateFlag.current || fileNameDuplicateFlag.current) {
      setIsSubmitting(false);
      return setNameDuplicatesWarning(true);
    } else return uploadFoldersAndFiles();
  };

  const handleClose = () => {
    if (folderEntriesToUpload.length || filesToUpload.length) {
      return setConfirmClose(true);
    }
    close();
  };

  useEventTriggerOnEscPress(handleClose);

  return (
    <>
      <section className="modal">
        <div className="modal__wrapper">
          <div className="modal__inner">
            <section className="modal__body">
              <div className="modal__header">
                <h2 className="modal__title">Добавить {renderTitle()}</h2>
                <button className="modal__close-modal" onClick={handleClose}></button>
              </div>

              <div className="modal__row">
                <ul className="storage-list">
                  {folderEntriesToUpload.map((folder, i) => {
                    return (
                      <li
                        key={i}
                        className={`catalog-folder catalog-folder--upload catalog-file--upload-${folder.uploadStatus} ${
                          folder.nameExists ? 'input-error' : ''
                        }`}
                      >
                        <p className="catalog-file__name">{folder.name}</p>
                        <button
                          onClick={() => {
                            const updFoldersToUpload = folderEntriesToUpload.filter((file, ind) => ind !== i);
                            if (!updFoldersToUpload.length && !filesToUpload.length) close();
                            else setFolderEntriesToUpload(updFoldersToUpload);
                          }}
                          disabled={isSubmitting}
                          className="catalog-file__button-delete"
                        ></button>
                        <div className="progress-bar">
                          <div className="progress-bar__fill" style={{ width: `${folder.progress || 0}%` }}></div>
                        </div>
                      </li>
                    );
                  })}

                  {filesToUpload.map((file, i) => (
                    <li
                      key={i}
                      className={`catalog-file catalog-file--upload catalog-file--upload-${file.uploadStatus} ${
                        file.nameExists ? 'input-error' : ''
                      }`}
                    >
                      <p className="catalog-file__name ">{file.name}</p>
                      <button
                        onClick={() => {
                          const updFilesToUpload = filesToUpload.filter((file, ind) => ind !== i);
                          if (!updFilesToUpload.length && !folderEntriesToUpload.length) close();
                          else setFilesToUpload(updFilesToUpload);
                        }}
                        disabled={isSubmitting}
                        className="catalog-file__button-delete"
                      ></button>
                      <div className="progress-bar">
                        <div className="progress-bar__fill" style={{ width: `${file.progress || 0}%` }}></div>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>

              {nameDuplicatesWarning && (
                <div className="modal__row modal__error-text">
                  Названия выделенных папок или файлов существуют по этому пути. Подтвердите, что хотите загрузить эти
                  папки и файлы как новые версии или удалите из списка.
                </div>
              )}

              <div className="modal__button-box">
                <button
                  className="modal__button modal__button--create"
                  onClick={handleSubmit}
                  disabled={(!filesToUpload.length && !folderEntriesToUpload.length) || isSubmitting}
                >
                  {`${nameDuplicatesWarning ? 'Подтвердить' : 'Добавить'}`}
                </button>
                <button className="modal__button modal__button--cancel" onClick={handleClose}>
                  Отмена
                </button>
              </div>
            </section>
          </div>
        </div>
      </section>
      {confirmClose && (
        <ConfirmAction
          confirm={() => {
            if (abortRequest.current) abortRequest.current.abort();
            const checkStatus = (f) => f.uploadStatus === 'done';
            if (folderEntriesToUpload.some(checkStatus) || filesToUpload.some(checkStatus)) {
              dispatch(getStorageTree({ storageId, showSnackbar }));
            }
            close();
          }}
          cancel={() => setConfirmClose(false)}
          actionText="Вы уверены что хотите закрыть форму, не сохранив изменения?"
        />
      )}
    </>
  );
};

export default DropFoldersAndFilesModal;

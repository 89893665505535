import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import DatePicker from 'react-datepicker';

import ProjectFilterSelect from '../../../../components/form/select/ProjectFilterSelect';
import { isEmpty } from '../../../../utilize/helper-functions';

const ProjectListFilter = () => {
  const users = useSelector((state) => state.users.employees);
  const tags = useSelector((state) => state.tags.tagsList);

  const [filters, setFilters] = useState({
    responsible: {
      label: 'Ответственный',
      value: '',
    },
    executor: {
      label: 'Исполнитель',
      value: '',
    },
    creator: {
      label: 'Кто создал',
      value: '',
    },
    tags: [],
  });
  const [searchParams, setSearchParams] = useSearchParams();

  // поставить фильтры в селектах, если в адресной строке указаны параметры фильтрации
  useEffect(() => {
    // const newFiltersState = { ...filters };
    const newFiltersState = {};
    for (const [key, value] of searchParams) {
      if (key === 'responsible_id' && value && !filters.responsible.value && users?.length) {
        const userResponsible = users.find((user) => user.id === +value);
        if (userResponsible) {
          newFiltersState.responsible = {
            label: `${userResponsible.last_name || ''} ${userResponsible.first_name}`,
            value: userResponsible.id,
          };
        }
      }

      if (key === 'executor_id' && value && !filters.executor.value && users?.length) {
        const userExecutor = users.find((user) => user.id === +value);
        if (userExecutor) {
          newFiltersState.executor = {
            label: `${userExecutor.last_name || ''} ${userExecutor.first_name}`,
            value: userExecutor.id,
          };
        }
      }

      if (key === 'creator_id' && value && !filters.creator.value && users?.length) {
        const userCreator = users.find((user) => user.id === +value);
        if (userCreator) {
          newFiltersState.creator = {
            label: `${userCreator.last_name || ''} ${userCreator.first_name}`,
            value: userCreator.id,
          };
        }
      }

      if (key === 'date_start_from' && value && !filters.date_start_from) {
        newFiltersState.date_start_from = new Date(+value);
      }

      if (key === 'tags' && value && !filters.tags.length) {
        newFiltersState.tags = value.split(',').map((id) => +id);
      }

      if (!isEmpty(newFiltersState)) setFilters((f) => ({ ...f, ...newFiltersState }));
    }
  }, [searchParams, filters, users]);

  // обновить state селекта, изменить параметры в адресной строке,
  const handleResponsibleChange = (optionResponsible) => {
    if (optionResponsible.value !== filters.responsible?.value) {
      const newSearchParams = {};
      for (const [key, value] of searchParams) newSearchParams[key] = value;

      optionResponsible.value
        ? (newSearchParams.responsible_id = optionResponsible.value)
        : delete newSearchParams.responsible_id;

      setFilters((f) => ({ ...f, responsible: optionResponsible }));
      setSearchParams(newSearchParams);
    }
  };

  const handleExecutorChange = (optionExecutor) => {
    if (optionExecutor.value !== filters.creator?.value) {
      const newSearchParams = {};
      for (const [key, value] of searchParams) newSearchParams[key] = value;

      optionExecutor.value ? (newSearchParams.executor_id = optionExecutor.value) : delete newSearchParams.executor_id;

      setFilters((f) => ({ ...f, executor: optionExecutor }));
      setSearchParams(newSearchParams);
    }
  };

  const handleCreatorChange = (optionCreator) => {
    if (optionCreator.value !== filters.creator?.value) {
      const newSearchParams = {};
      for (const [key, value] of searchParams) newSearchParams[key] = value;

      optionCreator.value ? (newSearchParams.creator_id = optionCreator.value) : delete newSearchParams.creator_id;

      setFilters((f) => ({ ...f, creator: optionCreator }));
      setSearchParams(newSearchParams);
    }
  };

  const handleDateChange = (date, type) => {
    if (date instanceof Date) {
      const dateNum = date.getTime();

      const newSearchParams = {};
      for (const [key, value] of searchParams) newSearchParams[key] = value;

      setFilters((f) => ({ ...f, [type]: date }));
      setSearchParams({ ...newSearchParams, [type]: dateNum });
    }
  };

  const handleTagSelect = (tagId) => {
    const newFilters = { ...filters };
    const newSearchParams = {};
    for (const [key, value] of searchParams) newSearchParams[key] = value;

    if (newFilters.tags.includes(tagId)) {
      newFilters.tags = newFilters.tags.filter((id) => id !== tagId);
      setFilters(newFilters);
    } else {
      newFilters.tags.push(tagId);
      setFilters(newFilters);
    }

    let { tags } = newSearchParams;
    if (!tags) {
      newSearchParams.tags = `${tagId}`;
      return setSearchParams(newSearchParams);
    }
    tags = tags.split(',');

    if (tags.find((id) => +id === tagId)) {
      tags = tags.filter((id) => +id !== tagId);
      tags.length > 0 ? (newSearchParams.tags = tags.join()) : delete newSearchParams.tags;
      return setSearchParams(newSearchParams);
    }
    tags.push(tagId);
    newSearchParams.tags = tags.join();
    setSearchParams(newSearchParams);
  };

  return (
    <form className="project-list__filter project-filter">
      <div className="project-filter__row">
        <div className="project-filter__col project-filter__tags">
          {tags?.map((tag, i) => (
            <span
              key={i}
              onClick={() => handleTagSelect(tag.id)}
              className={`project-filter__tag tag tag--icon-${tag.color} 
             ${filters.tags.includes(tag.id) ? 'tag--active' : ''}`}
            >
              {tag.name}
            </span>
          ))}
        </div>
        <div className="project-filter__col project-filter__col--dropdown">
          <ProjectFilterSelect
            valuesArr={users}
            value="last_name"
            value2="first_name"
            handleChange={handleResponsibleChange}
            selectedValue={filters.responsible}
            placeholder="Ответственный"
          />
          <ProjectFilterSelect
            valuesArr={users}
            value="last_name"
            value2="first_name"
            handleChange={handleExecutorChange}
            placeholder="Исполнитель"
            selectedValue={filters.executor}
          />
          <ProjectFilterSelect
            valuesArr={users}
            value="last_name"
            value2="first_name"
            handleChange={handleCreatorChange}
            placeholder="Кто создал"
            selectedValue={filters.creator}
          />
        </div>
      </div>
      <div className="project-filter__row">
        <div className="project-filter__col">
          <div className="project-filter__date-range date-range">
            <p className="date-range__title">Период начала:</p>
            <div className="date-range__input-wrapper">
              <DatePicker
                placeholderText="от:"
                dateFormat="dd.MM.yyyy"
                className="date-range__input date-range__input--start date-range__input--small"
                onChange={(date) => handleDateChange(date, 'date_start_from')}
                selected={filters.date_start_from}
              />
              <DatePicker
                placeholderText="до:"
                dateFormat="dd.MM.yyyy"
                className="date-range__input date-range__input--end date-range__input--small"
                onChange={(date) => handleDateChange(date, 'date_start_to')}
                selected={filters.date_start_to}
              />
            </div>
            <span className="date-range__icon-calendar"></span>
            <span className="date-range__icon-arrow"></span>
          </div>
          <div className="project-filter__date-range date-range">
            <p className="date-range__title">Период окончания:</p>
            <div className="date-range__input-wrapper">
              <DatePicker
                placeholderText="от:"
                dateFormat="dd.MM.yyyy"
                className="date-range__input date-range__input--start date-range__input--small"
                onChange={(date) => handleDateChange(date, 'date_finish_from')}
                selected={filters.date_finish_from}
              />
              <DatePicker
                placeholderText="до:"
                dateFormat="dd.MM.yyyy"
                className="date-range__input date-range__input--end date-range__input--small"
                onChange={(date) => handleDateChange(date, 'date_finish_to')}
                selected={filters.date_finish_to}
              />
            </div>
            <span className="date-range__icon-calendar"></span>
            <span className="date-range__icon-arrow"></span>
          </div>
          <div className="project-filter__date-range date-range">
            <p className="date-range__title">Дата создания:</p>
            <div className="date-range__input-wrapper">
              <DatePicker
                placeholderText="от:"
                dateFormat="dd.MM.yyyy"
                className="date-range__input date-range__input--start date-range__input--small"
                onChange={(date) => handleDateChange(date, 'date_created_from')}
                selected={filters.date_created_from}
              />
              <DatePicker
                placeholderText="до:"
                dateFormat="dd.MM.yyyy"
                className="date-range__input date-range__input--end date-range__input--small"
                onChange={(date) => handleDateChange(date, 'date_created_to')}
                selected={filters.date_created_to}
              />
            </div>
            <span className="date-range__icon-calendar"></span>
            <span className="date-range__icon-arrow"></span>
          </div>
        </div>
        {/* <div className="project-filter__col">
          <div className="project-filter__wrapper">
            <p className="project-filter__title">Сортировать:</p>
            <div className="project-filter__dropdown dropdown dropdown--narrow">
              <div className="dropdown__input" data-selected="1">
                по дате
              </div>
              <ul className="dropdown__select select">
                <li className="select__item" data-option="1">
                  по дате
                </li>
                <li className="select__item" data-option="2">
                  по варианту 2
                </li>
                <li className="select__item" data-option="3">
                  по варианту 3
                </li>
              </ul>
            </div>
          </div>
        </div> */}
      </div>
    </form>
  );
};

export default ProjectListFilter;

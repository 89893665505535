import React from 'react';

import { StyledContainer } from './style';

export const Container = ({ direction, align, justify, children, gap, ...props }) => {
  return (
    <StyledContainer direction={direction} align={align} justify={justify} gap={gap} {...props}>
      {children}
    </StyledContainer>
  );
};

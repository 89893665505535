import { useState, useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { useSocketContext } from '../../contexts/SocketContext';
import useAuth from '../../hooks/useAuth';
import { onUserWrites, onUserStoppedWriting, offUserWrites } from '../../settings/sockets';

let writingUserTimeout;

const UserTextingListener = ({ chatId }) => {
  const auth = useAuth();
  const { socket } = useSocketContext();
  const { employees } = useSelector((state) => state.users);
  const [writingUser, setWritingUser] = useState({ id: null, name: null });

  // функция-коллбэк когда слушают socket как другие юзеры печатают
  const userWritesListener = useCallback(
    (userId, serverChatId) => {
      if (auth?.user?.id) {
        if (serverChatId === chatId && userId !== auth.user.id) {
          if (writingUser.id !== userId && Array.isArray(employees)) {
            const writingEmployee = employees.find((employee) => employee.id === userId);
            if (writingEmployee) {
              setWritingUser({
                id: userId,
                name: `${writingEmployee.first_name || ''} ${writingEmployee.last_name || ''}`,
              });

              clearTimeout(writingUserTimeout);

              writingUserTimeout = setTimeout(() => {
                setWritingUser({ id: null, name: null });
              }, 2000);
            }
          }
        }
      }
    },
    [auth, employees, writingUser, chatId],
  );

  // функция-коллбэк когда слушают socket как другой юзер перестает печатать
  const userStoppedWritingListener = useCallback(
    (employee_id, chat_id) => {
      if (writingUser.id === employee_id) {
        setWritingUser({ id: null, name: null });
      }
    },
    [writingUser],
  );

  // запустить функции для отслеживания socket событий из сервера
  useEffect(() => {
    if (socket && socket.connected) {
      onUserWrites(socket, userWritesListener);
      onUserStoppedWriting(socket, userStoppedWritingListener);

      return () => offUserWrites(socket);
    }
  }, [socket, userWritesListener, userStoppedWritingListener]);

  return (
    <p className="chat__texting" style={{ height: '12px', marginBottom: '15px' }}>
      {writingUser?.name ? `${writingUser.name} печатает...` : ''}
    </p>
  );
};

export default UserTextingListener;

import { useState, useCallback, useEffect, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { registerLocale, setDefaultLocale } from 'react-datepicker';
import ru from 'date-fns/locale/ru';

import SnackbarContext from 'src/contexts/SnackbarContext';

// import ProjectFormCreateEdit from 'src/windows/projects/project-form-create-edit/ProjectFormCreateEdit';

import useAuth from '../../hooks/useAuth';

import MyProfileModal from '../../windows/profile/MyProfileModal';

import QuickAddNav from './QuickAddNav/QuickAddNav';
import UserNavListItem from './user-nav-list-item';
import Starred from './Starred';

// регистрация русской локализации для календарей
registerLocale('ru', ru);
setDefaultLocale('ru');

const UserNavList = ({ handler, isOpen, handlerBlur }) => {
  const { t /*, i18n*/ } = useTranslation();
  const auth = useAuth();

  const onLogOut = useCallback(() => {
    auth.logOut();
  }, [auth]);

  // const changeLanguage = (language) => {
  //   i18n.changeLanguage(language);
  //   // установка локализации для всех дат
  //   moment.locale(language);
  //   // установка локализации для всех календерей
  //   setDefaultLocale(language);
  // };

  const [myProfileModal, toggleMyProfileModal] = useState(false);
  const [userData, setUserData] = useState(null);

  const editProfile = () => toggleMyProfileModal(true);

  const { showSnackbar } = useContext(SnackbarContext);

  const getUserData = useCallback(async () => {
    if (auth?.user?.id) {
      await axios
        .get(`/api/personal_data/${auth.user.id}`)
        .then((res) => {
          if (res.data.personalData) {
            setUserData(res.data.personalData);
          }
        })
        .catch(() => {
          showSnackbar('Возникла ошибка при получении данных о профиле');
        });
    }
  }, [auth]);

  useEffect(() => {
    getUserData();
  }, [getUserData]);

  const userNavLinkList = useMemo(() => {
    const linkList = {
      [t('UserNavList.edit')]: {
        handler: editProfile,
        separator: true,
      },

      // [t("UserNavList.request_for_vacation")]: {
      //   handler: getVacation,
      //   to: "./",
      // },
      [t('UserNavList.log_out')]: {
        handler: onLogOut,
      },
    };
    if (auth?.user?.is_guest === 1) {
      delete linkList[t('UserNavList.edit')];
    }

    return linkList;
  }, [auth?.user?.is_guest, onLogOut, t]);

  // const [quickAddModal, setQuickAddModal] = useState();

  return (
    <>
      <div
        className="header__user-nav user-nav"
        tabIndex={0}
        onBlur={() => {
          handlerBlur(false);
        }}
      >
        <div className="user-nav__controls nav-controls">
          {auth?.user?.is_guest === 0 && <QuickAddNav />}
          {/* <div className="nav-controls__item nav-controls__item--search">
          <Link
            to="#"
            className="nav-controls__link nav-controls__link--search"
          ></Link>
        </div> */}
        </div>

        <div className="user-nav__list">
          {/* <UserNavListItem
          id="user-nav__link--recent"
          handler={handler}
          isOpen={isOpen}
          classIcon="user-nav__link--recent"
          linkList={{
            Недавние: { to: "/" },
          }}
        /> */}
          <Starred />
          {/* <UserNavListItem
            id="user-nav__link--star"
            handler={handler}
            isOpen={isOpen}
            classIcon="user-nav__link--star"
            linkList={{
              Избранные: { to: '/starred' },
            }}
          /> */}

          {/* <UserNavListItem
          id="user-nav__link--notify"
          handler={handler}
          isOpen={isOpen}
          classIcon="user-nav__link--notify"
          linkList={{
            Уведомления: { to: "./forgot", handler: getVacation },
          }}
        /> */}

          <UserNavListItem
            handler={handler}
            isOpen={isOpen}
            userId={auth?.user?.id}
            firstName={auth?.user?.name}
            lastName={auth?.user?.surname}
            profile
            id="user-nav__link--profile"
            classIcon="user-nav__link--profile"
            linkList={userNavLinkList}
          />
          {/* кнопки для переключения языков */}
          {/* <button onClick={() => changeLanguage("en")}>EN</button>
        <button onClick={() => changeLanguage("ru")}>RU</button> */}
        </div>
      </div>

      {myProfileModal && (
        // <InlineModal close={() => toggleMyProfileModal(false)}>
        //   <MyProfileModalBody userData={userData} getUserData={getUserData} />
        // </InlineModal>

        <MyProfileModal close={() => toggleMyProfileModal(false)} userData={userData} getUserData={getUserData} />
      )}

      {/* {quickAddModal === 'project' && <ProjectFormCreateEdit data={{}} onClose={() => setQuickAddModal(false)} />} */}
    </>
  );
};

export default UserNavList;

import React, { useState } from 'react';

import { Link } from 'react-router-dom';

import Logo from '../../assets/images/logo.svg';

import UserNavLists from './user-nav-list';
import HeaderSiteNavList from './header-site-nav-list';

const Nav = ({ popupWindow }) => {
  const [navListOpen, setNavListOpen] = useState(false);
  const [mobilMenu, setMobilMenu] = useState(false);

  const handlerMobilMenu = (value) => {
    if (!value) {
      setMobilMenu(!mobilMenu);
      setNavListOpen(false);
    } else {
      setMobilMenu(false);
    }
  };

  const handlerSelectList = (value) => {
    value === navListOpen ? setNavListOpen(false) : setNavListOpen(value);
  };

  // для закрытия списка при клике на пустую область
  const handlerBlur = () => {
    setNavListOpen(false);
  };

  return (
    <header className="header">
      <nav className="header__nav container">
        <div className="header__logo-wrapper">
          <div
            className={mobilMenu ? 'header__mobile-menu _active' : 'header__mobile-menu'}
            onClick={() => handlerMobilMenu()}
          >
            <span></span>
          </div>
          <Link className="site-nav__link" to="/">
            <img src={Logo} alt="Лого компании" className="logo" />
          </Link>
        </div>

        {/* навигационная панель */}
        <HeaderSiteNavList
          popupWindow={popupWindow}
          mobilMenu={mobilMenu}
          navListOpen={navListOpen}
          setNavListOpen={setNavListOpen}
          handlerSelectList={handlerSelectList}
          handlerBlur={handlerBlur}
          handlerMobilMenu={handlerMobilMenu}
        />

        {/* избранные , прошлые действия, уведомления  */}
        <UserNavLists
          handler={handlerSelectList}
          handlerBlur={handlerBlur}
          isOpen={navListOpen}
          popupWindow={popupWindow}
        />
      </nav>
    </header>
  );
};

export default Nav;

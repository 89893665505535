import { useMessageContext } from '../../contexts/MessageContext';
import useAuth from '../../hooks/useAuth';

import ChatTextEditor from './ChatTextEditor';

const ChatTextEditorContainer = (props) => {
  const auth = useAuth();
  //
  const { quoteMessage, setQuoteMessage, messageToEdit, editMessage, guestRights } = useMessageContext();

  if (auth?.user?.is_guest && !guestRights?.includes('write')) return '';
  return (
    <ChatTextEditor
      {...props}
      quoteMessage={quoteMessage}
      setQuoteMessage={setQuoteMessage}
      messageToEdit={messageToEdit}
      editMessage={editMessage}
      filesUploadDisabled={auth?.user?.is_guest && !guestRights?.includes('add_files')}
    />
  );
};

export default ChatTextEditorContainer;

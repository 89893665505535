import React from 'react';

import { StyledAvatar, StyledMockAvatar } from './styles';

export const Avatar = (props) => {
  if (!props.src) {
    return <StyledMockAvatar {...props} />;
  }
  return <StyledAvatar {...props} />;
};

import { Button } from '@shared/components/Button/Button';

import { Container } from '../Container';

import {
  StyledCloseModalButton,
  StyledModal,
  StyledModalBody,
  StyledModalFooter,
  StyledModalHeader,
  StyledModalInner,
  StyledModalTitle,
  StyledModalWrapper,
} from './style';

export const Modal = ({
  type,
  modalSize,
  onSave,
  onClose,
  title,
  headerContent,
  withoutButtons,
  confirmButtonText,
  cancelButtonText,
  disabledSaveButton,
  children,
}) => {
  return (
    <StyledModal>
      <StyledModalWrapper>
        <StyledModalInner modalsize={modalSize}>
          <StyledModalBody>
            <StyledModalHeader>
              <Container justify="space-between">
                <StyledModalTitle size={3} as="h3">
                  {title}
                </StyledModalTitle>
                {headerContent}
              </Container>

              <StyledCloseModalButton onClick={onClose} />
            </StyledModalHeader>

            {children}

            <StyledModalFooter>
              {!withoutButtons && (
                <>
                  <Button type={type} width={'153px'} color="green" onClick={onSave} disabled={disabledSaveButton}>
                    {confirmButtonText ? confirmButtonText : 'Подтвердить'}
                  </Button>
                  <Button width={'153px'} color={'light'} onClick={onClose}>
                    {cancelButtonText ? cancelButtonText : 'Отмена'}
                  </Button>
                </>
              )}
            </StyledModalFooter>
          </StyledModalBody>
        </StyledModalInner>
      </StyledModalWrapper>
    </StyledModal>
  );
};

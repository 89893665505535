import { useState, useMemo, useContext } from 'react';
import axios from 'axios';

import { useForm } from 'react-hook-form';

import { useSelector } from 'react-redux';

import { number, object, string, array } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import SelectMulty from '../../components/form/select/SelectMulty';
import {
  formatReactSelectEmployeesOptions,
  formatReactSelectTagsOptions,
  useEventTriggerOnEscPress,
} from '../../utilize/helper-functions';
import ProjectAttachedStorage from '../../components/storage/ProjectAttachedStorage';
import IndependentStorage from '../../components/storage/IndependentStorage';
import ConfirmAction from '../../components/warnings/ConfirmAction';
import SnackbarContext from '../../contexts/SnackbarContext';

const formSchema = object({
  title: string().required(),
  description: string().nullable(true),
  project_id: number().nullable(true),
  storage_tags: array().of(object()),
  viewers: array().of(object()),
  writers: array().of(object()),
});

const CreateStorageModal = ({ close, editData, getData, getStoragesList }) => {
  const [modalClosePrompt, setModalClosePrompt] = useState();
  const [storageType, setStorageType] = useState('independent');
  const navigate = useNavigate();

  const {
    register,
    control,
    // watch,
    handleSubmit,
    reset,
    formState: { isDirty, isValid },
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(formSchema),
  });

  // установить первоначальные данные, если хранилище редактируется
  useEffect(() => {
    if (editData) {
      const { storage } = editData;
      reset({
        title: storage.title,
        description: storage.description,
        project_id: storage.project_id,
        storage_tags: storage.storage_tags.map((t) => ({
          label: t.name,
          value: t.name,
          id: t.id,
        })),
        writers: storage.storage_writers.map((w) => ({
          label: `${w.last_name || ''} ${w.first_name || ''}`,
          value: `${w.last_name || ''}${w.first_name || ''}`,
          id: w.id,
        })),
        viewers: storage.storage_viewers.map((w) => ({
          label: `${w.last_name || ''} ${w.first_name || ''}`,
          value: `${w.last_name || ''}${w.first_name || ''}`,
          id: w.id,
        })),
      });
      if (storage?.project_id) setStorageType('attached');
    }
  }, [editData, reset]);

  const handleCloseBtnClick = () => {
    if (modalClosePrompt) return;
    if (isDirty) {
      return setModalClosePrompt(true);
    }
    close();
  };

  useEventTriggerOnEscPress(handleCloseBtnClick);

  const employees = useSelector((state) => state.users.employees);
  const tags = useSelector((state) => state.tags.tagsList);

  // данные для Select компонента writers
  const writers = useMemo(
    () => ({
      name: 'writers',
      label: '',
      type: 'select',
      optionsType: 'tags',
      options: formatReactSelectEmployeesOptions(employees) || [],
    }),
    [employees],
  );
  // данные для Select компонента viewers
  const viewers = useMemo(
    () => ({
      name: 'viewers',
      label: '',
      type: 'select',
      optionsType: 'tags',
      options: formatReactSelectEmployeesOptions(employees) || [],
    }),
    [employees],
  );

  // данные для Select компонента tags
  const tagsData = useMemo(
    () => ({
      name: 'storage_tags',
      label: '',
      // placeholder:'Выбрать тэги',
      // label: t(`${translateKey}.viewers_role`),
      type: 'select',
      subType: 'tag',
      options: formatReactSelectTagsOptions(tags) || [],
    }),
    [tags],
  );

  const [isSubmitting, setIsSubmitting] = useState(false);

  const { showSnackbar } = useContext(SnackbarContext);

  const submitData = (data) => {
    const reqBody = {
      row: {
        title: data.title,
        description: storageType === 'independent' ? data.description : null,
        project_id: storageType === 'attached' ? data.project_id : null,
      },
      storage_tags: data.storage_tags?.map((tag) => ({ tag_id: tag.id })),
      writers: data.writers?.map((w) => ({ employee_id: w.id })),
      viewers: data.viewers?.map((v) => ({ employee_id: v.id })),
    };
    setIsSubmitting(true);
    if (editData) {
      reqBody.storage_id = editData.storage.id;
      axios
        .put('/api/storages/edit', reqBody)
        .then(() => {
          if (getData) getData();
          close();
        })
        .catch(() => {
          setIsSubmitting(false);
          showSnackbar('Возникла ошибка при редактировании');
        });
      return;
    }

    axios
      .post('/api/storages/add', reqBody)
      .then((r) => {
        if (getStoragesList) getStoragesList();
        close();
        navigate(`/storages/${r.data.result}`);
      })
      .catch(() => {
        setIsSubmitting(false);
        showSnackbar('Возникла ошибка при создании');
      });
  };

  return (
    <section className="modal">
      <div className="modal__wrapper">
        <div className="modal__inner modal__inner--medium">
          <section className="modal__body">
            <div className="modal__header">
              <h2 className="modal__title">{`${editData ? 'Редактировать' : 'Создать'}`} хранилище</h2>
              <button className="modal__close-modal" onClick={handleCloseBtnClick}></button>
            </div>
            <div className="modal__row">
              <label className="modal__label" htmlFor="name">
                Название
              </label>
              <input className="modal__input" id="name" {...register('title')} />
            </div>
            <div className="modal__row">
              <label className="modal__label" htmlFor="surname">
                Тип
              </label>
              <div className="modal__radio-box">
                <input
                  className="modal__radio-input"
                  type="radio"
                  id="var1"
                  name="type"
                  value="independent"
                  checked={storageType === 'independent'}
                  onChange={() => setStorageType('independent')}
                />
                <label className="modal__radio-label" htmlFor="var1">
                  Независимое
                </label>
                <input
                  className="modal__radio-input"
                  type="radio"
                  id="var2"
                  name="type"
                  value="attached"
                  checked={storageType === 'attached'}
                  onChange={() => setStorageType('attached')}
                />
                <label className="modal__radio-label" htmlFor="var2">
                  Привязанное к проекту
                </label>
              </div>
            </div>
            <div className="modal__row">
              {storageType === 'independent' && <IndependentStorage register={register} />}

              {storageType === 'attached' && (
                <ProjectAttachedStorage control={control} selectedProjectTitle={editData?.project_title} />
              )}
            </div>

            <div className="modal__row">
              <h3 className="modal__subtitle">Кто может записывать</h3>
              <SelectMulty item={writers} control={control} options={writers.options} />
            </div>
            <div className="modal__row">
              <h3 className="modal__subtitle">Кто видит (если пусто, то видят все сотрудники)</h3>
              <SelectMulty item={viewers} control={control} options={viewers.options} />
            </div>
            <div className="modal__row">
              <h3 className="modal__subtitle">Тэги</h3>
              {/* тэги */}
              <SelectMulty item={tagsData} control={control} options={tagsData.options} />
            </div>
            <div className="modal__button-box">
              <button
                className="modal__button modal__button--create"
                onClick={handleSubmit(submitData)}
                disabled={!isDirty || !isValid || isSubmitting}
              >
                {`${editData ? 'Сохранить' : 'Создать'}`}
              </button>
              <button className="modal__button modal__button--cancel" onClick={handleCloseBtnClick}>
                Отмена
              </button>
            </div>
          </section>
        </div>
      </div>

      {modalClosePrompt && (
        <ConfirmAction
          confirm={close}
          cancel={() => setModalClosePrompt(false)}
          actionText="Уверены что хотите закрыть окно, не сохранив изменения?"
        />
      )}
    </section>
  );
};

export default CreateStorageModal;

import { Ticket } from '../Tickets/Ticket/Ticket';

const StyledTest = () => {
  // const radioButtons = [
  //   {id: 1, label: 'qwerty', value: 'qwerty'},
  //   {id: 2, label: 'qwerty2', value: 'qwerty2'},
  //   {id: 3, label: 'qwerty3', value: 'qwerty3'},
  // ];

  return (
    <>
      {/*<Modal modalSize='767px'>*/}
      {/*  <ModalHeader*/}
      {/*    title="Текст заголовка"*/}
      {/*    onClose={() => {}}*/}
      {/*  >*/}
      {/*  </ModalHeader>*/}

      {/*  <ModalStatusInfo>fdfdfddfdf</ModalStatusInfo>*/}

      {/*  <div>*/}
      {/*    <Label>Состояние инпута при ошибке</Label>*/}
      {/*    <Input error="true" callback={value => {console.log(value)}} />*/}
      {/*  </div>*/}

      {/*  <div>*/}
      {/*    <Label>Обычное поле</Label>*/}
      {/*    <Textarea rows="3" error="false" callback={value => {console.log(value)}} />*/}

      {/*    <StyledIconButtonWrapper>*/}
      {/*      <IconButton size={12} icon={boldTextIcon}></IconButton>*/}
      {/*      <IconButton size={12} icon={attachIcon}></IconButton>*/}
      {/*    </StyledIconButtonWrapper>*/}
      {/*  </div>*/}

      {/*  <Checkbox*/}
      {/*    callback={(value) => {console.log(value)}}*/}
      {/*    id='checkbox_id'*/}
      {/*  >*/}
      {/*    Текст рядом с чекбоксом*/}
      {/*  </Checkbox>*/}

      {/*  <Radio name='radio_name' items={radioButtons} direction='row' callback={(value) => {console.log(value)}}></Radio>*/}

      {/*  <Subtitle>Заголовок раздела в модалке</Subtitle>*/}

      {/*  <User size='sm' picture={profilePic} name='Имя Фамильевич' ></User>*/}

      {/*  <Button size='sm' color='green' callback={() => console.log('vvv')}>fedfd</Button>*/}

      {/*  <SingleSelect></SingleSelect>*/}

      {/*  <DateInput*/}
      {/*    placeholderText="от:"*/}
      {/*    dateFormat="dd.MM.yyyy"*/}
      {/*    callback={(evt) => {console.log(evt)}}*/}
      {/*    selected={''}*/}
      {/*  />*/}
      {/*  */}
      {/*  <ModalFooter>*/}
      {/*    <Button*/}
      {/*      color="green"*/}
      {/*      callback={() => console.log('vvv')}*/}
      {/*    >*/}
      {/*      Название кнопки*/}
      {/*    </Button>*/}

      {/*    <ButtonWithIcon*/}
      {/*      position="right"*/}
      {/*      color="light"*/}
      {/*      size={16}*/}
      {/*      icon={icon}*/}
      {/*      callback={() => console.log('vvv')}*/}
      {/*    >*/}
      {/*      Название кнопки*/}
      {/*    </ButtonWithIcon>*/}
      {/*  </ModalFooter>*/}
      {/*</Modal>*/}
      <Ticket></Ticket>
    </>
  );
};

export default StyledTest;

import { useRef, useState, useContext, useMemo } from 'react';

import { object, string } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, Controller } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import axios from 'axios';

import { randomSequence, useEventTriggerOnEscPress } from '../../utilize/helper-functions';
import TextEditor from '../../components/text-editor/TextEditor';
import DropFilesWrapperContainer from '../../components/drag-field/DropFilesWrapper';
import FilesUploadManage from '../../components/files/FilesUploadManage';
import SnackbarContext from '../../contexts/SnackbarContext';
import ConfirmAction from '../../components/warnings/ConfirmAction';
import { files_url } from '../../settings/base-url';
import useAuth from '../../hooks/useAuth';

const formSchema = object({
  title: string().required(),
  description: string(),
});

const CreateEditNewsModal = ({ close, type, dataToEdit, getData }) => {
  const formId = useRef(randomSequence());

  const [isSubmitting, setIsSubmitting] = useState();
  const { showSnackbar } = useContext(SnackbarContext);

  const navigate = useNavigate();

  const [filesDeleteArr, setFilesDeleteArr] = useState([]);

  const submitData = async (data) => {
    setIsSubmitting(true);
    const submitData = {
      row: {
        title: data.title,
        description: data.description,
        type: type,
      },
      form_id: formId.current,
    };

    if (dataToEdit) {
      submitData.news_blog_id = dataToEdit.id;
      if (filesDeleteArr.length) {
        submitData.deleted_files = filesDeleteArr;
      }
      axios
        .put('/api/news_blogs/edit', submitData)
        .then(() => {
          close();
          getData();
        })
        .catch(() => {
          showSnackbar('Ошибка при отправке запроса');
        });
    } else {
      axios
        .post('/api/news_blogs/add', submitData)
        .then((r) => {
          navigate(`/${type === 'news' ? 'news' : 'blogs'}/${r.data.result}`);
          close();
        })
        .catch(() => {
          setIsSubmitting(false);
          showSnackbar('Ошибка при отправке запроса');
        });
    }
  };

  const {
    register,
    control,
    handleSubmit,
    formState: { isDirty, isValid },
    // getValues,
  } = useForm({
    resolver: yupResolver(formSchema),
    mode: 'onChange',
    defaultValues: dataToEdit && {
      title: dataToEdit.title,
      description: dataToEdit.description || '',
    },
  });

  const [uploadingFiles, setUploadingFiles] = useState([]);
  const removeFilesOnClose = useRef();

  const [modalClosePrompt, setModalClosePrompt] = useState();

  const closeModal = () => {
    if (removeFilesOnClose.current) removeFilesOnClose.current();
    close();
  };

  const handleCloseBtnClick = () => {
    if (isDirty || uploadingFiles.length || filesDeleteArr.length) {
      return setModalClosePrompt(true);
    }
    closeModal();
  };

  useEventTriggerOnEscPress(handleCloseBtnClick);

  const auth = useAuth();

  const filesToEdit = useMemo(() => {
    if (dataToEdit?.files && auth?.token) {
      return dataToEdit.files.map((file) => ({
        ...file,
        name: file.file,
        src: `${files_url}/news_blogs/files/${dataToEdit.id}/${file.file}?token=${auth.token}`,
      }));
    }
  }, [dataToEdit, auth]);

  const filesAddFormData = useMemo(() => {
    if (!dataToEdit) return;
    return { type: 'news_blog_id', id: dataToEdit.id };
  }, [dataToEdit]);

  const removeFilesReqData = useMemo(() => {
    const reqBody = {};
    if (dataToEdit) reqBody.news_blog_id = dataToEdit.id;
    else reqBody.form_id = formId.current;
    return reqBody;
  }, [dataToEdit]);

  return (
    <>
      <section className="modal">
        <div className="modal__wrapper">
          <div className="modal__inner">
            <DropFilesWrapperContainer setFiles={setUploadingFiles}>
              <section className="modal__body">
                <div className="modal__header">
                  <h2 className="modal__title">Создать {`${type === 'news' ? 'новости' : 'блог'}`}</h2>
                  <button className="modal__close-modal" onClick={handleCloseBtnClick}></button>
                </div>
                <div className="modal__row">
                  <label className="modal__label" htmlFor="name">
                    Заголовок
                  </label>
                  <input className="modal__input" id="name" placeholder="Введите заголовок" {...register('title')} />
                </div>
                <div className="modal__row">
                  <label className="modal__label" htmlFor="description">
                    Описание
                  </label>
                  <Controller
                    control={control}
                    name="description"
                    render={({ field }) => <TextEditor sendFormat={field.onChange} prev={dataToEdit?.description} />}
                  />
                </div>
                <section className="modal__row drag-drop">
                  <label className="drag-drop__label" htmlFor="upload"></label>
                  <input
                    className="drag-drop__input"
                    type="file"
                    id="upload"
                    multiple="multiple"
                    onChange={(e) => {
                      if (e.target.files?.length) {
                        setUploadingFiles([...e.target.files]);
                      }
                    }}
                  />
                  <p className="drag-drop__text" onDragOver={() => {}}>
                    Перетяните файлы
                    <br />
                    или кликните, чтобы выбрать
                  </p>
                </section>
                {(uploadingFiles.length > 0 || dataToEdit?.files.length > 0) && (
                  <div className="chat__attach attach">
                    <FilesUploadManage
                      filesToUpload={uploadingFiles}
                      filesToEdit={filesToEdit}
                      filesDeleteArr={filesDeleteArr}
                      setFilesDeleteArr={setFilesDeleteArr}
                      formId={formId.current}
                      filesAddFormData={filesAddFormData}
                      removeFilesOnClose={removeFilesOnClose}
                      filesUploadPath="/api/news_blogs/files/add"
                      filesDeletePath="/api/news_blogs/files/remove"
                      removeFilesReqBody={removeFilesReqData}
                    />
                  </div>
                )}
                <div className="modal__button-box">
                  <button
                    className="modal__button modal__button--create"
                    onClick={handleSubmit(submitData)}
                    disabled={!isValid || isSubmitting}
                  >
                    {`${dataToEdit ? 'Сохранить' : 'Создать'}`}
                  </button>
                  <button className="modal__button modal__button--cancel" onClick={handleCloseBtnClick}>
                    Отмена
                  </button>
                </div>
              </section>
            </DropFilesWrapperContainer>
          </div>
        </div>
      </section>
      {modalClosePrompt && (
        <ConfirmAction
          confirm={closeModal}
          cancel={() => setModalClosePrompt(false)}
          actionText="Уверены что хотите закрыть окно, не сохранив изменения?"
        />
      )}
    </>
  );
};

export default CreateEditNewsModal;

import reactHtmlParser from 'html-react-parser';
import { stateToHTML } from 'draft-js-export-html';
import { convertFromRaw } from 'draft-js';

const options = {
  entityStyleFn: (entity) => {
    const entityType = entity.get('type').toLowerCase();

    if (entityType === 'mention') {
      const { mention } = entity.get('data');
      return {
        element: 'a',
        attributes: {
          userid: mention.userId,
        },
      };
    } else if (entityType === 'LINK') {
      const { url } = entity.get('data');
      return {
        element: 'a',
        attributes: {
          href: url,
        },
      };
    }
  },
};

// если в аттрибутах присутствует userId, то трансформировать в span с функцией открытия профиля юзера
// если это ссылка с аттрибутом href - то указать как обычную ссылку
const transform = (node, showUserProfile) => {
  if (node.type === 'tag' && node.name === 'a') {
    if (node.attribs.userid) {
      let childNode = node;
      while (childNode.children) {
        childNode = childNode.children[0];
      }
      if (childNode.type === 'text') {
        return (
          <span
            style={{ cursor: 'pointer' }}
            // to={`/team/users/${node.attribs.userid}`}
            onClick={() => showUserProfile(node.attribs.userid)}
            className="chat__username"
            key={Math.random() * 10000}
          >
            {childNode.data}
          </span>
        );
      }
    } else if (node.attribs.href) {
      return (
        <a
          href={node.attribs.href}
          target="_blank"
          rel="noreferrer"
          className="message__content-link"
          key={Math.random() * 10000}
        >
          {node.children[0].data}
        </a>
      );
    }
    return null;
  }
};

const removeParagraphTags = (str) => {
  if (!str) return '';
  if (str.startsWith('<p>') && str.endsWith('</p>')) {
    str = str.slice(3);
    str = str.slice(0, -4);
  }
  return str;
};

export const renderMessage = (draftJsState, showUserProfile, noParagraph) => {
  if (!draftJsState) return null;

  try {
    let htmlString = stateToHTML(convertFromRaw(JSON.parse(draftJsState)), options);

    if (noParagraph) htmlString = removeParagraphTags(htmlString);

    const reactHtml = reactHtmlParser(htmlString, {
      replace: (node) => transform(node, showUserProfile),
    });
    return reactHtml;
  } catch (e) {
    return null;
  }
};

import styled from 'styled-components';

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: ${({ direction }) => direction};
  align-items: ${({ align }) => align || 'normal'};
  justify-content: ${({ justify }) => justify};
  width: 100%;
  gap: ${({ gap }) => gap};
`;

import React from 'react';

const FormSubmitButton = ({ isValid, text, isSending }) => {
  return (
    <input
      // disabled={isValid}
      className="form__button"
      type="submit"
      value={text}
      disabled={!isValid || isSending}
    />
  );
};

export default FormSubmitButton;

import { useTranslation } from 'react-i18next';

import { project_window } from '../../../settings/window-types';

import ProjectListFilter from './project-list-filter/ProjectListFilter';
// import ProjectListTabs from "./project-list-tabs/ProjectListTabs";
import ProjectListContainer from './project-list-container/ProjectListContainer';

const ProjectList = ({ props }) => {
  const { t } = useTranslation();

  return (
    <div className="project-list__container container">
      <section className="project-list__header">
        {/* кнопка добавления проекта */}
        <button className="project-list__button" onClick={() => props.popupWindow.props.onOpen(project_window)}>
          {t('Project.add_project')}
        </button>
        <br />
      </section>
      {/* <ProjectListTabs /> */}
      {/* панель фильтрации проектов */}
      <ProjectListFilter />

      {/* список проектов */}
      <ProjectListContainer {...props} />
    </div>
  );
};

export default ProjectList;

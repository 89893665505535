import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import InlineModal from '../../modals/InlineModal';
import CreateTaskModal from '../../tasks/CreateTaskModal';

import ProjectTree from './ProjectTree';

const StructureSidePanel = ({ sideMenu, openSideMenu }) => {
  const { projectId, taskId } = useParams();
  const { t } = useTranslation();

  const [addTaskModal, toggleAddTaskModal] = useState(false);

  return (
    <aside data-aside="db" className={`aside ${sideMenu === 'db' ? '_active' : ''}`}>
      <div className="aside__header">
        <span className="aside__title aside__title--db">{t('Project.structure')}</span>
        <button className="aside__button-arrow" onClick={() => openSideMenu(null)}></button>
      </div>
      <div className="aside__body">
        {/* обновлять боковое меню Структура, если sideMenu равно "db" */}
        <ProjectTree update={sideMenu === 'db'} />

        <button className="aside__button-add aside__button-add--center" onClick={() => toggleAddTaskModal(true)}>
          {t('Project.add_subtask')}
        </button>
      </div>

      {addTaskModal && (
        <InlineModal close={() => toggleAddTaskModal(false)}>
          <CreateTaskModal projectId={projectId} parentTaskId={taskId} sidePanelOpen={true} />
        </InlineModal>
      )}
    </aside>
  );
};

export default StructureSidePanel;

import { useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { useEventTriggerOnEscPress } from '../../utilize/helper-functions';

const ConfirmAction = ({ actionText, cancel, confirm, isSubmitting }) => {
  const { t } = useTranslation();

  useEventTriggerOnEscPress(cancel);

  const confirmRef = useRef();

  useEffect(() => {
    confirmRef.current.focus();
  }, []);

  return (
    <section className="modal">
      <div className="modal__wrapper">
        <div className="modal__inner modal__inner--info">
          <section className="modal__body">
            <div className="modal__header">
              <button className="modal__close-modal" onClick={cancel}></button>
            </div>
            <h2 className="modal__confirm-text">{actionText}</h2>
            <div className="modal__button-box">
              <button
                className="modal__button modal__button--yes"
                onClick={confirm}
                disabled={isSubmitting}
                ref={confirmRef}
                tabIndex={1}
              >
                {t('FormButtons.yes')}
              </button>
              <button className="modal__button modal__button--no" onClick={cancel} tabIndex={2}>
                {t('FormButtons.no')}
              </button>
            </div>
          </section>
        </div>
      </div>
    </section>
  );
};

export default ConfirmAction;

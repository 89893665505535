import { useEffect, useState, useContext } from 'react';
import axios from 'axios';

import { useParams } from 'react-router-dom';

import SnackbarContext from '../../contexts/SnackbarContext';
import Preloader from '../preloaders/Preloader';

const StoragesDropdown = ({ handleAnotherStorageSelect, selectedStorageTitle }) => {
  const [showDropdown, setShowDropdown] = useState();
  const [storagesList, setStoragesList] = useState();
  const { storageId } = useParams();

  const { showSnackbar } = useContext(SnackbarContext);

  useEffect(() => {
    if (showDropdown && !storagesList) {
      axios
        .get('/api/storages_list')
        .then((r) => setStoragesList(r.data.result))
        .catch(() => showSnackbar('Возникла ошибка при загрузке списка хранилищ'));
    }
  }, [showSnackbar, storagesList, showDropdown]);

  return (
    <div className={`modal__storage-dropdown dropdown ${showDropdown ? '_show' : ''} `}>
      <div className="dropdown__input" data-selected="" onClick={() => setShowDropdown(!showDropdown)}>
        {selectedStorageTitle || 'Выбрать другое хранилище'}
      </div>

      <ul className="dropdown__select select">
        {!storagesList && (
          <li>
            <Preloader />
          </li>
        )}
        {storagesList?.map((storage, i) => {
          if (storage.id === +storageId) return '';
          return (
            // <Link
            //   key={i}
            //   to={`/storages/${storage.id}`}
            //   onClick={() => setShowDropdown(false)}
            // >
            <li
              key={i}
              className="select__item select__item--rows _pointer"
              onClick={() => {
                handleAnotherStorageSelect(storage);
                setShowDropdown(false);
              }}
            >
              <p className="select__name">{storage.title}</p>
              <p className="select__row">К проекту: название проекта</p>
            </li>
            // </Link>
          );
        })}
        {/* <li className="select__item select__item--rows" data-option="1">
          <p className="select__name">Хранилище 1</p>
          <p className="select__row">К проекту: название проекта</p>
        </li>
        <li className="select__item select__item--rows" data-option="2">
          <p className="select__name">Хранилище 2</p>
        </li>
        <li className="select__item select__item--rows" data-option="3">
          <p className="select__name">Хранилище 3</p>
        </li>
        <li className="select__item select__item--rows" data-option="4">
          <p className="select__name">Хранилище 4</p>
        </li> */}
      </ul>
    </div>
  );
};

export default StoragesDropdown;

import { Fragment, useState } from 'react';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';

import Avatar from '../../assets/images/userpic.png';
import useAuth from '../../hooks/useAuth';
import { files_url } from '../../settings/base-url';
import GuestModalCurrent from '../../windows/profile/GuestModalCurrent';
import UserProfileModal from '../../windows/profile/UserProfileModal';

const UserStatus = ({
  userId,
  type,
  showProfileOnClick,
  firstName,
  lastName,
  projectData, // используется для показа гостевых профилей
  projectType, // используется для показа гостевых профилей
  projectId, // используется для показа гостевых профилей
  is_guest, // используется для показа гостевых профилей
}) => {
  const auth = useAuth();
  const author = useSelector((state) => state.users.employees?.find((employee) => employee.id === +userId));
  const userOnline = useSelector((state) => state.users.onlineEmployees?.find((user) => user.id === +userId));

  const [userProfileModal, toggleUserProfileModal] = useState(false);
  const [guestProfileModal, toggleGuestProfileModal] = useState(false);

  const handleAvatarClick = useCallback(() => {
    if (!showProfileOnClick?.current) return;
    if (is_guest && projectType && projectId) {
      return toggleGuestProfileModal(true);
    }
    toggleUserProfileModal(true);
  }, [showProfileOnClick, is_guest, projectId, projectType]);

  if (type === 'team-card__userpic') {
    if (!author?.avatar) {
      return (
        <span className={`${type} _avatar-initials _avatar-initials--md `}>
          {firstName && firstName[0]}
          {lastName && lastName[0]}
        </span>
      );
    }
    return (
      <img
        className={type}
        src={
          author?.avatar
            ? `${files_url}/employees/avatar/${author.id}/small_${author.avatar}?token=${auth.token}`
            : Avatar
        }
        alt="userpic"
      />
    );
  } else if (type === 'profile__userpic') {
    return (
      <div className={type}>
        {author?.avatar ? (
          <img src={`${files_url}/employees/avatar/${author.id}/${author.avatar}?token=${auth.token}`} alt="userpic" />
        ) : (
          <span className={`${type} _avatar-initials _avatar-initials--lg`}>
            {firstName && firstName[0]}
            {lastName && lastName[0]}
          </span>
        )}
      </div>
    );
  } else if (type === 'message__username--status') {
    return (
      <p
        className={`message__username message__username--status message__username--${
          userOnline ? 'online' : 'offline'
        }`}
      >
        {`${lastName || ''} ${firstName}`}
      </p>
    );
  }

  return (
    <>
      <div className={`message__user-status user-status user-status--${userOnline ? 'online' : 'offline'}`}>
        {author?.avatar ? (
          <img
            className="message__userpic"
            src={
              author?.avatar
                ? `${files_url}/employees/avatar/${author.id}/small_${author.avatar}?token=${auth.token}`
                : Avatar
            }
            alt="userpic"
            onClick={handleAvatarClick}
          />
        ) : (
          <span className={`message__userpic _avatar-initials`} onClick={handleAvatarClick}>
            {firstName && firstName[0]}
            {lastName && lastName[0]}
          </span>
        )}
      </div>

      {userProfileModal && <UserProfileModal profileId={userId} onClose={() => toggleUserProfileModal(false)} />}

      {guestProfileModal && (
        <GuestModalCurrent
          dataType={projectType}
          dataId={projectId}
          guestId={userId}
          projectData={projectData}
          close={() => toggleGuestProfileModal(false)}
        />
      )}
    </>
  );
};

export default UserStatus;

import { StyledLayout } from '@shared/components/Layout/styles';
import { Title } from '@shared/components/Title/Title';
import React from 'react';

import { useGetTicketsQuery } from 'src/redux/features/api/tickets';

import { TicketCard } from '@components/Tickets/TicketsForMe';

import Preloader from '@components/preloaders/Preloader';

import { TicketsContainer } from './styles';

export const PageTicketsForMe = () => {
  const { data, isLoading } = useGetTicketsQuery();

  return (
    <>
      <StyledLayout>
        <Title>Поручения мне</Title>
        {isLoading ? (
          <Preloader />
        ) : (
          <TicketsContainer>
            {data && data.tickets_for_me.map((ticket) => <TicketCard ticket={ticket} />)}
          </TicketsContainer>
        )}
      </StyledLayout>
    </>
  );
};

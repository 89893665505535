import axios from 'axios';
import { useContext } from 'react';
import { useRef, useState, useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';

import { useStorageContext } from 'src/windows/storage/StorageModal';

import SnackbarContext from '../../contexts/SnackbarContext';
import useAuth from '../../hooks/useAuth';
import { getStorageTree } from '../../redux/features/storageSlice';
import { files_url } from '../../settings/base-url';
import { convertBytes, doubleClickHandle, downloadFile, useOutsideTrigger } from '../../utilize/helper-functions';
import { errorTypes, processResponseErrors } from '../../utilize/processResponseErrors';
import { formatDateWithDots } from '../calendar-form/CalendarForm';
import ConfirmAction from '../warnings/ConfirmAction';

import FileVersionsModal from './FileVersionsModal';
import RenameModal from './RenameModal';
import TransferCopyFilesModal from './TransferCopyFilesModal';

const FileDetailsRow = ({
  fileData,
  renderUserName,
  versionsModal,
  folderPath,
  removeItem,
  openTrashStack, // стэк открытых папок корзины
  storageId,
  // addStorageFileReference,
}) => {
  const auth = useAuth();

  const [openFileVersions, setOpenFileVersions] = useState();

  const { addStorageFileReference } = useStorageContext();
  console.log('🚀 ~ addStorageFileReference:', addStorageFileReference);

  const filePath = useMemo(() => {
    if (folderPath) {
      return `${folderPath}/${fileData.title}?token=${auth.token}`;
    } else if (openTrashStack) {
      return `${files_url}/storages/trash/files/${storageId}/${fileData.id}/${fileData.title}?token=${auth.token}`;
    } else {
      return `${files_url}/storages/${fileData.path}?token=${auth.token}`;
    }
  }, [fileData, auth, openTrashStack, storageId, folderPath]);

  const fileMenu = useRef();
  const fileMenuBtn = useRef();
  const refsArray = useRef([fileMenuBtn, fileMenu]);
  const [openFileMenu, setOpenFileMenu] = useState('');
  const outsideClickEvent = useCallback(() => setOpenFileMenu(''), []);

  useOutsideTrigger(refsArray.current, outsideClickEvent, openFileMenu);

  const [renameModal, setRenameModal] = useState();
  const [transferCopyModal, setTransferCopyModal] = useState();
  const [confirmTrashing, setConfirmTrashing] = useState();
  const [confirmDelete, setConfirmDelete] = useState();

  const [isSubmitting, setIsSubmitting] = useState();

  const { showSnackbar } = useContext(SnackbarContext);
  const dispatch = useDispatch();

  const sendToTrash = () => {
    setIsSubmitting(true);
    axios
      .patch('/api/storage_files/to_trash', {
        file_id: fileData.id,
      })
      .then(() => {
        dispatch(getStorageTree({ storageId, showSnackbar }));
        setConfirmTrashing(false);
        setIsSubmitting(false);
      })
      .catch(() => {
        showSnackbar('Возникла ошибка при удалении файла в корзину');
        setIsSubmitting(false);
      });
  };

  const deleteFile = () => {
    setIsSubmitting(true);
    axios
      .patch('/api/storage_files/remove', {
        file_id: fileData.id,
      })
      .then(() => {
        removeItem({ dataType: 'files', dataId: fileData.id });
        setConfirmDelete(false);
      })
      .catch(() => {
        showSnackbar('Возникла ошибка при удалении файла');
        setIsSubmitting(false);
      });
  };

  const restoreFile = () => {
    axios
      .patch('/api/restore_from_trash', {
        file_id: fileData.id,
      })
      .then(() => {
        removeItem({ dataType: 'files', dataId: fileData.id });
        dispatch(getStorageTree({ storageId, showSnackbar }));
        showSnackbar('Файл восстановлен', 'success');
      })
      .catch((e) => {
        const errorType = processResponseErrors(e);
        if (errorType === errorTypes.NO_PARENT_FOLDER) {
          showSnackbar('Ошибка при восстановлении: родительская папка была удалена');
        } else {
          showSnackbar('Возникла ошибка при восстановлении файла');
        }
      });
  };

  if (!auth?.token) return '';
  return (
    <>
      <li
        className="storage__catalog-file catalog-file _no_selection"
        onClick={() => {
          // если детализация папки открыта через модальное окно версий папки
          if (versionsModal || openTrashStack) downloadFile(filePath);
          // при двойном клике открыть/скачать файл
          // при одиночном клике открыть версии файла
          else {
            doubleClickHandle(
              () => downloadFile(filePath),
              () => setOpenFileVersions(true),
            );
          }
        }}
      >
        {fileData.title}
        <p className="catalog-file__weight">{convertBytes(fileData.size)}</p>
        <p className="catalog-file__date">{formatDateWithDots(fileData.date_created)}</p>
        {renderUserName(fileData.creator_id)}
        <button
          ref={fileMenuBtn}
          className="catalog-file__button-dots"
          onClick={(e) => {
            e.stopPropagation();
            setOpenFileMenu(openFileMenu ? '' : 'active');
          }}
        ></button>
        <section
          ref={fileMenu}
          className={`catalog-folder__menu chat-menu ${openFileMenu}`}
          onClick={(e) => e.stopPropagation()}
        >
          {/* <p className="chat-menu__item">Предпросмотр</p> */}
          <p
            className="chat-menu__item"
            onClick={(e) => {
              e.stopPropagation();
              downloadFile(filePath);
              setOpenFileMenu('');
            }}
          >
            Скачать
          </p>
          <p className="chat-menu__separator"></p>

          {addStorageFileReference && !openTrashStack && (
            <p
              className="chat-menu__item"
              onClick={(e) => {
                e.stopPropagation();
                addStorageFileReference({
                  ...fileData,
                  src: filePath,
                  name: fileData.title,
                });
                setOpenFileMenu('');
              }}
            >
              Добавить ссылку на документ в сообщении
            </p>
          )}

          <p className="chat-menu__separator"></p>

          {!versionsModal && !openTrashStack && (
            <p
              className="chat-menu__item"
              onClick={(e) => {
                e.stopPropagation();
                setOpenFileVersions(true);
                setOpenFileMenu('');
              }}
            >
              История версий
            </p>
          )}

          <p className="chat-menu__separator"></p>

          {/* функционал, когда файл не в корзине    */}
          {!openTrashStack && (
            <>
              <p
                className="chat-menu__item"
                onClick={(e) => {
                  e.stopPropagation();
                  setTransferCopyModal({ mode: 'transfer' });
                  setOpenFileMenu('');
                }}
              >
                Перенести в папку этого хранилища
              </p>

              <p
                className="chat-menu__item"
                onClick={(e) => {
                  e.stopPropagation();
                  setTransferCopyModal({ mode: 'copy' });
                  setOpenFileMenu('');
                }}
              >
                Скопировать в другое хранилище
              </p>

              <p className="chat-menu__separator"></p>

              <p
                className="chat-menu__item"
                onClick={(e) => {
                  e.stopPropagation();
                  setRenameModal(true);
                  setOpenFileMenu('');
                }}
              >
                Переименовать
              </p>

              <p
                className="chat-menu__item chat-menu__item--delete"
                onClick={(e) => {
                  e.stopPropagation();
                  setConfirmTrashing(true);
                  setOpenFileMenu('');
                }}
              >
                Удалить в корзину
              </p>
            </>
          )}

          {openTrashStack && (
            <>
              {!!fileData.recoverable && (
                <p
                  className="chat-menu__item"
                  onClick={(e) => {
                    e.stopPropagation();
                    restoreFile();
                    setOpenFileMenu('');
                  }}
                >
                  Восстановить
                </p>
              )}
              <p className="chat-menu__separator"></p>
              <p
                className="chat-menu__item chat-menu__item--delete"
                onClick={(e) => {
                  e.stopPropagation();
                  setConfirmDelete(true);
                  setOpenFileMenu('');
                }}
              >
                Удалить навсегда
              </p>
            </>
          )}
        </section>
      </li>
      {openFileVersions && (
        <FileVersionsModal
          currentFileData={fileData}
          close={() => setOpenFileVersions(false)}
          renderUserName={renderUserName}
        />
      )}

      {renameModal && (
        <RenameModal
          close={() => setRenameModal(false)}
          dataType="file"
          dataId={fileData.id}
          oldTitle={fileData.title}
          storageId={storageId}
        />
      )}

      {transferCopyModal && (
        <TransferCopyFilesModal
          close={() => setTransferCopyModal(false)}
          data={fileData}
          type="file"
          mode={transferCopyModal.mode}
          renderUserName={renderUserName}
          storageId={storageId}
        />
      )}

      {confirmTrashing && (
        <ConfirmAction
          actionText="Вы уверены что хотите удалить этот файл в корзину?"
          cancel={() => setConfirmTrashing(false)}
          confirm={sendToTrash}
          isSubmitting={isSubmitting}
        />
      )}

      {confirmDelete && (
        <ConfirmAction
          actionText="Вы уверены что хотите полностью удалить этот файл?"
          cancel={() => setConfirmDelete(false)}
          confirm={deleteFile}
          isSubmitting={isSubmitting}
        />
      )}
    </>
  );
};

export default FileDetailsRow;

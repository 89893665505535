import { isFileNameImage } from '../../utilize/helper-functions';
import { unique13NumRegex } from '../../utilize/regex';

import FileVersionsPopup from './FileVersionsPopup/FileVersionsPopup';

const AttachedFile = ({
  fileUrl,
  fileName,
  removeFile,
  storageFileId, // id файла в хранилище
  fileId,
}) => {
  if (isFileNameImage(fileName)) {
    return (
      <div className="attach__item">
        <a
          className="attach__image"
          href={fileUrl}
          target="_blank"
          rel="noreferrer"
          // key={i}
        >
          <img className="attach__user-image" src={fileUrl} alt="" />
        </a>
        <span>{fileName?.replace(unique13NumRegex, '.')}</span>

        {storageFileId && <FileVersionsPopup storageFileId={storageFileId} fileId={fileId} mainVersionUrl={fileUrl} />}

        {removeFile && (
          <button
            className="attach__button-delete"
            onClick={(e) => {
              e.stopPropagation();
              removeFile();
            }}
            type="button"
          ></button>
        )}
      </div>
    );
  } else
    return (
      <div className="attach__item">
        <>
          <a
            className="attach__item"
            // className="attach__image attach__image--xls"
            href={fileUrl}
            download
            target="_blank"
            rel="noreferrer"
            style={{ display: 'inline-flex', marginRight: 0 }}
            // key={i}
          >
            <div className="attach__image attach__image--xls"></div>
            <span>{fileName?.replace(unique13NumRegex, '.')}</span>
          </a>
          {storageFileId && (
            <FileVersionsPopup storageFileId={storageFileId} fileId={fileId} mainVersionUrl={fileUrl} />
          )}
          {removeFile && (
            <button
              className="attach__button-delete"
              onClick={(e) => {
                e.stopPropagation();
                removeFile();
              }}
              type="button"
            ></button>
          )}
        </>
      </div>
    );
};

export default AttachedFile;

import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { setOpenFolder } from '../../redux/features/storageSlice';

import FolderAside from './FolderAside';

const StorageTree = ({ handleFolderSelect, openFolderId }) => {
  // const { folderId, storageId } = useParams();
  const params = useParams();
  const storageTree = useSelector((state) => state.storage.storageTree);
  // const navigate = useNavigate();
  const dispatch = useDispatch();

  // установить корневой каталог как открытый при первоначальном рендере
  useEffect(() => {
    if (storageTree && /*!params*/ !openFolderId && !params['*'].includes('/bin')) {
      dispatch(setOpenFolder({ openFolder: storageTree, folderPath: null }));
    }
  }, [storageTree, dispatch, params, openFolderId]);

  return (
    <aside className="storage__aside">
      <ul className="rubric">
        {storageTree?.folders?.map((folder, i) => (
          <FolderAside
            key={i}
            data={folder}
            folderIdParam={openFolderId}
            storageId={params.storageId}
            handleSelect={handleFolderSelect}
          />
        ))}
      </ul>

      <div
        className="storage__delete-element"
        // onClick={() => navigate(`/storages/${params.storageId}/bin`)}
        onClick={() => handleFolderSelect('bin')}
      >
        Корзина
      </div>
    </aside>
  );
};

export default StorageTree;

import { Fragment } from 'react';

import useAuth from '../../../../hooks/useAuth';
import { rightsTranslation } from '../../../../utilize/helper-functions';
import UserStatus from '../../../employees/UserStatus';

const AsideUserCard = ({ userId, lastName, firstName, showUserProfile, guest }) => {
  const auth = useAuth();

  return (
    <>
      <div className={`aside__item ${auth?.user?.is_guest ? '_no-pointer' : ''}`}>
        <div
          className={`aside__usercard ${auth?.user?.is_guest ? '_no-pointer' : ''}`}
          onClick={() => {
            if (!auth?.user?.is_guest) showUserProfile(userId);
          }}
        >
          <UserStatus userId={userId} guest={guest} firstName={firstName} lastName={lastName} />
          <div>
            <span className="aside__username">{`${lastName || ''} ${firstName || ''}`}</span>
            {guest && (
              <>
                <a className="aside__email" href={`mailto:${guest.email}`}>
                  {guest.email}
                </a>
                <span className="aside__access">
                  {guest.access.map((right, i) => (
                    <Fragment key={i}>{rightsTranslation[right] ? `${rightsTranslation[right]}. ` : ''}</Fragment>
                  ))}
                </span>
              </>
            )}
          </div>
        </div>
        {guest && (
          <div className="aside__control-box">
            <button
              className="aside__control aside__control--edit"
              type="button"
              onClick={() => showUserProfile(userId, true)}
            ></button>
          </div>
        )}
      </div>
    </>
  );
};

export default AsideUserCard;

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import ConfirmAction from '../warnings/ConfirmAction';
// компонент списка должностей -
const PositionDeleteEdit = ({ item, onDelete, onEdit, hasRightToEdit }) => {
  const { title, qty } = item;
  const { t } = useTranslation();

  const [confirmDeleteModal, showConfirmDeleteModal] = useState();

  return (
    <>
      <div className="modal-grid__tr">
        <div className="modal-grid__td">
          <span className="modal-grid__description">Должность</span>
          <p>{title}</p>
        </div>
        <div className="modal-grid__td">
          <span className="modal-grid__description">Сотрудники</span>
          <p>{qty}</p>
        </div>
        <div className="modal-grid__td modal-grid__td--button">
          <div className="modal-grid__control-box">
            {hasRightToEdit && (
              <>
                <button onClick={() => onEdit(item)} className="modal-grid__control modal-grid__control--edit"></button>
                {qty === 0 && (
                  <button
                    onClick={() => showConfirmDeleteModal(true)}
                    className="modal-grid__control modal-grid__control--delete"
                  ></button>
                )}
              </>
            )}
          </div>
        </div>
      </div>

      {confirmDeleteModal && (
        <ConfirmAction
          actionText={t('PromptMessages.confirm_delete')}
          cancel={() => showConfirmDeleteModal(false)}
          confirm={() => {
            onDelete(item);
            showConfirmDeleteModal(false);
          }}
        />
      )}
    </>
  );
};

export default PositionDeleteEdit;

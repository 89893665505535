import React from 'react';

import { StyledControl } from './styles';

export const inlineStyles = [
  { label: 'B', style: 'BOLD' },
  { label: 'I', style: 'ITALIC' },
  { label: 'U', style: 'UNDERLINE' },
];

const InlineStyleControls = ({ currentInlineStyle, onToggle }) => {
  return (
    <div>
      {inlineStyles.map((type) => (
        <StyledControl
          style={{
            fontWeight: currentInlineStyle.has(type.style) ? 'bold' : 'normal',
          }}
          key={type.label}
          onMouseDown={(e) => {
            e.preventDefault();
            onToggle(type.style);
          }}
        >
          {type.label}
        </StyledControl>
      ))}
    </div>
  );
};

export default InlineStyleControls;

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
// import {
//   processResponseErrors,
//   errorTypes,
// } from "../../utilize/processResponseErrors";

const initialState = {
  storageData: null,
  storageTree: null,
  isLoading: false,
  isLoadingStorageData: false,
  isLoadingStorageTree: false,
  error: null,
  openFolder: null,
};

export const getStorageData = createAsyncThunk('storage/getStorageData', async (payload, thunkAPI) => {
  try {
    const response = await axios.get(`/api/storage_edit/${payload.storageId}`);

    return response.data.result;
  } catch (e) {
    payload.showSnackbar('Возникла ошибка при получении данных о хранилище');

    return thunkAPI.rejectWithValue('ERROR WHILE FETCHING STORAGE DATA');
  }
});

export const getStorageTree = createAsyncThunk('storage/getStorageTree', async (payload, thunkAPI) => {
  try {
    const response = await axios.get(`/api/storage_tree/${payload.storageId}`);
    return response.data.storage_tree;
  } catch (e) {
    payload.showSnackbar('Возникла ошибка при получении данных о хранилище');

    return thunkAPI.rejectWithValue('ERROR WHILE FETCHING STORAGE TREE');
  }
});

const storageSlice = createSlice({
  name: 'storage',
  initialState,
  reducers: {
    clearState: (state) => {
      state = initialState;
      return state;
    },
    setOpenFolder: (state, { payload }) => {
      state.openFolder = payload.openFolder;
      state.folderPath = payload.folderPath;
    },
  },
  extraReducers: {
    [getStorageData.pending]: (state) => {
      state.isLoading = true;
      state.isLoadingStorageData = true;
    },
    [getStorageData.fulfilled]: (state, { payload }) => {
      state.isLoadingStorageData = false;
      if (!state.isLoadingStorageTree) state.isLoading = false;
      state.storageData = payload;
    },
    [getStorageData.rejected]: (state, { payload }) => {
      state.isLoadingStorageData = false;
      if (!state.isLoadingStorageTree) state.isLoading = false;
      state.error = payload;
    },

    [getStorageTree.pending]: (state) => {
      state.isLoading = true;
      state.isLoadingStorageTree = true;
    },
    [getStorageTree.fulfilled]: (state, { payload }) => {
      state.isLoadingStorageTree = false;
      if (!state.isLoadingStorageData) state.isLoading = false;
      state.storageTree = payload;
    },
    [getStorageTree.rejected]: (state, { payload }) => {
      state.isLoadingStorageTree = false;
      if (!state.isLoadingStorageData) state.isLoading = false;
      state.error = payload;
    },
  },
});

export const { clearState, setOpenFolder } = storageSlice.actions;

export default storageSlice.reducer;

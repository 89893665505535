import { useEffect, useState, useMemo, Fragment } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
// import { rightsTranslation } from "../../../utilize/helper-functions";

import UserProfileModal from '../../../windows/profile/UserProfileModal';

import Preloader from '../../preloaders/Preloader';

// import UserStatus from "../../employees/UserStatus";
import GuestModalCurrent from '../../../windows/profile/GuestModalCurrent';
import CreateEditGuestModal from '../../../windows/profile/CreateEditGuestModal';
import InlineModal from '../../modals/InlineModal';
import useAuth from '../../../hooks/useAuth';

import { renderProjectOrTaskName } from './SideMenu';
import ProjectTree from './ProjectTree';
import AsideUserCard from './sideMenuComponents/AsideUserCard';

const MembersSidePanel = ({ sideMenu, openSideMenu, projectData }) => {
  const { projectId, taskId } = useParams();
  const { t } = useTranslation();
  const auth = useAuth();

  const [panelData, setPanelData] = useState();
  const [projectTree, showProjectTree] = useState(false);
  const [userProfile, showUserProfile] = useState();
  const [guestProfile, showGuestProfile] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [errorLoading, setErrorLoading] = useState(false);
  const fetchData = async (dataType, dataId, title) => {
    setIsLoading(true);
    setErrorLoading(false);
    try {
      const response = await axios.get(`/api/sidebar_members/${dataType}/${dataId}`);
      if (response.data.project) {
        setPanelData({ ...response.data.project, title });
      } else if (response.data.task) {
        setPanelData({ ...response.data.task, title });
      }
    } catch (e) {
      setErrorLoading(true);
    } finally {
      setIsLoading(false);
    }
  };

  // получить данные по участникам, при первоначальном открытии этого сайдбара
  useEffect(() => {
    if (sideMenu === 'people' && !panelData) {
      const dataType = taskId ? 'task' : 'project';

      fetchData(dataType, projectData.id, projectData.title);
    } else if (sideMenu !== 'people' && (panelData || projectTree)) {
      setTimeout(() => {
        setPanelData(null);
        showProjectTree(null);
        setSidebarDataType(taskId ? 'task' : 'project');
      }, 1000);
    }
  }, [sideMenu, projectData, taskId, panelData, projectTree]);

  const [sidebarDataType, setSidebarDataType] = useState(taskId ? 'task' : 'project');

  const showRelevantTaskInfo = (dataType, dataId, title) => {
    showProjectTree(false);
    setSidebarDataType(dataType);
    fetchData(dataType, dataId, title);
  };

  const {
    creator_first_name,
    creator_last_name,
    creator_id,
    responsible_first_name,
    responsible_last_name,
    responsible_id,
    executor_first_name,
    executor_last_name,
    executor_id,
  } = useMemo(() => {
    if (panelData) return panelData;
    return {};
  }, [panelData]);

  const renderMembers = (members) => {
    if (!members || !members.length) return null;
    return (
      <>
        <p className="aside__subtitle">{t('Project.members')}</p>

        {members.map((member, i) => {
          const { first_name, last_name } = member;

          return (
            <AsideUserCard
              key={i}
              userId={member.id}
              lastName={last_name}
              firstName={first_name}
              showUserProfile={showUserProfile}
            />
            // <div className="aside__control-box">
            //   <button className="aside__control aside__control--delete"></button>
            // </div>
          );
        })}
      </>
    );
  };

  const [addGuestModal, showAddGuestModal] = useState();

  return (
    <>
      {/* Показать профиль участника, если нажали на соответствующую ссылку */}
      {userProfile && <UserProfileModal profileId={userProfile} onClose={() => showUserProfile(null)} />}

      {/* Показать профиль участника, если нажали на соответствующую ссылку */}
      {guestProfile && (
        <GuestModalCurrent
          {...guestProfile}
          close={() => showGuestProfile(null)}
          projectData={panelData}
          fetchData={() => fetchData(sidebarDataType, panelData.id, panelData.title)}
        />
      )}

      {addGuestModal && (
        <InlineModal close={() => showAddGuestModal(false)}>
          <CreateEditGuestModal
            dataType={sidebarDataType}
            projectData={panelData}
            fetchData={() => fetchData(sidebarDataType, panelData.id, panelData.title)}
          />
        </InlineModal>
      )}

      <aside data-aside="people" className={`aside ${sideMenu === 'people' ? '_active' : ''}`}>
        <div className="aside__header">
          <span className="aside__title aside__title--profile">{t('Project.members_roles')}</span>
          <button
            className="aside__button-arrow"
            onClick={() => {
              openSideMenu(null);
            }}
          ></button>
        </div>
        <div className="aside__body">
          {projectTree ? (
            <ProjectTree
              update={sideMenu === 'people'}
              showRelevantTaskInfo={showRelevantTaskInfo}
              showProjectTree={showProjectTree}
            />
          ) : (
            <>
              <span className="aside__backward _pointer" onClick={() => showProjectTree(true)}>
                Структура проекта
              </span>

              {renderProjectOrTaskName(panelData, taskId, sidebarDataType, projectData)}

              {sidebarDataType === 'project' && taskId && (
                <Link to={`/projects/${projectId}`} className="aside__link">
                  {t('Project.open_project')}
                </Link>
              )}

              {sidebarDataType === 'task' && panelData && panelData.id !== +taskId && (
                <Link to={`/projects/${projectId}/tasks/${panelData.id}`} className="aside__link">
                  {t('Project.open_task')}
                </Link>
              )}

              {isLoading && <Preloader />}

              {errorLoading && <div>{t('ErrorMessages.default')}</div>}

              {panelData && (
                <>
                  {/* TODO: открыть/закрыть доступ к проекту/задаче */}
                  {/* <div className="aside__access access">
                    <button className="access__button-lock">
                      {t("Project.lock")}
                    </button>
                    <p className="access__description">
                      {t("Project.lock_description")}
                    </p>
                  </div> */}

                  <p className="aside__subtitle">{t('Project.created_by')}</p>
                  <AsideUserCard
                    userId={creator_id}
                    lastName={creator_last_name}
                    firstName={creator_first_name}
                    showUserProfile={showUserProfile}
                  />
                  <p className="aside__subtitle">{t('Project.responsible')}</p>
                  <AsideUserCard
                    userId={responsible_id}
                    lastName={responsible_last_name}
                    firstName={responsible_first_name}
                    showUserProfile={showUserProfile}
                  />
                  {/* <div className="aside__control-box">
                      <button className="aside__control aside__control--edit"></button>
                    </div> */}
                  <p className="aside__subtitle">{t('Project.executor')}</p>
                  <AsideUserCard
                    userId={executor_id}
                    lastName={executor_last_name}
                    firstName={executor_first_name}
                    showUserProfile={showUserProfile}
                  />

                  {renderMembers(panelData.project_members || panelData.members)}

                  {/* <button className="aside__button-add">
                    {t("FormButtons.add")}
                  </button> */}

                  <p className="aside__subtitle">Гости</p>
                  {panelData.guests?.map((guest, i) => (
                    <AsideUserCard
                      key={i}
                      userId={guest.id}
                      firstName={guest.first_name}
                      guest={guest}
                      showUserProfile={(userId, toEdit) =>
                        showGuestProfile({
                          guestId: userId,
                          dataType: sidebarDataType,
                          dataId: panelData.id,
                          toEdit: toEdit,
                        })
                      }
                    />
                  ))}
                  {auth?.user && !auth.user.is_guest && (
                    <button className="aside__button-add" onClick={() => showAddGuestModal(true)}>
                      Добавить
                    </button>
                  )}

                  {/* <p className="aside__subtitle">{t("Project.roles")}</p>
                  <ul className="aside__roles roles">
                    <li className="roles__item roles__item--set">
                      Программист backend
                    </li>
                    <li className="roles__item">Программист frontend</li>
                    <li className="roles__item roles__item--set">Дизайнер</li>
                  </ul>
                  <button className="aside__button-add">
                    {t("FormButtons.add")}
                  </button>
                  <p className="aside__subtitle">
                    {t("Project.roles_history")}
                  </p>
                  <div className="aside__info">
                    <span className="aside__description">
                      {t("Project.replacement")}
                    </span>
                    <span className="aside__description">03.11.2021</span>
                  </div>
                  <p className="aside__position">Программист frontend</p>
                  <div className="aside__row">
                    <span className="aside__preposition">с</span>
                    <Link to="linktoprofile" className="aside__usercard">
                      <img
                        className="aside__userpic"
                        src="assets/images/userpic.png"
                        alt=""
                      />
                      <span className="aside__username">Васильев Василий</span>
                    </Link>
                  </div>
                  <div className="aside__row">
                    <span className="aside__preposition">на</span>
                    <Link to="linktoprofile" className="aside__usercard">
                      <img
                        className="aside__userpic"
                        src="assets/images/userpic.png"
                        alt=""
                      />
                      <span className="aside__username">Алексеев Алексей</span>
                    </Link>
                  </div>
                  <div className="aside__separator"></div>
                  <div className="aside__info">
                    <span className="aside__description">
                      {t("Project.assignment")}
                    </span>
                    <span className="aside__description">03.11.2021</span>
                  </div>
                  <p className="aside__position">Программист backend</p>
                  <Link to="linktoprofile" className="aside__usercard">
                    <img
                      className="aside__userpic"
                      src="assets/images/userpic.png"
                      alt=""
                    />
                    <span className="aside__username">Алексеев Алексей</span>
                  </Link>
                  <div className="aside__separator"></div>
                  <div className="aside__info">
                    <span className="aside__description">
                      {t("Project.replacement")}
                    </span>
                    <span className="aside__description">03.11.2021</span>
                  </div>
                  <p className="aside__position">Исполнитель</p>
                  <div className="aside__row">
                    <span className="aside__preposition">с</span>
                    <Link to="linktoprofile" className="aside__usercard">
                      <img
                        className="aside__userpic"
                        src="assets/images/userpic.png"
                        alt=""
                      />
                      <span className="aside__username">Николаев Николай</span>
                    </Link>
                  </div>
                  <div className="aside__row">
                    <span className="aside__preposition">на</span>
                    <Link to="linktoprofile" className="aside__usercard">
                      <img
                        className="aside__userpic"
                        src="assets/images/userpic.png"
                        alt=""
                      />
                      <span className="aside__username">Петров Петр</span>
                    </Link>
                  </div>
                  <div className="aside__separator"></div>
                  <div className="aside__info">
                    <span className="aside__description">
                      {t("Project.removal")}
                    </span>
                    <span className="aside__description">03.11.2021</span>
                  </div>
                  <p className="aside__position">Программист backend</p>
                  <Link to="linktoprofile" className="aside__usercard">
                    <img
                      className="aside__userpic"
                      src="assets/images/userpic.png"
                      alt=""
                    />
                    <span className="aside__username">Иванов Иван</span>
                  </Link> */}
                </>
              )}
            </>
          )}
        </div>
      </aside>
    </>
  );
};

export default MembersSidePanel;

function NotFound() {
  return (
    <div>
      {' '}
      Page not found.
      <ul>
        <li>Page not found.</li>
        <li>Page not found.</li>
        <li>Page not found.</li>
        <li>Page not found.</li>
        <li>Page not found.</li>
        <li>Page not found.</li>
      </ul>
    </div>
  );
}

export default NotFound;

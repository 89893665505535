import React from 'react';

import Preloader from '../../../components/preloaders/Preloader';
import { useEventTriggerOnEscPress } from '../../../utilize/helper-functions';

import DepartmentItem from './DepartmentItem';

const DepartmentList = ({ departments, onEdit, onDelete, onCloseDepartmentList, hasRightToEdit }) => {
  useEventTriggerOnEscPress(onCloseDepartmentList);

  return (
    <>
      {!departments && <Preloader />}
      {departments?.map((item, i) => (
        <DepartmentItem key={i} item={item} onDelete={onDelete} onEdit={onEdit} hasRightToEdit={hasRightToEdit} />
      ))}
    </>
  );
};

export default DepartmentList;

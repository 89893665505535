import { useCallback, useEffect, useRef, useState, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { useMessageContext } from '../../contexts/MessageContext';
import useAuth from '../../hooks/useAuth';
import InlineModal from '../modals/InlineModal';
import CreateTaskModal from '../tasks/CreateTaskModal';

const MessageTextSelectionPopoverContainer = (props) => {
  const { popover, showPopover, setQuoteMessage, guestRights } = useMessageContext();

  return (
    <MessageTextSelectionPopover
      {...props}
      popover={popover}
      showPopover={showPopover}
      setQuoteMessage={setQuoteMessage}
      guestRights={guestRights}
    />
  );
};

const MessageTextSelectionPopover = memo(
  ({ popover, showPopover, setQuoteMessage, guestRights, hideProjectOptions }) => {
    const auth = useAuth();
    const { projectId, taskId } = useParams();

    const popoverRef = useRef();
    const { t } = useTranslation();

    // при нажатии на кнопку Ответить цитированием, собрать данные по выделенному тексту и сообщению
    const replyByQuoting = () => {
      const selection = window.getSelection();
      const quoteText = selection.toString();

      selection.collapseToEnd();
      selection.collapseToEnd();

      const quoteData = {
        messageId: popover.data.reply_id,
        author: popover.data.author,
        dateTime: popover.data.date,
        quoteText: quoteText,
      };

      //
      setQuoteMessage(quoteData);
      showPopover({
        show: false,
        x: null,
        y: null,
        data: null,
      });
    };

    const hidePopover = useCallback(
      (event) => {
        if (popover?.show && !popoverRef.current?.contains(event.target)) {
          showPopover({
            show: false,
            x: null,
            y: null,
            data: null,
          });
        }
      },
      [popover, showPopover],
    );

    useEffect(() => {
      document.addEventListener('mousedown', hidePopover);

      const cleanup = () => {
        document.removeEventListener('mousedown', hidePopover);
      };
      return cleanup;
    }, [hidePopover]);

    const [createTaskModal, showCreateTaskModal] = useState(false);
    return (
      <>
        {popover?.show && (
          <section
            ref={popoverRef}
            style={{
              left: `${popover.x}px`,
              top: `${popover.y}px`,
              position: 'absolute',
            }}
            className={'chat-menu active'}
          >
            {auth?.user && (!auth.user.is_guest || guestRights?.includes('write')) && (
              <button className="chat-menu__item" onClick={replyByQuoting}>
                {t('FormButtons.quote')}
              </button>
            )}

            {!hideProjectOptions && auth?.user && !auth.user.is_guest && (
              <button
                className="chat-menu__item"
                onClick={() => {
                  showCreateTaskModal(true);
                  showPopover((p) => ({ ...p, show: false }));
                }}
              >
                {t('FormButtons.create_subtask_for_this')}
              </button>
            )}

            <button
              className="chat-menu__item"
              onClick={() => {
                try {
                  const selection = window.getSelection();
                  navigator.clipboard.writeText(selection.toString());
                  showPopover((p) => ({ ...p, show: false }));
                } catch (e) {}
              }}
            >
              {t('FormButtons.copy')}
            </button>
          </section>
        )}

        {createTaskModal && (
          <InlineModal close={() => showCreateTaskModal(false)}>
            <CreateTaskModal
              messageBasis={{
                message_id: popover.data.reply_id,
                author: popover.data.author,
                plainText: window.getSelection().toString(),
                LocalDateTime: popover.data.date,
              }}
              projectId={projectId}
              parentTaskId={taskId}
            />
          </InlineModal>
        )}
      </>
    );
  },
);
export default MessageTextSelectionPopoverContainer;

import { useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import ModalHeader from '../modals/modal-header';

import ConfirmAction from '../warnings/ConfirmAction';

import TagsListModal from './TagsListModal';
import AddTagModal from './AddTagModal';

const TagsModal = ({ checkInputsAndClose }) => {
  const [addTagModal, toggleAddTagModal] = useState(false);
  const { t } = useTranslation();
  const isChanged = useRef(false);
  const [modalClosePrompt, setModalClosePrompt] = useState(false);

  return (
    <>
      <section className="modal__body">
        <ModalHeader
          addDepartment={!addTagModal}
          title={t(addTagModal ? 'Tags.create_tag' : 'common.tags')}
          onAdd={() => toggleAddTagModal(true)}
          onClose={checkInputsAndClose}
          hasRightToEdit
        />
        {addTagModal ? (
          <AddTagModal
            onCancel={() => toggleAddTagModal(false)}
            close={checkInputsAndClose}
            isChanged={isChanged}
            setModalClosePrompt={setModalClosePrompt}
          />
        ) : (
          <TagsListModal close={checkInputsAndClose} />
        )}
        {/* <div className="modal__header">
        <h2 className="modal__title">{t("Tags.create_tag")}</h2>
        <button
          className="modal__close-modal"
          onClick={checkInputsAndClose}
        ></button>
      </div> */}
      </section>

      {modalClosePrompt && (
        <ConfirmAction
          actionText={t('common.confirm_modal_close')}
          cancel={() => setModalClosePrompt(false)}
          confirm={() => {
            toggleAddTagModal(false);
            setModalClosePrompt(false);
          }}
        />
      )}
    </>
  );
};

export default TagsModal;

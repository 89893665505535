import React from 'react';

import { useTranslation } from 'react-i18next';

import { useMemo } from 'react';

import Preloader from '../preloaders/Preloader';

import { useEventTriggerOnEscPress } from '../../utilize/helper-functions';

import useAuth from '../../hooks/useAuth';

import PositionsHeaderAdd from './PositionsHeaderAdd';
import PositionDeleteEdit from './PositionDeleteEdit';

const PositionsList = (props) => {
  const { t } = useTranslation();
  const { list, onDelete, onEdit, onClose, onAdd } = props;

  const { isUserRightful, rightTypes } = useAuth();

  const hasRightToEdit = useMemo(() => isUserRightful(rightTypes.positions_edit), [isUserRightful, rightTypes]);

  useEventTriggerOnEscPress(onClose);

  return (
    <section className="modal">
      {/* {props.children} */}
      <div className="modal__wrapper">
        <div className="modal__inner">
          <section className="modal__body">
            <PositionsHeaderAdd
              addDepartment
              title={t('Positions.positions')}
              //   title={t("TeamHeader.depatrments")}
              onClose={onClose}
              onAdd={onAdd}
              hasRightToEdit={hasRightToEdit}
            />

            {!list && <Preloader />}

            <div className="modal__grid modal-grid modal-grid--three">
              {props.children}

              {list?.map((item, i) => (
                <PositionDeleteEdit
                  key={i}
                  item={item}
                  onDelete={onDelete}
                  onEdit={onEdit}
                  hasRightToEdit={hasRightToEdit}
                />
              ))}
            </div>
          </section>
        </div>
      </div>
    </section>
  );
};

export default PositionsList;

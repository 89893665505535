import { memo } from 'react';
import { useTranslation } from 'react-i18next';

const SideMenuTags = ({ tags }) => {
  const { t } = useTranslation();

  if (tags && tags.length) {
    return (
      <>
        <p className="aside__subtitle">{t('common.tags')}</p>
        {tags.map((tag, i) => {
          return (
            <button className={`aside__tag tag tag--icon-${tag.color}`} key={i}>
              {tag.name}
            </button>
          );
        })}
        {/* <button className="aside__tag tag tag--active">тэг</button> */}
      </>
    );
  }
  return null;
};

export default memo(SideMenuTags);

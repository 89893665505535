import { useState, useMemo, useEffect, useContext, useCallback } from 'react';
import axios from 'axios';
import { useParams, useLocation, Link } from 'react-router-dom';

import useAuth from '../../hooks/useAuth';
import CreateStorageModal from '../../windows/storage/CreateStorageModal';
import Preloader from '../../components/preloaders/Preloader';
import SnackbarContext from '../../contexts/SnackbarContext';
import StorageModal from '../../windows/storage/StorageModal';

const StorageList = () => {
  const [storagesList, setStoragesList] = useState([]);
  const [createStorageModal, setCreateStorageModal] = useState();
  const auth = useAuth();
  const location = useLocation();
  const { storageId } = useParams();

  const userHasRight = useMemo(() => {
    if (auth?.isUserRightful) return auth.isUserRightful('create_storage');
  }, [auth]);

  const [isLoading, setIsLoading] = useState(true);

  const { showSnackbar } = useContext(SnackbarContext);

  const getStoragesList = useCallback(() => {
    axios
      .get('/api/storages_list')
      .then((r) => {
        setStoragesList(r.data.result);
      })
      .catch(() => {
        showSnackbar('Возникла ошибка при загрузке списка хранилищ');
      })
      .finally(() => setIsLoading(false));
  }, [showSnackbar]);

  useEffect(() => {
    getStoragesList();
  }, [getStoragesList]);

  return (
    <>
      <main>
        <section className="project-list">
          <div className="project-list__container container">
            <section className="project-list__header">
              {userHasRight && (
                <button className="project-list__button" onClick={() => setCreateStorageModal(true)}>
                  Создать хранилище
                </button>
              )}
            </section>
            {/* <form className="project-list__filter project-filter">
              <div className="project-filter__col project-filter__tags">
                <span className="project-filter__tag tag">тэг</span>
                <span className="project-filter__tag tag tag--icon-red">
                  тэг
                </span>
                <span className="project-filter__tag tag tag--icon-yellow">
                  тэг
                </span>
                <span className="project-filter__tag tag tag--icon-blue">
                  тэг
                </span>
                <span className="project-filter__tag tag tag--icon-green">
                  тэг
                </span>
                <span className="project-filter__tag tag tag--active">
                  активный тэг
                </span>
                <span className="project-filter__tag tag tag--active">
                  активный тэг
                </span>
                <span className="project-filter__tag tag tag--active">
                  активный тэг
                </span>
                <span className="project-filter__tag tag tag--active">
                  активный тэг
                </span>
                <span className="project-filter__tag tag tag--active">
                  активный тэг
                </span>
                <span className="project-filter__tag tag tag--active">
                  активный тэг
                </span>
                <span className="project-filter__tag tag tag--active">
                  активный тэг
                </span>
                <span className="project-filter__tag tag tag--active">
                  активный тэг
                </span>
                <span className="project-filter__tag tag tag--icon-red">
                  тэг
                </span>
                <span className="project-filter__tag tag">тэг</span>
                <span className="project-filter__tag tag">тэг</span>
              </div>
            </form> */}
            {isLoading && <Preloader />}
            <div className="project-list__cards">
              {storagesList.map((storage, i) => (
                <Link
                  to={`/storages/${storage.id}`}
                  state={{ from: location }}
                  key={i}
                  className="project-list__card project-card"
                  // onClick={() =>
                  //   navigate(`/storages/${storage.id}`, {
                  //     state: { from: location },
                  //   })
                  // }
                >
                  <div className="project-card__header">
                    <span>{storage.project_id ? 'К проекту' : 'Самостоятельное'}</span>
                  </div>
                  <p className="project-card__title">{storage.title}</p>
                  <p className="project-card__description">{storage.description} &nbsp;</p>
                  <ul className="project-card__file-list">
                    {/* <li className="project-card__file-item">
                      <span
                        className="project-card__file-link project-card__file-icon project-card__file-icon--folder"
                        // href="#link-to-files"
                      >
                        12
                      </span>
                    </li>
                    <li className="project-card__file-item">
                      <span
                        className="project-card__file-link project-card__file-icon project-card__file-icon--file"
                        // href="#link-to-files"
                      >
                        12
                      </span>
                    </li> */}
                  </ul>
                  {/* <span className="project-card__staff">
                    Последнее обновление: 25.02.2023
                  </span> */}
                  <div>
                    {storage.storage_tags.map((tag, i) => (
                      <span key={i} className={`project-card__tag tag tag--icon-${tag.color}`}>
                        {tag.name}
                      </span>
                    ))}
                  </div>
                </Link>
              ))}
              {/* <div className="project-list__card project-card">
                <div className="project-card__header">
                  <span>Самостоятельное</span>
                </div>
                <p className="project-card__description">
                  Строим здание, тут может быть какое-то большое описание
                  проекта
                </p>
                <ul className="project-card__file-list">
                  <li className="project-card__file-item">
                    <a
                      className="project-card__file-link project-card__file-icon project-card__file-icon--folder"
                      href="#link-to-files"
                    >
                      12
                    </a>
                  </li>
                  <li className="project-card__file-item">
                    <a
                      className="project-card__file-link project-card__file-icon project-card__file-icon--file"
                      href="#link-to-files"
                    >
                      12
                    </a>
                  </li>
                </ul>
                <span className="project-card__staff">
                  Последнее обновление: 25.02.2023
                </span>
                <div>
                  <span className="project-card__tag tag">тэг</span>
                  <span className="project-card__tag tag">тэг</span>
                </div>
              </div> */}
              {/* <div className="project-list__card project-card">
                <div className="project-card__header">
                  <a
                    className="project-card__project-link"
                    href="#link-to-project"
                  >
                    К проекту
                  </a>
                  <span className="project-card__status project-card__status--green">
                    В работе
                  </span>
                </div>
                <p className="project-card__description">
                  Строим здание, тут может быть какое-то большое описание
                  проекта
                </p>
                <ul className="project-card__file-list">
                  <li className="project-card__file-item">
                    <a
                      className="project-card__file-link project-card__file-icon project-card__file-icon--folder"
                      href="#link-to-files"
                    >
                      12
                    </a>
                  </li>
                  <li className="project-card__file-item">
                    <a
                      className="project-card__file-link project-card__file-icon project-card__file-icon--file"
                      href="#link-to-files"
                    >
                      12
                    </a>
                  </li>
                </ul>
                <span className="project-card__staff">Ответственный</span>
                <div className="project-card__user">
                  <img
                    className="project-card__userpic"
                    src="/../assets/images/userpic.png"
                    alt=""
                  />
                  <span className="project-card__username">Никита Родин</span>
                </div>
                <div className="project-card__user">
                  <img
                    className="project-card__userpic"
                    src="/../assets/images/userpic.png"
                    alt=""
                  />
                  <span className="project-card__username">Никита Родин</span>
                </div>
                <div className="project-card__user">
                  <img
                    className="project-card__userpic"
                    src="/../assets/images/userpic.png"
                    alt=""
                  />
                  <span className="project-card__username">Никита Родин</span>
                </div>
                <span className="project-card__staff">
                  Последнее обновление: 25.02.2023
                </span>
                <div>
                  <span className="project-card__tag tag">тэг</span>
                  <span className="project-card__tag tag">тэг</span>
                </div>
              </div> */}
            </div>
            {/* <div className="project-list__pagination pagination">
              <button
                className="pagination__button pagination__button--prev"
                disabled="disabled"
              ></button>
              <button className="pagination__item pagination__item--active">
                1
              </button>
              <button className="pagination__item">2</button>
              <button className="pagination__item">3</button>
              <button className="pagination__item">4</button>
              <button className="pagination__item">5</button>
              <button className="pagination__button pagination__button--next"></button>
            </div> */}
          </div>
        </section>
      </main>

      {createStorageModal && <CreateStorageModal close={() => setCreateStorageModal(false)} />}

      {storageId && <StorageModal storageId={storageId} />}
    </>
  );
};

export default StorageList;

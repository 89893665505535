import axios from 'axios';
import { useContext } from 'react';
import { useState, useRef, useCallback } from 'react';
import { useDispatch } from 'react-redux';

// import { useParams } from "react-router-dom";
import SnackbarContext from '../../contexts/SnackbarContext';
import { getStorageTree } from '../../redux/features/storageSlice';
import { convertBytes, doubleClickHandle, useOutsideTrigger } from '../../utilize/helper-functions';
import { errorTypes, processResponseErrors } from '../../utilize/processResponseErrors';
import { formatDateWithDots } from '../calendar-form/CalendarForm';
import ConfirmAction from '../warnings/ConfirmAction';

import FolderVersionsModal from './FolderVersionsModal';
import RenameModal from './RenameModal';
import TransferCopyFilesModal from './TransferCopyFilesModal';

const FolderDetailsRow = ({
  storageId,
  folder,
  renderUserName,
  handleFolderSelect,
  modifiedFolderOpen, // optional
  versionsModal, // optional - для индикации что открыто модальное окно версий
  openTrashStack, // optional - используется, когда папка в корзине, для отслеживания дерева открытых папок
  removeItem, // optional - используется, когда папка в корзине, для удаления элемента из дерева открытых папок
}) => {
  //
  // const { storageId } = useParams();
  const [openFolderMenu, setOpenFolderMenu] = useState('');

  const folderMenuBtn = useRef();
  const folderMenu = useRef();
  const refsArray = useRef([folderMenuBtn, folderMenu]);

  const outsideClickEvent = useCallback(() => setOpenFolderMenu(''), []);

  useOutsideTrigger(refsArray.current, outsideClickEvent, openFolderMenu);

  // const navigate = useNavigate();

  const [openFolderVersions, setOpenFolderVersions] = useState();
  const [transferCopyModal, setTransferCopyModal] = useState();
  const [renameModal, setRenameModal] = useState();

  const [isSubmitting, setIsSubmitting] = useState();
  const [confirmTrashing, setConfirmTrashing] = useState();
  const [confirmDelete, setConfirmDelete] = useState();
  const { showSnackbar } = useContext(SnackbarContext);

  const dispatch = useDispatch();

  const sendToTrash = () => {
    setIsSubmitting(true);
    axios
      .patch('/api/storage_folders/to_trash', {
        folder_id: folder.id,
      })
      .then(() => {
        dispatch(getStorageTree({ storageId, showSnackbar }));
        setConfirmTrashing(false);
        setIsSubmitting(false);
      })
      .catch(() => {
        showSnackbar('Возникла ошибка при удалении папки в корзину');
        setIsSubmitting(false);
      });
  };

  const deleteFolder = () => {
    setIsSubmitting(true);
    axios
      .patch('/api/storage_folders/remove', {
        folder_id: folder.id,
      })
      .then(() => {
        removeItem({ dataType: 'folders', dataId: folder.id });
        setConfirmDelete(false);
      })
      .catch(() => {
        showSnackbar('Возникла ошибка при удалении папки');
      });
  };

  const restoreFolder = () => {
    axios
      .patch('/api/restore_from_trash', {
        folder_id: folder.id,
      })
      .then(() => {
        removeItem({ dataType: 'folders', dataId: folder.id });
        showSnackbar('Папка восстановлена', 'success');
        dispatch(getStorageTree({ storageId, showSnackbar }));
      })
      .catch((e) => {
        const errorType = processResponseErrors(e);

        if (errorType === errorTypes.NO_PARENT_FOLDER) {
          showSnackbar('Ошибка при восстановлении: родительская папка была удалена');
        } else {
          showSnackbar('Возникла ошибка при восстановлении файла');
        }
      });
  };

  const handleFolderDownload = () => {
    if (openTrashStack) {
      showSnackbar('Идет архивация папки для скачивания...', 'success');
      axios
        .get(`/api/download_folder_from_trash/${folder.id}`, {
          responseType: 'blob',
          timeout: 30000,
        })
        .then((r) => {
          const blob = r.data;
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = url;

          a.download = folder.title;
          a.style.display = 'none';
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(url);
        })
        .catch(() => {
          showSnackbar('Возникла ошибка при скачивании папки');
        });
    }
  };

  return (
    <>
      <li
        className="storage__catalog-folder catalog-folder _no_selection"
        // компонент FolderDetailsRow используется в двух местах:
        // 1) в открытой актуальной папке 2) в открытой папке прошлых версий
        // В зависимости от вида открытой папки, использовать соответствующий функционал по открытию подпапки
        onClick={() => {
          // открыть выбранную папку, если эта папка открывается в модальном окне версий
          if (versionsModal || openTrashStack) modifiedFolderOpen();
          else {
            // при двойном клике открыть папку
            // при одиночном клике открыть версии папки
            doubleClickHandle(
              () => handleFolderSelect(folder.id),
              () => setOpenFolderVersions(true),
            );
          }
        }}
      >
        <p className="catalog-folder__name">{folder.title}</p>
        <p className="catalog-folder__weight">{convertBytes(folder.size)}</p>
        <p className="catalog-folder__date">{formatDateWithDots(folder.date_created)}</p>

        {renderUserName(folder.creator_id)}
        {/* {users?.find(user=>user.id === folder.creator_id)?} */}

        <button
          ref={folderMenuBtn}
          className="catalog-folder__button-dots"
          onClick={(e) => {
            e.stopPropagation();
            setOpenFolderMenu(openFolderMenu ? '' : 'active');
          }}
        ></button>
        <section ref={folderMenu} className={`catalog-folder__menu chat-menu ${openFolderMenu}`}>
          {/* не показывать эту кнопку если окно версий уже открыто */}
          {!versionsModal && (
            <p
              className="chat-menu__item"
              onClick={(e) => {
                e.stopPropagation();
                setOpenFolderVersions(true);
                setOpenFolderMenu('');
              }}
            >
              История версий
            </p>
          )}
          {/* показать кнопку скачивания, если это Корзина (реализовано только в коризне)  */}
          {openTrashStack && (
            <p
              className="chat-menu__item"
              onClick={(e) => {
                e.stopPropagation();
                handleFolderDownload();
                setOpenFolderMenu('');
              }}
            >
              Скачать
            </p>
          )}

          {/* Функционал, когда папка не в корзине */}
          {!openTrashStack && (
            <>
              <p
                className="chat-menu__item"
                onClick={(e) => {
                  e.stopPropagation();
                  setTransferCopyModal({ mode: 'transfer' });
                  setOpenFolderMenu('');
                }}
              >
                Перенести в папку этого хранилища
              </p>
              <p
                className="chat-menu__item"
                onClick={(e) => {
                  e.stopPropagation();
                  setTransferCopyModal({ mode: 'copy' });
                  setOpenFolderMenu('');
                }}
              >
                Скопировать в другое хранилище
              </p>
              {!versionsModal && (
                <p
                  className="chat-menu__item"
                  onClick={(e) => {
                    e.stopPropagation();
                    setRenameModal(true);
                    setOpenFolderMenu('');
                  }}
                >
                  Переименовать
                </p>
              )}
              <p
                className="chat-menu__item chat-menu__item--delete"
                onClick={(e) => {
                  e.stopPropagation();
                  setConfirmTrashing(true);
                  setOpenFolderMenu('');
                }}
              >
                Удалить в корзину
              </p>
            </>
          )}
          {openTrashStack && (
            <>
              <p
                className="chat-menu__item"
                onClick={(e) => {
                  e.stopPropagation();
                  restoreFolder();
                  setOpenFolderMenu('');
                }}
              >
                Восстановить
              </p>
              <p className="chat-menu__separator"></p>
              <p
                className="chat-menu__item chat-menu__item--delete"
                onClick={(e) => {
                  e.stopPropagation();
                  setConfirmDelete(true);
                  setOpenFolderMenu('');
                }}
              >
                Удалить навсегда
              </p>
            </>
          )}
        </section>
      </li>

      {openFolderVersions && (
        <FolderVersionsModal
          currentFolderData={folder}
          close={() => setOpenFolderVersions(false)}
          renderUserName={renderUserName}
        />
      )}

      {renameModal && (
        <RenameModal
          close={() => setRenameModal(false)}
          dataType="folder"
          dataId={folder.id}
          oldTitle={folder.title}
          storageId={storageId}
        />
      )}

      {confirmTrashing && (
        <ConfirmAction
          actionText="Вы уверены что хотите удалить эту папку в корзину?"
          cancel={() => setConfirmTrashing(false)}
          confirm={sendToTrash}
          isSubmitting={isSubmitting}
        />
      )}

      {confirmDelete && (
        <ConfirmAction
          actionText="Вы уверены что хотите полностью удалить эту папку?"
          cancel={() => setConfirmDelete(false)}
          confirm={deleteFolder}
          isSubmitting={isSubmitting}
        />
      )}

      {transferCopyModal && (
        <TransferCopyFilesModal
          close={() => setTransferCopyModal(false)}
          data={folder}
          type="folder"
          mode={transferCopyModal.mode}
          renderUserName={renderUserName}
          storageId={storageId}
        />
      )}
    </>
  );
};

export default FolderDetailsRow;

import { useRef, useState, useContext, useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import ModalBottom from '../modals/modal-bottom';
import { addTag, clearErrors } from '../../redux/features/tagsSlice';
import SnackbarContext from '../../contexts/SnackbarContext';
import useAuth from '../../hooks/useAuth';
import { useEventTriggerOnEscPress } from '../../utilize/helper-functions';

const AddTagModal = ({ onCancel, isChanged, setModalClosePrompt }) => {
  const auth = useAuth();

  const { showSnackbar } = useContext(SnackbarContext);

  const inputRef = useRef();
  const dispatch = useDispatch();
  const { error, isSending } = useSelector((state) => state.tags);
  const { t } = useTranslation();

  const [tagName, setTagName] = useState('');
  const [color, setColor] = useState('green');

  const [dropdown, toggleDropdown] = useState('');

  const selectColor = (e) => setColor(e.currentTarget.dataset.option);

  const clearOnSuccess = () => {
    showSnackbar('Новый тэг добавлен', 'success');
    setTagName('');
    inputRef.current.focus();
  };

  const onCreate = async () => {
    if (auth?.user && tagName && color) {
      dispatch(
        addTag({
          data: {
            name: tagName,
            color: color,
          },
          clear: clearOnSuccess,
        }),
      );
    }
  };

  useEffect(() => {
    dispatch(clearErrors());
  }, [dispatch]);

  useEffect(() => {
    if (tagName) {
      isChanged.current = true;
    }
  }, [tagName, isChanged]);

  const handleClose = () => {
    if (tagName) {
      setModalClosePrompt(true);
    } else onCancel();
  };

  useEventTriggerOnEscPress(handleClose);

  return (
    <>
      <div className="modal__row modal__row--start">
        <div
          className={`modal__dropdown dropdown dropdown--tag ${dropdown}`}
          onClick={() => toggleDropdown(dropdown ? '' : '_show')}
        >
          <div className="dropdown__input">
            <span className={`dropdown__tag dropdown__tag--${color}`}></span>
          </div>
          <ul className="dropdown__select select select--relative">
            <li className="select__item" data-option="red" onClick={selectColor}>
              <span className="dropdown__tag dropdown__tag--red"></span>
            </li>
            <li className="select__item" data-option="green" onClick={selectColor}>
              <span className="dropdown__tag dropdown__tag--green"></span>
            </li>
            <li className="select__item" data-option="yellow" onClick={selectColor}>
              <span className="dropdown__tag dropdown__tag--yellow"></span>
            </li>
            <li className="select__item" data-option="grey" onClick={selectColor}>
              <span className="dropdown__tag dropdown__tag--grey"></span>
            </li>
          </ul>
        </div>
        <input
          ref={inputRef}
          className="modal__input"
          id="name"
          placeholder={t('Tags.new_tag_name')}
          value={tagName}
          onChange={(e) => setTagName(e.target.value)}
          autoComplete="off"
        />
      </div>

      {error && <div className="modal__error-text">{error}</div>}

      <ModalBottom isValid={tagName} onCreate={onCreate} onClose={handleClose} isSending={isSending} />
    </>
  );
};

export default AddTagModal;

import styled, { css } from 'styled-components';

export const StyledStatus = styled.div`
  padding: 1px 8px;
  width: max-content;
  border-radius: 2px;
  font-size: 12px;
  line-height: 20px;
  border: 1px solid #91d5ff;
  background-color: ${({ theme }) => theme.lightest};
  color: ${({ theme }) => theme.main};

  ${(props) =>
    props.color === 'red' &&
    css`
      background-color: ${({ theme }) => theme.errorDark};
      border: 1px solid ${({ theme }) => theme.error};
      color: ${({ theme }) => theme.lightest};
    `}

  ${(props) =>
    props.color === 'green' &&
    css`
      background-color: ${({ theme }) => theme.success};
      border: 1px solid ${({ theme }) => theme.successDark};
      color: ${({ theme }) => theme.lightest};
    `}

    ${(props) =>
    props.color === 'yellow' &&
    css`
      background-color: ${({ theme }) => theme.yellow};
      border: 1px solid ${({ theme }) => theme.yellow};
      color: ${({ theme }) => theme.lightest};
    `}
`;

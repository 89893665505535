import React from 'react';
import { useState } from 'react';
// import { Link } from 'react-router-dom';

import UserStatus from '../employees/UserStatus';

const linkList = ['hello'];

const Starred = ({ id, handler, classIcon, profile, userId, firstName, lastName }) => {
  const [isOpen, setIsOpen] = useState();

  const basicClass = profile ? 'user-nav__item user-nav__item--profile' : 'user-nav__item';
  return (
    <div key={id} data-menu className={isOpen ? basicClass + ' _active' : basicClass}>
      <div
        onMouseEnter={() => setIsOpen(true)}
        onMouseLeave={() => setIsOpen(false)}
        className="user-nav__link user-nav__link--star"
      >
        {profile && <UserStatus userId={userId} firstName={firstName} lastName={lastName} />}
      </div>

      <div className={isOpen === id ? 'user-nav__inner-list z-index' : 'user-nav__inner-list'}>
        {/* <>
          {Object.keys(linkList).map((oneKey, i) => {
            if (linkList[oneKey].handler) {
              return (
                <div key={i}>
                  <div
                    onClick={() => {
                      linkList[oneKey].handler();
                    }}
                    className="user-nav__link"
                  >
                    {oneKey}
                  </div>
                  {linkList[oneKey].separator && <span className="separator"></span>}
                </div>
              );
            } else {
              return (
                <Link to={linkList[oneKey].to} key={linkList[oneKey]} className="user-nav__link">
                  {oneKey}
                </Link>
              );
            }
          })}
        </> */}
      </div>
    </div>
  );
};
export default Starred;

import { useEffect, useContext, useState, useCallback, createContext } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import ReactHTMLParser from 'html-react-parser';
import DropFilesWrapper from 'src/components/drag-field/DropFilesWrapper';
import DropFoldersAndFilesModal from 'src/components/storage/DropFoldersAndFilesModal';

import { useEventTriggerOnEscPress } from '../../utilize/helper-functions';
import StoragesDropdown from '../../components/storage/StoragesDropdown';
import Preloader from '../../components/preloaders/Preloader';
import SnackbarContext from '../../contexts/SnackbarContext';
import { clearState, getStorageData, getStorageTree, setOpenFolder } from '../../redux/features/storageSlice';
import AddFilesModal from '../../components/storage/AddFilesModal';
import AddFolderModal from '../../components/storage/AddFolderModal';
import StorageTree from '../../components/storage/StorageTree';
import StorageContent from '../../components/storage/StorageContent';

import CreateStorageModal from './CreateStorageModal';

const StorageContext = createContext({
  addStorageFileReference: null,
});

export const useStorageContext = () => {
  const context = useContext(StorageContext);

  if (context) {
    return context;
  }
  throw new Error('useMessageContext must be used within a MessageContextProvider');
};

const StorageModal = ({ storageId, close, noURLParams, addStorageFileReference }) => {
  const [storageIdToReq, setStorageIdToReq] = useState(storageId);
  const [openFolderId, setOpenFolderId] = useState();

  const { showSnackbar } = useContext(SnackbarContext);
  const params = useParams();

  // при изменении URL параметра, обновить storageid и folderId в state, эти id в дальнейшем используются для отправки запросов
  useEffect(() => {
    if (params?.storageId || params?.folderId) {
      setStorageIdToReq(params.storageId);
      setOpenFolderId(params.folderId);
    }
  }, [params]);

  const location = useLocation();

  const navigate = useNavigate();
  const { storageData, isLoading, storageTree } = useSelector((state) => state.storage);

  const [editStorageModal, setEditStorageModal] = useState();
  const [addFolderModal, setAddFolderModal] = useState();
  const [addFilesModal, setAddFilesModal] = useState();

  const handleClose = () => {
    if (editStorageModal || addFolderModal || addFolderModal || addFilesModal) {
      return;
    }
    if (typeof close === 'function') {
      close();
      return;
    }
    navigate(location?.state?.from?.pathname || '/storages');
  };

  useEventTriggerOnEscPress(handleClose);

  const dispatch = useDispatch();

  const getData = useCallback(() => {
    dispatch(getStorageData({ storageId: storageIdToReq, showSnackbar }));
    dispatch(getStorageTree({ storageId: storageIdToReq, showSnackbar }));
  }, [showSnackbar, storageIdToReq, dispatch]);

  //reset state при первоначальном рендере
  useEffect(() => {
    dispatch(clearState());
  }, [dispatch]);

  // получение данных о хранилище
  useEffect(() => {
    if (!storageIdToReq) return;
    if (!storageData?.storage || storageData?.storage.id !== +storageIdToReq) {
      getData();
    }
  }, [storageIdToReq, storageData, getData]);

  const handleAnotherStorageSelect = useCallback(
    (storageData) => {
      if (location?.pathname.includes('storages')) {
        navigate(`/storages/${storageData.id}`);
      } else if (typeof close === 'function') {
        setStorageIdToReq(storageData.id);
      }
    },
    [navigate, location, close],
  );

  const handleFolderSelect = useCallback(
    (folderId) => {
      if (noURLParams) return setOpenFolderId(folderId);
      navigate(`/storages/${params.storageId}/folder/${folderId}`);
    },
    [navigate, noURLParams, params],
  );

  const [droppedItems, setDroppedItems] = useState();

  return (
    <>
      <section className="modal">
        <div className="modal__wrapper modal__wrapper--no-padding">
          <div className="modal__inner modal__inner--xl">
            <DropFilesWrapper setDroppedItems={setDroppedItems}>
              <section className="modal__body">
                <div className="modal__header-storage">
                  <div className="modal__title-wrapper">
                    {/* <Link to={`/storages/${storageIdToReq}`}> */}
                    <span
                      className="modal__title _pointer"
                      onClick={() => {
                        if (noURLParams) {
                          setOpenFolderId(null);
                          dispatch(setOpenFolder({ openFolder: storageTree, path: null }));
                          return;
                        }
                        setOpenFolderId(null);
                        navigate(`/storages/${storageIdToReq}`);
                      }}
                    >
                      {storageData?.storage.title}
                    </span>
                    {/* </Link> */}
                    <button className="modal__button-info"></button>
                    <div className="modal__project-card project-card">
                      <div className="project-card__description">
                        {storageData?.storage.description ||
                          (typeof storageData?.project_description === 'string'
                            ? ReactHTMLParser(storageData?.project_description)
                            : '')}
                      </div>
                      <div>
                        {storageData?.storage.storage_tags.map((tag, i) => (
                          <span key={i} className={`project-card__tag tag tag--icon-${tag.color}`}>
                            {tag.name}
                          </span>
                        ))}
                      </div>
                      <button
                        className="subtask__controls subtask__controls--start _mt-sm"
                        onClick={() => setEditStorageModal(true)}
                      >
                        Редактировать
                      </button>
                    </div>
                  </div>
                  <StoragesDropdown handleAnotherStorageSelect={handleAnotherStorageSelect} />
                  {/* <div className="modal__search-input-wrapper">
                  <input className="modal__search-input" type="text" />
                  <button className="modal__search-button"></button>
                </div> */}

                  <button className="modal__close-modal" onClick={handleClose}></button>
                </div>
                {isLoading && <Preloader />}
                {storageData && (
                  <section className="storage">
                    <StorageTree openFolderId={openFolderId} handleFolderSelect={handleFolderSelect} />
                    <StorageContext.Provider value={{ addStorageFileReference }}>
                      <StorageContent
                        setAddFilesModal={setAddFilesModal}
                        setAddFolderModal={setAddFolderModal}
                        handleFolderSelect={handleFolderSelect}
                        openFolderId={openFolderId}
                        setOpenFolderId={setOpenFolderId}
                        noURLParams={noURLParams}
                        storageIdToReq={storageIdToReq}
                        addStorageFileReference={addStorageFileReference}
                      />
                    </StorageContext.Provider>
                  </section>
                )}
              </section>
            </DropFilesWrapper>
          </div>
        </div>
      </section>

      {addFolderModal && (
        <AddFolderModal
          dataType={'storage'}
          folderId={params.folderId || openFolderId}
          setOpenFolderId={setOpenFolderId}
          storageId={storageIdToReq}
          close={() => setAddFolderModal(false)}
        />
      )}

      {addFilesModal && (
        <AddFilesModal
          close={() => setAddFilesModal(false)}
          storageIdToReq={storageIdToReq}
          folderId={params.folderId || openFolderId}
        />
      )}

      {editStorageModal && (
        <CreateStorageModal close={() => setEditStorageModal(false)} editData={storageData} getData={getData} />
      )}

      {droppedItems && (
        <DropFoldersAndFilesModal
          droppedItems={droppedItems}
          close={() => setDroppedItems(null)}
          storageId={storageIdToReq}
          folderId={params.folderId || openFolderId}
        />
      )}
    </>
  );
};

export default StorageModal;

import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import Cookies from 'js-cookie';
import { server_url } from 'src/settings/base-url';

export const ticketsApi = createApi({
  reducerPath: 'tickets',
  tagTypes: ['Tickets', 'FromMe', 'ForMe', 'Active', 'Completed'],
  baseQuery: fetchBaseQuery({
    baseUrl: server_url,
    prepareHeaders: (headers) => {
      const token = Cookies.get('auth-token');
      if (token) {
        headers.set('Authorization', token);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getTickets: builder.query({
      query: () => '/api/tickets_for_me',
      providesTags: ['Tickets', 'ForMe'],
    }),
    getActiveTicketsFromMe: builder.query({
      query: ({ page, limit }) => `/api/my_active_tickets/${limit}/${page}`,
      providesTags: ['Tickets', 'Active', 'FromMe'],
    }),
    getCompletedTicketsFromMe: builder.query({
      query: ({ page, limit }) => `/api/my_completed_tickets/${limit}/${page}`,
      providesTags: ['Tickets', 'Completed', 'FromMe'],
    }),
    sendReport: builder.mutation({
      query: (body) => ({
        url: '/api/confirmation_ticket',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Tickets', 'ForMe'],
    }),
    confirmTicket: builder.mutation({
      query: (body) => ({
        url: '/api/confirmed_ticket',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Tickets', 'Active', 'FromMe'],
    }),
    returnTicket: builder.mutation({
      query: (body) => ({
        url: '/api/returned_ticket',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Tickets', 'Active', 'FromMe'],
    }),
    cancelTicket: builder.mutation({
      query: (body) => ({
        url: '/api/cancel_ticket',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Tickets', 'Active', 'FromMe'],
    }),
  }),
});

export const {
  useGetActiveTicketsFromMeQuery,
  useGetCompletedTicketsFromMeQuery,
  useGetTicketsQuery,
  useSendReportMutation,
  useConfirmTicketMutation,
  useReturnTicketMutation,
  useCancelTicketMutation,
} = ticketsApi;

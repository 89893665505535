import { Label } from '@shared/components/Label/Label';
import { Text } from '@shared/components/Text/Text';
import { User } from '@shared/components/User/User';
import { Modal } from '@shared/components/Modal/Modal';

import { StyledModalRowEnd } from '@shared/components/Modal/style';
import { files_url } from 'src/settings/base-url';
import useAuth from 'src/hooks/useAuth';

import { ModalStatusInfo } from '@shared/components/Modal/ModalStatusInfo';

import { useMemo, useRef } from 'react';

import moment from 'moment';

import { Status } from 'src/shared/components/Status';

import { Container } from '@shared/components/Container';

import { Link } from '@shared/components/Link/Link';

import AttachedFile from '@components/files/AttachedFile';

import { TicketReport } from '@components/Tickets/TicketsForMe/TicketReport/TicketReport';

import HTMLReactParser from 'html-react-parser';

import { ReportForm } from './ui/ReportForm';

export const TicketModal = ({ ticket, onClose }) => {
  const {
    id,
    title,
    description,
    creator_id,
    creator_avatar,
    creator_first_name,
    executor_id,
    base_chat_message_id,
    base_chat_message,
    date_finish,
    ticket_actions,
  } = ticket;

  const auth = useAuth();
  const date = new Date(date_finish);

  const isReturned = useMemo(
    () => ticket.ticket_actions[ticket.ticket_actions.length - 1].action === 'returned',
    [ticket],
  );

  const isExpired = useMemo(() => moment().diff(ticket.date_finish) > 0, [ticket]);

  const currentAction = useMemo(() => ticket.ticket_actions[ticket.ticket_actions.length - 1].action, [ticket]);

  const removeFilesOnClose = useRef();

  const onCloseHandler = async () => {
    const onRemove = removeFilesOnClose.current;

    if (onRemove) {
      await onRemove();
    }

    onClose();
  };

  return (
    <Modal modalSize="767px" title="Поручение" onClose={onCloseHandler} withoutButtons>
      {isReturned && <ModalStatusInfo color="error">Поручение возвращено</ModalStatusInfo>}
      {base_chat_message_id && (
        <ModalStatusInfo>
          <Text tag="p">
            Поручение создано на основе {base_chat_message.project_title ? 'проекта' : 'задачи'}
            <Link link="/linktotask" tag="b" hover="var(--main)">
              {` "${base_chat_message.project_title || base_chat_message.task_title}".`}
            </Link>
          </Text>
          <Text tag="p">{base_chat_message.message}</Text>
        </ModalStatusInfo>
      )}
      <div>
        <StyledModalRowEnd>
          <div>
            <Label>Название</Label>
            <Text size={2} tag="b">
              {title}
            </Text>
          </div>
          <div>
            <Label>Задание от</Label>
            <User
              size="sm"
              picture={`${files_url}/employees/avatar/${creator_id}/small_${creator_avatar}?token=${auth.token}`}
              name={creator_first_name}
            ></User>
          </div>
        </StyledModalRowEnd>
      </div>
      <Container direction="column" gap={'8px'}>
        <div>
          <Label>Описание</Label>
          <Text size={2} tag="p">
            {HTMLReactParser(description)}
          </Text>
        </div>
        <div>
          {ticket_actions[0].ticket_action_files?.length > 0 && (
            <>
              {ticket_actions[0].ticket_action_files.map((file, i) => {
                const url = `${files_url}/ticket_actions/files/${ticket_actions[0].id}/${file.id}/${file.file}?token=${auth.token}`;
                return (
                  <AttachedFile
                    fileUrl={url}
                    fileName={file.file}
                    fileId={file.id}
                    key={i}
                    storageFileId={file.from_storage}
                  />
                );
              })}
            </>
          )}
        </div>
      </Container>
      <div>
        <Label>Срок исполнения</Label>
        <Container direction="column" gap="2px">
          <Text size={2} tag="b">
            {moment.utc(date).format('L')} {moment.utc(date).format('LT')}
          </Text>
          {isExpired && <Status color="red">Просрочено</Status>}
        </Container>
      </div>
      <Container direction={'column'}>
        {ticket.ticket_actions.map((action) => {
          return action.report ? <TicketReport action={action} /> : null;
        })}
      </Container>

      <ReportForm
        ticketId={id}
        currentAction={currentAction}
        onClose={onClose}
        removeFilesOnClose={removeFilesOnClose}
      />
    </Modal>
  );
};

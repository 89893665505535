import { useCallback, useEffect, useMemo, useState } from 'react';
import Editor from '@draft-js-plugins/editor';

import createMentionPlugin from '@draft-js-plugins/mention';
import { useSelector } from 'react-redux';

import createLinkDetectionPlugin from 'draft-js-link-detection-plugin';

import editorStyles from './MentionsStyles.module.css';
import './mentionsStyles.css';
import Entry from './Entry';

// import createLinkifyPlugin from "@draft-js-plugins/linkify";

const linkDetectionPlugin = createLinkDetectionPlugin();
// const plugins2 = [linkDetectionPlugin];

// const linkifyPlugin = createLinkifyPlugin({
//   target: "_blank",
//   component: (props) => (
//     // eslint-disable-next-line no-alert, jsx-a11y/anchor-has-content
//     <a
//       style={{ cursor: "pointer", color: "blue" }}
//       {...props}
//       onClick={() => alert("Clicked on Link!")}
//     />
//   ),
// });

const EditorWithMentions = ({
  editorState,
  setEditorState,
  handleKeyCommand,
  quoteMessage,
  editorRef,
  containerRef,
}) => {
  const { employees } = useSelector((state) => state.users);
  const [open, setOpen] = useState(false);
  const [suggestions, setSuggestions] = useState([]);

  //
  const usersMentionData = useMemo(() => {
    if (employees?.length) {
      return employees.map((e) => ({
        name: `${e.first_name ? e.first_name : ''} ${e.last_name ? e.last_name : ''}`,
        firstName: e.first_name,
        lastName: e.last_name,
        email: e.email,
        userId: e.id,
      }));
    } else return [];
  }, [employees]);

  const { MentionSuggestions, plugins } = useMemo(() => {
    const mentionPlugin = createMentionPlugin({
      entityMutability: 'IMMUTABLE',
      mentionComponent(mentionProps) {
        return <span className="chat__username">{mentionProps.children}</span>;
      },
      // popperOptions: {
      //   placement: "left",
      //   modifiers: [
      //     {
      //       name: "offset",
      //       options: {
      //         offset: [0, 100],
      //       },
      //     },
      //   ],
      // },
    });
    const { MentionSuggestions } = mentionPlugin;
    const plugins = [mentionPlugin, linkDetectionPlugin];
    return { plugins, MentionSuggestions };
  }, []);

  const onOpenChange = useCallback((_open) => {
    setOpen(_open);
  }, []);

  const onSearchChange = useCallback(
    ({ value }) => {
      setSuggestions(usersMentionData.filter((user) => user.name.toLowerCase().includes(value)));

      // setSuggestions(defaultSuggestionsFilter(value, mentions));
    },
    [usersMentionData],
  );

  useEffect(() => {
    if (quoteMessage?.quoteText) {
      if (editorRef.current) {
        editorRef.current.focus();
        // if (containerRef.current) {
        //   containerRef.current.scrollIntoView({
        //     behavior: "smooth",
        //     block: "center",
        //     inline: "start",
        //   });
        // }
      }
    }
  }, [quoteMessage, editorRef, containerRef]);

  return (
    <div
      className={editorStyles.editor}
      onClick={() => {
        if (editorRef.current) editorRef.current.focus();
      }}
      ref={containerRef}
      id="hello_world"
    >
      <Editor
        editorKey={'edtitor'}
        editorState={editorState}
        onChange={setEditorState}
        // plugins={plugins}
        plugins={plugins}
        ref={editorRef}
        handleKeyCommand={handleKeyCommand}
      />
      <MentionSuggestions
        open={open}
        onOpenChange={onOpenChange}
        suggestions={suggestions}
        onSearchChange={onSearchChange}
        entryComponent={Entry}

        // popoverContainer={({ children }) => (
        //   <div style={{ border: "1px solid black", position: "absolute" }}>
        //     {children}
        //   </div>
        // )}
      />
    </div>
  );
};

export default EditorWithMentions;

import { useState, useCallback, memo } from 'react';
// import { useParams } from "react-router-dom";
import { useSelector } from 'react-redux';

import UserProfileModal from '../../windows/profile/UserProfileModal';

import FolderDetails from './FolderDetails';
import StorageTrash from './StorageTrash';

const StorageContent = ({
  setAddFilesModal,
  setAddFolderModal,
  handleFolderSelect,
  openFolderId,
  setOpenFolderId,
  noURLParams,
  storageIdToReq,
  addStorageFileReference,
}) => {
  // const params = useParams();
  const users = useSelector((state) => state.users.employees);

  const [userProfileId, setUserProfileId] = useState();

  const renderUserName = useCallback(
    (creatorId) => {
      const creator = users.find((user) => user.id === creatorId);
      let userName = '';
      if (creator) {
        userName = `${creator.first_name || ''} ${creator.last_name || ''}`;
      }

      return (
        <span
          onClick={(e) => {
            e.stopPropagation();
            setUserProfileId(creatorId);
          }}
          className="catalog-folder__author"
        >
          {userName}
        </span>
      );
    },
    [users],
  );

  return (
    <>
      {openFolderId === 'bin' ? (
        <StorageTrash renderUserName={renderUserName} storageId={storageIdToReq} />
      ) : (
        <FolderDetails
          setAddFolderModal={setAddFolderModal}
          setAddFilesModal={setAddFilesModal}
          renderUserName={renderUserName}
          handleFolderSelect={handleFolderSelect}
          setOpenFolderId={setOpenFolderId}
          noURLParams={noURLParams}
          storageIdToReq={storageIdToReq}
          addStorageFileReference={addStorageFileReference}
        />
      )}

      {userProfileId && <UserProfileModal profileId={userProfileId} onClose={() => setUserProfileId(null)} />}
    </>
  );
};

export default memo(StorageContent);

import { useState, useMemo, useRef, useContext, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import { object, string, array, date } from 'yup';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { useCallback } from 'react';

import SnackbarContext from 'src/contexts/SnackbarContext';

import { files_url } from 'src/settings/base-url';

import ModalBottom from '../../components/modals/modal-bottom';
import TextEditor from '../text-editor/TextEditor';
import SelectMulty from '../form/select/SelectMulty';
import {
  formatDateforPicker,
  formatReactSelectEmployeesOptions,
  formatReactSelectTagsOptions,
  randomSequence,
  useEventTriggerOnEscPress,
} from '../../utilize/helper-functions';
import SelectInput from '../form/form-select/SelectInput';
import ProjectFormTabs from '../../windows/projects/project-form-create-edit/ProjectFormTabs';
import ProjectFormResult from '../../windows/projects/project-form-create-edit/project-form-result/ProjectFormResult';
import CalendarForm, { getFormatCalendarData } from '../calendar-form/CalendarForm';
import useAuth from '../../hooks/useAuth';
import DropFilesWrapper from '../drag-field/DropFilesWrapper';
// import FileToUpload from "../files/FileToUpload";
import FilesUploadManage from '../files/FilesUploadManage';
import { getProjectStructure } from '../../redux/features/projectsSlice';
import ConfirmAction from '../warnings/ConfirmAction';
import Preloader from '../preloaders/Preloader';

// import LocalDateTime from "../shared/LocalDateTime";

const formSchema = object({
  title: string().required(),
  responsible: object().required(),
  executor: object().required(),
  description: string(),
  attendee: array(),
  tags: array(),
  deadline_start: date(),
  deadline_end: date(),
});

const CreateTaskModal = ({
  close,
  projectId,
  parentTaskId,
  sidePanelOpen,
  messageBasis,
  taskIdToEdit,
  getTaskData,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const employees = useSelector((state) => state.users.employees);
  const tags = useSelector((state) => state.tags.tagsList);
  const auth = useAuth();
  const navigate = useNavigate();

  // данные для Select компонента attendee
  const attendee = useMemo(
    () => ({
      name: 'attendee',
      label: '',
      type: 'select',
      optionsType: 'tags',
      options: formatReactSelectEmployeesOptions(employees) || [],
    }),
    [employees],
  );

  // данные для Select компонента responsible
  const responsible = useMemo(
    () => ({
      name: 'responsible',
      label: t('Project.responsible') + '*',
      type: 'select',
      subType: 'tag',
      options: employees || [],
      value: 'last_name',
      value2: 'first_name',
    }),
    [employees, t],
  );

  // данные для Select компонента executor
  const executor = useMemo(
    () => ({
      name: 'executor',
      label: t('Project.executor') + '*',
      type: 'select',
      subType: 'tag',
      options: employees || [],
      value: 'last_name',
      value2: 'first_name',
    }),
    [employees, t],
  );

  // данные для Select компонента tags
  const tagsData = useMemo(
    () => ({
      name: 'tags',
      label: '',
      // placeholder:'Выбрать тэги',
      // label: t(`${translateKey}.attendee_role`),
      type: 'select',
      subType: 'tag',
      options: formatReactSelectTagsOptions(tags) || [],
    }),
    [tags],
  );

  const {
    register,
    control,
    handleSubmit,
    formState: { isDirty, isValid, errors },
    reset,
  } = useForm({
    resolver: yupResolver(formSchema),
    mode: 'onChange',
  });

  const [lockedTask, setLockedTask] = useState(false);
  const [textFormat, setTextFormat] = useState(null);

  const [formTabs, setFormTabs] = useState({
    result: false,
    deadlines: false,
    tags: false,
    incoming_data: false,
    outgoing_data: false,
  });

  const handlerTab = (tab) => {
    let item = { ...formTabs };
    let value = (item[`${tab}`] = !formTabs[`${tab}`]);
    setFormTabs(item, value);
  };

  const [results, setResults] = useState([]);

  // колонка результата при редактировании
  const [editedItemResult, setEditedItemResult] = useState(null);

  const handlerAddResult = (value, resultId) => {
    // если есть id значит нужно отредачить существующую колонку результата
    if (!resultId) {
      setResults((prev) => [...prev, value]);
    } else {
      const getEditedState = (arr, newItem) => {
        const ind = arr.findIndex(({ id }) => id === newItem.id);
        return [...arr.slice(0, ind), newItem, ...arr.slice(ind + 1)];
      };
      setResults((prev) => getEditedState(prev, value));
      setEditedItemResult(null);
    }
  };

  // назначим редактируемый результат
  const initEditableResult = (value) => {
    setEditedItemResult(value);
  };

  // удалить строку результата
  const deleteResult = (value) => {
    const getEditedState = (arr, newItem) => {
      const ind = arr.findIndex(({ id }) => id === newItem.id);
      return [...arr.slice(0, ind), ...arr.slice(ind + 1)];
    };
    setResults((prev) => getEditedState(prev, value));
  };

  const formId = useRef(randomSequence());

  const [uploadingFiles, setUploadingFiles] = useState([]);

  const onSubmitFiles = (files) => {
    //
    setUploadingFiles([...files]);
  };

  const removeFilesOnClose = useRef();

  const { showSnackbar } = useContext(SnackbarContext);

  const [isSubmitting, setIsSubmitting] = useState();

  const [isLoadingEditData, setIsLoadingEditData] = useState();
  const [taskDataToEdit, setTaskDataToEdit] = useState();
  const [filesDeleteArr, setFilesDeleteArr] = useState([]);

  const { filesAddFormData, filesToEdit } = useMemo(() => {
    if (!taskDataToEdit) return {};
    const filesAddFormData = { type: 'task_id', id: taskDataToEdit.id };
    let filesToEdit;
    if (taskDataToEdit.task_files?.length && auth?.token) {
      filesToEdit = taskDataToEdit.task_files.map((file) => ({
        ...file,
        name: file.file,
        src: `${files_url}/tasks/files/${taskDataToEdit.id}/${file.file}?token=${auth.token}`,
      }));
    }

    return { filesAddFormData, filesToEdit };
  }, [taskDataToEdit]);

  // если открывается окно редактирования, то получить и внедрить все данные для редактирования
  useEffect(() => {
    if (taskIdToEdit) {
      setIsLoadingEditData(true);
      axios
        .get(`/api/task_edit/${taskIdToEdit}`)
        .then((r) => {
          const taskData = r.data.result;
          reset({
            title: taskData.title,
            attendee: taskData.task_members.map((member) => {
              return {
                label: `${member.last_name || ''} ${member.first_name}`,
                value: `${member.last_name || ''}${member.first_name}`,
                id: member.id,
              };
            }),
            responsible: {
              label: `${taskData.responsible_last_name || ''} ${taskData.responsible_first_name || ''}`,
              value: taskData.responsible_id,
            },
            executor: {
              label: `${taskData.executor_last_name || ''} ${taskData.executor_first_name || ''}`,
              value: taskData.executor_id,
            },
            deadline_start: formatDateforPicker(taskData.date_start),
            deadline_end: formatDateforPicker(taskData.date_finish),
            tags: taskData.task_tags?.map((tag) => ({
              color: tag.color,
              id: tag.tag_id,
              label: tag.name,
              value: tag.name,
            })),
          });
          if (taskData.task_goals?.length) {
            setResults(taskData.task_goals);
          }
          setTaskDataToEdit(taskData);
        })
        .catch(() => {
          showSnackbar('Возникла ошибка при запросе данных на редактирование');
        })
        .finally(() => setIsLoadingEditData(false));
    }
  }, []);

  const submitData = (data) => {
    // return
    if (auth) {
      const submitData = {
        row: {
          project_id: parentTaskId ? null : projectId,
          parent_task_id: parentTaskId,
          locked: lockedTask,
          title: data.title,
          description: textFormat,
          creator_id: auth.user.id,
          executor_id: data.executor.value,
          responsible_id: data.responsible.value,
          base_chat_message_id: messageBasis ? messageBasis.message_id : null,
        },
        form_id: formId.current,
        task_id: taskIdToEdit,
      };
      if (formTabs.deadlines) {
        if (data.deadline_start) {
          submitData.row.date_start = getFormatCalendarData(data.deadline_start);
        }
        if (data.deadline_end) {
          submitData.row.date_finish = getFormatCalendarData(data.deadline_end);
        }
      }
      if (formTabs.tags && data.tags?.length) {
        submitData.task_tags = data.tags.map((tag) => ({ tag_id: tag.id }));
      }
      if (data.attendee?.length) {
        submitData.task_members = data.attendee.map((member) => ({
          employee_id: member.id,
        }));
      }
      if (formTabs.result && results.length) {
        submitData.task_goals = results.map((result) => ({
          type: result.type,
          description: result.description,
        }));
      }

      setIsSubmitting(true);
      if (taskIdToEdit) {
        if (filesDeleteArr.length) {
          axios
            .patch('/api/tasks/files/remove', {
              task_id: taskIdToEdit,
              files: filesDeleteArr.map((f) => f.file),
            })
            .catch(() => showSnackbar('Возникла ошибка при удалении файлов'));
        }

        axios
          .put('/api/tasks/edit', submitData)
          .then(() => {
            getTaskData();
            showSnackbar('Задача отредактирована', 'success');
            close();
          })
          .catch(() => {
            showSnackbar('Возникла ошибка при редактировании задачи');
            setIsSubmitting(false);
          });
        return;
      }

      axios
        .post('/api/tasks/add', submitData)
        .then((response) => {
          //
          if (sidePanelOpen) dispatch(getProjectStructure({ projectId }));
          navigate(`/projects/${projectId}/tasks/${response.data.result}`);
          close();
        })
        .catch(() => {
          showSnackbar('Возникла ошибка при создании задачи');
          setIsSubmitting(false);
        });
    }
  };

  const [modalClosePrompt, setModalClosePrompt] = useState(false);

  const handleModalClose = useCallback(() => {
    if (isDirty || uploadingFiles.length || results.length || textFormat) {
      setModalClosePrompt(true);
    } else close();
  }, [isDirty, results, textFormat, uploadingFiles]);

  // регистрация eventa keypress для выхода из модального окна в случае нажатии на кнопку Esc
  useEventTriggerOnEscPress(handleModalClose);

  return (
    <>
      <section className="modal__body">
        <div className="modal__header">
          <h2 className="modal__title">{taskIdToEdit ? 'Редактировать задачу' : t('Project.create_task')}</h2>
          {messageBasis && (
            <div className="modal_row">
              {t('Project.message_basis')}
              <strong>{` ${messageBasis.author} `}</strong>
              <div>
                {t('common.date') + ' '}
                <strong>
                  {/* <LocalDateTime dateTime={messageBasis.date_created} /> */}
                  {messageBasis.LocalDateTime}
                </strong>
              </div>
            </div>
          )}
          <button
            type="button"
            className="modal__close-modal"
            onClick={handleModalClose}
            // onClick={() => {
            //   if (removeFilesOnClose.current) {
            //     removeFilesOnClose.current();
            //   }
            //   checkInputsAndClose();
            // }}
          ></button>
        </div>

        {isLoadingEditData ? (
          <Preloader />
        ) : (
          <DropFilesWrapper setFiles={setUploadingFiles}>
            <form>
              <div className="modal__row">
                <label className="modal__label" htmlFor="name">
                  {t('common.title_name') + '*'}
                </label>
                <input
                  className={`modal__input ${errors.title ? 'form__input--invalid' : ''}`}
                  id="name"
                  placeholder={t('common.enter_title')}
                  // defaultValue={""}
                  {...register('title')}
                />
                {errors.title && <p className="form__error">{t('ErrorMessages.required_field')}</p>}
              </div>

              <div className="modal__row">
                <label className="modal__label" htmlFor="description">
                  {t('Project.description')}
                </label>
                <TextEditor
                  prev={taskDataToEdit?.description}
                  sendFormat={setTextFormat}
                  onSubmitFiles={onSubmitFiles}
                  messageBasis={messageBasis}
                />
                {(uploadingFiles.length > 0 || filesToEdit?.length) && (
                  <div className="chat__attach attach">
                    <FilesUploadManage
                      filesToUpload={uploadingFiles}
                      filesToEdit={filesToEdit}
                      filesDeleteArr={filesDeleteArr}
                      setFilesDeleteArr={setFilesDeleteArr}
                      formId={formId.current}
                      removeFilesOnClose={removeFilesOnClose}
                      filesUploadPath="/api/tasks/files/add"
                      filesDeletePath="/api/tasks/files/remove"
                      filesAddFormData={filesAddFormData}
                    />
                  </div>
                )}
              </div>

              {/* Участники и роли */}
              <div className="modal__subtitle-wrapper">
                <h3 className="modal__subtitle">{t('Project.members_roles')}</h3>

                <button
                  type="button"
                  className={`modal__controls modal__controls--lock _btn_animate_scale ${
                    lockedTask ? '_lock_button--pressed' : ''
                  }`}
                  onClick={() => setLockedTask(!lockedTask)}
                ></button>
              </div>
              <SelectMulty
                item={attendee}
                control={control}
                // options={formatReactSelectEmployeesOptions(employees)}
                options={attendee.options}
              />

              {/* ответсвенный и исполнитель */}
              <div className="modal__row modal__row--start">
                <div className="modal__col">
                  <div className="dropdown"></div>{' '}
                  <SelectInput
                    control={control}
                    data={responsible}
                    // для отображения в выпадашке
                    value={responsible.value}
                    value2={responsible.value2}
                  />
                </div>

                <div className="modal__col">
                  <div className="dropdown"></div>{' '}
                  <SelectInput
                    control={control}
                    data={executor}
                    // для отображения в инпуте
                    value={executor.value}
                    value2={executor.value2}
                  />
                </div>
              </div>

              {/* сроки результат и тд., вкладки, разворачивающиеся  при клике */}
              <ProjectFormTabs handler={handlerTab} tabs={formTabs} />

              {/* результат */}
              {formTabs.result && (
                <ProjectFormResult
                  // tab="result"
                  results={results}
                  setResult={handlerAddResult}
                  initEditableResult={initEditableResult}
                  editedItemResult={editedItemResult}
                  deleteResult={deleteResult}
                />
              )}

              {/* сроки  */}
              {formTabs.deadlines && (
                <>
                  <h3 className="modal__subtitle">{t(`Project.deadlines`)}</h3>
                  <div className="modal__row modal__row--start date-range">
                    <CalendarForm
                      data={{
                        name: 'deadline_start',
                        placeholder: t('Project.start_date'),
                      }}
                      control={control}
                      minDate={new Date()}
                      // prev={projectData?.date_start}
                    />
                    <CalendarForm
                      data={{
                        name: 'deadline_end',
                        placeholder: t('Project.end_date'),
                      }}
                      control={control}
                      minDate={new Date()}
                      // prev={projectData?.date_finish}
                    />
                  </div>
                </>
              )}

              {/* тэги */}
              {formTabs.tags && (
                <>
                  <h3 className="modal__subtitle">{t('common.tags')}</h3>
                  <SelectMulty item={tagsData} control={control} options={tagsData.options} />
                </>
              )}
            </form>

            {/* <div className="modal__row">
          <h3 className="modal__subtitle">Входящие данные</h3>
          <div className="data">
            <button type="button" className="data__button-add"></button>
            <span className="data__item">
              Сделать договор по уборке полей
              <button
                type="button"
                className="data__button-delete"
              ></button>{" "}
            </span>
            <span className="data__item">
              Убрать рисовое поле
              <button
                type="button"
                className="data__button-delete"
              ></button>{" "}
            </span>
            <span className="data__item">
              Сделать договор по уборке полей
              <button
                type="button"
                className="data__button-delete"
              ></button>{" "}
            </span>
            <span className="data__item">
              Убрать рисовое поле
              <button type="button" className="data__button-delete"></button>
            </span>
          </div>
        </div>
        <div className="modal__row">
          <h3 className="modal__subtitle">Исходящие данные</h3>
          <div className="modal__row data">
            <button type="button" className="data__button-add"></button>
            <span className="data__item">
              Придумываем устройство часов
              <button
                type="button"
                className="data__button-delete"
              ></button>{" "}
            </span>
            <span className="data__item">
              Придумываем устройство часов
              <button
                type="button"
                className="data__button-delete"
              ></button>{" "}
            </span>
            <span className="data__item">
              Придумываем устройство часов
              <button
                type="button"
                className="data__button-delete"
              ></button>{" "}
            </span>
            <span className="data__item">
              Придумываем устройство часов
              <button type="button" className="data__button-delete"></button>
            </span>
          </div>
        </div> */}
            <ModalBottom
              action={taskIdToEdit && 'edit'}
              onCreate={handleSubmit(submitData)}
              onClose={handleModalClose}
              isValid={isValid}
              isSending={isSubmitting}
            />
            {/* </form> */}
          </DropFilesWrapper>
        )}
      </section>

      {modalClosePrompt && (
        <ConfirmAction
          cancel={() => setModalClosePrompt(false)}
          confirm={close}
          actionText={t('common.confirm_modal_close')}
        />
      )}
    </>
  );
};

export default CreateTaskModal;

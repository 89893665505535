import Form from '../../../src/components/form/Form';
import api from '../../services/api';

function Login() {
  const identifier = {
    name: 'identifier',
    icon: true,
    // bubble: "Текст",
    placeholder: 'Введите идентификатор',
    label: 'Идентификатор компании',
    type: 'text',
  };

  const email = {
    name: 'email',
    icon: true,
    // bubble: "Текст",
    placeholder: 'Введите почту',
    label: 'Почта',
    type: 'text',
  };

  const password = {
    name: 'password',
    icon: false,
    //  bubble: "Текст ",
    placeholder: 'Введите пароль',
    label: 'Пароль',
    type: 'password',
  };

  const inputs = [{ ...identifier }, { ...email }, { ...password }];
  return (
    <div className="page">
      <Form
        auth__title="Вход"
        inputs={inputs}
        submitBtnValue="Войти"
        sendingBtnText={'Авторизация...'}
        action="login"
        resetPassword
        requestURL={api.auth.login}
      />
    </div>
  );
}

export default Login;

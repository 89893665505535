import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import { Controller } from 'react-hook-form';
import 'react-datepicker/dist/react-datepicker.css';
import './CalendarForm.css';
import { useTranslation } from 'react-i18next';

// форматируем дату для  отправки на сервер, эту функцию следует импортировать и исп при отправке формы
export const getFormatCalendarData = (date) => {
  if (!date) return;
  return `${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()}`;
};

//  удаляем лишнее, что приходит с сервера с датой (время и тд) через слэш 2020/12/23
const formatDateforPicker = (dateString) => {
  let dateToReturn = '';
  if (dateString) {
    const formattedDate = new Date(dateString.substr(0, 10).replace(/-/g, '/'));
    if (formattedDate instanceof Date && !isNaN(formattedDate)) dateToReturn = formattedDate;
  }
  return dateToReturn;
};
//   через точку  12.12.2020
export const formatDateWithDots = (dateString) => {
  if (!dateString) return '';
  return dateString.substr(0, 10).replace(/-/g, '.').split('.').reverse().join(`.`);
};
const CalendarForm = (props) => {
  const { t } = useTranslation();
  const { data, control, prev } = props;
  // если известна дата, которую нужно отобразить

  // если выдает ошибку в календаре при  редактировании - ошибка из-за ручной отправки формы, на продакшне такого не будет. приходит неправильный формат, когда писали тестовые данные типа 0000 000 в дате
  const [startDate, setStartDate] = useState(prev ? formatDateforPicker(prev) : null);
  const handlerChange = (date, field) => {
    field.onChange(date);
    setStartDate(date);
  };

  return (
    <div className="modal__col">
      <label className="modal__label" htmlFor="date">
        {data.label}
      </label>

      <Controller
        control={control}
        name={data.name}
        // placeholder="red"
        render={({ field, fieldState }) => {
          return (
            <>
              <div className="date-range__input-wrapper">
                <div className={`${fieldState.invalid && 'form__input--invalid'}`}>
                  <DatePicker
                    placeholderText={data.placeholder}
                    onChange={(date) => {
                      handlerChange(date, field);
                    }}
                    selected={prev ? startDate : field.value}
                    // selected={null}
                    dateFormat="dd.MM.yyyy"
                    className="date-range__input"
                    minDate={props.minDate}
                  />
                </div>
                <span className="modal__icon-calendar"></span>
              </div>
              {fieldState.invalid && <p className="form__error">{t('ErrorMessages.required_field')}</p>}
            </>
          );
        }}
      />
    </div>
  );
};

export default CalendarForm;

import { useState } from 'react';

import { useNavigate, useParams } from 'react-router-dom';

import { formatDateWithDots } from '../calendar-form/CalendarForm';
import Preloader from '../preloaders/Preloader';

import FolderVersionRowMenu from './FolderVersionsRowMenu';

const FolderVersionsList = ({
  renderUserName,
  setFolderVersionDetails,
  folderVersions,
  currentFolderData,
  isLoadingVersions,
  setParentFolderStack,
}) => {
  const navigate = useNavigate();
  const { storageId } = useParams();

  const [isSubmitting, setIsSubmitting] = useState();

  return (
    <>
      {isSubmitting ? (
        <Preloader />
      ) : (
        <>
          <div className="modal__row">
            <h3 className="modal__subtitle">Текущая</h3>
            <ul className="storage-list">
              <li
                className="storage__catalog-folder catalog-folder "
                onClick={() => navigate(`/storages/${storageId}/folder/${currentFolderData.id}`)}
              >
                <p className="catalog-file__name">{currentFolderData.title}</p>
                <p className="catalog-file__weight">{/*20 мб*/}</p>
                <p className="catalog-file__date">{formatDateWithDots(currentFolderData.date_created)}</p>
                {renderUserName(currentFolderData.creator_id)}
              </li>
            </ul>
          </div>
          {isLoadingVersions && <Preloader />}
          {folderVersions?.length > 0 && (
            <div className="modal__row">
              <h3 className="modal__subtitle">Предыдущие</h3>
              <ul className="storage-list">
                {folderVersions?.map((versionData, i) => (
                  <li
                    key={i}
                    className="storage__catalog-folder catalog-folder"
                    onClick={() => {
                      setParentFolderStack((p) => [...p, versionData]);
                      setFolderVersionDetails(versionData);
                    }}
                  >
                    <p className="catalog-file__name">{versionData.title}</p>
                    <p className="catalog-file__weight">{/*20 мб*/}</p>
                    <p className="catalog-file__date">{formatDateWithDots(versionData.date_created)}</p>
                    {renderUserName(versionData.creator_id)}
                    <FolderVersionRowMenu
                      setIsSubmitting={setIsSubmitting}
                      versionData={versionData}
                      dataType="folder"
                    />
                  </li>
                ))}
              </ul>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default FolderVersionsList;

import { CreateTicketModal } from '@components/Tickets/TicketsFromMe';
import { useRef, useState, useCallback } from 'react';
import { useLocation, matchPath } from 'react-router-dom';
import InlineModal from 'src/components/modals/InlineModal';
import ModalPortal from 'src/components/modals/ModalPortal';
import CreateTaskModal from 'src/components/tasks/CreateTaskModal';
import { useOutsideTrigger } from 'src/utilize/helper-functions';
import CreateEditNewsModal from 'src/windows/news/CreateEditNewsModal';
import ProjectFormCreateEdit from 'src/windows/projects/project-form-create-edit/ProjectFormCreateEdit';

// react-router не определяет параметры в url за пределами соответствующих компонентов.
// данная функция вручную проверяет url на наличие параметров в url
function useParams(path) {
  const { pathname } = useLocation();
  const match = matchPath({ path }, pathname);
  return match?.params || {};
}

const QuickAddNav = () => {
  const { projectId } = useParams('/projects/:projectId');
  const { taskProjectId, taskId } = useParams('/projects/:taskProjectId/tasks/:taskId');

  const [popup, setPopup] = useState();
  const [modal, setModal] = useState();

  const quickAddBtnRef = useRef();
  const quickAddPopup = useRef();

  const refsArray = useRef([quickAddBtnRef, quickAddPopup]);

  const hidePopup = useCallback(() => {
    setPopup(false);
  }, []);

  useOutsideTrigger(refsArray.current, hidePopup, popup);

  return (
    <>
      <div data-menu className={`nav-controls__item nav-controls__item--add ${popup ? '_active' : ''} `}>
        {/* Кнопка - зеленый плюс */}
        <span
          ref={quickAddBtnRef}
          className="nav-controls__link nav-controls__link--add"
          onClick={() => setPopup(!popup)}
        ></span>
        <div className="nav-controls__inner-list " ref={quickAddPopup}>
          <span
            onClick={() => {
              setPopup(false);
              setModal('project');
            }}
          >
            Проект
          </span>
          {(projectId || taskProjectId) && (
            <span
              onClick={() => {
                setPopup(false);
                setModal('task');
              }}
            >
              {`Подзадачу к ${taskId ? 'текущей задаче' : 'текущему проекту'}`}
            </span>
          )}
          <span
            onClick={() => {
              setPopup(false);
              setModal('ticket');
            }}
          >
            Создать поручение
          </span>
          {/* <span
            onClick={() => {
              setPopup(false);
              setModal('poll');
            }}
          >
            Опрос
          </span> */}
          <span
            onClick={() => {
              setPopup(false);
              setModal('news');
            }}
          >
            Новость
          </span>
          <span
            onClick={() => {
              setPopup(false);
              setModal('blog');
            }}
          >
            Блог
          </span>
        </div>
      </div>

      {modal && (
        <ModalPortal>
          {modal === 'project' && <ProjectFormCreateEdit data={{}} onClose={() => setModal(false)} />}
          {modal === 'task' && (
            <InlineModal close={() => setModal(false)}>
              <CreateTaskModal projectId={projectId || taskProjectId} parentTaskId={taskId} />
            </InlineModal>
          )}
          {(modal === 'news' || modal === 'blog') && <CreateEditNewsModal type={modal} close={() => setModal(false)} />}
          {modal === 'ticket' && <CreateTicketModal onClose={() => setModal(false)} />}
        </ModalPortal>
      )}
    </>
  );
};

export default QuickAddNav;

import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import SnackbarContext from 'src/contexts/SnackbarContext';
import { useDuplicateErrorHandler } from 'src/utilize/helper-functions';

import { url_get_positions_list, url_post_new_positions, url_put_positions } from '../../settings/base-url';
import PositionsList from '../../components/positions/PositionsList';

import Preloader from '../../components/preloaders/Preloader';
import PositionSubtitle from '../../components/positions/PositionSubTitle';
import { useSocketContext } from '../../contexts/SocketContext';

import PositionFormCreateEdit from './PositionFormCreateEdit';

const Positions = (props) => {
  const { socket } = useSocketContext();
  const { t } = useTranslation();
  const translateKey = 'Positions'; // для перевода

  // список должностей и статус загрузки
  const [infoIsLoad, setInfoIsLoad] = useState({
    positions: null,
    loaded: false,
  });
  //предыдущее значение навания должности
  const [prevPositionData, setPrevPositionData] = useState(null);
  // статус модального окна
  const [modalAddEdit, setModalAddEdit] = useState({
    isOpen: false,
    action: null,
  });

  useEffect(() => {
    if (socket && socket.connected) {
      socket.on('position_changed', (data, action) => {
        // после изменений на сервере меняем стейт локально
        const updateState = (data, action) => {
          // добавим должность в список стейта
          const getStateAfterAdding = (prev, data) => {
            if (!prev?.positions)
              return {
                positions: null,
                loaded: false,
              };
            return {
              positions: [...prev.positions, data],
              loaded: false,
            };
          };
          // заменяем название должности
          const getStateAfterEditing = (prev, newItem) => {
            if (!prev?.positions)
              return {
                positions: null,
                loaded: false,
              };
            const ind = prev.positions.findIndex(({ id }) => id === newItem.id);
            return {
              positions: [...prev.positions.slice(0, ind), newItem, ...prev.positions.slice(ind + 1)],
              loaded: false,
            };
          };
          const getStateAfterDeleting = (prev) => {
            return {
              loaded: false,
              positions: prev.positions.filter((item) => item.id !== data.id),
            };
          };

          switch (action) {
            case 'added':
              setInfoIsLoad((prev) => getStateAfterAdding(prev, data));
              break;
            case 'edited':
              setInfoIsLoad((prev) => getStateAfterEditing(prev, data));
              break;
            case 'deleted':
              setInfoIsLoad((prev) => getStateAfterDeleting(prev, data));
              break;
            default:
          }
        };
        updateState(data, action);
      });
      return () => socket.off('position_changed');
    }
  }, [socket]);

  const { showSnackbar } = useContext(SnackbarContext);

  const getPositionsInfo = () => {
    axios
      .get(url_get_positions_list)
      .then((res) => setInfoIsLoad({ loaded: true, positions: res.data.positions }))
      .catch(() => showSnackbar('Возникла ошибка при получении данных о должностях'));
  };

  // список должностей , вход в комнату
  useEffect(() => {
    if (socket && socket.connected) {
      socket.emit('add_to_room', 'positions_list');
    }

    getPositionsInfo();
  }, [socket]);

  const isDuplicateError = useDuplicateErrorHandler();

  // вызов модального окна для добавления должности
  const handlerAdd = () => {
    setModalAddEdit({ isOpen: true, action: 'add' });
  };
  // настройка для полей формы название должности
  const title = {
    name: 'title',
    icon: true,
    placeholder: '',
    label: t(`${translateKey}.title_position`),
    type: 'text',
  };
  const inputs = { title: { ...title } };
  // отправляем data о новом отделе на сервер, закрываем модалку создания
  const addNewPosition = (data) => {
    const { title } = data;
    let body = {
      row: {
        title: title,
      },
    };
    axios
      .post(url_post_new_positions, body)
      .then(() => {
        onCloseAddModal();
        if (!socket || !socket.connected) {
          setInfoIsLoad({ loaded: true, positions: null });
          getPositionsInfo();
        }
      })
      .catch((error) => {
        if (isDuplicateError(error, 'Должность с таким названием уже существет')) return;
        showSnackbar('Возникла ошибка при создании должности');
      });
  };

  const handlerDelete = (data) => {
    axios.delete('/api/positions/delete', {
      data: { position_id: data.id },
    });
  };
  // закрыть окно создания-редактирования
  const onCloseAddModal = () => {
    setModalAddEdit({ isOpen: false, action: null });
    // setInfoIsLoad(false);
    setPrevPositionData(null);
  };
  // отправим на сервер измененные данные
  const sendEditPosition = (data) => {
    let body = {
      row: {
        title: data.title,
      },
      position_id: prevPositionData.id,
    };
    axios
      .put(url_put_positions, body)
      .then(() => {
        onCloseAddModal();
      })
      .catch((error) => {
        if (isDuplicateError(error, 'Должность с таким названием уже существет')) return;
        showSnackbar('Возникла ошибка при редактировании должности');
      });
  };
  // срабатывает на кнопку отправить в форме (submit)
  const sendDataForm = (data) => {
    switch (modalAddEdit.action) {
      case 'add':
        return addNewPosition(data);
      case 'edit':
        return sendEditPosition(data);
      default:
        return addNewPosition(data);
    }
  };

  // получить редактируемую должность при клике на карандаш, вызвать модалку редактирования
  const handlerEdit = (data) => {
    setPrevPositionData(data);
    setModalAddEdit({ isOpen: true, action: 'edit' });
  };
  // при клике на крестик модалки с списком  => вышли из комнаты
  const handlerCloseWindow = () => {
    if (socket && socket.connected) {
      socket.emit('leave_room', 'positions_list');
    }
    props.onClose();
  };

  if (infoIsLoad) {
    return (
      <>
        {(modalAddEdit.action === 'edit' || modalAddEdit.action === 'add') && (
          <section className="modal">
            <div className="modal__wrapper">
              <div className="modal__inner modal__inner--medium">
                <section className="modal__body">
                  <PositionFormCreateEdit
                    infoIsLoad={infoIsLoad}
                    prevData={prevPositionData} //  данные с сервера об отделе
                    action={modalAddEdit.action === 'add' ? 'add' : 'edit'}
                    inputs={inputs} // поля hook forms
                    onClose={onCloseAddModal}
                    onCreate={sendDataForm}
                  />
                </section>
              </div>
            </div>
          </section>
        )}
        {!modalAddEdit.isOpen && (
          <PositionsList
            list={infoIsLoad.positions}
            onClose={handlerCloseWindow}
            onAdd={handlerAdd}
            onEdit={handlerEdit}
            onDelete={handlerDelete}
          >
            <PositionSubtitle
              columns={{
                col_1: t(`${translateKey}.position`),
                col_2: t(`${translateKey}.employees`),
              }}
            />
          </PositionsList>
        )}
      </>
    );
  } else {
    return <Preloader />;
  }
};

export default Positions;

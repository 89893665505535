import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import axios from 'axios';

import { updateOldMessage } from '../redux/features/oldMessagesSlice';
import MessageContainer from '../components/chat/message/MessageContainer';

// функция для определения координат при селекте текста в сообщении и вывода контекстного меню
export const selectableTextAreaMouseUp = (e, isMousePressedHere, showPopover, localDateCreated, author, id) => {
  const offsetLeftToContainer = e.target.offsetLeft;
  const offsetTopToContainer = e.target.offsetTop;
  // в зависимости от dom event или react dom event, извлечь offsets
  const offsetX = e.offsetX ? e.offsetX : e.nativeEvent.offsetX;
  const offsetY = e.offsetY ? e.offsetY : e.nativeEvent.offsetY;

  if (isMousePressedHere.current) {
    setTimeout(() => {
      const selectedText = window.getSelection().toString();
      if (selectedText.length) {
        showPopover({
          show: true,
          x: offsetX + offsetLeftToContainer,
          y: offsetY + offsetTopToContainer + 15,
          data: {
            reply_id: id,
            reply_text: selectedText,
            date: localDateCreated,
            author: author,
          },
        });
      }
    }, 0);
    isMousePressedHere.current = false;
  }
};

// react custom hook для отслеживания изменений в чате, прикрепляется к сокету внутри react компонента
export const useChatListener = ({
  additionalMessageProps,
  projectData,
  chatPageRef,
  setMessagesList,
  messagesList,
  pinnedMessages,
  setPinnedMessages,
  oldMessages,
  isChatEnd,
}) => {
  const dispatch = useDispatch();
  // функция реагирует на изменения в чате
  const chatChangeListener = useCallback(
    (data, action) => {
      if (projectData?.chat?.id) {
        switch (action) {
          case 'added':
            if (isChatEnd && data.chat_id === projectData.chat.id) {
              let focusOnThisMessage = false;
              // если юзер находится в конце страницы чата, то, после добавления сообщения, сфокусировать на последнем сообщении
              if (
                chatPageRef?.current?.scrollHeight - chatPageRef?.current?.scrollTop ===
                chatPageRef?.current?.clientHeight
              ) {
                focusOnThisMessage = true;
              }

              setMessagesList((messages) => [
                ...messages,
                <MessageContainer
                  key={data.id}
                  {...data}
                  message_files={data.files}
                  goals={data.chat_goal_results}
                  projectData={projectData}
                  {...additionalMessageProps}
                />,
              ]);

              if (focusOnThisMessage) {
                setTimeout(() => {
                  const newMessage = document.getElementById(`messageId${data.id}`);
                  if (newMessage) {
                    newMessage.scrollIntoView();
                  }
                }, 100);
              }
            }
            break;

          case 'edited':
          case 'like':
          case 'dislike':
          case 'cancel_like_dislike':
          case 'recovered':
            if (data.chat_id === projectData.chat.id) {
              const foundIndex = messagesList.findIndex((message) => message.props.id === data.id);
              if (foundIndex !== -1) {
                setMessagesList((messagesList) => {
                  const updMessages = [...messagesList];
                  updMessages[foundIndex] = (
                    <MessageContainer
                      key={data.id}
                      {...data}
                      message_files={data.files}
                      goals={data.chat_goal_results}
                      projectData={projectData}
                      {...additionalMessageProps}
                    />
                  );
                  return updMessages;
                });
              }

              const foundPinndeMessageIndex = pinnedMessages?.findIndex((message) => message.props.id === data.id);
              if (foundPinndeMessageIndex !== undefined && foundPinndeMessageIndex !== -1) {
                setPinnedMessages((messagesList) => {
                  const updMessages = [...messagesList];
                  updMessages[foundPinndeMessageIndex] = (
                    <MessageContainer
                      key={data.id}
                      {...data}
                      message_files={data.files}
                      goals={data.chat_goal_results}
                      projectData={projectData}
                      pinnedMessageType={true}
                      {...additionalMessageProps}
                    />
                  );
                  return updMessages;
                });
              }

              dispatch(updateOldMessage({ data }));
            }
            break;

          case 'pinned':
            const messageToPin = messagesList.find((message) => message.props.id === data.id);
            if (messageToPin) {
              setMessagesList((messages) =>
                messages.map((message) => {
                  if (message.props.id === messageToPin.props.id) {
                    return (
                      <MessageContainer
                        key={data.id}
                        {...messageToPin.props}
                        pinned={1}
                        pinnedMessageType={false}
                        {...additionalMessageProps}
                      />
                    );
                  } else return message;
                }),
              );
              setPinnedMessages((pinnedMessages) => [
                ...pinnedMessages,
                <MessageContainer
                  key={data.id}
                  {...messageToPin.props}
                  pinned={1}
                  pinnedMessageType={true}
                  {...additionalMessageProps}
                />,
              ]);
            } else {
              const oldMessageToPin = oldMessages.find((message) => message.id === data.id);

              if (oldMessageToPin) {
                setPinnedMessages((pinnedMessages) => [
                  ...pinnedMessages,
                  <MessageContainer
                    key={data.id}
                    {...oldMessageToPin}
                    pinned={1}
                    pinnedMessageType={true}
                    {...additionalMessageProps}
                  />,
                ]);
              }
            }

            dispatch(updateOldMessage({ data, type: action }));
            break;

          case 'unpinned':
            const messageToUnpin = pinnedMessages.find((message) => message.props.id === data.id);
            if (messageToUnpin) {
              setPinnedMessages((pinnedMessages) =>
                pinnedMessages.filter((pinnedMessage) => pinnedMessage.props.id !== messageToUnpin.props.id),
              );
              setMessagesList((messages) => {
                const unpinnedMessageIndex = messages.findIndex((message) => message.props.id === data.id);
                if (unpinnedMessageIndex !== -1) {
                  return messages.map((message) => {
                    if (message.props.id === data.id) {
                      return (
                        <MessageContainer
                          key={data.id}
                          {...messageToUnpin.props}
                          pinned={0}
                          {...additionalMessageProps}
                        />
                      );
                    } else return message;
                  });
                } else return messages;
              });
            }
            dispatch(updateOldMessage({ data, type: action }));
            break;

          case 'deleted':
            const messageIndexToDelete = messagesList.findIndex((message) => message.props.id === data.id);

            if (messageIndexToDelete !== -1) {
              setMessagesList((messages) => {
                const updMessages = [...messages];
                updMessages[messageIndexToDelete] = (
                  <MessageContainer
                    key={data.id}
                    {...updMessages[messageIndexToDelete].props}
                    deleted={1}
                    {...additionalMessageProps}
                  />
                );
                return updMessages;
              });
            }

            const pinnedMessageIndexToDelete = pinnedMessages?.findIndex((message) => message.props.id === data.id);
            if (pinnedMessageIndexToDelete !== undefined && pinnedMessageIndexToDelete !== -1) {
              setPinnedMessages((pinnedMessages) => {
                const updPinnedMessages = [...pinnedMessages];
                updPinnedMessages[pinnedMessageIndexToDelete] = (
                  <MessageContainer
                    key={data.id}
                    {...updPinnedMessages[pinnedMessageIndexToDelete].props}
                    deleted={1}
                    pinnedMessageType={true}
                    {...additionalMessageProps}
                  />
                );
                return updPinnedMessages;
              });
            }
            dispatch(updateOldMessage({ data, type: action }));

            break;

          default:
            return;
        }
      }
    },
    [
      projectData,
      messagesList,
      pinnedMessages,
      setMessagesList,
      setPinnedMessages,
      chatPageRef,
      dispatch,
      oldMessages,
      additionalMessageProps,
      isChatEnd,
    ],
  );

  return chatChangeListener;
};

// для загрузки предыдущих сообщений в чате
export const requestPreviousMessages = (chatId, earliestMessageId) => {
  return axios.get(`/api/load_chat_prev_messages/${chatId}/${earliestMessageId}`);
};

export const requestNextMessages = (chatId, lastMessageId) => {
  return axios.get(`/api/load_chat_next_messages/${chatId}/${lastMessageId}`);
};

export const formatMessages = ({ projectData, messages, isPinned, additionalMessageProps }) => {
  if (messages) {
    return messages.map((message) => {
      return (
        <MessageContainer
          key={message.id}
          {...message}
          goals={message.chat_goal_results}
          likes={message.message_likes}
          projectData={projectData}
          tags={message.message_tags}
          pinnedMessageType={isPinned}
          {...additionalMessageProps}
        />
      );
    });
  }

  return [];
};

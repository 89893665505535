import { useEffect, useState, useContext } from 'react';
import axios from 'axios';
import { Controller } from 'react-hook-form';

import SnackbarContext from 'src/contexts/SnackbarContext';

import { formatDateWithDots } from '../calendar-form/CalendarForm';

const ProjectAttachedStorage = ({ control, selectedProjectTitle }) => {
  const [projects, setProjects] = useState([]);
  const [showDropdown, setShowDropdown] = useState();
  const [projectStatus, setProjectStatus] = useState('all');
  const [searchString, setSearchString] = useState('');

  const { showSnackbar } = useContext(SnackbarContext);

  // получить список проектов, доступных для создания хранилища
  useEffect(() => {
    axios
      .get('/api/storage_create')
      .then((r) => setProjects(r.data.result.projects))
      .catch(() => {
        showSnackbar('Возникла ошибка при получении данных ');
      });
  }, []);

  const renderSelectedProject = (selectedProjectId) => {
    const selectedProject = projects.find((p) => p.id === selectedProjectId);
    if (selectedProject) return selectedProject.title;
    else if (selectedProjectTitle) return selectedProjectTitle;
    return '';
  };

  return (
    <>
      <label className="modal__label" htmlFor="">
        Привязать к проекту
      </label>

      <Controller
        control={control}
        name="project_id"
        render={({ field: { value, onChange } }) => {
          return (
            <div className={`dropdown ${showDropdown ? '_show' : ''}`}>
              <div className="dropdown__input" data-selected="1" onClick={() => setShowDropdown(!showDropdown)}>
                {renderSelectedProject(value)}
              </div>
              <div className="dropdown__select select select--extended">
                <div className="select__header">
                  <ul className="select__tabs">
                    <li
                      className={`select__tab select__tab--${projectStatus === 'all' ? 'active' : ''}`}
                      onClick={() => setProjectStatus('all')}
                    >
                      Все
                    </li>
                    <li
                      className={`select__tab select__tab--${projectStatus === 'actual' ? 'active' : ''}`}
                      onClick={() => setProjectStatus('actual')}
                    >
                      Актуальные
                    </li>
                    <li
                      className={`select__tab select__tab--${projectStatus === 'completed' ? 'active' : ''}`}
                      onClick={() => setProjectStatus('completed')}
                    >
                      Завершенные
                    </li>
                  </ul>
                  <div className="select__search-input-wrapper">
                    <input
                      className="select__search-input"
                      type="text"
                      value={searchString}
                      onChange={(e) => setSearchString(e.target.value)}
                    />
                    <button className="select__search-button"></button>
                  </div>
                </div>
                <ul>
                  {projects
                    .filter((p) => {
                      if (projectStatus === 'actual' && p.status === 'finished') return false;
                      if (projectStatus === 'completed' && p.status !== 'finished') return false;
                      if (searchString && !p.title.includes(searchString)) return false;
                      return true;
                    })
                    .map((p, i) => (
                      <li
                        key={i}
                        className="select__item"
                        onClick={() => {
                          onChange(p.id);
                          setShowDropdown(false);
                        }}
                      >
                        <span className="select__name">{p.title}</span>
                        <span
                          className={`select__project-status select__project-status--${
                            p.status === 'finished' ? 'completed' : 'actual'
                          }`}
                        >
                          {p.status === 'finished' ? 'Завершенный' : 'Актуальный'}
                        </span>
                        {(p.date_real_start || p.date_real_finish) && (
                          <span>
                            {formatDateWithDots(p.date_real_start)} -&nbsp;
                            {formatDateWithDots(p.date_real_finish)}
                          </span>
                        )}
                      </li>
                    ))}
                </ul>
              </div>
            </div>
          );
        }}
      />
    </>
  );
};

export default ProjectAttachedStorage;

import { useCallback, useEffect, useState } from 'react';
import axios from 'axios';
import { Link, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Preloader from '../../preloaders/Preloader';

import UserProfileModal from '../../../windows/profile/UserProfileModal';
import LocalDateTime from '../../shared/LocalDateTime';
import { renderMessage } from '../../../utilize/draftjs-helper-functions';
import useAuth from '../../../hooks/useAuth';
import AttachedFile from '../../files/AttachedFile';
import UserStatus from '../../employees/UserStatus';
import { files_url } from '../../../settings/base-url';

import ProjectTree from './ProjectTree';
import { renderProjectOrTaskName } from './SideMenu';

const ResultsSidePanel = ({ sideMenu, openSideMenu, projectData }) => {
  const { projectId, taskId } = useParams();
  const { t } = useTranslation();
  const auth = useAuth();
  //

  const [dataType, setDataType] = useState(null);
  const [panelData, setPanelData] = useState(null);
  const [userProfile, showUserProfile] = useState();
  const [projectTree, showProjectTree] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errorLoading, setErrorLoading] = useState(false);
  // каждый раз, когда нажимается кнопка открытия панели, производится reset данных
  useEffect(() => {
    if (sideMenu === 'results' && !panelData && dataType && projectData && !isLoading) {
      setIsLoading(true);
      axios
        .get(`/api/sidebar_goals/${dataType}/${projectData.id}`)
        .then((response) => {
          const chat_messages = response.data.chat_messages;
          const data_goals = response.data.data_goals;

          setPanelData({
            goals: data_goals,
            messages: chat_messages,
            id: projectData.id,
            title: projectData.title,
          });

          setIsLoading(false);
          setErrorLoading(false);
        })
        .catch(() => {
          setIsLoading(false);
          setErrorLoading(true);
        });
    } else if (sideMenu !== 'results' && panelData) {
      setTimeout(() => {
        setPanelData(null);
        setDataType(null);
        showProjectTree(false);
        setSidebarDataType(null);
      }, 1000);
    }
  }, [sideMenu, dataType, projectData, panelData, isLoading]);

  useEffect(() => {
    if (taskId && dataType !== 'task') setDataType('task');
    if (!taskId && dataType !== 'project') setDataType('project');
  }, [taskId, dataType]);

  const [sidebarDataType, setSidebarDataType] = useState();

  const showRelevantTaskInfo = useCallback((dataType, dataId, title) => {
    setPanelData(null);
    showProjectTree(false);
    setErrorLoading(false);
    setIsLoading(true);
    axios
      .get(`/api/sidebar_goals/${dataType}/${dataId}`)
      .then((response) => {
        const chat_messages = response.data.chat_messages;
        const data_goals = response.data.data_goals;

        setPanelData({
          goals: data_goals,
          messages: chat_messages,
          id: dataId,
          title: title,
        });

        setIsLoading(false);
        setSidebarDataType(dataType);
        setErrorLoading(false);
      })
      .catch(() => {
        setPanelData({});
        setIsLoading(false);
        setErrorLoading(true);
      });
  }, []);

  return (
    <>
      {userProfile && <UserProfileModal profileId={userProfile} onClose={() => showUserProfile(null)} />}

      <aside data-aside="results" className={`aside ${sideMenu === 'results' ? '_active' : ''}`}>
        <div className="aside__header">
          <span className="aside__title aside__title--results">{t('common.results')}</span>
          <button
            className="aside__button-arrow"
            onClick={() => {
              setTimeout(() => {
                setPanelData(null);
                setDataType(null);
              }, 1000);
              openSideMenu(null);
            }}
          ></button>
        </div>
        <div className="aside__body">
          {isLoading && <Preloader />}
          {errorLoading && <div>{t('ErrorMessages.default')}</div>}
          {panelData && (
            <>
              {projectTree ? (
                <ProjectTree
                  update={sideMenu === 'results'}
                  showRelevantTaskInfo={showRelevantTaskInfo}
                  showProjectTree={showProjectTree}
                />
              ) : (
                <>
                  <Link to="#" className="aside__backward" onClick={() => showProjectTree(true)}>
                    Структура проекта
                  </Link>

                  <p className="aside_parent-task">
                    {renderProjectOrTaskName(panelData, taskId, sidebarDataType, projectData)}
                  </p>

                  {sidebarDataType === 'project' && taskId && (
                    <Link to={`/projects/${projectId}`} className="aside__link">
                      {t('Project.open_project')}
                    </Link>
                  )}

                  {sidebarDataType === 'task' && panelData && panelData.id !== +taskId && (
                    <Link to={`/projects/${projectId}/tasks/${panelData.id}`} className="aside__link">
                      {t('Project.open_task')}
                    </Link>
                  )}

                  <ol className="aside__result-list">
                    {Array.isArray(panelData.messages) &&
                      panelData.goals.map((goal, i) => {
                        const messagesForThisGoal = panelData.messages.filter((message) => {
                          const result = message.chat_goal_results && message.chat_goal_results[0];
                          if (!result) return false;
                          return goal.id === result.project_goal_id || goal.id === result.task_goal_id;
                        });

                        return (
                          <li className="aside__result-task result-task" key={i}>
                            <h3 className="result-task__title">{goal.description}</h3>
                            {messagesForThisGoal.map((chat_message, i) => {
                              const {
                                employee_id,
                                employee_first_name,
                                employee_last_name,
                                date_created,
                                editor_state,
                                message_files,
                                message_tags,
                                id,
                                chat_goal_results,
                              } = chat_message;

                              return (
                                <div className="result-task__item" key={i}>
                                  <div
                                    className={`result-task__userlink ${
                                      auth?.user?.is_guest ? '_no-pointer' : '_pointer'
                                    }`}
                                    onClick={() => {
                                      if (!auth?.user?.is_guest) showUserProfile(employee_id);
                                    }}
                                  >
                                    <UserStatus
                                      userId={employee_id}
                                      lastName={employee_last_name}
                                      firstName={employee_first_name}
                                    />
                                    {/* <img
                                      className="result-task__userpic"
                                      src="assets/images/userpic.png"
                                      alt=""
                                    /> */}
                                    <span className="result-task__username">
                                      {`${employee_first_name || ''} ${employee_last_name || ''}`}
                                    </span>
                                  </div>

                                  <p className="result-task__date">
                                    <LocalDateTime dateTime={date_created} />
                                  </p>

                                  {message_tags.length > 0 && (
                                    <p>
                                      {message_tags.map((tag, i) => (
                                        <span className={`task-current__tag tag tag--icon-${tag.color}`} key={i}>
                                          {tag.name}
                                        </span>
                                      ))}
                                    </p>
                                  )}

                                  <p className="result-task__attach-text">{renderMessage(editor_state)}</p>
                                  <div className="result-task__col">
                                    {message_files.map((file, i) => {
                                      const url = `${files_url}/chat_messages/files/${id}/${file.id}/${file.file}?token=${auth.token}`;

                                      return (
                                        <AttachedFile
                                          fileUrl={url}
                                          fileName={file.file}
                                          key={i}
                                          storageFileId={file.from_storage}
                                        />
                                      );
                                    })}
                                  </div>
                                  <div className="result-task__control">
                                    {chat_goal_results[0].result === 'wait' && (
                                      <p className="result-task__pending">{t('Goals.pending')}</p>
                                    )}
                                    {chat_goal_results[0].result === 'ok' && (
                                      <p className="result-task__accept">Принят</p>
                                    )}
                                    {chat_goal_results[0].result === 'declined' && (
                                      <p className="result-task__decline">Не принят</p>
                                    )}
                                    {/* <button className="result-task__button-dots"></button> */}
                                  </div>
                                </div>
                              );
                            })}
                          </li>
                        );
                      })}
                  </ol>
                </>
              )}
            </>
          )}
        </div>
      </aside>
    </>
  );
};

export default ResultsSidePanel;

import './ProgressBar.css';

const ProgressBar = ({ progressPercent, isRemoving, isUploaded, errorUploading }) => {
  const uploadStatusColor = () => {
    if (isRemoving || errorUploading) return '_fill--error';
    if (isUploaded) return '_fill--complete';
    return '';
  };

  return (
    <div className="_progress">
      <div className={`_fill ${uploadStatusColor()}`} style={{ width: `${progressPercent || 0}%` }}></div>
    </div>
  );
};

export default ProgressBar;

import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { department_window, position_window } from '../../settings/window-types';
import InlineModal from '../modals/InlineModal';
import TagsModal from '../tags/TagsModal';
import useAuth from '../../hooks/useAuth';

import SiteNavInnerList from './siteNavInnerList';

const HeaderSiteNavList = ({
  mobilMenu,
  navListOpen,
  handlerSelectList,
  handlerBlur,
  handlerMobilMenu,
  popupWindow,
}) => {
  const { t } = useTranslation();
  // для открывания модального окна с тэгами
  const [tagsModal, toggleTagsModal] = useState();
  const auth = useAuth();

  return (
    <>
      {auth.user?.is_guest === 0 && (
        <div
          tabIndex={1}
          onBlur={() => {
            handlerBlur();
          }}
          className={mobilMenu ? 'header__site-nav _active' : 'header__site-nav'}
        >
          <Link onClick={() => handlerMobilMenu('close')} className="site-nav__link" to="/team/users">
            {t('HeaderSiteNavList.team')}
          </Link>

          {/* выпадающее навигационное меню - Работа */}
          <div
            data-menu
            className={(mobilMenu || navListOpen) === 'work' ? 'site-nav__list _active' : 'site-nav__list'}
          >
            <span
              className="site-nav__link"
              onClick={
                !mobilMenu
                  ? () => {
                      handlerSelectList('work');
                    }
                  : null
              }
            >
              Работа
            </span>
            <SiteNavInnerList
              mobilMenu={mobilMenu}
              navListOpen={navListOpen}
              id="work"
              list={{
                Проекты: {
                  // открыть страницу проектов
                  to: '/projects',
                },
                'Мои задачи': {
                  // открыть страницу Мои задачи
                  to: '/tasks?relation=creator,responsible,executor,member',
                },
                'Поручения мне': {
                  // TODO открыть Поручения мне
                  to: '/tickets/me',
                },
                'Поручения от меня': {
                  // TODO открыть Поручения мне
                  to: '/tickets?filter=active',
                },
                Хранилища: {
                  to: '/storages',
                },
              }}
            />
          </div>

          {/* выпадающее навигационное меню - Компания */}
          <div
            data-menu
            className={(mobilMenu || navListOpen) === 'company' ? 'site-nav__list _active' : 'site-nav__list'}
          >
            <span
              className="site-nav__link"
              onClick={
                !mobilMenu
                  ? () => {
                      handlerSelectList('company');
                    }
                  : null
              }
            >
              Компания
            </span>
            <SiteNavInnerList
              mobilMenu={mobilMenu}
              navListOpen={navListOpen}
              id="company"
              list={{
                Новости: {
                  // открыть страницу Новости
                  to: '/news',
                },
                Блоги: {
                  // открыть страницу Блоги
                  to: '/blogs',
                },
              }}
            />
          </div>

          {/* выпадающее навигационное меню - Настройки */}
          <div
            data-menu
            className={(mobilMenu || navListOpen) === 'settings' ? 'site-nav__list _active' : 'site-nav__list'}
          >
            <span
              className="site-nav__link"
              onClick={
                !mobilMenu
                  ? () => {
                      handlerSelectList('settings');
                    }
                  : null
              }
            >
              {t('HeaderSiteNavList.settings')}
            </span>
            <SiteNavInnerList
              mobilMenu={mobilMenu}
              navListOpen={navListOpen}
              id="settings"
              list={{
                [t('SiteNavInnerList_1.manage_department')]: {
                  // открыть окно со списком отделов
                  handler: () => popupWindow.props.onOpen(department_window),
                },
                [t('SiteNavInnerList_1.manage_position')]: {
                  // открыть окно со списком должностей
                  handler: () => popupWindow.props.onOpen(position_window),
                  // to: "./",
                },
                [t('common.tags')]: {
                  handler: () => toggleTagsModal(true),
                  to: './',
                },
              }}
            />
          </div>
        </div>
      )}

      {tagsModal && (
        <InlineModal close={() => toggleTagsModal(false)}>
          <TagsModal />
        </InlineModal>
      )}
    </>
  );
};

export default HeaderSiteNavList;

import { styled } from 'styled-components';

export const StyledAvatar = styled.img`
  border-radius: 50%;
  width: ${({ size }) => size}px;
  min-width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
`;

export const StyledMockAvatar = styled.div`
  border-radius: 50%;
  width: ${({ size }) => size}px;
  min-width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  min-width: ${({ size }) => size}px;

  background-color: #cecece;

  display: flex;
  justify-content: center;
  align-items: center;
`;

import React from 'react';

import ru from 'date-fns/locale/ru';

import { registerLocale } from 'react-datepicker';

import { StyledDatePicker, StyledDateWrapper } from './style';

const DateInput = ({ placeholder, dateFormat, size, selected, onChange, ...props }) => {
  registerLocale('ru', ru);

  const onChangeHandler = (value) => onChange(value);

  return (
    <StyledDateWrapper>
      <StyledDatePicker
        {...props}
        placeholderText={placeholder}
        dateFormat={dateFormat}
        size={size}
        onChange={onChangeHandler}
        selected={selected}
        locale="ru"
      />
    </StyledDateWrapper>
  );
};

export default DateInput;

import { useState, memo } from 'react';
import { useTranslation } from 'react-i18next';

import { ProjectStructureContextProvider } from '../../../contexts/ProjectStructureContext';

import { useMessageContext } from '../../../contexts/MessageContext';

import useAuth from '../../../hooks/useAuth';

import InfoSidePanel from './InfoSidePanel';
import FilesSidePanel from './FilesSidePanel';
import LinksSidePanel from './LinksSidePanel';
import StructureSidePanel from './StructureSidePanel';
import ResultsSidePanel from './ResultsSidePanel';
import SearchSidePanel from './SearchSidePanel';
import TagsSidePanel from './TagsSidePanel';
// import EventsSidePanel from "./EventsSidePanel";
import MembersSidePanel from './MembersSidePanel';

export const renderProjectOrTaskName = (panelData, taskId, sidebarDataType, projectData) => {
  let projectType = '';
  if (!panelData) {
    if (!projectData) return;
    projectType = taskId ? 'Задача' : 'Проект';
    return `${projectType}: ${projectData.title} `;
  }
  if (!sidebarDataType) {
    projectType = taskId ? 'Задача' : 'Проект';
  } else {
    projectType = sidebarDataType === 'task' ? 'Задача' : 'Проект';
  }
  return `${projectType}: ${panelData.title}`;
};

const SideMenuContainer = (props) => {
  const { guestRights } = useMessageContext();
  const auth = useAuth();

  return <SideMenu {...props} guestRights={guestRights} auth={auth} />;
};

const SideMenu = memo(({ projectData, guestRights, auth }) => {
  const { t } = useTranslation();

  const [sideMenu, openSideMenu] = useState();

  const toggleSideMenu = (e) => {
    const menuName = e.target.dataset.triggerAside;
    if (sideMenu === menuName) return openSideMenu(null);
    openSideMenu(menuName);
  };

  return (
    <>
      <section className="side-menu">
        <button
          className={`side-menu__button side-menu__button--info ${sideMenu === 'info' ? '_active' : ''}`}
          data-trigger-aside="info"
          title={t('common.info')}
          onClick={toggleSideMenu}
        ></button>
        <button
          className={`side-menu__button side-menu__button--file ${sideMenu === 'file' ? '_active' : ''}`}
          data-trigger-aside="file"
          title={t('common.files')}
          onClick={toggleSideMenu}
        ></button>
        <button
          className={`side-menu__button side-menu__button--attach ${sideMenu === 'link' ? '_active' : ''}`}
          data-trigger-aside="link"
          title={t('common.links')}
          onClick={toggleSideMenu}
        ></button>
        {(!auth?.user?.is_guest || guestRights?.includes('access_children')) && (
          <button
            className={`side-menu__button side-menu__button--db ${sideMenu === 'db' ? '_active' : ''}`}
            data-trigger-aside="db"
            title={t('common.structure')}
            onClick={toggleSideMenu}
          ></button>
        )}
        <button
          className={`side-menu__button side-menu__button--filedone ${sideMenu === 'results' ? '_active' : ''}`}
          data-trigger-aside="results"
          title={t('common.results')}
          onClick={toggleSideMenu}
        ></button>
        <button
          className={`side-menu__button side-menu__button--search ${sideMenu === 'search' ? '_active' : ''}`}
          data-trigger-aside="search"
          title={t('common.search')}
          onClick={toggleSideMenu}
        ></button>
        <button
          className={`side-menu__button side-menu__button--tags ${sideMenu === 'tags' ? '_active' : ''}`}
          data-trigger-aside="tags"
          title={t('common.tags')}
          onClick={toggleSideMenu}
        ></button>
        {/* <button
          className={`side-menu__button side-menu__button--event ${
            sideMenu === "event" ? "_active" : ""
          }`}
          data-trigger-aside="event"
          title={t("common.events")}
          onClick={toggleSideMenu}
        ></button> */}
        <button
          className={`side-menu__button side-menu__button--profile ${sideMenu === 'people' ? '_active' : ''}`}
          data-trigger-aside="people"
          title={t('common.members')}
          onClick={toggleSideMenu}
        ></button>
      </section>
      <ProjectStructureContextProvider>
        <InfoSidePanel sideMenu={sideMenu} openSideMenu={openSideMenu} projectData={projectData} />

        <FilesSidePanel sideMenu={sideMenu} openSideMenu={openSideMenu} projectData={projectData} />

        <LinksSidePanel sideMenu={sideMenu} openSideMenu={openSideMenu} projectData={projectData} />

        {(!auth?.user?.is_guest || guestRights?.includes('access_children')) && (
          <StructureSidePanel sideMenu={sideMenu} openSideMenu={openSideMenu} projectData={projectData} />
        )}

        <ResultsSidePanel sideMenu={sideMenu} openSideMenu={openSideMenu} projectData={projectData} />

        <SearchSidePanel sideMenu={sideMenu} openSideMenu={openSideMenu} projectData={projectData} />

        <TagsSidePanel sideMenu={sideMenu} openSideMenu={openSideMenu} projectData={projectData} />

        {/* <EventsSidePanel
          sideMenu={sideMenu}
          openSideMenu={openSideMenu}
          projectData={projectData}
        /> */}

        <MembersSidePanel sideMenu={sideMenu} openSideMenu={openSideMenu} projectData={projectData} />
      </ProjectStructureContextProvider>
    </>
  );
});

export default SideMenuContainer;

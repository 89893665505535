import { useState, useContext, useEffect, useRef } from 'react';
import { useForm } from 'react-hook-form';

import { string, object } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import axios from 'axios';

import { useDispatch } from 'react-redux';

import ConfirmAction from '../warnings/ConfirmAction';
import SnackbarContext from '../../contexts/SnackbarContext';

import { useEventTriggerOnEscPress } from '../../utilize/helper-functions';
import { getStorageTree } from '../../redux/features/storageSlice';

const formSchema = object({ title: string().required() });

// извлечь тип файла после точки, для прикрепления этого типа при отправке запроса
const extractFileExtension = (filename, extensionRef) => {
  const splitName = filename.split('.');
  if (splitName.length > 1) {
    extensionRef.current = splitName.pop();
    return splitName.join('.');
  } else return filename;
};

const RenameModal = ({ oldTitle, dataType, dataId, close, storageId }) => {
  // const { storageId } = useParams();

  const extensionRef = useRef('');

  const {
    register,
    handleSubmit,
    // watch,
    formState: { isDirty, isValid },
    setFocus,
  } = useForm({
    resolver: yupResolver(formSchema),
    mode: 'onChange',
    defaultValues: {
      title: dataType === 'folder' ? oldTitle : extractFileExtension(oldTitle, extensionRef),
    },
  });

  const [confirmClose, setConfirmClose] = useState();

  const handleClose = () => {
    if (isDirty) return setConfirmClose(true);
    close();
  };

  useEventTriggerOnEscPress(handleClose);

  const { showSnackbar } = useContext(SnackbarContext);

  const [isSubmitting, setIsSubmitting] = useState();

  const dispatch = useDispatch();

  // сфокусировать на текстовом поле
  useEffect(() => {
    setFocus('title');
  }, [setFocus]);

  const submitName = async (data) => {
    setIsSubmitting(true);
    let title = data.title;
    if (dataType === 'file') title += '.' + extensionRef.current;
    axios
      .put('/api/rename_storage_content', {
        title: title,
        [`${dataType}_id`]: dataId,
      })
      .then(() => {
        dispatch(getStorageTree({ storageId, showSnackbar }));
        // dispatch(getStorageData({ storageId, showSnackbar }));
        close();
      })
      .catch((e) => {
        if (!e.response) showSnackbar('Ошибка сервера');
        if (e.response.status === 409) {
          showSnackbar(`${dataType === 'folder' ? 'Папка' : 'Файл'} с таким названием уже существует`);
        } else {
          showSnackbar('Возникла ошибка при переименовании');
        }
        setIsSubmitting(false);
      });
  };

  return (
    <>
      <section className="modal">
        <div className="modal__wrapper">
          <div className="modal__inner">
            <section className="modal__body">
              <div className="modal__header">
                <h2 className="modal__title">{`Переименовать ${dataType === 'folder' ? 'папку' : 'файл'}`}</h2>
                <button className="modal__close-modal" onClick={handleClose}></button>
              </div>
              <div className="modal__row">
                <label className="modal__label" htmlFor="folderTitle">
                  {`Название ${dataType === 'folder' ? 'папки' : 'файла'}`}
                </label>
                <input className="modal__input" id="folderTitle" {...register('title')} />
              </div>

              <div className="modal__button-box">
                <button
                  className="modal__button modal__button--create"
                  disabled={!isValid || !isDirty || isSubmitting}
                  onClick={handleSubmit(submitName)}
                >
                  Сохранить
                </button>
                <button className="modal__button modal__button--cancel" onClick={handleClose}>
                  Отмена
                </button>
              </div>
            </section>
          </div>
        </div>
      </section>
      {confirmClose && (
        <ConfirmAction
          confirm={close}
          cancel={() => setConfirmClose(false)}
          actionText="Вы уверены что хотите закрыть форму, не сохранив изменения?"
        />
      )}
    </>
  );
};

export default RenameModal;

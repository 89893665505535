import React, { useState, useCallback } from 'react';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { useTranslation } from 'react-i18next';

import FormField from '../../components/form/form-field/form-field';
import ModalBottom from '../../components/modals/modal-bottom';
import PositionsHeaderAdd from '../../components/positions/PositionsHeaderAdd';
import { useEventTriggerOnEscPress } from '../../utilize/helper-functions';
import ConfirmAction from '../../components/warnings/ConfirmAction';

// настройка валидации формы
const schemaDepartment = yup.object().shape({
  title: yup.string().required(),
});

const PositionFormCreateEdit = ({ action, inputs, onCreate, onClose, prevData }) => {
  const { t } = useTranslation();

  const {
    control,
    formState: { isValid, isDirty },
    handleSubmit,
    setValue,
  } = useForm({
    resolver: yupResolver(schemaDepartment),
    mode: 'onChange',
    // значение по умолчанию ставим всегда - в зависимости от выбранного action (edit или add) ставим пустое поле или уже выбранные value
    defaultValues: {
      title: action === 'edit' ? prevData.title : '',
    },
  });

  const [modalClosePrompt, setModalClosePrompt] = useState();

  const handleClose = useCallback(() => {
    if (isDirty) {
      setModalClosePrompt(true);
    } else onClose();
  }, [onClose, isDirty, setModalClosePrompt]);

  useEventTriggerOnEscPress(handleClose);

  return (
    <>
      <PositionsHeaderAdd
        addDepartment
        title={action === 'add' ? t('Positions.create_position') : t('Positions.edit_position')}
        //   title={t("TeamHeader.depatrments")}
        onClose={onClose}
      />
      <form className="auth__form form" onSubmit={handleSubmit(onCreate)}>
        <FormField
          prev={prevData?.title}
          classValid="form__input"
          classInValid="form__input form__input--invalid"
          control={control}
          settings={inputs.title}
          name={inputs.title.name}
          action={action}
          setValue={setValue}
        />

        <ModalBottom onClose={onClose} isValid={isValid} onCreate={handleSubmit(onCreate)} action={action} />
      </form>

      {modalClosePrompt && (
        <ConfirmAction
          actionText={t('common.confirm_modal_close')}
          cancel={() => setModalClosePrompt(false)}
          confirm={onClose}
        />
      )}
    </>
  );
};

export default PositionFormCreateEdit;

import React, { useState, useEffect, useMemo, useContext, Fragment } from 'react';
import { useMatch, useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';

import { useTranslation } from 'react-i18next';

import { useDispatch } from 'react-redux';

import { useCallback } from 'react';

import useAuth from '../../hooks/useAuth';
import { files_url, url_delete_emplyee, url_get_emplyee_info } from '../../settings/base-url';
import EmployeesForm from '../../components/employees/employees-form/EmployeesForm';
// import Team from "../../pages/team/team";
// import TeamTree from "../../pages/team/team-tree/TeamTree";
import Preloader from '../../components/preloaders/Preloader';
// import WindowFire from "../employees/WindowFire";
import { assignIconLink, useEventTriggerOnEscPress } from '../../utilize/helper-functions';
import ConfirmAction from '../../components/warnings/ConfirmAction';

import { getUsers } from '../../redux/features/usersSlice';
import AttachedFile from '../../components/files/AttachedFile';

import UserStatus from '../../components/employees/UserStatus';
import SnackbarContext from '../../contexts/SnackbarContext';
import { errorTypes, processResponseErrors } from '../../utilize/processResponseErrors';

// шапка модалки - уволенб в отпуске и тд
// const StatusEmployee = () => {
//   return (
//     <>
//       {/* <section className="status-header status-header--fired">
//         <div className="status-header__container">
//           <div className="status-header__info">
//             <p className="status-header__text">Уволен</p>
//           </div>
//           <div className="status-header__button-box">
//             <button className="status-header__button">Отменить</button>
//             <button className="status-header__button">&#43;</button>
//           </div>
//         </div>
//       </section> */}
//       {/* <section className="status-header status-header--info">
//       <div className="status-header__wrapper">
//         <div className="status-header__info-box">
//           <p className="status-header__title">Причина увольнения</p>
//           <p className="status-header__text">По собственному желанию</p>
//         </div>
//         <div className="status-header__info-box">
//           <p className="status-header__title">Комментарий</p>
//           <p className="status-header__text">Ла ла ла ла ла ла</p>
//         </div>
//       </div>
//     </section>
//     <section className="status-header status-header--leaving">
//       <div className="status-header__container">
//         <div className="status-header__info">
//           <p className="status-header__text">Увольняется с 12.12.2021</p>
//         </div>
//         <div className="status-header__button-box">
//           <button className="status-header__button">Изменить</button>
//           <button className="status-header__button">Отменить</button>
//           <button className="status-header__button">&#43;</button>
//         </div>
//       </div>
//     </section> */}
//       {/* <section className="status-header status-header--go-vacation">
//       <div className="status-header__container">
//         <div className="status-header__info">
//           <p className="status-header__text">Собирается в отпуск</p>
//           <p className="status-header__text">12.12.2021 - 12.01.2022</p>
//         </div>
//         <div className="status-header__button-box">
//           <button className="status-header__button">
//             Отправить сообщение
//           </button>
//           <button className="status-header__button">Подтвердить</button>
//           <button className="status-header__button">Отменить</button>
//           <button className="status-header__button">&#43;</button>
//         </div>
//       </div>
//     </section> */}
//       <section className="status-header status-header--on-vacation">
//         <div className="status-header__container">
//           <div className="status-header__info">
//             <p className="status-header__text">Идет в отпуск</p>
//             <p className="status-header__text">12.12.2021 - 12.01.2022</p>
//           </div>
//           <div className="status-header__button-box">
//             {/* <button className="status-header__button" value="Отменить" /> */}
//             <button className="status-header__button">Изменить</button>
//             <button className="status-header__button">Отменить</button>
//           </div>
//         </div>
//       </section>
//     </>
//   );
// };

const UserProfileModal = ({ profileId, onClose }) => {
  const { t } = useTranslation();
  const translateKey = 'common.rights'; // для перевода
  const navigate = useNavigate();
  let location = useLocation();
  const auth = useAuth();

  const userHasRight = useMemo(() => auth?.isUserRightful(auth?.rightTypes.salary_view), [auth]);

  const routeMatchDepartments = useMatch('/team/departments/:id');
  const routeMatchUsers = useMatch('/team/users/:id');
  // получение id автоматически с двух url - с  team departments и users
  const userId = useMemo(() => {
    if (profileId) return profileId;
    return routeMatchDepartments?.params.id || routeMatchUsers?.params.id;
  }, [routeMatchDepartments, routeMatchUsers, profileId]);

  // статус модалки для редактирования
  const [modalEdit, setModalEdit] = useState(false);
  // подтверждаем удаление сотрудника
  const [confirmRemove, setConfirmRemove] = useState(false);

  // const headers = useMemo(
  //   () => ({
  //     Accept: "application/json",
  //     "Content-Type": "application/json",
  //     Authorization: auth.token,
  //     employee_id: userId,
  //   }),
  //   [auth, userId]
  // );

  const [employeeData, setEmployeeData] = useState(null);

  // при закрытии модалки отправляем на пред страницу
  const handlerCloseModal = () => {
    if (onClose) return onClose();
    let from = location.state?.backgroundLocation?.pathname || '/';
    navigate(from, { replace: true });
  };

  // инфа для построения модалки профиля

  const [isLoading, setIsLoading] = useState(false);

  const getUserData = useCallback(() => {
    if (userId) {
      setIsLoading(true);
      axios.get(`${url_get_emplyee_info}/${userId}`).then(
        (res) => {
          setEmployeeData(res.data.employeeInfo);
          setIsLoading(false);
        },
        () => {
          setIsLoading(false);
        },
      );
    }
  }, [userId]);

  useEffect(() => {
    // socket.emit("add_to_room", "employees_list");
    // url_get_emplyee_info`/${userId}`
    getUserData();
  }, [getUserData]);

  // функция для закрытия модального окна, при нажатии на кнопку Esc
  useEventTriggerOnEscPress(handlerCloseModal);

  const hasRightToEdit = useMemo(() => {
    return auth.isUserRightful(auth.rightTypes.team_edit);
  }, [auth]);

  const {
    first_name,
    last_name,
    date_start,
    birthday,
    contacts_phone,
    contacts_address,
    contacts_other,
    email,
    // passport_hidden,
    // passport_visible,
    status,
    position,
    department,
    head_first_name,
    head_last_name,
  } = useMemo(() => {
    if (employeeData?.employee[0]) return employeeData?.employee[0];
    return {};
  }, [employeeData]);

  const { employee_salaries, employee_rights } = useMemo(() => {
    if (employeeData) {
      return employeeData;
    }
    return {};
  }, [employeeData]);

  const handlerEdit = () => {
    setModalEdit(true);
  };

  const handlerDelete = () => {
    setConfirmRemove(true);
  };

  const dispatch = useDispatch();
  const { showSnackbar } = useContext(SnackbarContext);

  const sendRemovedEmployee = () => {
    axios
      .delete(url_delete_emplyee, {
        data: { employee_id: userId },
      })
      .then(() => {
        dispatch(getUsers());
        if (onClose) onClose();
        else {
          const from = routeMatchDepartments ? '/team/departments' : '/team/';
          navigate(from, { replace: true });
        }
      })
      .catch((error) => {
        const errorType = processResponseErrors(error);
        let errorMessage = 'Аккаунт пользователя привязан к объекту.';
        if (errorType === errorTypes.REFERENCE_MESSAGES) {
          errorMessage = 'У пользователя есть отправленные сообщения.';
        } else if (errorType === errorTypes.REFERENCE_FILES) {
          errorMessage = 'К пользователю прикреплены файлы.';
        }

        setConfirmRemove(false);
        showSnackbar(`Ошибка: ${errorMessage}`, 'fail');
      });
  };

  //  удаляем лишнее, что приходит с сервера с датой (время и тд)
  const formatDate = (dateString) => {
    if (dateString) {
      return dateString.substr(0, 10).replace(/-/g, '.').split('.').reverse().join('.');
    } else {
      return '';
    }
  };

  return (
    <>
      {/* {page && page === "TeamList" ? <Team /> : <TeamTree />} */}

      <section className="modal">
        <div className="modal__wrapper">
          <div className="modal__inner">
            <section className="modal__body modal__body--no-padding">
              {/* <StatusEmployee /> */}
              {confirmRemove && (
                <ConfirmAction
                  actionText="Уверены что хотите удалить?"
                  confirm={sendRemovedEmployee}
                  cancel={() => setConfirmRemove(false)}
                />
              )}

              <div className="modal__header modal__header--padding">
                <h2 className="modal__title">Карточка работника</h2>
                {hasRightToEdit && (
                  <div className="modal__header-buttons">
                    <button
                      onClick={() => {
                        handlerEdit();
                      }}
                      className="modal__header-button"
                    >
                      Ред.
                    </button>
                    <button
                      //
                      onClick={() => {
                        handlerDelete();
                      }}
                      className="modal__header-button"
                    >
                      Удалить
                    </button>
                  </div>
                )}
                <button onClick={() => handlerCloseModal()} className="modal__close-modal"></button>
              </div>
              <section className="modal__profile profile">
                {isLoading ? (
                  <Preloader />
                ) : (
                  <>
                    <div className="modal__row modal__row--center">
                      <div className="modal__col">
                        <div className="profile__userpic">
                          <UserStatus
                            userId={userId}
                            type="profile__userpic"
                            firstName={first_name}
                            lastName={last_name}
                          />
                          {/* <img src={userPic} alt="" /> */}
                        </div>
                      </div>
                      <div className="modal__col">
                        <p className="profile__username">
                          {last_name} {first_name}
                        </p>
                        {/* <p className="profile__state person-state person-state--vacation">
                          Собирается в отпуск
                        </p> */}
                        <p className="profile__status">{(status && status) || ' Статус  не указан'}</p>
                        <div>
                          {employeeData?.employee_social.length > 0 &&
                            employeeData?.employee_social.map((social, i) => {
                              if (!social.value) return '';
                              return (
                                <div key={i}>
                                  {assignIconLink(social.type, social.value)}
                                  <span>{social.value}</span>
                                </div>
                              );
                            })}
                        </div>
                      </div>
                    </div>
                    <div className="modal__row modal__row--start">
                      <div className="modal__col">
                        <p className="profile__label">Дата рождения</p>
                        <p className="profile__value">{formatDate(birthday)}</p>
                      </div>
                      {userHasRight && (
                        <div className="modal__col">
                          <p className="profile__label">Зарплата</p>
                          {employee_salaries && <p className="profile__value">{employee_salaries[0]?.salary}</p>}
                        </div>
                      )}
                    </div>
                    <div className="modal__row modal__row--start">
                      <div className="modal__col">
                        <p className="profile__label">Должность</p>
                        <p className="profile__value">{position}</p>
                      </div>
                      <div className="modal__col">
                        <p className="profile__label">Электронная почта</p>
                        <a className="profile__value" href="mailto:alex@yandex.ru">
                          {email}
                        </a>
                      </div>
                    </div>
                    <div className="modal__row modal__row--start">
                      <div className="modal__col">
                        <p className="profile__label">Отдел</p>
                        <p className="profile__value">{department}</p>
                      </div>
                      <div className="modal__col">
                        <p className="profile__label">Телефон</p>
                        <a className="profile__value" href="tel: +79121231212">
                          {contacts_phone}
                        </a>
                      </div>
                    </div>
                    <div className="modal__row modal__row--start">
                      <div className="modal__col">
                        <p className="profile__label">Руководитель</p>
                        <a className="profile__value" href="link-to-profile">
                          {`${head_first_name || ''} ${head_last_name || ''}` || '----'}
                        </a>
                      </div>
                      <div className="modal__col">
                        <p className="profile__label">Другие контакты</p>

                        <p className="profile__value">{contacts_other || 'не указано'}</p>
                      </div>
                    </div>
                    <div className="modal__row modal__row--start">
                      <div className="modal__col">
                        <p className="profile__label">Дата выхода на работу</p>
                        <p className="profile__value">{formatDate(date_start)}</p>
                      </div>
                      <div className="modal__col">
                        <p className="profile__label">Адрес</p>
                        <p className="profile__value">{(contacts_address && contacts_address) || ''}</p>
                      </div>
                    </div>
                    <div className="modal__row modal__row--start">
                      {/* <div className="modal__col">
                        <p className="profile__label">Стаж</p>

                        <p className="profile__value">
                        </p>
                      </div> */}
                      {/* <div className="modal__col">
                        <p className="profile__label">
                          Паспорт основной (закрытое)
                        </p>
                        <p className="profile__value">{passport_hidden}</p>
                      </div> */}
                    </div>
                    <div className="modal__row modal__row--start">
                      <div className="modal__col">
                        <p className="profile__label">Доступы</p>

                        {employee_rights?.length > 0 ? (
                          <div>
                            {employee_rights.map((el, i) => {
                              return (
                                <p key={i} className="profile__value">
                                  {t(`${translateKey}.${el}`)}
                                </p>
                              );
                            })}
                          </div>
                        ) : (
                          <p className="profile__value">Базовые права</p>
                        )}
                      </div>
                      {/* <div className="modal__col">
                        <p className="profile__label">
                          Паспорт дополнительный (открытое)
                        </p>
                        <p className="profile__value">{passport_visible}</p>
                      </div> */}
                    </div>
                    <div className="modal__row modal__row--start">
                      <div className="modal__col"></div>
                      {/* <div className="modal__col">
                        <button className="modal__button modal__button--edit">
                          Редактировать личные данные
                        </button>
                      </div> */}
                    </div>
                    {Array.isArray(employeeData?.employee_files) && employeeData.employee_files.length > 0 && (
                      <div className="profile__attach attach">
                        {employeeData?.employee_files?.map((file, i) => {
                          const url = `${files_url}/employees/files/${employeeData.employee[0].id}/${file.file}?token=${auth.token}`;
                          return <AttachedFile fileUrl={url} fileName={file.file} key={i} />;
                        })}
                      </div>
                    )}
                  </>
                )}
              </section>
            </section>
          </div>
        </div>
      </section>

      {/* {confirmRemove && (
          <BeforeDeleteWarning agree={()=> {sendRemovedEmployee()} } refuse={()=> console.log(' закрываем окно удаления')} />
        )} */}

      {modalEdit && (
        // <WindowFire />
        <EmployeesForm
          employeeData={employeeData}
          onCloseModal={() => {
            setModalEdit(false);
            getUserData();
          }}
          action="edit"
        />
      )}

      {/* <WindowFire /> */}
    </>
  );
};

export default UserProfileModal;

import React, { useMemo, useContext, useRef, useState, useCallback } from 'react';

import { Modal } from '@shared/components/Modal/Modal';
import { Label } from '@shared/components/Label/Label';
import { SingleSelect } from '@shared/components/Select/Select';
import { Input } from '@shared/components/Input/Input';
import { IconButton } from '@shared/components/IconButton/IconButton';
import { StyledModalRowEnd } from '@shared/components/Modal/style';
import DateInput from '@shared/components/DateInput/DateInput';

import databaseIcon from '@assets/images/icons/database.svg';
import attachIcon from '@assets/images/icons/chat-attach.svg';
import { useDispatch, useSelector } from 'react-redux';
import useAuth from 'src/hooks/useAuth';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import SnackbarContext from 'src/contexts/SnackbarContext';

import axios from 'axios';
import { files_url, server_url } from 'src/settings/base-url';

import { ticketsApi } from 'src/redux/features/api/tickets';

import { StyledMockAvatar } from '@shared/components/Avatar/styles';

import { randomSequence } from 'src/utilize/helper-functions';

import FilesUploadManage from '@components/files/FilesUploadManage';

import ModalPortal from '@components/modals/ModalPortal';

import StorageModal from 'src/windows/storage/StorageModal';

import FileToUpload from '@components/files/FileToUpload';

import { RichTextEditor } from '@components/RichTextEditor';

import { useRichTextEditor } from '@components/RichTextEditor/useRichTextEditor';

import { ContentState, EditorState, convertFromHTML } from 'draft-js';

import { formatOptionLabel, getOptions, schema, transformFormData } from './lib';
import { FilesContainer, StyledForm } from './styles';

export const EditTicketModal = ({ ticket, executor, executionDate, onClose }) => {
  const [filesToUpload, setFilesToUpload] = useState([]);
  const [filesDeleteArr, setFilesDeleteArr] = useState([]);
  const [filesFromStorage, setFilesFromStorage] = useState([]);
  const [isFilesUploading, setIsFilesUploading] = useState();
  const [formId, setFormId] = useState(randomSequence());
  const [showStorage, setShowStorage] = useState(false);

  const { state, onChange } = useRichTextEditor();

  const removeFilesOnClose = useRef();
  const clearFileManager = useRef();

  const auth = useAuth();
  const users = useSelector((state) => state.users.employees);

  const options = useMemo(() => getOptions(users, auth.token), [users]);

  const dispatch = useDispatch();

  const { showSnackbar } = useContext(SnackbarContext);

  const customOption = {
    id: executor.id,
    name: executor.first_name,
    pic: !!executor.avatar
      ? `${files_url}/employees/avatar/${executor.id}/small_${executor.avatar}?token=${auth.token}`
      : StyledMockAvatar,
  };

  const { filesToEdit, addFilesFormDataTemplate } = useMemo(() => {
    let filesToEdit, addFilesFormDataTemplate;
    if (ticket.ticket_actions[0].ticket_action_files && auth?.token) {
      filesToEdit = ticket.ticket_actions[0].ticket_action_files.map((file) => ({
        ...file,
        name: file.file,
        src: `${files_url}/ticket_actions/files/${ticket.ticket_actions[0].id}/${file.id}/${file.file}?token=${auth.token}`,
      }));

      addFilesFormDataTemplate = new FormData();
      addFilesFormDataTemplate.append('ticket_action_id', ticket.ticket_actions[0].id);
    }
    return { filesToEdit, addFilesFormDataTemplate };
  }, [ticket, auth]);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      title: ticket.title,
      description: EditorState.createWithContent(
        ContentState.createFromBlockArray(
          convertFromHTML(ticket.description).contentBlocks,
          convertFromHTML(ticket.description).entityMap,
        ),
      ),
      date: new Date(
        executionDate.getUTCFullYear(),
        executionDate.getUTCMonth(),
        executionDate.getUTCDate(),
        executionDate.getUTCHours(),
        executionDate.getUTCMinutes(),
        executionDate.getUTCSeconds(),
      ),
      user: customOption,
    },
  });

  const addStorageFileReference = useCallback((storageFileData) => {
    setFilesFromStorage((files) => {
      const alreadyAdded = files.some((fileData) => fileData.id === storageFileData.id);
      if (alreadyAdded) return files;
      return [...files, storageFileData];
    });
    showSnackbar('Ссылка на файл из хранилища добавлена', 'success');
  }, []);

  const addFilesFromStorage = async () => {
    const filesIds = filesFromStorage.map((file) => file.id);

    await axios
      .post('/api/ticket_action_files/add_from_storage', {
        ticket_action_id: ticket.ticket_actions[0].id,
        storage_files: filesIds,
      })
      .catch(() => showSnackbar('Возникла ошибка при отправке файлов из хранилища'));
  };

  const editTicket = async (data) => {
    try {
      if (filesFromStorage.length) {
        await addFilesFromStorage();
      }

      await axios.put(`${server_url}/api/tickets/edit`, data);

      if (filesDeleteArr.length) {
        const filesRemoveReq = {
          ticket_action_id: ticket.ticket_actions[0].id,
          files: filesDeleteArr.map((f) => f.id),
          form_id: formId,
        };
        await axios.patch('/api/ticket_action_files/remove', filesRemoveReq).catch(() => {
          showSnackbar('Возникла ошибка при удалении файла');
        });
      }

      dispatch(ticketsApi.util.invalidateTags(['Tickets', 'FromMe']));

      onClose();
    } catch (error) {
      showSnackbar('Возникла ошибка при редактировании поручения');
    }
  };

  const onSubmit = async (data) => {
    editTicket(transformFormData(ticket.id, data));
  };

  const onCloseHandler = async () => {
    const onRemove = removeFilesOnClose.current;

    if (onRemove) {
      await onRemove({ ticket_action_id: ticket.ticket_actions[0].id });
    }

    onClose();
  };

  const openFileSelectPopup = () => {
    document.getElementById('upfile').click();
  };

  return (
    <>
      <Modal
        type="submit"
        modalSize="767px"
        title="Редактировать поручение"
        confirmButtonText={'Сохранить'}
        onSave={handleSubmit(onSubmit)}
        onClose={onCloseHandler}
      >
        <StyledForm>
          <div>
            <Label>Кому</Label>
            <Controller
              name="user"
              control={control}
              render={({ field }) => (
                <SingleSelect {...field} options={options} formatOptionLabel={formatOptionLabel} />
              )}
            />
          </div>

          <div>
            <Label>Название</Label>
            <Controller name="title" control={control} render={({ field }) => <Input {...field} error="false" />} />
          </div>

          <div>
            <Label>Описание</Label>
            <Controller
              name="description"
              control={control}
              defaultValue={state}
              render={({ field }) => (
                <RichTextEditor
                  {...field}
                  extraButtons={
                    <>
                      <IconButton size={14} icon={attachIcon} onClick={openFileSelectPopup}>
                        <input
                          type="file"
                          multiple
                          id="upfile"
                          style={{ display: 'none' }}
                          onChange={(e) => setFilesToUpload([...e.target.files])}
                        />
                      </IconButton>
                      <IconButton size={14} icon={databaseIcon} onClick={() => setShowStorage(true)}></IconButton>
                    </>
                  }
                />
              )}
            />

            {(!!filesToUpload?.length || !!filesFromStorage?.length || !!filesToEdit?.length) && (
              <FilesContainer>
                {filesFromStorage.map((f, i) => (
                  <FileToUpload
                    file={f}
                    progressPercent={100}
                    isUploaded
                    removeFile={() => {
                      setFilesFromStorage((files) => files.filter((file, index) => index !== i));
                    }}
                    key={i}
                    index={i}
                  />
                ))}

                <FilesUploadManage
                  formId={formId}
                  filesToUpload={filesToUpload}
                  filesToEdit={filesToEdit}
                  filesDeleteArr={filesDeleteArr}
                  setFilesDeleteArr={setFilesDeleteArr}
                  addFilesFormDataTemplate={addFilesFormDataTemplate}
                  filesUploadPath="/api/ticket_action_files/add"
                  filesDeletePath="/api/ticket_action_files/remove"
                  setIsFilesUploading={setIsFilesUploading}
                  removeFilesOnClose={removeFilesOnClose}
                  clearFileManager={clearFileManager}
                  removeById
                />
              </FilesContainer>
            )}
          </div>

          <div>
            <Label>Срок исполнения</Label>
            <StyledModalRowEnd>
              <Controller
                name="date"
                control={control}
                render={({ field }) => (
                  <DateInput
                    {...field}
                    placeholder="Дата"
                    onChange={(date) => field.onChange(date)}
                    selected={field.value}
                    timeIntervals={15}
                    timeCaption="Время"
                    timeFormat="p"
                    dateFormat="Pp"
                    showTimeSelect
                  />
                )}
              />
            </StyledModalRowEnd>
          </div>
        </StyledForm>
      </Modal>

      {showStorage && (
        <ModalPortal>
          <StorageModal
            addStorageFileReference={addStorageFileReference}
            noURLParams
            close={() => setShowStorage(false)}
            storageId={'storageId'}
          />
        </ModalPortal>
      )}
    </>
  );
};

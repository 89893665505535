// форма логина, входа и восстановления пароля
// форму логина писала отсюда https://www.youtube.com/watch?v=QHIx5SGvpEE&list=PLn5fI7sMcqd1xyjGSKhM8SroKEG82qs0W&index=10&t=778s&ab_channel=Shchepotin
// исходный код https://github.com/Shchepotin/react-tutorial-real-app/blob/b98749cd424f03bd1b6cfc1be8ef3ddb3c771660/src/providers/AuthProvider/index.jsx
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { useNavigate, useLocation, Link, useParams } from 'react-router-dom';

import * as yup from 'yup';

import { useDispatch } from 'react-redux';

import useAuth from '../../hooks/useAuth';

import { useSocketContext } from '../../contexts/SocketContext';

import { getOnlineUsers } from '../../redux/features/usersSlice';

import FormField from './form-field/form-field';
import FormSubmitButton from './form-field/form-submit-button';
import CheckEmailNotification from './check-email-notification';

const schemaForgot = yup.object().shape({
  email: yup.string().email().required(),
  identifier: yup.string().required(),
});

const schemaLogin = yup.object().shape({
  email: yup.string().email().required(),
  password: yup.string(),
  identifier: yup.string().required(),
});

const shemaRestorePassword = yup.object().shape({
  password: yup.string().required(),
  passwordConfirm: yup.string().oneOf([yup.ref('password'), null], 'Пароли должны совпадать'),
});

const Form = ({
  auth__title,
  auth__info,
  submitBtnValue,
  sendingBtnText,
  inputs,
  action,
  requestURL,
  resetPassword,
}) => {
  const [checkEmail, setCheckEmail] = useState(false);
  const auth = useAuth();
  const navigate = useNavigate();
  const { token } = useParams();
  let location = useLocation();

  const getDefValues = (items) => {
    let newObj = {};
    items.forEach((el) => {
      newObj[el.name] = '';
    });
    return newObj;
  };

  const findSchema = (typeOfForm) => {
    switch (typeOfForm) {
      case 'login':
        return schemaLogin;

      case 'forgot':
        return schemaForgot;

      case 'restorePassword':
        return shemaRestorePassword;

      default:
        return schemaLogin;
    }
  };

  const {
    control,
    formState: { isValid },
    handleSubmit,
  } = useForm({
    resolver: yupResolver(findSchema(action)),
    // action === "login" ? yupResolver(schemaLogin) : yupResolver(schemaForgot),
    mode: 'onChange',
    defaultValues: getDefValues(inputs),
  });

  // очищаем инпут при неправильном вводе
  // const clearInputs = (items) => {
  //   items.forEach((el, ind) => {
  //     resetField(el.name);
  //   });
  // };

  let from = location.state?.from?.pathname || '/';

  const [isSending, setIsSending] = useState(false);
  const [requestError, setRequestError] = useState('');

  const { socket, initSocket } = useSocketContext();
  const dispatch = useDispatch();

  const onSubmit = async (credentials) => {
    setIsSending(true);
    try {
      // setIsLoading(true);

      if (action === 'login') {
        const { data } = await requestURL(credentials);
        auth.setUser({
          name: data.first_name || '',
          surname: data.last_name || '',
          id: data.user_id,
          company_id: data.company_id,
          rights: data.rights,
          is_guest: data.is_guest,
        });
        auth.setToken(data.token);

        // обновить онлайн статус
        if (!socket || !socket.connected) {
          initSocket(data.token);
        } else {
          dispatch(getOnlineUsers());
        }

        navigate(from, { replace: true });
      } else if (action === 'forgot') {
        await requestURL(credentials);
        setCheckEmail(true);
        setIsSending(false);
      } else if (action === 'restorePassword') {
        // тут будет отправляться токен и пароль
        await requestURL({
          password: credentials.password,
          token,
        });

        // navigate(from, { replace: true });
      }
    } catch (e) {
      setIsSending(false);

      if (
        (e.response?.status === 400 && e.response?.data?.message === 'There is no such user') ||
        (e.response?.status === 401 && e.response?.data?.message === 'Password incorrect')
      ) {
        setRequestError('Идентификатор компании, логин или пароль указаны неверно');
      }
    }
  };
  return (
    <>
      {checkEmail && <CheckEmailNotification />}
      <section className="auth">
        <h1 className="auth__title">{auth__title}</h1>

        <p className="auth__info">{auth__info}</p>

        {action === 'forgot' && (
          <p className="auth__info">
            Вспомнили данные аккаунта?{' '}
            <Link to="/login" className="auth__link">
              Войти
            </Link>
          </p>
        )}

        <form className="auth__form form" onSubmit={handleSubmit(onSubmit)}>
          {inputs.map((input, i) => (
            <FormField
              key={i}
              classValid="form__input"
              classInValid="form__input form__input--invalid"
              control={control}
              settings={input}
              name={input.name}
              action={action}
              resetPassword={resetPassword}
            />
          ))}

          {requestError && <div className="form__warning-box warning-box">{requestError}</div>}

          <FormSubmitButton
            isValid={isValid}
            text={isSending ? sendingBtnText : submitBtnValue}
            isSending={isSending}
          />
        </form>
      </section>
    </>
  );
};

export default Form;

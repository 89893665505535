import { useRef, useCallback } from 'react';
import { object, string, boolean, array } from 'yup';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useState } from 'react';

import axios from 'axios';

import { useContext } from 'react';

import { useEffect } from 'react';

import ConfirmAction from '../../components/warnings/ConfirmAction';
import useAuth from '../../hooks/useAuth';

import SnackbarContext from '../../contexts/SnackbarContext';

import { useEventTriggerOnEscPress } from '../../utilize/helper-functions';
import Preloader from '../../components/preloaders/Preloader';
import ThreeDotsLoader from '../../components/preloaders/ThreeDotsLoader';

const formSchema = object({
  toEdit: boolean(),
  userExists: boolean(),
  name: string().when('userExists', {
    is: false,
    then: () => string().required('Укажите имя'),
  }),
  email: string().email().required('Укажите e-mail'),
  password: string().when(['toEdit', 'userExists'], {
    is: (toEdit, userExists) => !toEdit && !userExists,
    then: () => string().required('Укажите пароль'),
  }),
  rights: array(),
});

const CreateEditGuestModal = ({ close, guestData, projectData, dataType, toEdit, fetchData }) => {
  const auth = useAuth();
  const { showSnackbar } = useContext(SnackbarContext);
  // для валидации и изменения state без re-rendering при вводе данных
  const {
    register,
    handleSubmit,
    formState: { isDirty, isValid, errors },
    setValue,
    reset,
    control,
  } = useForm({
    resolver: yupResolver(formSchema),
    mode: 'onChange',
    defaultValues: {
      toEdit: false,
      userExists: false,
      rights: [],
    },
  });

  useEffect(() => {
    if (toEdit && guestData) {
      reset({
        name: guestData.first_name,
        email: guestData.email,
        rights: guestData.rights,
        toEdit: true,
      });
    }
  }, [toEdit, guestData, reset]);

  const [isSubmitting, setIsSubmiting] = useState();

  const [isCheckingEmail, setIsCheckingEmail] = useState(false);
  const [emailStatus, setEmailStatus] = useState('available');

  const isEmailChecked = useRef(false);

  const checkEmailAvailability = async (e) => {
    const email = e.target?.value || e;
    if (toEdit && email === guestData.email) return;
    //
    if (email && dataType && projectData) {
      setIsCheckingEmail(true);
      await axios
        .get(`/api/guests/check_email/${email}/${dataType}/${projectData.id}`)
        .then((r) => {
          isEmailChecked.current = true;
          const { result } = r.data;
          if (result.id) {
            if (result.is_guest === 0 && emailStatus !== 'employee') {
              setEmailStatus('employee');
            } else if (result.is_guest === 1 && emailStatus !== 'guest_exists') {
              setEmailStatus('guest_exists');
              setValue('userExists', true, { shouldValidate: true });
              return true;
            }
          } else if (emailStatus !== 'available') {
            setEmailStatus('available');
            return true;
          }
        })
        .catch(() => {
          showSnackbar('Возникла ошибка при проверке электронного адреса');
        })
        .finally(() => setIsCheckingEmail(false));
    }
  };

  const submitRequest = async (data) => {
    if (!toEdit && !isEmailChecked.current) {
      const isEmailAvailable = await checkEmailAvailability(data.email);
      if (!isEmailAvailable) return;
    }

    const body = {
      row: {
        first_name: data.name,
        email: data.email,
        company_id: auth?.user?.company_id,
        password: data.password,
      },
      rights: data.rights,
    };
    if (!dataType || !projectData) return;
    body[`${dataType}_id`] = projectData.id;

    if (data.password === '') delete body.row.password;
    if (toEdit) body.guest_id = guestData.id;

    setIsSubmiting(true);

    axios({
      method: toEdit ? 'put' : 'post',
      url: `/api/guests/${toEdit ? 'edit' : 'add'}`,
      data: body,
    })
      .then(() => {
        showSnackbar(toEdit ? 'Гостевой доступ обновлен' : 'Новый гостевой доступ добавлен', 'success');
        if (fetchData) fetchData();
        close();
      })
      .catch((e) => {
        if (e?.response?.data?.message === 'Email already exists') {
          showSnackbar('Данный e-mail уже используется');
        } else showSnackbar('Ошибка при обработке запроса');
        setIsSubmiting(false);
      });
  };

  // для выбора всех прав
  const allRightsRef = useRef();

  // выбрать/снять все права, при нажатии на checkbox "Отметить все"
  // const handleAllRightsSelect = (e) => {
  //   setValue(
  //     "rights",
  //     e.target.checked ? ["access_children", "vote", "write", "add_files"] : []
  //   );
  //   // const rights = getValues("rights");
  //   // for (const right in rights) rights[right] = e.target.checked;
  //   // setValue("rights", rights);
  // };

  const [modalClosePrompt, setModalClosePrompt] = useState(false);

  const handleModalClose = useCallback(() => {
    if (isDirty) {
      setModalClosePrompt(true);
    } else close();
  }, [isDirty, close]);

  useEventTriggerOnEscPress(handleModalClose);

  return (
    <>
      <section className="modal__body">
        <div className="modal__header">
          <h2 className="modal__title">Просмотр задач</h2>
          <button className="modal__close-modal" onClick={handleModalClose}></button>
        </div>
        {toEdit && !guestData ? (
          <Preloader />
        ) : (
          <>
            <div className="modal__row modal__row--start">
              <div className="modal__col">
                <label className="modal__label" htmlFor="mail">
                  Электронная почта*
                </label>

                <Controller
                  control={control}
                  name="email"
                  render={({ field }) => {
                    return (
                      <input
                        className={`modal__input ${errors['email'] ? 'form__input--invalid' : ''}`}
                        id="mail"
                        value={field.value}
                        onChange={(e) => {
                          if (isEmailChecked.current) isEmailChecked.current = false;
                          field.onChange(e.target.value);
                        }}
                        onBlur={checkEmailAvailability}
                      />
                    );
                  }}
                />
                <ThreeDotsLoader visible={isCheckingEmail} />
              </div>

              <div
                className={`modal__col 
                  ${emailStatus !== 'available' ? '_hidden' : ''}
                `}
              >
                <>
                  <label className="modal__label" htmlFor="name">
                    Имя*
                  </label>
                  <input
                    className={`modal__input ${errors['name'] ? 'form__input--invalid' : ''}`}
                    id="name"
                    placeholder=""
                    {...register('name')}
                  />
                </>
              </div>
            </div>

            <div className="modal__row">
              {emailStatus === 'employee' && (
                <div className="_invalid">Данная электронная почта принадлежит негостевому пользователю портала.</div>
              )}
              {emailStatus === 'guest_exists' && (
                <div className="_valid">
                  Гостевой пользователь с этим электронным адресом уже существуeт. <br /> Права будут обновлены у этого
                  пользователя.
                </div>
              )}

              {emailStatus === 'available' && (
                <div className={`${emailStatus === 'guest_exists' ? '_hidden' : ''}`}>
                  <label className="modal__label" htmlFor="pass">
                    {`Пароль${toEdit ? '' : '*'} `}
                  </label>
                  <input
                    type="password"
                    id="pass"
                    className={`modal__input ${errors['password'] ? 'form__input--invalid' : ''}`}
                    {...register('password')}
                  />
                </div>
              )}
            </div>

            <Controller
              control={control}
              name="rights"
              render={({ field }) => {
                const handleChange = (e) => {
                  const newRightsArr = e.target.checked ? ['access_children', 'vote', 'write', 'add_files'] : [];
                  field.onChange(newRightsArr);
                  // чтобы галочки визуально обновились, не только в state
                  setValue('rights', newRightsArr);
                };

                return (
                  <div className="modal__subtitle-wrapper">
                    <h3 className="modal__subtitle">Права доступа</h3>

                    <input
                      className="modal__checkbox"
                      id="all"
                      name="checkbox"
                      type="checkbox"
                      // value="rights"
                      ref={allRightsRef}
                      onChange={handleChange}
                    />
                    <label htmlFor="all" className="modal__label modal__label--checkbox">
                      Отметить все
                    </label>
                  </div>
                );
              }}
            />

            <div className="modal__row modal__row--start">
              <div className="modal__col">
                <input
                  className="modal__checkbox"
                  id="1"
                  name="checkbox"
                  type="checkbox"
                  value="access_children"
                  {...register('rights')}
                />
                <label htmlFor="1" className="modal__label modal__label--checkbox">
                  Доступ к дочерним задачам
                </label>
                {/* <input
              className="modal__checkbox"
              id="2"
              name="checkbox"
              type="checkbox"
              {...register("rights.incoming")}
            />
            <label htmlFor="2" className="modal__label modal__label--checkbox">
              Видит входящие данные
            </label> */}
                <input
                  className="modal__checkbox"
                  id="3"
                  name="checkbox"
                  type="checkbox"
                  value="write"
                  {...register('rights')}
                />
                <label htmlFor="3" className="modal__label modal__label--checkbox">
                  Может писать
                </label>
              </div>
              <div className="modal__col">
                <input
                  className="modal__checkbox"
                  id="4"
                  name="checkbox"
                  type="checkbox"
                  value="vote"
                  {...register('rights')}
                />

                <label htmlFor="4" className="modal__label modal__label--checkbox">
                  Может голосовать
                </label>
                <input
                  className="modal__checkbox"
                  id="5"
                  name="checkbox"
                  type="checkbox"
                  value="add_files"
                  {...register('rights')}
                />
                <label htmlFor="5" className="modal__label modal__label--checkbox">
                  Может добавлять картинки и файлы
                </label>
              </div>
            </div>
          </>
        )}
        <div className="modal__button-box">
          <button
            className="modal__button modal__button--create"
            onClick={handleSubmit(submitRequest)}
            disabled={!isValid || !isDirty || isSubmitting || emailStatus === 'employee'}
          >
            {toEdit ? 'Сохранить' : 'Добавить'}
          </button>
          <button className="modal__button modal__button--cancel" onClick={handleModalClose}>
            Отмена
          </button>
        </div>
      </section>

      {modalClosePrompt && (
        <ConfirmAction
          cancel={() => setModalClosePrompt(false)}
          confirm={close}
          actionText="Уверены что хотите закрыть окно, не сохранив изменения?"
        />
      )}
    </>
  );
};

export default CreateEditGuestModal;

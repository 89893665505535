import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { useDispatch } from 'react-redux';

import { getUsers } from '../../redux/features/usersSlice';
import { getTags } from '../../redux/features/tagsSlice';
import { server_url } from '../../settings/base-url';
import Departments from '../../windows/departments/Departments';
import AllRouters from '../../routes/routes/all-routes';
import { department_window, position_window, project_window } from '../../settings/window-types';
import Positions from '../../windows/positions/Positions';
import useAuth from '../../hooks/useAuth';
import ProjectFormCreateEdit from '../../windows/projects/project-form-create-edit/ProjectFormCreateEdit';
import { useSocketContext } from '../../contexts/SocketContext';
import SnackbarContext from '../../contexts/SnackbarContext';
import Snackbar from '../warnings/Snackbar/Snackbar';
import { getCurrentGuestRights } from '../../redux/features/projectsSlice';

// установить адрес сервера по умолчанию для http запросов
axios.defaults.baseURL = server_url;

// компонент, который передаем через пропс по проекту,  где нужно вызвать модалку из других дочерних компонентов, (из шапки сайта или списка сотрудников деревом например )
// используется для модальных окон отделов, должностей и создания проекта
const Window = (props) => {
  const { data } = props;
  const { type } = data;
  // какую модалку мы открываем, приходит от event в вызове диалогового окна внктри компонента
  return (
    <>
      {type === department_window && <Departments {...props} />}
      {type === position_window && <Positions {...props} />}
      {/* при клике на nav открываем окно создания проекта */}
      {type === project_window && <ProjectFormCreateEdit {...props} />}
    </>
  );
};

const App = () => {
  const dispatch = useDispatch();
  const auth = useAuth();
  const { socket, initSocket } = useSocketContext();
  // установить токен по умолчанию для дальнейших запросов, если юзер ранее проходил авторизацию.
  if (auth?.token) axios.defaults.headers.Authorization = auth.token;

  // получить список всех юзеров и тэгов
  useEffect(() => {
    if (auth?.token && auth?.user?.id) {
      dispatch(getUsers());
      dispatch(getTags());
    }
  }, [dispatch, auth]);

  // инициировать socket соединение
  useEffect(() => {
    if (!socket && auth?.user?.id && auth?.token) {
      initSocket(auth.token);
    }
  }, [auth, socket, initSocket]);

  //получить список гостевых прав, если юзер - гость
  useEffect(() => {
    if (auth?.user?.is_guest) {
      dispatch(getCurrentGuestRights(auth.user.id));
    }
  }, [auth?.user, dispatch]);

  const [windowData, setWindowData] = useState({
    isOpen: false,
    type: null, // проекты или отделы отображать?
  });

  // для закрытия модального окна
  const onClose = () => {
    setWindowData({ isOpen: false, type: null });
  };

  // для открытия модального окна
  const onOpen = (type) => {
    setWindowData({ isOpen: true, type: type });
  };

  // если нужно редактировать что-то из разных частей приложения
  // editable - id проекта для редактирования
  const onEdit = (type, editable) => {
    setWindowData({ isOpen: true, type: type, editable: editable });
  };

  // модалка для вызова из nav компонента и в теле страницы
  const popupWindow = <Window data={windowData} onClose={onClose} onOpen={onOpen} onEdit={onEdit} />;

  // для показа всплывающих сообщений
  const snackbarDetails = useRef();
  const snackbarRef = useRef();

  const showSnackbar = (message, type) => {
    snackbarDetails.current = { message, type };
    if (snackbarRef.current?.show) {
      snackbarRef.current.show();
    }
  };

  // делаем проверку, есть ли у нас data о пользователе ( токены и тд, иначе формы не будут работать, token null )
  return auth.isLoaded ? (
    <>
      <SnackbarContext.Provider value={{ showSnackbar }}>
        {windowData.isOpen && <Window data={windowData} onOpen={onOpen} onClose={onClose} onEdit={onEdit} />}

        <AllRouters popupWindow={popupWindow} />

        <Snackbar ref={snackbarRef} snackbarDetails={snackbarDetails} />
      </SnackbarContext.Provider>
    </>
  ) : (
    <p>Загружаем информацию ... </p>
  );
};

export default App;

import React, { useEffect } from 'react';
import { useController } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

const EmployeesTextarea = (props) => {
  const { t } = useTranslation();
  const { prev, setValue, height } = props;
  const { field, fieldState } = useController(props);
  const { label, name } = props.settings;

  // отображаем предыдущее значение поля, если мы редактируем
  useEffect(() => {
    if (prev) {
      if (name !== 'password') {
        setValue(`${name}`, prev);
      }
    }
  }, [prev, name, setValue]);

  return (
    <div className="modal__row">
      <label className="modal__label" htmlFor={name}>
        {label}
      </label>
      <input
        className={`modal__textarea modal__textarea--height ${fieldState.invalid && 'form__input--invalid'}`}
        id={name}
        rows="2"
        style={{ height: height }}
        {...field}
      />
      {fieldState.invalid && <p className="form__error">{t('ErrorMessages.required_field')}</p>}
    </div>
  );
};

export default EmployeesTextarea;

import React from 'react';
import { Controller } from 'react-hook-form';
import Select, { components } from 'react-select';
// import makeAnimated from "react-select/animated";

// надстройка стилей react-select
const colourStyles = {
  // выпадающие штуки списка
  // data - инфа о item списка - label, value
  option: (styles, { isDisabled, isFocused }) => {
    return {
      ...styles,
      backgroundColor: isFocused ? '#a1a1a163' : 'transparent',
      cursor: isDisabled ? 'not-allowed' : 'default',
    };
  },
  // поле с выбранными списками
  control: (styles, { isDisabled }) => {
    //
    return {
      ...styles,
      width: '100%',
      opacity: '1',
      // border: isFocused ? "2px solid  #1890ff" : "1px solid #d9d9d9",
      border: 'none',
      boxShadow: 'none',
      // boxShadow: isFocused ? "0 0 4px rgba(24, 144, 255, 0.5)" : 0,
      ':hover': {
        // border: isFocused ? "2px solid  #1890ff" : "1px solid #d9d9d9",
        border: 'none',
        boxShadow: 'none',
        // boxShadow: isFocused ? "0 0 4px rgba(24, 144, 255, 0.5)" : 0,
      },
      cursor: isDisabled ? 'not-allowed' : 'default',
    };
  },
  multiValue: (styles) => {
    return {
      ...styles,
      backgroundColor: 'transparent',
    };
  },
  // кнопка удаления
  multiValueRemove: (styles) => ({
    ...styles,
    color: 'gray',
    borderRadius: '100px',
    border: '1px solid gray',
    padding: '0px',
    marginTop: '6px',
    marginLeft: '8px',
    height: '12px',
    width: '12px',
    ':hover': {
      color: 'red',
      border: '1px solid red',
    },
  }),
  IndicatorsContainer: (styles) => ({
    ...styles,
    display: 'none',
  }),
  // то что внутри инпута
  multiValueLabel: (/*styles, { data }*/) => {
    // const { color } = data;
    return {
      position: 'relative',
      marginLeft: 30,
      width: '100%',
      // ":before": {
      //   position: "absolute",
      //   left: "-13px",
      //   top: "8px",
      //   backgroundColor: color || "transparent",
      //   border: color ? "2px solid red" : "none",
      //   borderRadius: 10,
      //   content: '" "',
      //   height: 8,
      //   width: 8,
      // },
    };
  },
  //   вертик полоска справа
  // indicatorSeparator: (styles)=> {
  //     return {
  //         border: "2px solid red",
  //         width: "30px",
  //         height:"30px"

  //     };
  // }
  // иконка выпадающего списка
  dropdownIndicator: () => {
    return {
      // border: "2px solid red",
      // backgroundImage: "url(../assets/images/icons/plus.svg)",
      backgroundColor: 'white',
      width: '30px',
      // height: "30px",
      borderRadius: '3px',
      // padding: "0 13px 0 0px",
      position: 'absolute',
      left: '0px',
      top: '0',
      // display: "flex"

      // marginRight: "60px"
    };
  },
  // container: (styles) => {
  //   return {
  //     border: "2px solid red",
  //     backgroundColor: "red",
  //     width: "300px",
  //     height: "30px",
  //     display: "flex",
  //     flexDirection: "column"
  //   };
  // },
  // loadingIndicator: (styles) => {
  //   return {
  //     // border: "2px solid red",
  //     backgroundColor: "#00e444",
  //     // width: "30px",
  //     // height: "30px",
  //     position: "absolute",
  //     left: "0px",
  //     top: "8px",
  //   };
  // },
};

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      {/* <div style={{ padding: "2px 0 0 2px" }} className="">
        <PlusIcon />
      </div> */}
      <button className="data__button-add" type="button"></button>
    </components.DropdownIndicator>
  );
};

const MultiValue = (props) => {
  return <components.MultiValue {...props}></components.MultiValue>;
};

// const MultiValueTags = (props) => {
//   return (
//     <components.MultiValue {...props}>
//       <span
//         className={`dropdown__tag dropdown__tag--${props.data.color || ""}`}
//         style={{
//           display: "inline-block",
//           margin: "0 5px 0 0",
//         }}
//       ></span>
//       {props.children}
//     </components.MultiValue>
//   );
// };

// const TagsOption = (props) => {
//   return (
//     <components.Option {...props}>
//       <span
//         className={`dropdown__tag dropdown__tag--${props.data.color || ""}`}
//         style={{
//           display: "inline-block",
//           margin: "0 5px 0 0",
//         }}
//       ></span>
//       {props.children}
//     </components.Option>
//   );
// };

// компонент для тегов, мультивыбора с выпадашкой и крестиком, переиспользуемый
const SelectMulty = (props) => {
  const { item, control, options } = props;
  // item - инпут и его настройки
  // const animatedComponents = makeAnimated({
  //   MultiValue: item.optionsType === "tags" ? MultiValueTags : MultiValue,
  //   DropdownIndicator,
  //   Option: TagsOption,
  // }); // при удалении анимация
  return (
    <div className="modal__row">
      {/* label необходим, иначе не работает выбор в списке */}
      <label className="modal__label">{item.label}</label>

      <Controller
        name={item.name}
        control={control}
        render={({ field }) => {
          return (
            <Select
              {...field}
              styles={colourStyles}
              closeMenuOnSelect={false}
              components={{ DropdownIndicator, MultiValue }}
              // components={animatedComponents}
              placeholder={item?.placeholder || ''}
              isMulti
              name="colors"
              isClearable={false}
              options={options} // список выпадашки. по дефолту значения устанавлиаваются в настройках формы в родителе
              // className="modal__row data red-test"
              // classNamePrefix="data__button-add"
              // defaultValue={{ label: "Select Dept", value: 0 }}
            />
          );
        }}
      />
    </div>
  );
};

export default SelectMulty;

import Select from 'react-select';

const selectStyles = () => ({
  menu: (provided) => ({
    ...provided,
  }),
  control: (provided) => ({
    ...provided,
    fontSize: '12px',
    lineHeight: '20px',
    height: '24px',
    minHeight: '20px',
    borderRadius: 0,
    // padding: "2px",
  }),
  container: (provided) => ({
    ...provided,
    height: '24px',
    marginRight: '16px',
    // borderRadius: "none",
  }),
  input: (provided) => ({
    ...provided,
    caretColor: 'transparent',
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  menuList: (provided) => ({
    ...provided,
    borderRadius: 0,
  }),
  clearIndicator: (provided) => ({
    ...provided,
    padding: 0,
    // height: "20px",
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    padding: 0,
    height: '15px',
    width: '15px',
    alignSelf: 'center',
    transition: '0.1s',
    transform: state.selectProps.menuIsOpen && 'rotate(180deg) translateY(-3px)',
    // position: "absolute",
    // right: "5px",
    // top: "2px",
  }),
  indicatorsContainer: (provided) => ({
    ...provided,
    alignSelf: 'center',
    marginTop: '-5px',
  }),
  singleValue: (provided) => ({
    ...provided,
    position: 'absolute',
    top: '2px',
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: '1px 0',
    width: '163.5px',
    display: 'inline-block',
    maxHeight: '24px',
  }),
  svg: (provided) => ({
    ...provided,
    height: '10px',
    width: '10px',
  }),
  indicatorContainer: (provided) => ({
    ...provided,
    height: '10px',
    width: '10px',
  }),
});

const formatSelectOptions = (placeholder, arr, value, value2, value3) => {
  if (!Array.isArray(arr)) {
    return {
      label: placeholder,
      value: '',
    };
  }
  // если у нас селект не в виде объекта, а как простой массив типа  options: ["Документ", "Картинка"],
  return [
    { label: placeholder, value: '' },
    ...arr.map((el) => {
      if (value !== null) {
        return {
          label: (el[`${value}`] || '') + ' ' + (el[`${value2}`] || '') + (el[`${value3}`] || ''),
          value: el.id || el.value, // если у нас в списке селектов нужно получать не id, а значение какое то
        };
      } else {
        return {
          label: el,
          value: el,
        };
      }
    }),
  ];
};

const ProjectFilterSelect = ({ valuesArr, value, value2, handleChange, selectedValue, placeholder }) => {
  return (
    <Select
      styles={selectStyles('open')}
      placeholder={placeholder}
      options={formatSelectOptions(placeholder, valuesArr, value, value2)}
      onChange={handleChange}
      value={selectedValue}
    />
  );
};

export default ProjectFilterSelect;

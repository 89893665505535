import styled from 'styled-components';

export const StyledForm = styled.form`
  display: grid;
  gap: 32px;
`;

export const FilesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 8px;
  padding: 12px;
  gap: 8px;
`;

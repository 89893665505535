import { useState, useRef, useCallback, memo } from 'react';

import { useTranslation } from 'react-i18next';

import { useMemo } from 'react';

import { CreateTicketModal } from '@components/Tickets/TicketsFromMe';

import useAuth from '../../../hooks/useAuth';
import { randomSequence, useOutsideTrigger } from '../../../utilize/helper-functions';
import ConfirmAction from '../../warnings/ConfirmAction';
import { useSocketContext } from '../../../contexts/SocketContext';
import CreateTaskModal from '../../tasks/CreateTaskModal';
import ProjectFormCreateEditBody from '../../../windows/projects/project-form-create-edit/ProjectFormCreateEditBody';
import InlineModal from '../../modals/InlineModal';

import LocalDateTime from '../../shared/LocalDateTime';

import { useMessageContext } from '../../../contexts/MessageContext';

import MessageTagsModal from './MessageTagsModal';

const MessageControlsContainer = (props) => {
  const { guestRights } = useMessageContext();

  return <MessageControls {...props} guestRights={guestRights} />;
};

const MessageControls = memo(
  ({
    projectTitle,
    date_created,
    editor_state,
    employee_id,
    fullName,
    guestRights,
    handleMessageEditClick,
    handlePinClick,
    hideProjectOptions,
    hidePin,
    messageId,
    message,
    messageTags,
  }) => {
    const auth = useAuth();
    // const guestRights = null;

    const [dotsMenu, toggleDotsMenu] = useState(false);
    const [confirmDelete, setConfirmDelete] = useState();
    const [taskOrProjectCreateModal, showTaskOrProjectCreateModal] = useState();
    const [ticketModalVisible, setTicketModalVisible] = useState(false);
    const [tagsModal, toggleTagsModal] = useState(false);
    const { t } = useTranslation();
    const { deleteMessage } = useSocketContext();
    const dotsMenuRef = useRef();
    const dotsButtonRef = useRef();

    const refsForPopup = useRef([dotsMenuRef, dotsButtonRef]);
    const closePopup = useCallback(() => toggleDotsMenu(false), []);

    useOutsideTrigger(refsForPopup.current, closePopup, dotsMenu);

    const messageBasis = useMemo(
      () => ({
        message_id: messageId,
        editor_state,
        author: fullName,
        LocalDateTime: <LocalDateTime dateTime={date_created} />,
      }),
      [messageId, editor_state, fullName, date_created],
    );

    return (
      <>
        <div className="message__col">
          <div className={`message__row ${dotsMenu ? 'active' : ''}`}>
            <div className="message__controls-wrapper">
              {!hidePin && auth?.user && !auth.user.is_guest && (
                <button className="message__controls message__controls--pin" onClick={handlePinClick}></button>
              )}
            </div>
            <div className="message__controls-wrapper">
              {/* <button className="message__controls message__controls--attach"></button> */}
            </div>
            <div className="message__controls-wrapper">
              <button
                className="message__controls message__controls--dots"
                onClick={() => {
                  if (!dotsMenu) {
                    toggleDotsMenu(true);
                  } else {
                    toggleDotsMenu(false);
                  }
                }}
                ref={dotsButtonRef}
              ></button>
              <section className={`chat-menu ${dotsMenu ? 'active' : ''}`} ref={dotsMenuRef}>
                <div>
                  {/* <p className="chat-menu__item">Подсветить</p> */}
                  {/* <p className="chat-menu__separator"></p> */}
                  {(!auth?.user?.is_guest || guestRights?.includes('write')) && (
                    <>
                      {auth?.user?.id === employee_id && (
                        <>
                          <p
                            className="chat-menu__item"
                            onClick={() => {
                              toggleDotsMenu(false);
                              handleMessageEditClick();
                            }}
                          >
                            {t('FormButtons.edit')}
                          </p>

                          <p
                            className="chat-menu__item"
                            onClick={() => {
                              setConfirmDelete(true);
                              toggleDotsMenu(false);
                            }}
                          >
                            {t('FormButtons.delete')}
                          </p>
                          <p className="chat-menu__separator"></p>
                        </>
                      )}

                      <p
                        className="chat-menu__item"
                        onClick={() => {
                          toggleTagsModal(true);
                          toggleDotsMenu(false);
                        }}
                      >
                        Поставить тэги
                      </p>
                    </>
                  )}
                  {/* 
            <p className="chat-menu__separator"></p>*/}

                  {!hideProjectOptions && auth?.user && !auth.user.is_guest && (
                    <>
                      <p
                        className="chat-menu__item"
                        onClick={() => {
                          toggleDotsMenu(false);
                          showTaskOrProjectCreateModal('task');
                        }}
                      >
                        {t('Project.based_task_create')}
                      </p>
                      <p
                        className="chat-menu__item"
                        onClick={() => {
                          toggleDotsMenu(false);
                          showTaskOrProjectCreateModal('project');
                        }}
                      >
                        {t('Project.based_project_create')}
                      </p>
                      <p
                        className="chat-menu__item"
                        onClick={() => {
                          toggleDotsMenu(false);
                          setTicketModalVisible(true);
                        }}
                      >
                        {t('Project.based_ticket_create')}
                      </p>
                    </>
                  )}
                </div>
              </section>
            </div>
          </div>
        </div>

        {confirmDelete && (
          <ConfirmAction
            actionText={t('Chat.confirm_delete')}
            cancel={() => setConfirmDelete(false)}
            confirm={() => {
              deleteMessage(messageId);
              setConfirmDelete(false);
            }}
          />
        )}

        {tagsModal && (
          <InlineModal close={() => toggleTagsModal(false)}>
            <MessageTagsModal messageTags={messageTags} messageId={messageId} />
          </InlineModal>
        )}

        {taskOrProjectCreateModal === 'task' && (
          <InlineModal close={() => showTaskOrProjectCreateModal(false)}>
            <CreateTaskModal messageBasis={messageBasis} />
          </InlineModal>
        )}

        {taskOrProjectCreateModal === 'project' && (
          <InlineModal>
            <ProjectFormCreateEditBody
              formId={randomSequence()}
              onClose={() => showTaskOrProjectCreateModal(false)}
              messageBasis={messageBasis}
            />
          </InlineModal>
        )}

        {ticketModalVisible && (
          <CreateTicketModal
            projectTitle={projectTitle}
            messageId={messageId}
            message={message}
            onClose={() => setTicketModalVisible(false)}
          />
        )}
      </>
    );
  },
);

export default MessageControlsContainer;

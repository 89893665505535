import { useEffect, useState, Fragment, useMemo } from 'react';
import axios from 'axios';

import { useContext } from 'react';

import { rightsTranslation, useEventTriggerOnEscPress } from '../../utilize/helper-functions';
import ConfirmAction from '../../components/warnings/ConfirmAction';
import Preloader from '../../components/preloaders/Preloader';
import SnackbarContext from '../../contexts/SnackbarContext';
import useAuth from '../../hooks/useAuth';

import CreateEditGuestModal from './CreateEditGuestModal';

const GuestModalCurrent = ({ toEdit, dataType, dataId, guestId, close, projectData, fetchData }) => {
  const [editGuest, setGuestEdit] = useState(toEdit);
  const [guestData, setGuestData] = useState();

  // получить данные по просматриваемому гостю
  useEffect(() => {
    if (dataType && dataId && guestId) {
      axios
        .get(`/api/guest/${dataType}/${dataId}/${guestId}`)
        .then((r) => {
          setGuestData(r.data.guest);
        })
        .catch(() => showSnackbar('Ошибка при получении данных гостя'));
    }
  }, [dataType, dataId, guestId]);

  // проверить есть ли у юзера права на изменение статуса проекта/таска
  // const userHasRights = useMemo(() => {
  //   if (!auth?.user?.id || !auth?.rightTypes) return false;
  //   if (auth?.isUserRightful()) return true;
  //   if (auth.user.id === projectData?.creator_id) return true;
  //   if (auth.user.id === projectData?.responsible_id) return true;
  //   return false;
  // }, [auth, projectData]);

  const auth = useAuth();

  const hasRightToEdit = useMemo(() => {
    if (!auth?.user?.id || !auth?.rightTypes) return false;
    if (auth?.isUserRightful(auth.rightTypes.team_edit)) return true;
    if (auth.user.id === projectData?.creator_id) return true;
    if (auth.user.id === projectData?.responsible_id) return true;
  }, [auth, projectData]);

  const [isSubmitting, setIsSubmitting] = useState();

  const [confirmModal, showConfirmModal] = useState();
  const { showSnackbar } = useContext(SnackbarContext);

  const deleteAccess = () => {
    setIsSubmitting(true);
    const requestBody = {
      guest_id: guestId,
    };

    dataType === 'project' ? (requestBody.project_id = dataId) : (requestBody.task_id = dataId);

    axios
      .delete('/api/guest_rights/delete', { data: requestBody })
      .then(() => {
        showSnackbar('Гостевые права удалены', 'success');
        if (fetchData) fetchData();
        close();
      })
      .catch(() => {
        showSnackbar('Ошибка при удалении');
      });
  };

  useEventTriggerOnEscPress(() => {
    if (!editGuest) close();
  });

  return (
    <section className="modal">
      {confirmModal && (
        <ConfirmAction
          confirm={deleteAccess}
          cancel={() => showConfirmModal(false)}
          actionText="Вы уверены, что хотите удалить этот гостевой доступ?"
        />
      )}
      <div className="modal__wrapper">
        <div className="modal__inner">
          <>
            {editGuest ? (
              <CreateEditGuestModal
                toEdit={true}
                close={close}
                guestData={guestData}
                dataType={dataType}
                projectData={projectData}
                fetchData={fetchData}
              />
            ) : (
              <section className="modal__body">
                <div className="modal__header">
                  <h2 className="modal__title">Просмотр гостевого доступа</h2>

                  {guestData && (
                    <div className="modal__header-buttons">
                      {hasRightToEdit && (
                        <button className="modal__header-button" onClick={() => showConfirmModal(true)}>
                          Удалить
                        </button>
                      )}
                    </div>
                  )}
                  <button className="modal__close-modal" onClick={close}></button>
                </div>
                {guestData ? (
                  <>
                    <div className="modal__row modal__row--start">
                      <div className="modal__col">
                        <label className="modal__label modal__label--bold">Имя</label>
                        <span>{guestData.first_name}</span>
                      </div>
                      <div className="modal__col">
                        <label className="modal__label modal__label--bold">Электронная почта</label>
                        <span>{guestData.email}</span>
                      </div>
                    </div>
                    <div className="modal__subtitle-wrapper">
                      <h3 className="modal__subtitle">Права доступа</h3>
                    </div>
                    <div className="modal__row modal__row--start">
                      <div className="modal__col">
                        {guestData.rights.map((accessType, i) => {
                          if (!rightsTranslation[accessType]) return '';
                          return (
                            <Fragment key={i}>
                              <input
                                className="modal__checkbox"
                                id={accessType}
                                name="checkbox"
                                type="checkbox"
                                disabled="disabled"
                                checked="checked"
                              />
                              <label htmlFor={accessType} className="modal__label modal__label--checkbox">
                                {rightsTranslation[accessType]}
                              </label>
                            </Fragment>
                          );
                        })}
                      </div>
                    </div>
                    <div className="modal__button-box">
                      <button
                        className="modal__button modal__button--create"
                        disabled={!hasRightToEdit || isSubmitting}
                        onClick={() => setGuestEdit(true)}
                      >
                        Редактировать
                      </button>
                      <button className="modal__button modal__button--cancel" onClick={close}>
                        Отмена
                      </button>
                    </div>
                  </>
                ) : (
                  <Preloader />
                )}
              </section>
            )}
          </>
        </div>
      </div>
    </section>
  );
};

export default GuestModalCurrent;

import axios from 'axios';
import { useEffect, useState, useContext } from 'react';
import { showLocalDateTime } from 'src/utilize/helper-functions';

import SnackbarContext from '../../../contexts/SnackbarContext';
import useAuth from '../../../hooks/useAuth';
import { files_url } from '../../../settings/base-url';
// import { formatDateWithDots } from '../../calendar-form/CalendarForm';
import ThreeDotsLoader from '../../preloaders/ThreeDotsLoader';
import './FileVersionsPopup.css';

const FileVersionsPopup = ({ storageFileId }) => {
  const [isMenuShow, setIsMenuShow] = useState(false);
  const [isLoading, setIsLoading] = useState();
  const [fileVersions, setFileVersions] = useState([]);

  const showSnackbar = useContext(SnackbarContext);
  const auth = useAuth();

  useEffect(() => {
    if (isMenuShow) {
      axios
        .get(`/api/storage_file_info/${storageFileId}`)
        .then((r) => {
          if (Array.isArray(r.data)) {
            setFileVersions(r.data.sort((a, b) => b.id - a.id));
          }
          setIsLoading(false);
        })
        .catch(() => {
          showSnackbar('Возникла ошибка при запросе данных о версиях файла');
          setIsLoading(false);
        });
    }
  }, [isMenuShow, storageFileId, showSnackbar]);

  return (
    <>
      {' '}
      {/*если файл добавлен из хранилища, то появляется кнопка с выбором версии файла*/}
      <div className="attach__version">
        <button
          className="attach__version-btn"
          onClick={() => {
            if (!isMenuShow) setIsLoading(true);
            setIsMenuShow(!isMenuShow);
          }}
        ></button>
        <div className={`attach__version-list ${isMenuShow ? 'active' : ''}`}>
          {isLoading || !auth?.token ? (
            <ThreeDotsLoader visible />
          ) : (
            <>
              <h4 className="attach__version-title">Все версии файла</h4>
              {fileVersions.map((fileVersion, i) => {
                let fileUrl;
                if (fileVersion.is_main) {
                  fileUrl = `${files_url}/storages/${fileVersion.path}?token=${auth.token}`;
                } else {
                  fileUrl = `${files_url}/storages/versions/files/${fileVersion.storage_id}/${fileVersion.id}/${fileVersion.title}?token=${auth.token}`;
                }

                return (
                  <a key={i} className="attach__version-item" href={fileUrl} download target="_blank" rel="noreferrer">
                    {/* от {formatDateWithDots(fileVersion.date_created)} */}
                    от {showLocalDateTime(fileVersion.date_created, 'DD.MM.yyyy kk:mm')}
                  </a>
                );
              })}

              {/* <a className="attach__version-item" href="ya.ru" download>
                от 20.06.2023
              </a>
              <a className="attach__version-item" href="ya.ru" download>
                от 10.06.2023
              </a> */}
            </>
          )}
        </div>
        {/*если в дальнейшем файл будет удален из хранилища, то показываем только заголовок с предупреждением*/}
        {/*<div className="attach__version-list">*/}
        {/*  <h4 className="attach__version-title">Файл был удален из хранилища</h4>*/}
        {/*</div>*/}
      </div>
    </>
  );
};

export default FileVersionsPopup;

import React from 'react';
import { Link } from 'react-router-dom';

import { formatDateWithDots } from '../../components/calendar-form/CalendarForm';

const TaskListItem = ({ task }) => {
  console.log('TASK: ', task);
  const {
    parent_task_id,
    project_id,
    id,
    title,
    date_finish,
    date_start,
    project_title,
    root_project,
    project_date_start,
    project_date_finish,
  } = task;

  //
  return (
    <li className="my-tasks__item">
      <div className="my-tasks__info">
        <Link
          to={parent_task_id || project_id ? `/projects/${root_project}/tasks/${id}` : `/projects/${id}`}
          className="my-tasks__task-link"
        >
          {title}
        </Link>
        <Link to={`/projects/${root_project}`} className="my-tasks__project-link">
          {project_title}
        </Link>

        {(project_date_start || project_date_finish) && (
          <span className="my-tasks__date">
            {project_date_start && formatDateWithDots(project_date_start)} -{' '}
            {project_date_finish && formatDateWithDots(project_date_finish)}
          </span>
        )}
      </div>
      {(date_start || date_finish) && (
        <div className="my-tasks__term term">
          {date_start && <span className="term__item"> {formatDateWithDots(date_start)}</span>} -
          {date_finish && <span className="term__item">{formatDateWithDots(date_finish)}</span>}
        </div>
      )}
    </li>
  );
};

export default TaskListItem;

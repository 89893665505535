const IndependentStorage = ({ register }) => {
  return (
    <>
      <label className="modal__label" htmlFor="description">
        Описание
      </label>
      <textarea className="modal__textarea" id="description" rows="2" {...register('description')}></textarea>
    </>
  );
};

export default IndependentStorage;

import { Button } from '@shared/components/Button/Button';
import { Container } from '@shared/components/Container';
import { styled } from 'styled-components';

export const ButtonsContainer = styled(Container)`
  justify-content: center;
  flex-wrap: wrap;
  gap: 18px 20px;
`;

export const EditButton = styled(Button)``;

import UserStatus from '../../employees/UserStatus';

const Entry = (props) => {
  const { mention, theme, searchValue, isFocused, ...parentProps } = props;
  return (
    <div {...parentProps}>
      <div className={theme?.['chat-userlist__item']}>
        <div className="chat-userlist__item">
          <UserStatus userId={mention.userId} firstName={mention.firstName} lastName={mention.lastName} />
          {/* <img className="chat-userlist__userpic" src={Avatar} alt="" /> */}
          <span className="chat-userlist__username">{mention.name}</span>
          <span className="chat-userlist__usermail">{mention.email}</span>
        </div>
      </div>
    </div>

    // <div {...parentProps}>
    //   <div className={theme?.mentionSuggestionsEntryContainer}>
    //     <div className={theme?.mentionSuggestionsEntryContainerLeft}>
    //       <img
    //         src={Avatar}
    //         className={theme?.mentionSuggestionsEntryAvatar}
    //         alt=""
    //       />
    //     </div>

    //     <div className={theme?.mentionSuggestionsEntryContainerRight}>
    //       <div className={theme?.mentionSuggestionsEntryText}>
    //         {mention.name}
    //       </div>

    //       <div className={theme?.mentionSuggestionsEntryTitle}>
    //         {mention.title}
    //       </div>
    //     </div>
    //   </div>
    // </div>
  );
};

export default Entry;

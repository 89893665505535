import { useState, useRef } from 'react';
import Select, { components } from 'react-select';
import makeAnimated from 'react-select/animated';
import { useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <button className="data__button-add data__button-add--margin" type="button"></button>
    </components.DropdownIndicator>
  );
};

// const ValueContainer = () => {};

const MultiValue = (props) => {
  return (
    <span
      className="data__item data__item--border"
      style={{
        margin: '0 10px 0 0',
      }}
    >
      {props.children}
      <button
        className="data__button-delete"
        type="button"
        onMouseDown={(e) => {
          e.stopPropagation();
          props.removeProps.onTouchEnd();
        }}
      ></button>
    </span>
  );
};

const colorStyles = (provided) => ({
  ...provided,
  container: (styles) => ({
    ...styles,
    width: '100%',
    minWidth: '200px',
  }),
  control: (styles) => ({
    ...styles,
    borderWidth: 0,
    backgroundColor: 'transparent',
    boxShadow: 'none',
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  clearIndicator: () => ({
    display: 'none',
  }),
});

const options = [
  { label: 'Я автор', value: 'creator' },
  { label: 'Я ответственный', value: 'responsible' },
  { label: 'Я исполнитель', value: 'executor' },
  { label: 'Я участник', value: 'member' },
];

const TaskFilters = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [tagsFilter, setTagsFilter] = useState([]);

  const tagsList = useSelector((state) => state.tags.tagsList);

  const animatedComponents = makeAnimated({
    MultiValue,
    DropdownIndicator,
  });

  const reactSelectRef = useRef();

  useEffect(() => {
    const searchParamsObj = {};
    for (const [key, value] of searchParams) searchParamsObj[key] = value;

    if (searchParamsObj.relation) {
      const relationsArr = [];
      searchParamsObj.relation.split(',').forEach((value) => {
        const validOption = options.find((option) => option.value === value);
        if (validOption) relationsArr.push({ value, label: validOption.label });
      });
      const currentValue = reactSelectRef.current.getValue();
      if (relationsArr.length !== currentValue.length) {
        reactSelectRef.current.setValue(relationsArr);
      }
    }

    if (searchParamsObj.tags) {
      let selectedTagsArr = [];
      selectedTagsArr = searchParamsObj.tags.split(',').map((tagId) => +tagId);
      setTagsFilter(selectedTagsArr);
    }
  }, [searchParams]);

  //

  const handleSelectChange = (data) => {
    const newSearchParams = {};
    for (const [key, value] of searchParams) newSearchParams[key] = value;
    data.length > 0
      ? (newSearchParams.relation = data.map((option) => option.value).join())
      : delete newSearchParams.relation;
    // setTimeout для завершения анимации в react-select
    setTimeout(() => {
      setSearchParams(newSearchParams);
    }, 350);
  };

  const handleTagSelect = (tagId, isActive) => {
    const newTagsFilter = isActive ? tagsFilter.filter((id) => id !== tagId) : [...tagsFilter, tagId];

    const newSearchParams = {};
    for (const [key, value] of searchParams) newSearchParams[key] = value;

    newTagsFilter.length > 0 ? (newSearchParams.tags = newTagsFilter.join()) : delete newSearchParams.tags;

    // setTagsFilter(newTagsFilter);
    setSearchParams(newSearchParams);
  };

  return (
    <>
      <div className="my-tasks__row">
        <div className="my-tasks__data data _margin-bottom">
          <Select
            ref={reactSelectRef}
            isMulti
            components={animatedComponents}
            styles={colorStyles()}
            options={options}
            placeholder=""
            onChange={handleSelectChange}
            // value={selectedValues}
          />
        </div>
      </div>
      {tagsList?.map((tag, i) => {
        const isActive = tagsFilter.includes(tag.id) ? 'tag--active' : '';
        return (
          <button
            className={`my-tasks__tag tag tag--icon-${tag.color} ${isActive}`}
            onClick={() => handleTagSelect(tag.id, isActive)}
            key={i}
          >
            {tag.name}
          </button>
        );
      })}
    </>
  );
};

export default TaskFilters;

import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import ConfirmAction from '../../../components/warnings/ConfirmAction';

const DepartmentItem = ({ item, onDelete, onEdit, hasRightToEdit }) => {
  const { title, id, employees_qty, last_name, first_name } = item;

  const [confirmDeleteModal, showConfirmDeleteModal] = useState();

  const { t } = useTranslation();

  return (
    <>
      {' '}
      <li className="modal-table__row">
        <div className="modal-table__wrapper">
          <span className="modal-table__col">
            <span className="modal-table__description">Отдел</span>
            {title}
          </span>
          <span className="modal-table__col modal-table__col--wide">
            <span className="modal-table__description">Руководитель</span>
            <span className="modal-table__username" href="#">
              {last_name} {first_name}
            </span>{' '}
          </span>
          <span className="modal-table__col">
            <span className="modal-table__description">Сотрудников</span>
            {employees_qty}
          </span>
        </div>
        <div className="modal-table__col modal-table__col--buttons">
          <div className="modal-table__control-box">
            {hasRightToEdit && (
              <>
                <button
                  onClick={() => {
                    onEdit(item);
                  }}
                  className="modal-table__control modal-table__control--edit"
                ></button>
                {employees_qty === 0 && (
                  <button
                    onClick={() => showConfirmDeleteModal(true)}
                    className="modal-table__control modal-table__control--delete"
                  ></button>
                )}
              </>
            )}
          </div>
        </div>
      </li>
      {confirmDeleteModal && (
        <ConfirmAction
          actionText={t('PromptMessages.confirm_delete')}
          cancel={() => showConfirmDeleteModal(false)}
          confirm={() => {
            onDelete(id);
            showConfirmDeleteModal(false);
          }}
        />
      )}
    </>
  );
};

export default DepartmentItem;

import { useEffect, useMemo } from 'react';
import { useRef, useState, memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, Link } from 'react-router-dom';

import ProjectFormCreateEdit from 'src/windows/projects/project-form-create-edit/ProjectFormCreateEdit';

import { useSocketContext } from '../../contexts/SocketContext';
import useAuth from '../../hooks/useAuth';
import { renderMessage } from '../../utilize/draftjs-helper-functions';
import { useOutsideTrigger } from '../../utilize/helper-functions';
import { formatDateWithDots } from '../calendar-form/CalendarForm';
import CreateEditGuestModal from '../../windows/profile/CreateEditGuestModal';

import InlineModal from '../modals/InlineModal';
import ConfirmAction from '../warnings/ConfirmAction';

import CreateTaskModal from '../tasks/CreateTaskModal';

// цвет и русский текст статусов проектов
const status = {
  in_progress: {
    color: 'subtask__controls--process',
    text: (taskId) => {
      return taskId ? 'Задача начата' : 'Проект начат';
    },
  },
  finished: {
    color: 'subtask__controls--finish',
    text: (taskId) => {
      return taskId ? 'Задача завершена' : 'Проект завершен';
    },
  },
};

const SingleProjectHeader = ({ projectData, getProjectData, showSubtaskAddModal, pinnedMessagesList }) => {
  const { t } = useTranslation();
  const { projectId, taskId } = useParams();
  const auth = useAuth();
  const { socket, addToRoom, leaveRoom, startTask, finishTask, unfinishTask, onTaskStatusChange, addToFavorites } =
    useSocketContext();
  const [editModal, setEditModal] = useState();

  // войти в socket комнату для отслеживвания статуса проекта/таска
  useEffect(() => {
    if (socket?.connected) {
      addToRoom('project_task_status_changed');

      return () => leaveRoom('project_task_status_changed');
    }
  }, [addToRoom, leaveRoom, socket?.connected]);

  // проверить есть ли у юзера права на изменение статуса проекта/таска
  const userHasRights = useMemo(() => {
    if (!auth?.user?.id || !auth?.rightTypes) return false;
    if (auth?.isUserRightful()) return true;
    if (auth.user.id === projectData?.creator_id) return true;
    if (auth.user.id === projectData?.responsible_id) return true;
    if (auth.user.id === projectData?.executor_id) return true;
    return false;
  }, [auth, projectData]);

  const [optionsMenu, toggleOptionsMenu] = useState(false);
  const [unfinishButton, showUnfinishButton] = useState(false);

  // для показа окна создания гостевого доступа
  const [guestCreateModal, showGuestCreateModal] = useState(false);

  const clearOptionsMenu = useCallback(() => {
    toggleOptionsMenu(false);
    showUnfinishButton(false);
  }, []);

  // для закрытия всплывающих меню при нажатии вне этих меню
  const optionsBtnRef = useRef();
  const optionsMenuRef = useRef();
  const unfinishBtnRef = useRef();
  const projectStatusRef = useRef();
  const refsArray = useRef([optionsMenuRef, optionsBtnRef, unfinishBtnRef, projectStatusRef]);

  useOutsideTrigger(refsArray.current, clearOptionsMenu, optionsMenu || unfinishButton);

  // отправлять socket события на сервер при изменении статуса проекта текущим юзером
  const manageTaskStatus = (action) => {
    const data = {};
    taskId ? (data.task_id = projectData.id) : (data.project_id = projectData.id);

    if (action === 'start') startTask(data);
    else if (action === 'finish') finishTask(data);
    else if (action === 'unfinish') unfinishTask(data);
  };

  // статус проекта
  const [projectStatus, setProjectStatus] = useState(() => {
    if (projectData) {
      return projectData.status;
    }
    return null;
  });

  // socket callback для отслеживания изменений статуса проекта
  const statusChangeCallback = useCallback(
    (data) => {
      if ((taskId && projectData?.id === data.task_id) || (!taskId && projectData.id === data.project_id)) {
        setProjectStatus(data.status);
      }
    },
    [taskId, projectData?.id],
  );

  // слушать socket событие об изменении статуса проекта
  useEffect(() => {
    onTaskStatusChange(statusChangeCallback);
  }, [onTaskStatusChange, statusChangeCallback]);

  const [confirmModal, showConfirmModal] = useState(false);

  const { messageBasis, messageBasisLink } = useMemo(() => {
    const messageBasis = projectData.base_chat_message_id;
    let messageBasisLink = '';
    if (messageBasis) {
      const parentTaskId = projectData.parent_task_id;
      messageBasisLink = `/projects/${projectId}`;
      if (parentTaskId) messageBasisLink += `/tasks/${parentTaskId}`;
      messageBasisLink += `?msg=${messageBasis.id}`;
    }
    return { messageBasis, messageBasisLink };
  }, [projectData]);

  return (
    <>
      <div className="chat__sticky-header">
        <section className="subtask">
          <div className="subtask__header">
            <div className="subtask__col">
              <p className="subtask__name">{`${projectData?.title}`}</p>
              {/* <button
              className="subtask__controls subtask__controls--profile"
              onClick={() => showTaskAccess(!taskAccess)}
            ></button> */}
              <div className="subtask__controls-wrapper">
                {/* {taskAccess && (
                  <section className="task-access active">
                    <div className="task-access__header">
                      <button className="task-access__button-lock">
                        Закрыть
                      </button>
                      <p className="task-access__description">
                        Задача будет доступна только участникам
                      </p>
                    </div>
                    <div className="task-access__title-wrapper">
                      <h3 className="task-access__title">Создал</h3>
                    </div>
                    <div className="task-access__item">
                      <a href="linktoprofile" className="task-access__usercard">
                        <img
                          className="task-access__userpic"
                          src="assets/images/userpic.png"
                          alt=""
                        />
                        <div>
                          <p className="task-access__username">
                            Александр Иванов
                          </p>
                        </div>
                      </a>
                    </div>
                    <div className="task-access__title-wrapper">
                      <h3 className="task-access__title">Ответственный</h3>
                    </div>
                    <div className="task-access__item">
                      <a href="linktoprofile" className="task-access__usercard">
                        <img
                          className="task-access__userpic"
                          src="assets/images/userpic.png"
                          alt=""
                        />
                        <div>
                          <p className="task-access__username">
                            Александр Иванов
                          </p>
                        </div>
                      </a>
                      <div className="task-access__control-box">
                        <button className="task-access__control task-access__control--replace"></button>
                        <button className="task-access__control task-access__control--attach"></button>
                        <button className="task-access__control task-access__control--mail"></button>
                        <button className="task-access__control task-access__control--edit"></button>
                        <button className="task-access__control task-access__control--delete"></button>
                      </div>
                    </div>
                    <div className="task-access__title-wrapper">
                      <h3 className="task-access__title">Исполнитель</h3>
                    </div>
                    <div className="task-access__item">
                      <a href="linktoprofile" className="task-access__usercard">
                        <img
                          className="task-access__userpic"
                          src="assets/images/userpic.png"
                          alt=""
                        />
                        <div>
                          <p className="task-access__username">
                            Александр Иванов
                          </p>
                        </div>
                      </a>
                      <div className="task-access__control-box">
                        <button className="task-access__control task-access__control--edit"></button>
                      </div>
                    </div>
                    <div className="task-access__title-wrapper">
                      <h3 className="task-access__title">Участники</h3>
                      <button className="task-access__button-add"></button>
                    </div>
                    <div className="task-access__item">
                      <a href="linktoprofile" className="task-access__usercard">
                        <img
                          className="task-access__userpic"
                          src="assets/images/userpic.png"
                          alt=""
                        />
                        <div>
                          <p className="task-access__username">
                            Александр Иванов
                          </p>
                        </div>
                      </a>
                      <div className="task-access__control-box">
                        <button className="task-access__control task-access__control--delete"></button>
                      </div>
                    </div>
                    <div className="task-access__item">
                      <a href="linktoprofile" className="task-access__usercard">
                        <img
                          className="task-access__userpic"
                          src="assets/images/userpic.png"
                          alt=""
                        />
                        <div>
                          <p className="task-access__username">
                            Александр Иванов
                          </p>
                        </div>
                      </a>
                      <div className="task-access__control-box">
                        <button className="task-access__control task-access__control--delete"></button>
                      </div>
                    </div>
                    <div className="task-access__item">
                      <a href="linktoprofile" className="task-access__usercard">
                        <img
                          className="task-access__userpic"
                          src="assets/images/userpic.png"
                          alt=""
                        />
                        <div>
                          <p className="task-access__username">
                            Александр Иванов
                          </p>
                        </div>
                      </a>
                      <div className="task-access__control-box">
                        <button className="task-access__control task-access__control--delete"></button>
                      </div>
                    </div>
                    <div className="task-access__title-wrapper">
                      <h3 className="task-access__title">Гостевой доступ</h3>
                      <button className="task-access__button-add"></button>
                    </div>
                    <div className="task-access__item">
                      <a href="linktoprofile" className="task-access__usercard">
                        <img
                          className="task-access__userpic"
                          src="assets/images/userpic.png"
                          alt=""
                        />
                        <div>
                          <p className="task-access__username">
                            Александр Иванов
                          </p>
                          <p className="task-access__mail">mail@mail.ru</p>
                        </div>
                      </a>
                      <div className="task-access__control-box">
                        <button className="task-access__control task-access__control--delete"></button>
                      </div>
                    </div>
                    <div className="task-access__item">
                      <a href="linktoprofile" className="task-access__usercard">
                        <img
                          className="task-access__userpic"
                          src="assets/images/userpic.png"
                          alt=""
                        />
                        <div>
                          <p className="task-access__username">
                            Александр Иванов
                          </p>
                          <p className="task-access__mail">mail@mail.ru</p>
                        </div>
                      </a>
                      <div className="task-access__control-box">
                        <button className="task-access__control task-access__control--delete"></button>
                      </div>
                    </div>
                  </section>
                )} */}
              </div>
              {/* <span className="subtask__info subtask__info--success">
              {t("Project.input_data_ready")}
            </span> */}
              {/* <button
              className="
                        subtask__controls
                        subtask__controls--dots-white
                        subtask__controls--success
                      "
            ></button> */}
            </div>
            <div className="subtask__col">
              <div className="subtask__controls-wrapper">
                {projectStatus && status[projectStatus] && (
                  <button
                    style={{
                      cursor: 'pointer',
                      pointerEvents: projectStatus === 'finished' && userHasRights ? 'auto' : 'none',
                    }}
                    type="button"
                    className={`subtask__controls ${status[projectStatus].color}`}
                    onClick={() => {
                      showUnfinishButton(true);
                    }}
                    ref={projectStatusRef}
                  >
                    {status[projectStatus].text(taskId)}
                  </button>
                )}

                <section style={{ left: 0 }} className={`chat-menu ${unfinishButton ? 'active' : ''}`}>
                  <p
                    className="chat-menu__item"
                    onClick={() => {
                      manageTaskStatus('unfinish');
                      showUnfinishButton(false);
                    }}
                    ref={unfinishBtnRef}
                  >
                    Вернуть в работу
                  </p>
                </section>
              </div>

              {userHasRights && projectStatus === 'created' && (
                <button
                  type="button"
                  onClick={() => manageTaskStatus('start')}
                  className="subtask__controls subtask__controls--start"
                >
                  {`Начать ${taskId ? 'задачу' : 'проект'}`}
                </button>
              )}

              {(projectData?.date_start || projectData?.date_finish) && (
                <div className="subtask__term term">
                  {projectData?.date_start && (
                    <span className="term__item">{formatDateWithDots(projectData?.date_start)}</span>
                  )}
                  -
                  {projectData?.date_finish && (
                    <span className="term__item">{formatDateWithDots(projectData.date_finish)}</span>
                  )}
                </div>
              )}

              {auth?.user && !auth.user.is_guest && (
                <div className="subtask__controls-wrapper">
                  <button
                    ref={optionsBtnRef}
                    className="
                        subtask__controls
                        subtask__controls--light
                        subtask__controls--dots
                      "
                    onClick={() => toggleOptionsMenu(!optionsMenu)}
                  />

                  <section ref={optionsMenuRef} className={`chat-menu ${optionsMenu ? 'active' : ''}`}>
                    {/* <p className="chat-menu__item">Закрепить на главной</p>
                    <p className="chat-menu__item">Редактировать задачу</p>
                    <p className="chat-menu__item">
                      Смотреть историю редактирования задачи
                    </p> */}
                    {userHasRights && projectStatus && projectStatus !== 'finished' && (
                      <p className="chat-menu__item" onClick={() => setEditModal(true)}>
                        Редактировать
                      </p>
                    )}

                    <p
                      className="chat-menu__item"
                      onClick={() => {
                        const data = {};
                        taskId ? (data.task_id = taskId) : (data.project_id = projectId);
                        addToFavorites(data);
                        toggleOptionsMenu(false);
                      }}
                    >
                      Добавить в избранное
                    </p>

                    {projectStatus && projectStatus !== 'finished' && (
                      <p className="chat-menu__item" onClick={() => showSubtaskAddModal(true)}>
                        Добавить подзадачу
                      </p>
                    )}
                    {/* <p className="chat-menu__item">
                      Добавить задачу на том же уровне
                    </p> */}
                    {userHasRights && projectStatus && projectStatus !== 'finished' && (
                      <p className="chat-menu__item" onClick={() => showConfirmModal(true)}>
                        {`Завершить ${taskId ? 'задачу' : 'проект'}`}
                      </p>
                    )}
                    {userHasRights && (
                      <p className="chat-menu__item" onClick={() => showGuestCreateModal(true)}>
                        Добавить гостевой доступ
                      </p>
                    )}
                    {/* <p className="chat-menu__item">Удалить задачу</p> */}
                  </section>
                </div>
              )}
            </div>
          </div>
          {messageBasis && (
            <div className="subtask__header">
              <div>
                {t('Project.message_basis_created')}
                <Link className="message__content-link" to={messageBasisLink}>
                  {renderMessage(messageBasis.editor_state, null, true)}
                </Link>
              </div>
              <div className="subtask__col"></div>
            </div>
          )}
        </section>

        {pinnedMessagesList}
      </div>

      {/* окно подтверждения о завершении проекта */}
      {confirmModal && (
        <ConfirmAction
          confirm={() => {
            manageTaskStatus('finish');
            showConfirmModal(false);
          }}
          cancel={() => showConfirmModal(false)}
          actionText="Вы уверены, что хотите завершить проект?"
        />
      )}

      {/* окно создания гостевого доступа */}
      {guestCreateModal && (
        <InlineModal close={() => showGuestCreateModal(false)}>
          <CreateEditGuestModal projectData={projectData} dataType={taskId ? 'task' : 'project'} />
        </InlineModal>
      )}

      {/* окно редактирования */}
      {editModal && (
        <>
          {projectData?.project_id || projectData?.parent_task_id ? (
            <InlineModal close={() => setEditModal(false)}>
              <CreateTaskModal taskIdToEdit={projectData.id} getTaskData={getProjectData} />
            </InlineModal>
          ) : (
            <ProjectFormCreateEdit
              onClose={() => setEditModal(false)}
              data={{ editable: { projectId: projectData.id } }}
              getProjectData={getProjectData}
            />
          )}
        </>
      )}
    </>
  );
};

export default memo(SingleProjectHeader);

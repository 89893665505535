import React, { useEffect } from 'react';

// import { Link } from "react-router-dom";
// import TaskPagination from "./TaskPagination";
import { useState, useContext } from 'react';
import axios from 'axios';

import { useSearchParams } from 'react-router-dom';
import SnackbarContext from 'src/contexts/SnackbarContext';

import Preloader from '../../components/preloaders/Preloader';

import TaskFilters from './TaskFilters';
import TaskList from './TaskList';

const Tasks = () => {
  const [tasksList, setTasksList] = useState(null);

  const [searchParams] = useSearchParams();

  const { showSnackbar } = useContext(SnackbarContext);

  // получаем списки отделов, сотрудников для отображения selectов
  useEffect(() => {
    const filterObj = {};
    setTasksList(null);
    for (const [key, value] of searchParams) {
      switch (key) {
        case 'relation':
          value.split(',').forEach((v) => (filterObj[v] = true));
          break;

        case 'tags':
          filterObj.tags = value.split(',').map((tagId) => +tagId);
          break;

        default:
      }
    }
    axios.patch('/api/task_filter', filterObj).then(
      (res) => {
        const myTasksArr = [...res.data.projects.projects, ...res.data.tasks.tasks].sort((a, b) => {
          return new Date(a.date_created) < new Date(b.date_created);
        });
        //
        //
        setTasksList(myTasksArr);
        // setTasksList(
        //   [...res.data.tasks, ...res.data.projects].sort(
        //     (a, b) => new Date(a.date_created) < new Date(b.date_created)
        //   )
        // );
      },
      () => {
        showSnackbar('Возникла ошибка при получении списка задач');
      },
    );
  }, [searchParams]);

  return (
    <main>
      <section className="my-tasks">
        <div className="my-tasks__container container">
          <h2 className="my-tasks__title">Мои задачи</h2>

          {/* <div className="my-tasks__tabs tabs-xl">
              <Link to="#" className="tabs-xl__link tabs-xl__link--active">
                Актуальные <span className="tabs-xl__value">9</span>{" "}
              </Link>
              <Link to="#" className="tabs-xl__link">
                С проблемами <span className="tabs-xl__value">2</span>{" "}
              </Link>
              <Link to="#" className="tabs-xl__link">
                Запланирован <span className="tabs-xl__value">3</span>{" "}
              </Link>
              <Link to="#" className="tabs-xl__link">
                В работе <span className="tabs-xl__value">7</span>{" "}
              </Link>
              <Link to="#" className="tabs-xl__link">
                Завершен <span className="tabs-xl__value">7</span>{" "}
              </Link>
              <Link to="#" className="tabs-xl__link">
                Все <span className="tabs-xl__value">12</span>
              </Link>
             </div>*/}
          <TaskFilters />
          {tasksList ? <TaskList tasksList={tasksList} /> : <Preloader />}
        </div>
        {/* <TaskPagination /> */}
      </section>
    </main>
  );
};

export default Tasks;

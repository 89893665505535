import ReactSelect from 'react-select';
import styled from 'styled-components';

export const StyledSelect = styled(ReactSelect)`
  cursor: pointer;

  & .css-1nmdiq5-menu {
    display: block;
  }

  & .css-1u9des2-indicatorSeparator {
    display: none;
  }

  &.css-b62m3t-container {
    position: relative;
    outline: none;
    border: 1px solid ${({ theme }) => theme.border};
    border-radius: 2px;
    width: 100%;
    font: inherit;
    font-size: 16px;
    line-height: 24px;
    color: ${({ theme }) => theme.darkest};
  }

  & svg {
    display: none;
  }

  & .css-1hb7zxy-IndicatorsContainer {
    position: relative;
    width: 30px;

    &::after {
      border-bottom: 1px solid ${({ theme }) => theme.disabled};
      border-right: 1px solid ${({ theme }) => theme.disabled};
      content: '';
      height: 6px;
      pointer-events: none;
      position: absolute;
      right: 13px;
      top: 45%;
      transform: translateY(-50%) rotate(45deg);
      transition: all 0.3s ease-in-out;
      width: 6px;
    }
  }
`;

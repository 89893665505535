import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import LocalDateTime from 'src/components/shared/LocalDateTime';
import ReactHTMLParser from 'html-react-parser';
import UserStatus from 'src/components/employees/UserStatus';
import { useMemo } from 'react';
import { isFileNameImage } from 'src/utilize/helper-functions';
import { files_url } from 'src/settings/base-url';
import useAuth from 'src/hooks/useAuth';

const NewsBlogItem = ({ newsBlogItem, type }) => {
  const auth = useAuth();
  const creator = useSelector((state) => state.users.employees?.find((user) => user.id === newsBlogItem.creator_id));

  const imgSrc = useMemo(() => {
    if (!newsBlogItem) return;
    const imageFile = newsBlogItem.files.find((file) => isFileNameImage(file.file));
    if (!imageFile) return;

    return `${files_url}/news_blogs/files/${newsBlogItem.id}/${imageFile.file}?token=${auth.token}`;
  }, []);

  // if (newsBlogItem)
  return (
    <div className="index__post index-post">
      {imgSrc && (
        <div className="index-post__image">
          <img src={imgSrc} alt="" />
        </div>
      )}
      <div className="index__message message">
        <div className="message__col">
          <div className="message__row">
            <div className="message__info">
              {creator && (
                <UserStatus
                  userId={creator.id}
                  lastName={creator.last_name}
                  firstName={creator.first_name}
                  type="message__username--status"
                />
              )}
              <p className="message__date">
                <LocalDateTime dateTime={newsBlogItem.date_created} />
              </p>
            </div>
          </div>
          <div className="index__content">
            <Link to={`/${type}/${newsBlogItem.id}`}>
              <h3 className="blog__subtitle ">{newsBlogItem.title}</h3>
            </Link>
            {typeof newsBlogItem.description === 'string' ? ReactHTMLParser(newsBlogItem.description) : ''}
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewsBlogItem;

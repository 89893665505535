import { StyledLayout } from '@shared/components/Layout/styles';
import { Title } from '@shared/components/Title/Title';
import React, { useEffect, useMemo, useState } from 'react';

import { Button } from '@shared/components/Button/Button';

import { useSearchParams } from 'react-router-dom';

import Pagination from '@shared/components/Pagination';

import { CreateTicketModal, Tabs } from '@components/Tickets/TicketsFromMe';

import { TicketCard } from '@components/Tickets/TicketsFromMe/TicketCard';

import Preloader from '@components/preloaders/Preloader';

import { StyledSubheader, TicketsContainer } from './styles';

import { useLocalModel } from './model';

export const PageTickets = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [visible, setVisible] = useState(false);
  const toggleModal = () => setVisible((prev) => !prev);

  const { getActiveTickets, getCompletedTickets } = useLocalModel();

  const currentFilter = useMemo(() => searchParams.get('filter'), [searchParams]);
  const currentPage = useMemo(() => searchParams.get('page'), [searchParams]);

  const { data: activeTickets, isLoading: isActiveTicketsLoading } = getActiveTickets({ page: currentPage, limit: 10 });
  const { data: completedTickets, isLoading: isCompletedTicketsLoading } = getCompletedTickets({
    page: currentPage,
    limit: 10,
  });

  useEffect(() => {
    setSearchParams({ filter: currentFilter ? currentFilter : 'active', page: 1 });
  }, [currentFilter]);

  return (
    <>
      <StyledLayout>
        <Title>Поручения от меня</Title>
        <StyledSubheader>
          <Tabs searchParams={searchParams} setSearchParams={setSearchParams} />
          <Button onClick={toggleModal}>Создать поручение</Button>
        </StyledSubheader>
        {isActiveTicketsLoading && isCompletedTicketsLoading ? (
          <Preloader />
        ) : (
          <>
            <TicketsContainer>
              {activeTickets &&
                currentFilter === 'active' &&
                activeTickets.my_active_tickets.map((ticket) => {
                  return <TicketCard key={ticket.id} ticket={ticket} />;
                })}
              {completedTickets &&
                currentFilter === 'completed' &&
                completedTickets.my_completed_tickets.map((ticket) => {
                  return <TicketCard key={ticket.id} ticket={ticket} />;
                })}
            </TicketsContainer>

            {
              <Pagination
                totalPages={currentFilter === 'active' ? activeTickets?.page_count : completedTickets?.page_count}
              />
            }
          </>
        )}
      </StyledLayout>

      {visible && <CreateTicketModal onClose={toggleModal} />}
    </>
  );
};

import styled from 'styled-components';

export const StyledProfileUserpic = styled.div`
  height: 212px;
  width: 212px;
  position: relative;

  & img {
    border-radius: 50%;
    height: 100%;
    object-fit: cover;
    width: 100%;
  }

  & input {
    display: none;
  }

  @media (max-width: 578px) {
    margin: 0 auto;
  }
`;

export const StyledProfileChangeButton = styled.button`
  background-color: transparent;
  border: none;
  bottom: -32px;
  color: ${({ theme }) => theme.main};
  left: 50%;
  position: absolute;
  text-decoration: underline;
  transform: translateX(-50%);

  &:hover {
    text-decoration: none;
  }
`;

export const StyledProfileStatus = styled.textarea`
  border: 1px solid ${({ theme }) => theme.border};
  border-radius: 2px;
  color: ${({ theme }) => theme.darkest};
  font: inherit;
  outline: none;
  padding: 8px 34px 8px 12px;
  position: relative;
  width: 100%;
  resize: none;

  &:focus {
    border-color: ${({ theme }) => theme.main};
    box-shadow: ${({ theme }) => theme.shadowInput};
  }
`;

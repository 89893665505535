import { useEffect, useState, useRef, memo } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { getProjectStructure } from '../../../redux/features/projectsSlice';
import { useProjectStructureContext } from '../../../contexts/ProjectStructureContext';
import Preloader from '../../preloaders/Preloader';
import { arraysEqual } from '../../../utilize/helper-functions';

// используется рекурсивно для рендера ветвей дерева
// контейнер используется чтобы все под-компоненты лишний раз не обновлялись при
// обновлении react контекста useProjectStructure
const TaskTreeItemContainer = (props) => {
  const { selectedTask, setSelectedTask } = useProjectStructureContext();

  return <MemoizedTaskTreeItem {...props} selectedTask={selectedTask} setSelectedTask={setSelectedTask} />;
};

// обновлять компонент MemoizedTaskTreeItem, если:
// 1) id выбранной задачи не соответствовала этому компоненту и теперь соответствует
// 2) id выбранной задачи соответствовала этому компоненту и теперь не соответствует
// 3) массив подзадач обновился
const memoSettings = (prevProps, nextProps) => {
  if (
    (!prevProps.selectedTask || prevProps.selectedTask !== prevProps.id) &&
    nextProps.selectedTask &&
    nextProps.selectedTask === nextProps.id
  ) {
    return false;
  } else if (prevProps.selectedTask === prevProps.id && nextProps.selectedTask !== nextProps.id) {
    return false;
  } else if (arraysEqual(prevProps.tasks, nextProps.tasks)) return true;
};

const TaskTreeItem = ({ title, tasks, status, id, projectId, toggleParent, selectedTask, showRelevantTaskInfo }) => {
  const paragraph = useRef();
  const [subtasks, toggleSubtasks] = useState(false);
  const { taskId } = useParams();

  // если есть подзадачи, то показать стрелку рядом с этой задачей
  useEffect(() => {
    if (tasks.length) {
      paragraph.current.dataset.spoiler = true;
    }
  }, [tasks]);

  const toggleChildren = (status) => {
    toggleSubtasks(status);
    if (toggleParent) toggleParent(status);
  };

  // при первоначальной загрузке данных, если id открытой задачи соответствует элементу в дереве,
  // то раскрыть всю ветку задач в сайдбаре
  useEffect(() => {
    if (+taskId === id) {
      if (toggleParent) {
        toggleParent(true);
      }
    }
  }, [taskId, id, toggleParent]);

  // для определения одиночного и двойного клика
  const pendingClick = useRef();
  const clicked = useRef(0);

  return (
    <li className="task-list__item">
      <p
        className={`task-list__done ${subtasks ? '_active' : ''}`}
        ref={paragraph}
        onClick={(e) => {
          if (showRelevantTaskInfo) {
            // для реагирования на одиночный или двойной клик
            // при двойном клике открывается детализация задачи по текущему сайдбару
            // (работает во всех сайдбарах кроме Структура)
            clicked.current++;
            if (clicked.current >= 2) {
              showRelevantTaskInfo('task', id, title);
              clearTimeout(pendingClick.current);
              clicked.current = 0;
              return;
            }
            if (tasks.length) {
              clearTimeout(pendingClick.current);
              pendingClick.current = setTimeout(() => {
                toggleSubtasks((subtasks) => !subtasks);
                clicked.current = 0;
              }, 250);
            }
          } else if (tasks.length) {
            toggleSubtasks((subtasks) => !subtasks);
          }
        }}
        style={{
          backgroundColor: selectedTask === id ? '#e6f7ff' : '',
        }}
      >
        {title}
        <a
          href={`/projects/${projectId}/tasks/${id}`}
          target="_blank"
          rel="noreferrer"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <span className="task-list__icon-link"></span>
        </a>
        {status !== 'created' && <span className="task-list__icon-done"></span>}
      </p>

      <ul
        style={{
          transition: 'max-height 0.5s ease-in-out',
          maxHeight: subtasks ? '5000px' : '0',
          overflow: subtasks ? 'auto' : 'hidden',
          pointerEvents: subtasks ? 'auto' : 'none',
        }}
      >
        {tasks.map((task, i) => (
          <TaskTreeItemContainer
            key={i}
            {...task}
            projectId={projectId}
            toggleParent={toggleChildren}
            showRelevantTaskInfo={showRelevantTaskInfo}
          />
        ))}
      </ul>
    </li>
  );
};

// используется React memo, чтобы все ветви дерева не рендерились в лишний раз,
// чтобы обновлялась только выбранная ветвь
const MemoizedTaskTreeItem = memo(TaskTreeItem, memoSettings);

// ProjectTree используется в нескольких местах:
// в боковой панели StructureSidePanel - без возможности детализации выбранной задачи
// во всех остальных боковых панелях -  с возможностью отображения детализации по выбранной задаче
const ProjectTree = ({ update, showRelevantTaskInfo, showProjectTree }) => {
  const dispatch = useDispatch();

  // для данных по структуре проекта используется redux
  const { projectStructure, isLoadingProjectStructure, projectStructureError } = useSelector((state) => state.projects);

  const { projectId } = useParams();

  // получение данных по структуре проекта из сервера
  useEffect(() => {
    if (update && projectId) {
      dispatch(getProjectStructure({ projectId }));
    }
  }, [update, projectId, dispatch]);

  return (
    <>
      {/* показывать кнопку открытия текущей задачи, если это предусмотрено текущей боковой панелью  */}
      {showRelevantTaskInfo && (
        <button onClick={() => showProjectTree(false)} className="aside__forward _naked-btn">
          Текущая задача
        </button>
      )}
      <ul className="aside__task-list task-list" data-spoilers>
        {isLoadingProjectStructure && <Preloader />}

        {projectStructureError && <div>{projectStructureError}</div>}

        {projectStructure && (
          <>
            <li className="task-list__item">
              <p
                className="task-list__title _active"
                data-spoiler
                onDoubleClick={() => {
                  if (showRelevantTaskInfo) {
                    showRelevantTaskInfo('project', projectStructure.id, projectStructure.title);
                  }
                }}
              >
                {projectStructure.title}
                <a href={`/projects/${projectStructure.id}`} target="_blank" rel="noreferrer">
                  <span className="task-list__icon-link"></span>
                </a>
                <span className="task-list__icon-done"></span>
              </p>
              {projectStructure.tasks.length > 0 && (
                <ul>
                  {projectStructure.tasks.map((task, i) => (
                    <TaskTreeItemContainer
                      key={i}
                      {...task}
                      projectId={projectStructure.id}
                      showRelevantTaskInfo={showRelevantTaskInfo}
                    />
                  ))}
                </ul>
              )}
            </li>
          </>
        )}
      </ul>
    </>
  );
};

export default ProjectTree;

import React, { useState } from 'react';
import { Editor, RichUtils } from 'draft-js';

import 'draft-js/dist/Draft.css';
import { IconButton } from '@shared/components/IconButton/IconButton';

import boldTextIcon from '@assets/images/icons/chat-richtext.svg';

import { ButtonsWrapper, Container, HrLine, Wrapper } from './styles';
import InlineStyleControls from './ui/InlineStyleButtons';
import { StyledIconButtonWrapper } from './ui/InlineStyleButtons/styles';

export function RichTextEditor({ value, onChange, extraButtons }) {
  const [visible, setVisible] = useState(false);

  const editor = React.useRef(null);

  const focusEditor = () => {
    editor.current.focus();
  };

  const handleKeyCommand = (command) => {
    const newState = RichUtils.handleKeyCommand(value, command);
    if (newState) {
      onChange(newState);
      return 'handled';
    }
    return 'not-handled';
  };

  const toggleInlineStyle = (inlineStyle) => {
    const newState = RichUtils.toggleInlineStyle(value, inlineStyle);
    onChange(newState);
  };

  return (
    <Container>
      <Wrapper onClick={focusEditor} isControlsVisible={visible}>
        {visible && (
          <>
            <ButtonsWrapper>
              <InlineStyleControls currentInlineStyle={value.getCurrentInlineStyle()} onToggle={toggleInlineStyle} />
            </ButtonsWrapper>

            <HrLine />
          </>
        )}

        <Editor
          ref={editor}
          editorState={value}
          onChange={onChange}
          handleKeyCommand={handleKeyCommand}
          placeholder="Введите текст..."
        />
      </Wrapper>

      <StyledIconButtonWrapper>
        <IconButton
          size={14}
          icon={boldTextIcon}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();

            setVisible((prev) => !prev);
          }}
        ></IconButton>

        {extraButtons}
      </StyledIconButtonWrapper>
    </Container>
  );
}

import { useCallback, useContext, useState, useMemo, memo } from 'react';
import axios from 'axios';
import { useEffect, useRef } from 'react';

// import { useParams } from "react-router-dom";
import { useDispatch } from 'react-redux';

import SnackbarContext from '../../contexts/SnackbarContext';
import { files_url } from '../../settings/base-url';
import Preloader from '../preloaders/Preloader';

import { getStorageTree } from '../../redux/features/storageSlice';

import FileDetailsRow from './FileDetailsRow';
import FolderDetailsRow from './FolderDetailsRow';

const StorageTrash = ({ renderUserName, storageId }) => {
  // const { storageId } = useParams();

  const [isLoading, setIsLoading] = useState(true);
  const [trashData, setTrashData] = useState(null);
  const [openTrashStack, setOpenTrashStack] = useState([]);

  const { showSnackbar } = useContext(SnackbarContext);
  const isLoadingRef = useRef();

  const getTrashData = useCallback(() => {
    if (!showSnackbar || !storageId || isLoadingRef.current) return;
    // isLoadingRef.current = true;
    setIsLoading(true);
    setTrashData(null);
    axios
      .get(`/api/trash_tree/${storageId}`)
      .then((r) => {
        setTrashData(r.data.storage_tree);
        setOpenTrashStack((s) => {
          const newStack = [...s];
          newStack[0] = r.data.storage_tree;
          return newStack;
        });
      })
      .catch(() => {
        showSnackbar('Возникла ошибка при загрузке данных корзины');
      })
      .finally(() => setIsLoading(false));
  }, [showSnackbar, storageId]);

  useEffect(() => {
    getTrashData();
  }, [getTrashData]);

  const folderPath = useMemo(() => {
    if (openTrashStack.length <= 1) return null;

    let path = `${files_url}/storages/trash/folders/${storageId}/${openTrashStack[1].id}`;
    for (let i = 1; i < openTrashStack.length; i++) {
      path += `/${openTrashStack[i].title}`;
    }
    return path;
  }, [openTrashStack, storageId]);

  const removeItemInLastStackElement = ({ dataType, dataId }) => {
    setOpenTrashStack((s) => {
      const newStack = [...s];
      const lastIndex = newStack.length - 1;
      newStack[lastIndex][dataType] = newStack[lastIndex][dataType].filter((data) => data.id !== dataId);
      return newStack;
    });
  };

  const cleanTrash = () => {
    axios
      .patch('/api/clean_trash', {
        storage_id: storageId,
      })
      .then(() => {
        setOpenTrashStack([]);
        setTrashData(null);
        getTrashData();
      })
      .catch(() => {
        showSnackbar('Возникла ошибка при очистке корзины');
      });
  };

  const dispatch = useDispatch();

  const restoreAll = () => {
    axios
      .patch('/api/restore_all_from_trash', {
        storage_id: storageId,
      })
      .then(() => {
        setOpenTrashStack([]);
        setTrashData(null);
        getTrashData();
        dispatch(getStorageTree({ storageId, showSnackbar }));
      })
      .catch(() => {
        showSnackbar('Возникла ошибка при восстановлении всех файлов');
      });
  };

  return (
    <section className="storage__content">
      <section className="storage__header">
        <h2 className="storage__title storage__title--delete">Корзина</h2>
        {trashData && (
          <div className="storage__button-box">
            <button className="storage__header-button" onClick={cleanTrash}>
              Очистить
            </button>
            <button className="storage__header-button" onClick={restoreAll}>
              Восстановить все
            </button>
          </div>
        )}
      </section>
      {isLoading && <Preloader />}
      {!isLoading && openTrashStack.length > 0 && (
        <section className="storage__body">
          {openTrashStack.length > 1 && (
            <button
              className="storage__button-back"
              onClick={() => {
                setOpenTrashStack((s) => {
                  return s.slice(0, -1);
                });
              }}
            >
              На уровень выше
            </button>
          )}
          <ul className="storage__list storage-list">
            {openTrashStack.at(-1).folders.map((folder) => (
              <FolderDetailsRow
                storageId={storageId}
                key={folder.id}
                folder={folder}
                renderUserName={renderUserName}
                openTrashStack={openTrashStack}
                removeItem={removeItemInLastStackElement}
                modifiedFolderOpen={() => setOpenTrashStack((s) => [...s, folder])}
              />
            ))}
          </ul>
          <ul className="storage__list storage-list">
            {openTrashStack[openTrashStack.length - 1].files.map((fileData) => (
              <FileDetailsRow
                storageId={storageId}
                key={fileData.id}
                fileData={fileData}
                renderUserName={renderUserName}
                openTrashStack={openTrashStack}
                folderPath={folderPath}
                removeItem={removeItemInLastStackElement}
              />
            ))}
          </ul>
        </section>
      )}
    </section>
  );
};

export default memo(StorageTrash);

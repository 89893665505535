import { useSocketContext } from 'src/contexts/SocketContext';

import { useMessageContext } from '../../../contexts/MessageContext';

import Message from './Message';

const MessageContainer = (props) => {
  const { showPopover, editMessage, showUserProfile } = useMessageContext();
  const { sendResultFeedback } = useSocketContext();

  if (!props.pinnedMessageType && props.pinned) {
    return null;
  } else
    return (
      <Message
        {...props}
        showPopover={showPopover}
        editMessage={editMessage}
        sendResultFeedback={sendResultFeedback}
        showUserProfile={showUserProfile}
        pinnedMessageType={props.pinnedMessageType}
      />
    );
};

export default MessageContainer;

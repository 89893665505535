import React, { useEffect } from 'react';
import { useController } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

const classInValid = 'modal__input form__input--invalid';
const classValid = 'modal__input';

const EmployeesFormCol = (props) => {
  const { t } = useTranslation();
  const { setValue, name } = props;
  const { label, prev } = props.settings;
  const { field, fieldState } = useController(props);

  //  предыдущее значение поля, если мы редактируем
  useEffect(() => {
    if (prev) {
      setValue(`${name}`, prev);
    }
  }, [prev, name, setValue]);

  return (
    <div className="modal__col">
      <label className="modal__label" htmlFor={name}>
        {label}
      </label>
      <input className={!fieldState.error ? classValid : classInValid} id={name} {...field} />
      {fieldState.invalid && (
        <p className="form__error">
          {fieldState.error?.type === 'required' && t('ErrorMessages.required_field')}

          {fieldState.error?.type === 'email' && 'Нужен e-mail адрес'}
        </p>
      )}
    </div>
  );
};

export default EmployeesFormCol;

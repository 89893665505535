import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
// import {
//   processResponseErrors,
//   errorTypes,
// } from "../../utilize/helper-functions";

const initialState = {
  switchToOldMessages: false,
  requestedOldMessageId: null,
  oldMessages: null,
  loadingOldMessages: false,
  error: null,
};

export const getOldMessages = createAsyncThunk('oldMessages/getOldMessages', async ({ chat_id, old_message_id }) => {
  try {
    const response = await axios.get(`/api/load_chat_sided_messages/${chat_id}/${old_message_id}`);
    //
    console.log('OLD MESSAGE ID: ', old_message_id);
    console.log('🚀 ~ getOldMessages ~ response:', response.data);
    return {
      oldMessages: response.data.messages.chat_messages,
      requestedOldMessageId: old_message_id,
    };
    // await axios.get()
  } catch (e) {}
});

const oldMessagesSlice = createSlice({
  name: 'oldMessages',
  initialState,
  reducers: {
    resetOldMessagesState: () => initialState,

    addPreviousOldMessages: (state, action) => {
      if (action.payload?.length) {
        const previousMessages = action.payload.reverse();
        state.oldMessages = [...previousMessages, ...state.oldMessages];
      }
    },
    addNextOldMessages: (state, action) => {
      if (action.payload.messages?.length) {
        state.oldMessages = [...state.oldMessages, ...action.payload.messages];
      }
    },
    updateOldMessage: (state, action) => {
      if (state.oldMessages) {
        const { data, type } = action.payload;
        const foundIndex = state.oldMessages.findIndex((message) => message.id === data.id);
        if (foundIndex !== -1) {
          // state.oldMessages = null;
          const updMessages = [...state.oldMessages];
          if (type === 'pinned') {
            updMessages[foundIndex] = { ...updMessages[foundIndex], pinned: 1 };
          } else if (type === 'unpinned') {
            updMessages[foundIndex] = { ...updMessages[foundIndex], pinned: 0 };
          } else if (type === 'deleted') {
            updMessages[foundIndex] = {
              ...updMessages[foundIndex],
              deleted: 1,
            };
          } else {
            updMessages[foundIndex] = {
              ...data,
              message_likes: data.likes,
              message_files: data.files,
              message_tags: data.tags,
              goals: data.chat_goal_results,
            };
          }
          state.oldMessages = updMessages;
          // return updMessages;
        }
      }
    },
  },
  extraReducers: {
    [getOldMessages.pending]: (state, action) => {
      state.switchToOldMessages = true;
      state.loadingOldMessages = true;
      state.requestedOldMessageId = action.meta.arg.old_message_id;
    },
    [getOldMessages.fulfilled]: (state, { payload }) => {
      state.loadingOldMessages = false;
      state.error = null;
      state.oldMessages = payload.oldMessages;
    },
    [getOldMessages.rejected]: (state, { payload }) => {
      state.loadingOldMessages = false;
      state.error = payload;
    },
  },
});

export const { resetOldMessagesState, addPreviousOldMessages, addNextOldMessages, updateOldMessage } =
  oldMessagesSlice.actions;

export default oldMessagesSlice.reducer;
